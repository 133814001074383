import React, { forwardRef, useState } from "react";
import {
    AppBar,
    Box,
    ButtonBase,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Tab,
    Tabs,
    ThemeProvider,
    Typography,
    styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarTheme } from "../../../style/theme";
import { MainTemplateWrapper } from "../../templates/MainTemplateWrapper";
import { ResponsiveToolbar } from "../toolbar/ResponsiveToolbar";

const StyledTab = styled(Tab)(() => ({
    color: "#999999",
    transition: "all .15s",
    "&:hover": {
        color: "#000000",
    },
    "&.Mui-selected": {
        color: "#000000",
        fontWeight: 500,
    },
}));

const ResponsiveAppBar = forwardRef(
    (
        {
            tabItems,
            sx,
            rightElement,
            logoElement,
            onClick,
            theme,
            value,
            noMobileMenu,
            toolbarSx,
            centerElement,
            bannerElement,
        },
        ref
    ) => {
        const [drawerOpen, setDrawerOpen] = useState(false);

        const toggleDrawerOpen = () => {
            setDrawerOpen((prev) => !prev);
        };

        const leftTabItems = tabItems.filter(
            (tabItem) => tabItem.position === "left"
        );
        const rightTabItems = tabItems.filter(
            (tabItem) => tabItem.position === "right"
        );

        const menuClick = (_value) => {
            onClick(_value);
            toggleDrawerOpen();
        };

        return (
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <ThemeProvider theme={theme || AppBarTheme}>
                    <AppBar
                        ref={ref}
                        sx={{
                            ...sx,
                        }}
                    >
                        <MainTemplateWrapper>
                            <ResponsiveToolbar
                                disableGutters
                                sx={{
                                    position: "relative",
                                    justifyContent: noMobileMenu
                                        ? "space-between"
                                        : { xs: "center", md: "space-between" },
                                    ...toolbarSx,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={4}
                                    alignItems="center"
                                    sx={{
                                        height: "100%",
                                        display: noMobileMenu
                                            ? "flex"
                                            : { xs: "none", md: "flex" },
                                    }}
                                >
                                    {logoElement}
                                    <Tabs
                                        value={value || false}
                                        sx={{
                                            display: noMobileMenu
                                                ? { xs: "none", md: "flex" }
                                                : "flex",
                                        }}
                                    >
                                        {leftTabItems.map((tabItem) => (
                                            <StyledTab
                                                value={tabItem.value}
                                                key={tabItem.label}
                                                label={tabItem.label}
                                                onClick={() =>
                                                    onClick(tabItem.value)
                                                }
                                            />
                                        ))}
                                    </Tabs>
                                </Stack>
                                <Box
                                    sx={{
                                        display: noMobileMenu
                                            ? "none"
                                            : { xs: "flex", md: "none" },
                                        position: "absolute",
                                        left: 0,
                                    }}
                                >
                                    <IconButton
                                        onClick={toggleDrawerOpen}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Drawer
                                        anchor="left"
                                        open={drawerOpen}
                                        onClose={toggleDrawerOpen}
                                        sx={{
                                            display: {
                                                xs: "block",
                                                md: "none",
                                            },
                                        }}
                                        PaperProps={{
                                            sx: {
                                                p: 2,
                                            },
                                        }}
                                    >
                                        {tabItems.map((tabItem, i) => (
                                            <ButtonBase
                                                key={i}
                                                onClick={() =>
                                                    menuClick(tabItem.value)
                                                }
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Box width={160} px={2} py={1}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        textAlign="left"
                                                    >
                                                        {tabItem.label}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                            </ButtonBase>
                                        ))}
                                    </Drawer>
                                </Box>
                                <Box
                                    sx={{
                                        display: noMobileMenu
                                            ? "none"
                                            : { xs: "block", md: "none" },
                                    }}
                                >
                                    {centerElement || logoElement}
                                </Box>
                                <Box
                                    sx={{
                                        display: noMobileMenu
                                            ? "none"
                                            : { xs: "none", md: "block" },
                                    }}
                                >
                                    {centerElement}
                                </Box>
                                <Stack direction="row" alignItems="center">
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            display: noMobileMenu
                                                ? "flex"
                                                : { xs: "none", md: "flex" },
                                        }}
                                    >
                                        <Tabs
                                            value={value || false}
                                            sx={{
                                                display: noMobileMenu
                                                    ? { xs: "none", md: "flex" }
                                                    : "flex",
                                            }}
                                        >
                                            {rightTabItems.map((tabItem) => (
                                                <Tab
                                                    value={tabItem.value}
                                                    key={tabItem.label}
                                                    label={tabItem.label}
                                                    onClick={() =>
                                                        onClick(tabItem.value)
                                                    }
                                                />
                                            ))}
                                        </Tabs>
                                    </Stack>
                                    <Box
                                        sx={{
                                            position: {
                                                xs: "absolute",
                                                md: "initial",
                                            },
                                            right: { xs: 0, md: "none" },
                                        }}
                                    >
                                        {rightElement}
                                    </Box>
                                </Stack>
                            </ResponsiveToolbar>
                        </MainTemplateWrapper>
                    </AppBar>
                    {bannerElement}
                </ThemeProvider>
            </Box>
        );
    }
);

ResponsiveAppBar.defaultProps = {
    tabItems: [],
};

export default ResponsiveAppBar;
