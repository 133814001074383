import { GET, POST } from "../../../utils/api";

class LessonTemplateRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll() {
        return GET(`lesson_template/`);
    }

    findAllSimple() {
        return GET(`lesson_template_simple/`);
    }

    find({ lessonTemplateId }) {
        return GET(`lesson_template/${lessonTemplateId}/`);
    }

    folk({ classroomId, lessonTemplateId }) {
        return POST(`folk-lesson-template/${classroomId}/${lessonTemplateId}/`);
    }
}

export default new LessonTemplateRepository();
