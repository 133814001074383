import React from "react";
import {
    Box,
    ButtonBase,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    MissionNameTypeToColor,
    MissionNameTypeToColorCode,
    MissionNameTypeToInfo,
} from "../../../../common/modules/missions/mission/MissionModel";
import { MissionBook } from "../../../../common/components/book/MissionBook";
import { BookThemeType, SizeType } from "../../../../common/modules/ui/UIModel";

export const Catalog = ({ mission, onClick }) => {
    const theme = useTheme();
    const mdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

    const getLevelLabel = (order) => {
        if (order < 2) {
            return "초급";
        }

        if (order < 4) {
            return "중급";
        }

        return "고급";
    };

    return (
        <ButtonBase onClick={onClick}>
            <Box
                bgcolor="background.paper"
                borderRadius={3}
                boxShadow={5}
                p={2}
                sx={{
                    textAlign: "left",
                    transition: "all .2s",
                    cursor: "pointer",
                    opacity: 1,
                    height: {
                        xs: 289,
                        md: 394,
                    },
                    "&:hover": {
                        boxShadow: "2px 4px 16px rgb(0 0 0 / 16%)",
                    },
                }}
                boxSizing="border-box"
            >
                <Stack direction="column">
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            variant="caption"
                            color="#666666"
                            children={mission.name}
                        />
                        <Typography
                            sx={{
                                backgroundColor: "#333333",
                                borderRadius: 1,
                                py: 0.25,
                                px: 1,
                            }}
                            variant="caption"
                            fontWeight={700}
                            color="#FFDA80"
                            children={`Lv.${mission.order} ${getLevelLabel(
                                mission.order
                            )}`}
                        />
                    </Stack>
                    <Typography
                        color="#1A1A1A"
                        variant="h4"
                        fontWeight={700}
                        sx={{
                            mt: 0.5,
                        }}
                        children={mission.copy}
                    />
                    <Typography
                        sx={{
                            alignSelf: "center",
                            mt: {
                                xs: 1.5,
                                md: 3.75,
                            },
                        }}
                        color={MissionNameTypeToColorCode[mission.name]}
                        children={MissionNameTypeToInfo[mission.name]}
                        variant="h4"
                        fontWeight={700}
                    />
                    <Box alignSelf="center" mt={2}>
                        <MissionBook
                            theme={BookThemeType.Light}
                            color={MissionNameTypeToColor[mission.name]}
                            size={
                                mdBreakpoint ? SizeType.Medium : SizeType.Small
                            }
                        />
                    </Box>
                    <Typography
                        color="#666666"
                        variant="body2"
                        children={mission.description}
                        sx={{
                            mt: {
                                xs: 2,
                                md: 4.5,
                            },
                        }}
                    />
                </Stack>
            </Box>
        </ButtonBase>
    );
};
