import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { EclipseButton } from "../../../../common/components/button/EclipseButton";
import { DiscussionsFormControlLabel } from "../components/DiscussionsFormControlLabel";
import { DiscussionsCheckbox } from "../components/DiscussionCheckbox";
import { ClassroomSelector } from "../../../../common/components/menu/ClassroomSelector";
import { SearchField } from "../../../../common/components/textField/SearchField";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";

export const DiscussionsSearchBox = ({
    isTeacher,
    isMyQuestions,
    toggleIsMyQuestions,
    resolvedStatus,
    setResolvedStatus,
    isAdmin,
    searchDiscussions,
    value,
    setValue,
}) => {
    const BUTTON_ITEMS = [
        { label: "전체", value: "all" },
        { label: "해결했어요!", value: "resolved" },
        { label: "도와주세요!", value: "unresolved" },
    ];

    const handleButtonClick = (value) => {
        setResolvedStatus(value);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.default",
                boxShadow: 1,
                top: isAdmin ? 0 : 54,
                position: "fixed",
                width: isAdmin ? "calc(100vw - 290px)" : "100%",
            }}
        >
            <MainTemplateWrapper
                sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    py: { sm: 0, xs: 2.5 },
                    alignItems: "center",
                }}
            >
                {isTeacher && !isAdmin ? (
                    <ClassroomSelector
                        allowAllSelection
                        sx={{
                            width: 250,
                            mr: { sm: 2.5, xs: 0 },
                        }}
                    />
                ) : null}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Box
                        display="flex"
                        flexDirection={{ sm: "row", xs: "column" }}
                        justifyContent="space-between"
                        marginTop={2.5}
                    >
                        <Stack direction="row" spacing={0.5}>
                            {BUTTON_ITEMS.map((item) => (
                                <EclipseButton
                                    selected={resolvedStatus === item.value}
                                    onClick={() =>
                                        handleButtonClick(item.value)
                                    }
                                    key={item.value}
                                >
                                    {item.label}
                                </EclipseButton>
                            ))}
                        </Stack>
                        {!isAdmin ? (
                            <DiscussionsFormControlLabel
                                sx={{
                                    ml: 0,
                                }}
                                control={
                                    <DiscussionsCheckbox
                                        checked={isMyQuestions}
                                    />
                                }
                                label="내 질문만 보기"
                                onClick={toggleIsMyQuestions}
                            />
                        ) : null}
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginTop={1.5}
                        marginBottom={2.5}
                    >
                        <SearchField
                            value={value}
                            setValue={setValue}
                            onSubmit={searchDiscussions}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => searchDiscussions(value)}
                            sx={{
                                marginLeft: 1.25,
                            }}
                        >
                            검색
                        </Button>
                    </Box>
                </Box>
            </MainTemplateWrapper>
        </Box>
    );
};
