import React, { useEffect } from "react";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { DrawerTheme } from "../../../style/theme";
import { AdminDrawer } from "./templates/AdminDrawer";
import { NoClassTemplate } from "./templates/NoClassTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { AdminContainer } from "./AdminContainer";

export const AdminPage = observer(() => {
    const { classroomStore } = useStores();
    const { isFetching, classroom } = classroomStore;

    useEffect(() => {
        classroomStore.findAll({ isAdmin: true });
    }, []);

    if (isFetching)
        return <LoadingOverlay active text="학급 정보를 불러오는 중입니다." />;

    return (
        <Stack direction="row" height="100vh" width="100vw">
            <ThemeProvider theme={DrawerTheme}>
                <AdminDrawer />
            </ThemeProvider>
            <Box
                flexGrow={1}
                height="100%"
                width="calc(100vw - 290px)"
                bgcolor="background.default"
            >
                {classroom ? <AdminContainer /> : <NoClassTemplate />}
            </Box>
        </Stack>
    );
});
