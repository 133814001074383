import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SubmissionStatusType } from "../../../../../../../common/modules/submission/SubmissionModel";
import { StderrBox } from "../components/StderrBox";
import { CodeDialogDivider } from "../components/CodeDialogDivider";
import { CodeBox } from "./CodeBox";

export const CodeResultBox = ({ submission, codeBoxColor, sx, isFiddle }) => {
    const { result, isTest } = submission;
    const {
        status,
        compileStderr,
        executeStderr,
        failedIndex,
        correctStdout,
        actualStdout,
        userInput,
    } = result;

    return (
        <Stack direction="column">
            {status === SubmissionStatusType.CompileError ? (
                <Stack direction="column" p={2.5} sx={sx}>
                    <StderrBox>{compileStderr}</StderrBox>
                    <CodeDialogDivider />
                </Stack>
            ) : null}
            {status === SubmissionStatusType.RuntimeError ? (
                <Stack direction="column" p={2.5} sx={sx}>
                    <StderrBox>{executeStderr}</StderrBox>
                    <CodeDialogDivider />
                </Stack>
            ) : null}
            {status === SubmissionStatusType.WrongAnswer ? (
                <Stack direction="column" p={2.5} sx={sx}>
                    <CodeBox
                        backgroundColor={codeBoxColor}
                        title={
                            <Stack direction="row" alignItems="center">
                                <Typography
                                    variant="body2"
                                    letterSpacing="-0.6px"
                                    children="테스트 케이스"
                                    color="#525252"
                                />
                                <Typography
                                    variant="body2"
                                    color="#A94442"
                                    ml={0.625}
                                    backgroundColor="#F2D2D2"
                                    borderRadius={2}
                                    px={1}
                                    py={0.25}
                                    children={`${failedIndex}번`}
                                />
                            </Stack>
                        }
                        textUrl={isFiddle ? null : userInput}
                        defaultText={isFiddle ? userInput : null}
                        isInput
                        isTest={isTest}
                        submissionId={submission.id}
                    />
                    <Stack direction="row" width="100%" mt={2.5}>
                        <Box width="50%" mr={2.5}>
                            <CodeBox
                                backgroundColor={codeBoxColor}
                                title={
                                    <Typography
                                        variant="body2"
                                        letterSpacing="-0.6px"
                                        color="#525252"
                                        children="정답"
                                    />
                                }
                                textUrl={isFiddle ? null : correctStdout}
                                defaultText={isFiddle ? correctStdout : null}
                                isTest={isTest}
                                submissionId={submission.id}
                            />
                        </Box>
                        <Box width="50%">
                            <CodeBox
                                backgroundColor={codeBoxColor}
                                title={
                                    <Typography
                                        variant="body2"
                                        letterSpacing="-0.6px"
                                        color="#525252"
                                        children="나의 출력"
                                    />
                                }
                                textUrl={actualStdout}
                                isTest={isTest}
                                submissionId={submission.id}
                            />
                        </Box>
                    </Stack>
                    <CodeDialogDivider />
                </Stack>
            ) : null}
        </Stack>
    );
};
