import { makeAutoObservable } from "mobx";
import ClassroomRepository from "./ClassroomRepository";
import { ClassroomModel, SchoolModel, StudentModel } from "./ClassroomModel";

const LocalClassroomKey = "CT-OOE-CLASSROOM";

export default class ClassroomStore {
    schools = [];

    classrooms = [];

    isFetching = true;

    classroom = undefined;

    availableClassrooms = [];

    availableIsFetching = true;

    students = [];

    count = 0;

    failedStudents = [];

    student = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAllSchools() {
        const response = yield ClassroomRepository.findAllSchools();
        this.schools.replace(
            response.data.map((school) => new SchoolModel(school))
        );
    }

    *searchSchools({ name }) {
        const response = yield ClassroomRepository.searchSchools({ name });
        this.schools.replace(
            response.data.map((school) => new SchoolModel(school))
        );
    }

    *deleteClassroom() {
        yield ClassroomRepository.deleteClassroom({
            classroomId: this.classroom.id,
        });
        this.classrooms.replace(
            this.classrooms.filter(
                (classroom) => classroom.id !== this.classroom.id
            )
        );
        if (this.classrooms.length) {
            this.classroom = this.classrooms[0];
        } else {
            this.classroom = undefined;
        }
    }

    *addClassroom({ body }) {
        const response = yield ClassroomRepository.addClassroom({ body });
        const newClassroom = new ClassroomModel(response.data);
        this.classrooms.replace([newClassroom, ...this.classrooms]);
        this.classroom = newClassroom;
    }

    *changeClassroom({ body }) {
        const response = yield ClassroomRepository.changeClassroom({
            body,
            classroomId: this.classroom.id,
        });
        const newClassroom = new ClassroomModel(response.data);
        this.classrooms.replace(
            this.classrooms.map((_classroom) => {
                if (newClassroom.id === _classroom.id) {
                    return newClassroom;
                }
                return _classroom;
            })
        );
        this.classroom = newClassroom;
    }

    setClassroom(_classroom) {
        this.classroom = _classroom;
        if (_classroom) localStorage.setItem(LocalClassroomKey, _classroom.id);
    }

    *findAll({ isAdmin }) {
        this.isFetching = true;
        const response = yield ClassroomRepository.findAll({ isAdmin });
        this.classrooms.replace(
            response.data.map((classroom) => new ClassroomModel(classroom))
        );
        if (this.classrooms.length) {
            const localClassroomId = Number(
                localStorage.getItem(LocalClassroomKey)
            );
            const localClassroom = this.classrooms.find(
                (_classroom) => _classroom.id === localClassroomId
            );
            if (localClassroom) {
                this.setClassroom(localClassroom);
            } else {
                this.setClassroom(this.classrooms[0]);
            }
        } else {
            this.setClassroom(undefined);
        }
        this.isFetching = false;
    }

    *requestClassroom({ classroomId }) {
        const response = yield ClassroomRepository.requestClassroom({
            classroomId,
        });
        this.availableClassrooms = this.availableClassrooms.map((classroom) => {
            if (classroom.id === classroomId) {
                return new ClassroomModel(response.data);
            }
            return classroom;
        });
    }

    *approveStudents({ body }) {
        const response = yield ClassroomRepository.approveStudents({
            body,
            classroomId: this.classroom.id,
        });
        return response;
    }

    *findAvailableClassrooms(params) {
        this.availableIsFetching = true;
        const response = yield ClassroomRepository.findAvailableClassrooms(
            params
        );
        this.availableClassrooms.replace(
            response.data.map((classroom) => new ClassroomModel(classroom))
        );
        this.count = response.data.count;
        this.availableIsFetching = false;
    }

    _replaceStudents(response) {
        const { count, results } = response.data;
        this.count = count;
        this.students.replace(
            results.map((student) => new StudentModel(student))
        );
    }

    *findStudents({ page, pageSize, ...params }) {
        const response = yield ClassroomRepository.getStudents({
            classroomId: this.classroom.id,
            page,
            pageSize,
            ...params,
        });
        this._replaceStudents(response);
    }

    *findQueues({ page, pageSize, ...params }) {
        const response = yield ClassroomRepository.getQueues({
            classroomId: this.classroom.id,
            page,
            pageSize,
            ...params,
        });
        this._replaceStudents(response);
    }

    *addStudents({ body }) {
        const response = yield ClassroomRepository.addStudents({
            body,
            classroomId: this.classroom.id,
        });
        if (response.data) {
            this.failedStudents.replace(
                response.data.map((student) => new StudentModel(student))
            );
        }
        return response;
    }

    *removeStudents({ body }) {
        const response = yield ClassroomRepository.removeStudents({
            body,
            classroomId: this.classroom.id,
        });
        return response;
    }

    *removeQueues({ body }) {
        const response = yield ClassroomRepository.removeQueues({
            body,
            classroomId: this.classroom.id,
        });
        return response;
    }

    *findStudent({ studentId }) {
        const response = yield ClassroomRepository.findStudent({ studentId });
        this.student = new StudentModel(response.data);
        return this.student;
    }

    *updateStudent({ studentId, body }) {
        const response = yield ClassroomRepository.updateStudent({
            studentId,
            body,
        });
        const newStudent = new StudentModel(response.data);
        this.students.replace(
            this.students.map((student) => {
                if (student.id === Number(studentId)) {
                    return newStudent;
                }
                return student;
            })
        );
    }
}
