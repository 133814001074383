export const appBarThemeOption = {
    palette: {
        primary: {
            main: "#FFFFFF",
            contrastText: "#000000",
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    "& button": {
                        margin: "0 16px",
                    },
                    "& :first-of-type": {
                        marginLeft: 0,
                    },
                    "& :last-of-type": {
                        marginRight: 0,
                    },
                },
                indicator: {
                    backgroundColor: "#000000",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#000000",
                    padding: "0 10px",
                    "&.Mui-selected": {
                        color: "#00A71B",
                    },
                    minWidth: 0,
                    fontSize: 14,
                    lineHeight: "20px",
                    fontWeight: 400,
                },
            },
        },
    },
};
