import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DiscussionsContainer } from "../Discussions/DiscussionsContainer";
import { HomeworkEditPage } from "./Homework/HomeworkEditPage";
import { HomeworkDashboardPage } from "./Homework/HomeworkDashboardPage";
import { ClassroomDashboardPage } from "./Classroom/ClassroomDashboardPage";
import { ClassroomEditContainer } from "./Classroom/ClassroomEditContainer";
import { ClassroomStudentPage } from "./Classroom/ClassroomStudentPage";
import { ClassroomQueueContainer } from "./Classroom/ClassroomQueueContainer";
import { QuizDashboardPage } from "./Quiz/QuizDashboardPage";
import { HomeworkMarketPage } from "./Homework/HomeworkMarketPage";

export const AdminContainer = () => (
    <Routes>
        <Route
            path="/"
            element={<Navigate to="classroom-dashboard" replace />}
        />
        <Route path="/classroom-edit" element={<ClassroomEditContainer />} />
        <Route
            path={"/classroom-student/*"}
            element={<ClassroomStudentPage />}
        />
        <Route
            path={"/classroom-queue/*"}
            element={<ClassroomQueueContainer />}
        />
        <Route
            path="/discussion-list"
            element={<DiscussionsContainer isAdmin />}
        />
        <Route path={"/homework-edit/*"} element={<HomeworkEditPage />} />
        <Route path={"/homework-market/*"} element={<HomeworkMarketPage />} />
        <Route
            path={"/homework-dashboard/*"}
            element={<HomeworkDashboardPage />}
        />
        <Route path={"/quiz-dashboard/*"} element={<QuizDashboardPage />} />
        <Route
            path={"/classroom-dashboard/*"}
            element={<ClassroomDashboardPage />}
        />
        <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
);
