import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { PSFiddleNewContainer } from "./New/PSFiddleNewContainer";
import { Stack } from "@mui/material";
import ResponsiveAppBar from "../../../common/components/appBar/ResponsiveAppBar";
import { ResponsiveToolbar } from "../../../common/components/toolbar/ResponsiveToolbar";
import { UserComponent } from "./templates/UserComponent";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../common/modules/useStores";
import { PSFiddleListContainer } from "./List/PSFiddleListContainer";
import { PSFiddlePlaygroundPage } from "./Playground/PSFiddlePlaygroundPage";
import { AppBarLogo } from "./templates/AppBarLogo";

export const PSFiddlePage = observer(() => {
    const TAB_ITEMS = [
        { value: "new", label: "새 퀴즈 만들기", position: "left" },
        { value: "list", label: "내 퀴즈", position: "left" },
    ];

    const navigate = useNavigate();
    const { authStore } = useStores();
    const { isUserLoggedIn } = authStore;

    const handleTabClick = (_activeName) => {
        navigate(`${_activeName}`);
    };

    const activeName = TAB_ITEMS.find((tabItem) =>
        window.location.pathname.startsWith(`/psfiddle/${tabItem.value}`)
    )?.value;

    const filterTabItems = TAB_ITEMS.filter((_) => {
        return isUserLoggedIn();
    });

    return (
        <Stack width="100%" minHeight="100vh">
            <ResponsiveAppBar
                tabItems={filterTabItems}
                value={activeName}
                onClick={handleTabClick}
                rightElement={<UserComponent />}
                logoElement={<AppBarLogo redirectUrl={"/"} />}
            />
            <ResponsiveToolbar />
            <Stack flexGrow={1} sx={{ backgroundColor: "common.white" }}>
                <Routes>
                    <Route
                        path={"/"}
                        element={<Navigate to={"new"} replace />}
                    />
                    <Route path={"/new"} element={<PSFiddleNewContainer />} />
                    <Route path={"/list"} element={<PSFiddleListContainer />} />
                    <Route
                        path={"/:fiddleKey/*"}
                        element={<PSFiddlePlaygroundPage />}
                    />
                </Routes>
            </Stack>
        </Stack>
    );
});
