import React from "react";

export const MedalColor = {
    Gold: "Gold",
    Silver: "Silver",
    Bronze: "Bronze",
};

export const MedalOrder = [
    MedalColor.Gold,
    MedalColor.Silver,
    MedalColor.Bronze,
];

export const Medal = ({ color = MedalColor.Gold }) => {
    const colorMap = {
        [MedalColor.Gold]: [
            "#42526E",
            "#009D27",
            "#FFC433",
            "#705616",
            "#B69646",
        ],
        [MedalColor.Silver]: [
            "#42526E",
            "#009D27",
            "#B9D1F6",
            "#42526E",
            "#929AA9",
        ],
        [MedalColor.Bronze]: [
            "#42526E",
            "#009D27",
            "#F1B499",
            "#5D3E31",
            "#AC6D53",
        ],
    };

    const svgMap = {
        [MedalColor.Gold]: (
            <path
                d="M33.1875 28.7891H29.0273L23.7832 32.1289V36.0254L28.6465 32.9785H28.7637V50H33.1875V28.7891ZM43.627 42.5391H46.3418C46.1855 40.5273 44.5254 39.2578 41.8496 39.2578C39.1152 39.2578 37.3672 40.5273 37.377 42.5586C37.3672 44.1309 38.4023 45.166 40.4238 45.5664L42.1816 45.918C43.1777 46.123 43.627 46.4648 43.627 47.0117C43.627 47.6953 42.9238 48.1152 41.8691 48.125C40.8047 48.1152 40.1309 47.6855 39.9941 46.8555H37.084C37.3281 48.9258 39.0078 50.2051 41.8691 50.2148C44.6914 50.2051 46.5664 48.8086 46.5762 46.7188C46.5664 45.1953 45.5703 44.2773 43.5098 43.8477L41.6152 43.457C40.6094 43.252 40.2383 42.8809 40.248 42.4023C40.2383 41.7578 40.9512 41.3184 41.8887 41.3086C42.8555 41.3184 43.5 41.7969 43.627 42.5391ZM53.9395 39.3945H51.9473V36.8555H49.0566V39.3945H47.5918V41.5625H49.0566V47.1484C49.0371 49.209 50.4629 50.1465 52.5527 50.1367C53.2656 50.1465 53.7832 50.0879 54.0957 50.0391V47.7539C53.7441 47.7832 53.2363 47.8125 52.9434 47.8125C52.3574 47.793 51.9375 47.5879 51.9473 46.7383V41.5625H53.9395V39.3945Z"
                fill="#705616"
            />
        ),
        [MedalColor.Silver]: (
            <path
                d="M18.9248 50H34.1006V46.3672H25.0479V46.25L28.2705 43.1152C32.6357 39.1602 33.8076 37.1826 33.8076 34.7656C33.8076 31.0889 30.79 28.4961 26.249 28.4961C21.7812 28.4961 18.7051 31.1475 18.7197 35.293H22.9092C22.8945 33.2568 24.1982 31.9971 26.1904 32.0117C28.1387 31.9971 29.5596 33.1689 29.5596 35.0586C29.5596 36.7432 28.4902 37.915 26.5127 39.8047L18.8955 46.8066L18.9248 50ZM39.5889 43.8672C39.5889 42.4707 40.4189 41.6602 41.6201 41.6602C42.8213 41.6602 43.5244 42.4414 43.5342 43.75V50H46.4053V43.2422C46.415 40.7715 44.9697 39.2578 42.7529 39.2578C41.1807 39.2578 40.0479 40.0293 39.5693 41.25H39.4521V39.3945H36.6982V50H39.5889V43.8672ZM52.5576 50.1758C54.2861 50.1758 55.165 49.1797 55.5654 48.3008H55.7021V50H58.5537V35.8594H55.6631V41.1719H55.5654C55.1846 40.3125 54.335 39.2578 52.5576 39.2578C50.2139 39.2578 48.2412 41.0742 48.2412 44.707C48.2412 48.252 50.1357 50.1758 52.5576 50.1758ZM51.21 44.6875C51.2002 42.8418 51.9814 41.5625 53.4561 41.5625C54.9111 41.5625 55.7217 42.7832 55.7217 44.6875C55.7217 46.6016 54.9014 47.8613 53.4561 47.8711C51.9912 47.8613 51.2002 46.5625 51.21 44.6875Z"
                fill="#42526E"
            />
        ),
        [MedalColor.Bronze]: (
            <path
                d="M28.5977 50.293C33.3877 50.293 36.8301 47.6855 36.8008 44.0527C36.8301 41.4014 35.1162 39.5117 31.9961 39.1309V38.9551C34.3691 38.5303 36.0244 36.8457 36.0098 34.4727C36.0244 31.1182 33.0801 28.4961 28.6562 28.4961C24.3057 28.4961 21.0244 31.0303 20.9512 34.6777H25.1699C25.2432 33.0518 26.7812 31.9971 28.6562 32.0117C30.5312 31.9971 31.7764 33.1104 31.7617 34.7363C31.7764 36.4062 30.3115 37.5488 28.2168 37.5488H26.2539V40.8008H28.2168C30.707 40.8154 32.2451 42.0312 32.2305 43.7598C32.2451 45.4736 30.7363 46.6455 28.627 46.6602C26.5762 46.6455 25.0381 45.6055 24.9648 44.0234H20.5117C20.5996 47.7295 23.9395 50.293 28.5977 50.293ZM39.3691 50H42.2598V43.9648C42.25 42.666 43.2168 41.7578 44.5254 41.7578C44.9258 41.7578 45.4824 41.8262 45.7559 41.9141V39.3359C45.502 39.2773 45.1309 39.2383 44.8379 39.2383C43.6465 39.2383 42.6602 39.9316 42.2793 41.2305H42.1621V39.3945H39.3691V50ZM50.7363 50.1758C52.4648 50.1758 53.3438 49.1797 53.7441 48.3008H53.8809V50H56.7324V35.8594H53.8418V41.1719H53.7441C53.3633 40.3125 52.5137 39.2578 50.7363 39.2578C48.3926 39.2578 46.4199 41.0742 46.4199 44.707C46.4199 48.252 48.3145 50.1758 50.7363 50.1758ZM49.3887 44.6875C49.3789 42.8418 50.1602 41.5625 51.6348 41.5625C53.0898 41.5625 53.9004 42.7832 53.9004 44.6875C53.9004 46.6016 53.0801 47.8613 51.6348 47.8711C50.1699 47.8613 49.3789 46.5625 49.3887 44.6875Z"
                fill="#5D3E31"
            />
        ),
    };

    return (
        <svg
            width="78"
            height="111"
            viewBox="0 0 78 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61 51H17V110.125H19.1536L39 90.1875L58.8464 110.125H61V51Z"
                fill={colorMap[color][0]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55 52.3906H22V96.7344H23.6152L38.5 81.7812L53.3848 96.7344H55V52.3906Z"
                fill={colorMap[color][1]}
            />
            <rect
                x="6"
                y="6"
                width="66"
                height="66"
                rx="33"
                fill={colorMap[color][2]}
            />
            <path
                opacity="0.3"
                d="M63.7487 14.2513C57.185 7.68749 48.2826 4 39 4C29.7174 4 20.815 7.68749 14.2513 14.2513C7.68749 20.815 4 29.7174 4 39C4 48.2826 7.68749 57.185 14.2513 63.7487L39 39L63.7487 14.2513Z"
                fill="white"
            />
            {svgMap[color]}
            <rect
                x="6"
                y="6"
                width="66"
                height="66"
                rx="33"
                stroke={colorMap[color][3]}
                strokeWidth="4"
            />
            <rect
                x="2"
                y="2"
                width="74"
                height="74"
                rx="37"
                stroke={colorMap[color][4]}
                strokeWidth="4"
            />
        </svg>
    );
};
