import React from "react";
import { Stack, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { LargeWhiteTextField } from "../../../../common/components/textField/LargeWhiteTextField";

export const ClassroomForm = observer(({ inputs, handleChange }) => {
    const { name, startedDate, finishedDate } = inputs;

    return (
        <Stack
            direction="column"
            spacing={2.5}
            backgroundColor="#F5F5F7"
            p={2.5}
            width="100%"
        >
            <LargeWhiteTextField
                label="학급 이름"
                onChange={(e) => {
                    handleChange("name", e.target.value);
                }}
                value={name}
                placeholder="2022년 1학년 1반"
            />
            <Stack direction="row" spacing={2.5}>
                <TextField
                    id="date"
                    label="학급 시작일"
                    type="date"
                    variant="standard"
                    value={startedDate}
                    onChange={(e) =>
                        handleChange("startedDate", e.target.value)
                    }
                    sx={{
                        width: 150,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        sx: {
                            color: "common.black",
                        },
                    }}
                />
                <TextField
                    id="date"
                    label="학급 종료일"
                    type="date"
                    variant="standard"
                    value={finishedDate}
                    onChange={(e) =>
                        handleChange("finishedDate", e.target.value)
                    }
                    sx={{
                        width: 150,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        sx: {
                            color: "common.black",
                        },
                    }}
                />
            </Stack>
        </Stack>
    );
});
