import React, { useLayoutEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";

export const StudentAutoComplete = observer(({ userId, setUserId }) => {
    const { classroomStore } = useStores();

    const { students, student } = classroomStore;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        if (userId) {
            classroomStore.findStudent({ studentId: userId });
            classroomStore.findStudents({
                userId,
                page: 1,
                pageSize: 5,
            });
        }
    }, [userId]);

    const debouncedSearchUsers = _.debounce((_name) => {
        setLoading(true);
        classroomStore
            .findStudents({
                name: _name,
                page: 1,
                pageSize: 5,
            })
            .finally(() => {
                setLoading(false);
            });
    }, 100);

    const getUserOptions = (_name) => {
        debouncedSearchUsers(_name);
    };

    if (!student) return null;

    return (
        <Autocomplete
            sx={{
                minWidth: 200,
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(e, newValue) => {
                getUserOptions(newValue);
            }}
            value={student}
            onChange={(e, newValue) => {
                setUserId(newValue.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.username}
            filterOptions={(x) => x}
            options={students}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        backgroundColor: "#1111110F",
                        color: "#000000",
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                            color: "common.black",
                        },
                        "& .MuiOutlinedInput-input": {
                            fontWeight: 700,
                            fontSize: 16,
                            lineHeight: "150%",
                            p: 2.5,
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
});
