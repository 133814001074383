import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Menu, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { observer } from "mobx-react-lite";
import { useStores } from "../../modules/useStores";
import { ClassroomMenuItem } from "./ClassroomMenuItem";
import AddIcon from "@mui/icons-material/Add";
import { HomeworkAddButton } from "../../../pages/Main/Admin/components/HomeworkAddButton";
import moment from "moment";

const DURATION_LABEL = {
    Now: "현재",
    Past: "과거",
    Future: "예정",
};
const DURATION_LABEL_COLOR = {
    [DURATION_LABEL["Now"]]: "#FFCF00",
    [DURATION_LABEL["Past"]]: "#c0c0c0",
    [DURATION_LABEL["Future"]]: "#18A0FB",
};

export const HomeworkSelector = observer(
    ({ sx, allowCreate, defaultHomeworkId }) => {
        const { classroomStore, homeworkStore } = useStores();
        const { classroom } = classroomStore;
        const { isFetching, simpleHomeworks, setHomework, homework } =
            homeworkStore;

        const [anchorEl, setAnchorEl] = useState(null);
        const [filteredHomeworks, setFilteredHomeworks] = useState([]);

        useEffect(() => {
            if (classroom && classroom.id) {
                homeworkStore.findAllSimple({
                    isToday: false,
                });
            }
        }, [classroom]);

        useEffect(() => {
            if (simpleHomeworks.length) {
                const futureHomeworks = simpleHomeworks
                    .filter((homework) => {
                        const startedMoment = moment(homework.startedAt);
                        return (
                            moment().isSameOrBefore(startedMoment) &&
                            homework.isOwner
                        );
                    })
                    .map((homework) => ({
                        ...homework,
                        duration: DURATION_LABEL.Future,
                    }));

                const todayHomeworks = simpleHomeworks
                    .filter((homework) => {
                        const finishedMoment = moment(homework.finishedAt);
                        const startedMoment = moment(homework.startedAt);

                        return (
                            moment().isSameOrBefore(finishedMoment) &&
                            moment().isSameOrAfter(startedMoment)
                        );
                    })
                    .map((homework) => ({
                        ...homework,
                        duration: DURATION_LABEL.Now,
                    }));

                const passedHomeworks = simpleHomeworks
                    .filter((homework) => {
                        const finishedMoment = moment(homework.finishedAt);

                        return moment().isAfter(finishedMoment);
                    })
                    .map((homework) => ({
                        ...homework,
                        duration: DURATION_LABEL.Past,
                    }));

                const defaultHomework = simpleHomeworks.find(
                    (hw) => hw.id === Number(defaultHomeworkId)
                );

                setHomework(
                    defaultHomework
                        ? defaultHomework
                        : todayHomeworks.length
                        ? todayHomeworks[0]
                        : passedHomeworks[0]
                );
                setFilteredHomeworks([
                    ...futureHomeworks,
                    ...todayHomeworks,
                    ...passedHomeworks,
                ]);
            } else {
                setHomework(undefined);
                setFilteredHomeworks([]);
            }
        }, [isFetching, simpleHomeworks]);

        const navigateToHomeworkDashboard = () => {
            window.open("/admin/homework-edit", "_blank");
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleMenuClick = (_homework) => {
            setHomework(_homework);
            handleClose();
        };

        if (isFetching) {
            return (
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: "common.white",
                        borderRadius: 2,
                        color: "common.black",
                        border: "1px solid #1A1D24",
                        width: 250,
                        py: 2,
                        justifyContent: "space-between",
                        "&:hover": {
                            bgcolor: "#C4C4C4",
                        },
                        ...sx,
                    }}
                    disabled
                >
                    <Typography
                        variant="body1"
                        fontWeight={700}
                        children="숙제를 가져오는 중입니다"
                    />
                </Button>
            );
        }

        if (!filteredHomeworks.length) {
            if (classroom && classroom.isOwner)
                return <HomeworkAddButton sx={sx} />;
            else
                return (
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: "common.white",
                            borderRadius: 2,
                            color: "common.black",
                            border: "1px solid #1A1D24",
                            width: 250,
                            py: 2,
                            justifyContent: "space-between",
                            "&:hover": {
                                bgcolor: "#C4C4C4",
                            },
                            ...sx,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            children="아직 수업이나 과제가 없어요"
                        />
                    </Button>
                );
        }

        return (
            <>
                <Button
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        ...sx,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box display="flex" flexDirection="column">
                            {homework ? (
                                <>
                                    <Typography
                                        variant="body2"
                                        textAlign="left"
                                    >
                                        {`${moment(homework.startedAt).format(
                                            "LLL"
                                        )} ~ ${moment(
                                            homework.finishedAt
                                        ).format("LLL")}`}
                                    </Typography>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={1}
                                    >
                                        <Typography
                                            variant="body1"
                                            textAlign="left"
                                            fontWeight={700}
                                        >
                                            {homework.name}
                                        </Typography>
                                        <Box
                                            component="span"
                                            sx={{
                                                backgroundColor:
                                                    DURATION_LABEL_COLOR[
                                                        homework.duration
                                                    ],
                                                color: "#000000",
                                                px: 12 / 8,
                                                py: 3 / 8,
                                                borderRadius: 8 / 4,
                                                fontSize: 10,
                                                lineHeight: "12px",
                                                fontWeight: 800,
                                            }}
                                        >
                                            {homework.duration}
                                        </Box>
                                    </Stack>
                                </>
                            ) : isFetching ? (
                                "학급 정보를 불러오는 중입니다"
                            ) : null}
                        </Box>
                        <ArrowDropDownIcon />
                    </Box>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: { width: anchorEl && anchorEl.offsetWidth },
                    }}
                    PaperProps={{
                        style: {
                            maxHeight: 300,
                        },
                    }}
                >
                    {filteredHomeworks.map((_homework) => (
                        <ClassroomMenuItem
                            key={`homework-menu-${_homework.id}`}
                            onClick={() => handleMenuClick(_homework)}
                            selected={homework && homework.id === _homework.id}
                        >
                            <Stack direction="column">
                                <Typography variant="body2" textAlign="left">
                                    {`${moment(_homework.startedAt).format(
                                        "LLL"
                                    )} ~ ${moment(_homework.finishedAt).format(
                                        "LLL"
                                    )}`}
                                </Typography>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <Typography
                                        variant="body1"
                                        textAlign="left"
                                        fontWeight={700}
                                    >
                                        {_homework.name}
                                    </Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            backgroundColor:
                                                DURATION_LABEL_COLOR[
                                                    _homework.duration
                                                ],
                                            color: "#000000",
                                            px: 12 / 8,
                                            py: 3 / 8,
                                            borderRadius: 8 / 4,
                                            fontSize: 10,
                                            lineHeight: "12px",
                                            fontWeight: 800,
                                        }}
                                    >
                                        {_homework.duration}
                                    </Box>
                                </Stack>
                            </Stack>
                        </ClassroomMenuItem>
                    ))}
                    {allowCreate && (
                        <>
                            <Divider />
                            <ClassroomMenuItem
                                onClick={navigateToHomeworkDashboard}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Typography
                                        variant="body1"
                                        textAlign="left"
                                        fontWeight={700}
                                        children="새 수업/과제 만들기"
                                    />
                                    <AddIcon />
                                </Stack>
                            </ClassroomMenuItem>
                        </>
                    )}
                </Menu>
            </>
        );
    }
);
