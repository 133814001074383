import React from "react";
import { styled, Input, FormControl } from "@mui/material";

const ExecuteResultFormControlComponent = styled(FormControl)(({ theme }) => ({
    borderRadius: theme.shape,
    border: "1px solid #41444A",
    backgroundColor: theme.palette.mode === "light" ? "#F4F7F9" : "#1D1D1B",
    borderColor: theme.palette.mode === "light" ? "#F1F1F7" : "#41444A",
    height: "100%",
    display: "flex",
    overflow: "auto",
    padding: theme.spacing(1.75, 2.5),
}));

const ExecuteResultInputComponent = styled(Input)(({ theme }) => ({
    color: theme.palette.mode === "light" ? "#959595" : "#C4C4C4",
    fontSize: 14,
    fontFamily: "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
    height: "100%",
    display: "flex",
    alignItems: "start",
    flex: 1,
}));

export const ExecuteResultTextField = ({
    value,
    placeholder,
    readOnly,
    autoFocus,
    onChange,
    ...props
}) => (
    <ExecuteResultFormControlComponent fullWidth {...props}>
        <ExecuteResultInputComponent
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            autoFocus={autoFocus}
            disableUnderline
            multiline
            onChange={onChange}
        />
    </ExecuteResultFormControlComponent>
);
