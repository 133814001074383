import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../../utils/immutableLodash";
import { camelizeKeys } from "../../../../utils/renameKeys";
import { textToArray } from "../../../../utils/string";
import { f } from "../../../../utils/usefulFunc";

export const ProblemStatusType = {
    Ready: "Ready",
    InProgress: "In Progress",
    Passed: "Passed",
};

export const ProblemStatusTypeToColor = {
    [ProblemStatusType.Ready]: "#1A1A1A",
    [ProblemStatusType.InProgress]: "#FFBE00",
    [ProblemStatusType.Passed]: "#42D760",
};

export const ProblemStatusTypeToLabel = {
    [ProblemStatusType.Ready]: f("시도하지 않음"),
    [ProblemStatusType.InProgress]: f("시도함"),
    [ProblemStatusType.Passed]: f("해결함"),
};

export const LevelType = {
    Easy: "Easy",
    Medium: "Medium",
    Hard: "Hard",
};

export const LevelTypeToInt = {
    [LevelType.Easy]: 1,
    [LevelType.Medium]: 2,
    [LevelType.Hard]: 3,
};

export const LevelTypeToLabel = {
    [LevelType.Easy]: "쉬움",
    [LevelType.Medium]: "보통",
    [LevelType.Hard]: "어려움",
};

export const LevelTypeToColor = {
    [LevelType.Easy]: "#5cb85c",
    [LevelType.Medium]: "#f0ad4e",
    [LevelType.Hard]: "#d9534f",
};

export const getProblemTypeToLabel = (curatedProblem) => {
    if (!curatedProblem) {
        return "";
    }

    if (curatedProblem.isIntro) {
        return f("기본 개념");
    }

    if (curatedProblem.isTest) {
        return f("테스트");
    }

    return f("연습 문제");
};

export class CuratedProblemModel {
    id = 0;

    name = "";

    level = LevelType.Easy;

    isIntro = false;

    isTest = false;

    isClickedDate = null;

    progressStatus = ProblemStatusType.Ready;

    curatedProblemId = 0;

    number = "";

    discussionsCount = 0;

    type = "";

    mcAnswersCount = 0;

    mcQuestions = [];

    mcQuestionsEn = [];

    studyUnitName = "";

    conceptName = "";

    conceptLevel = 0;

    studyUnitId = 0;

    conceptId = 0;

    solvedMinute = 0;

    experiencePoints = 0;

    order = 0;

    aiEnabled = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const curatedProblem = pickCls(this, camelizeKeys(data));
        if (!curatedProblem.progressStatus)
            curatedProblem.progressStatus = ProblemStatusType.Ready;
        curatedProblem.mcQuestions = curatedProblem.mcQuestions
            ? textToArray(curatedProblem.mcQuestions)
            : [];
        curatedProblem.mcQuestionsEn = curatedProblem.mcQuestionsEn
            ? textToArray(curatedProblem.mcQuestionsEn)
            : [];
        set(this, curatedProblem);
    }
}
