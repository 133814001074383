import React from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { paddingZero } from "../../../../../utils/string";
import { StudyUnitBox } from "./StudyUnitBox";

export const ConceptBox = ({ homework, concept }) => (
    <Paper
        sx={{
            p: 2.5,
            borderRadius: 2,
        }}
    >
        <Stack direction="column" spacing={2}>
            <Stack direction="row">
                <Typography
                    variant="subtitle1"
                    color="#1A1A1A"
                    children={paddingZero(concept.level, 2)}
                    mr={9}
                />
                <Stack direction="column" spacing={1}>
                    <Typography
                        children={concept.name}
                        fontWeight={700}
                        variant="h4"
                        color="#1A1A1A"
                    />
                    <Typography
                        variant="caption"
                        color="#808080"
                        children={concept.description}
                    />
                </Stack>
            </Stack>
            <Divider
                sx={{
                    backgroundColor: "#000000",
                }}
            />
            <Stack
                direction="column"
                spacing={2}
                // pl={11.25}
            >
                {concept.studyUnits.map((studyUnit, i) => (
                    <StudyUnitBox
                        key={`study-unit-box-${i}`}
                        homework={homework}
                        studyUnit={studyUnit}
                    />
                ))}
            </Stack>
        </Stack>
    </Paper>
);
