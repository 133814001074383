import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DiscussionsTemplate } from "./DiscussionsTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";

export const DiscussionsContainer = observer(({ isAdmin }) => {
    const { classroomStore, discussionStore } = useStores();

    const { classroom } = classroomStore;
    const { discussions, count, isFetching } = discussionStore;

    const [page, setPage] = useState(1);
    const [isMyQuestions, setIsMyQuestions] = useState(false);
    const [resolvedStatus, setResolvedStatus] = useState("all");
    const [searchWords, setSearchWords] = useState("");
    const rowsPerPage = 4;

    const toggleIsMyQuestions = () => {
        setIsMyQuestions((prev) => !prev);
        setPage(1);
    };

    useEffect(() => {
        setPage(1);
        discussionStore.findAll({
            page: 1,
            pageSize: rowsPerPage,
            isMyQuestions,
        });
    }, [classroom]);

    useEffect(() => {
        discussionStore.findAll({
            page,
            pageSize: rowsPerPage,
            isMyQuestions,
            resolvedStatus,
            searchWords,
        });
    }, [page, isMyQuestions, resolvedStatus]);

    const searchDiscussions = (value) => {
        discussionStore.findAll({
            page: 1,
            pageSize: rowsPerPage,
            isMyQuestions,
            resolvedStatus,
            searchWords: value,
        });
    };

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="질문들을 불러오는 중입니다."
            />
        );

    return (
        <DiscussionsTemplate
            isTeacher
            discussions={discussions}
            page={page}
            setPage={setPage}
            isAdmin={isAdmin}
            count={count}
            rowsPerPage={rowsPerPage}
            isMyQuestions={isMyQuestions}
            toggleIsMyQuestions={toggleIsMyQuestions}
            resolvedStatus={resolvedStatus}
            setResolvedStatus={setResolvedStatus}
            value={searchWords}
            setValue={setSearchWords}
            searchDiscussions={searchDiscussions}
        />
    );
});
