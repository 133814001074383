import { makeAutoObservable, set } from "mobx";
import {
    camelizeKeys,
    renameKeys,
    snakelizeKeys,
} from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";

export const SubmissionStatusType = {
    Enqueued: "Enqueued",
    InProgress: "In Progress",
    CompileError: "Compile Error",
    Passed: "Passed",
    RuntimeError: "Runtime Error",
    WrongAnswer: "Wrong Answer",
    MemoryLimitExceed: "Memory Limit Exceed",
    TimeLimitExceed: "Time Limit Exceed",
    InternalError: "Internal Error",
};

export const SubmissionStatusToColor = {
    [SubmissionStatusType.Enqueued]: "#a6d4fa",
    [SubmissionStatusType.InProgress]: "#64b5f6",
    [SubmissionStatusType.Passed]: "#81c784",
    [SubmissionStatusType.CompileError]: "#ffb74d",
    [SubmissionStatusType.WrongAnswer]: "#e57373",
    [SubmissionStatusType.MemoryLimitExceed]: "#aa647b",
    [SubmissionStatusType.TimeLimitExceed]: "#aa647b",
    [SubmissionStatusType.RuntimeError]: "#424242",
    [SubmissionStatusType.InternalError]: "#424242",
};

export const SubmissionStatusToKorean = {
    [SubmissionStatusType.Enqueued]: "채점 대기",
    [SubmissionStatusType.InProgress]: "채점 중",
    [SubmissionStatusType.CompileError]: "컴파일 에러",
    [SubmissionStatusType.Passed]: "맞았습니다!",
    [SubmissionStatusType.RuntimeError]: "런타임 에러",
    [SubmissionStatusType.WrongAnswer]: "틀렸습니다",
    [SubmissionStatusType.MemoryLimitExceed]: "메모리 초과",
    [SubmissionStatusType.TimeLimitExceed]: "시간 초과",
    [SubmissionStatusType.InternalError]: "인터널 에러",
};

export class ExecuteResultModel {
    status = "";

    executeStderr = "";

    compileStderr = "";

    runtime = 0;

    memory = 0;

    correctStdoutUrl = "";

    actualStdoutUrl = "";

    userInputUrl = "";

    correctStdout = "";

    actualStdout = "";

    userInput = "";

    submissionId = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const result = pickCls(this, camelizeKeys(data));
        set(this, result);
    }
}

export class ResultModel {
    id = 0;

    runtime = 0;

    memory = 0;

    status = "";

    failedIndex = -1;

    correctStdout = "";

    actualStdout = "";

    userInput = "";

    compileStderr = "";

    executeStderr = "";

    isRejudged = false;

    process = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const result = pickCls(this, camelizeKeys(data));
        set(this, result);
    }
}

const submissionConvertMap = {
    problem: "problemId",
};

export class SubmissionModel {
    id = 0;

    language = "";

    code = "";

    createdAt = "";

    result = "";

    problemId = 0;

    problemName = "";

    isTest = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const submission = pickCls(
            this,
            renameKeys(camelizeKeys(data), submissionConvertMap)
        );
        submission.result = new ResultModel(submission.result);
        set(this, submission);
    }
}

export class SubmissionDAOModel {
    constructor(data) {
        data.problem = data.problemId;
        Object.assign(this, snakelizeKeys(data));
    }
}

export class McqSubmissionDAOModel {
    selections = [];

    contentTypeName = "";

    objectId = 0;

    constructor(data) {
        const mcqSubmission = pickCls(this, data);
        Object.assign(this, snakelizeKeys(mcqSubmission));
    }
}
