import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useStores } from "../../../common/modules/useStores";
import { QuiztimeContainer } from "./QuiztimeContainer";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";

export const QuiztimePage = () => {
    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    if (isUserLoggedIn()) {
        return (
            <Routes>
                <Route path="/" element={<QuiztimeContainer />} />
            </Routes>
        );
    }

    if (hasToken()) {
        return <LoadingOverlay active />;
    }

    return <Navigate to={"/"} />;
};
