import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Box,
    Chip,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import { observer } from "mobx-react-lite";
import { Markdown } from "../../../../../../../common/components/markdown/Markdown";
import { useCopyRef } from "../../../../../../../utils/ref";
import { LangToCodeMirrorMode } from "../../../../../../../common/modules/problems/ProblemModel";
import { StandaloneEditor } from "../../../../../../../common/components/editor/StandaloneEditor";
import { CopyButton } from "../../../../../../../common/components/button/CopyButton";
import { useStores } from "../../../../../../../common/modules/useStores";

export const ExplanationAccordion = observer(({ solution, solutionsCount }) => {
    const preventCopy = (e) => {
        e.preventDefault();
        alert("솔루션 복사는 금지되어 있습니다.");
    };

    const { authStore } = useStores();
    const { currUser } = authStore;

    const enableCopy = (e) => {
        e.stopPropagation();
    };

    const SolutionRef = useCopyRef(preventCopy);
    const CodeRef = useCopyRef(enableCopy);

    const initLang = solution.answerCodes.length
        ? solution.answerCodes[0].language
        : null;

    const [activeTab, setActiveTab] = useState(initLang);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        if (
            solution.answerCodes.some(
                (code) => code.language === currUser.language
            )
        ) {
            setActiveTab(currUser.language);
        }
    }, [currUser.language]);

    return (
        <Accordion
            defaultExpanded={solutionsCount <= 1 && solution.order === 1}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" spacing={1}>
                    <Typography>
                        Approach {solution.order}: {solution.name} [
                        {solution.category}]
                    </Typography>
                    {solution.isMain ? (
                        <Chip
                            icon={<DoneIcon />}
                            label="Main"
                            color="primary"
                            size="small"
                        />
                    ) : null}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3} ref={SolutionRef}>
                    <Typography variant="subtitle1" fontWeight={700}>
                        Intuition
                    </Typography>
                    <Markdown children={solution.intuition} />
                    <Typography variant="subtitle1" fontWeight={700}>
                        Algorithm
                    </Typography>
                    <Markdown children={solution.algorithm} />
                    {solution.answerCodes.length ? (
                        <Stack
                            direction="column"
                            sx={{
                                border: "1px solid #D8DEE4",
                                borderRadius: 1.5,
                                overflow: "hidden",
                            }}
                        >
                            <AppBar
                                position="static"
                                color="default"
                                elevation={0}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Tabs
                                            value={activeTab}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                        >
                                            {solution.answerCodes.map(
                                                (item) => (
                                                    <Tab
                                                        key={item.language}
                                                        value={item.language}
                                                        label={item.language}
                                                    />
                                                )
                                            )}
                                        </Tabs>
                                    </Box>
                                    <CopyButton
                                        iconSize="small"
                                        text={
                                            solution.answerCodes.find(
                                                (item) =>
                                                    item.language === activeTab
                                            ).code
                                        }
                                    />
                                </Stack>
                            </AppBar>
                            <Box ref={CodeRef}>
                                <StandaloneEditor
                                    value={
                                        solution.answerCodes.find(
                                            (item) =>
                                                item.language === activeTab
                                        ).code
                                    }
                                    language={LangToCodeMirrorMode[activeTab]}
                                    options={{
                                        readOnly: true,
                                        scrollBeyondLastLine: false,
                                        scrollbar: {
                                            alwaysConsumeMouseWheel: false,
                                            vertical: "hidden",
                                        },
                                        fontSize: 14,
                                        minimap: {
                                            enabled: false,
                                        },
                                        renderLineHighlight: "none",
                                    }}
                                />
                            </Box>
                        </Stack>
                    ) : null}
                    <Typography variant="subtitle1" fontWeight={700}>
                        Complexity Analysis
                    </Typography>
                    <Markdown children={solution.complexity} />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
});
