import React from "react";
import { Stack } from "@mui/material";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { MissionHeader } from "./templates/MissionHeader";
import { ContentsBox } from "./templates/ContentsBox";

export const MissionsTemplate = ({
    concepts,
    missionId,
    missions,
    setMissionId,
    latestSolvedProblemSet,
}) => {
    const {
        passedProblemCount,
        totalProblemCount,
        missionName,
        latestSolvedProblem,
    } = latestSolvedProblemSet;

    return (
        <MainTemplateWrapper
            sx={{
                pt: 4,
                pb: 15,
            }}
        >
            <Stack direction="row" spacing={20 / 8} alignItems={"flex-start"}>
                <MissionHeader
                    missionName={missionName}
                    missions={missions}
                    missionId={missionId}
                    setMissionId={setMissionId}
                    passedProblemCount={passedProblemCount}
                    totalProblemCount={totalProblemCount}
                />
                <ContentsBox
                    concepts={concepts}
                    latestSolvedProblem={latestSolvedProblem}
                    missionId={missionId}
                />
            </Stack>
        </MainTemplateWrapper>
    );
};
