import React from "react";
import { IconButton, Paper, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { StudentAutoComplete } from "../../templates/StudentAutoComplete";
import { MissionSelector } from "../../../../../common/templates/MissionSelector";
import { CuratedProblemAutoCompleteContainer } from "./CuratedProblemAutoCompleteContainer";
import { LargeButton } from "../../../../../common/components/button/LargeButton";
import { getMissionAndProblemId } from "../../../../../utils/string";

export const ClassroomDashboardHeader = ({
    userId,
    missions,
    missionId,
    problemId,
    problem,
}) => {
    const navigate = useNavigate();

    const redirectToMission = (_missionId) => {
        navigate(
            `/admin/classroom-dashboard/users/${userId}/missions/${_missionId}/problems/0`,
            { replace: true }
        );
    };

    const redirectToUser = (_userId) => {
        navigate(
            `/admin/classroom-dashboard/users/${_userId}/missions/${missionId}/problems/0`,
            { replace: true }
        );
    };

    const redirectToProblem = (_problemId) => {
        navigate(
            `/admin/classroom-dashboard/users/${userId}/missions/${missionId}/problems/${_problemId}`,
            { replace: true }
        );
    };

    const redirectToPlaygroundPage = (number) => {
        if (!number) {
            return;
        }

        const { missionId, problemId } = getMissionAndProblemId(number);

        navigate(`/missions/${missionId}/problems/${problemId}/`);
    };

    return (
        <Paper
            sx={{
                p: 2.5,
            }}
        >
            <Stack direction="row" spacing={1.25} alignItems="center">
                <IconButton
                    children={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                />
                <StudentAutoComplete
                    userId={Number(userId)}
                    setUserId={redirectToUser}
                />
                <Stack
                    direction="column"
                    justifyContent="center"
                    sx={{
                        backgroundColor: "#1111110F",
                        alignSelf: "normal",
                    }}
                >
                    <MissionSelector
                        missions={missions}
                        missionId={Number(missionId)}
                        setMissionId={redirectToMission}
                    />
                </Stack>
                <CuratedProblemAutoCompleteContainer
                    problemId={Number(problemId)}
                    setProblemId={redirectToProblem}
                    missionId={missionId}
                />
                <LargeButton
                    label="문제보기"
                    color="#FFFFFF"
                    backgroundColor="#000000"
                    endIcon={<OpenInNewIcon />}
                    onClick={() =>
                        redirectToPlaygroundPage(problem && problem.number)
                    }
                />
            </Stack>
        </Paper>
    );
};
