import React, { useEffect } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    Switch,
} from "@mui/material";
import { anchorRef, bindPopper } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { LangToCodeMirrorMode } from "../../../../../../../common/modules/problems/ProblemModel";
import { TutorialType } from "../../../../../../../common/modules/ui/tutorial/TutorialModel";
import { TutorialCard } from "../../TutorialCard";

export const EditorHeader = ({
    language,
    handleLanguageChange,
    isLightTheme,
    handleModeChange,
    isCodeBlock,
    currTutorial,
    setTutorial,
    handleReset,
}) => {
    const popupState = usePopupState({
        variant: "popover",
        popupId: "demoMenu",
    });

    useEffect(() => {
        if (popupState.anchorEl && currTutorial === TutorialType.Language) {
            popupState.open();
        }
    }, [popupState.anchorEl, currTutorial]);

    const handlePrevTutorial = () => {
        popupState.close();
        setTutorial(TutorialType.Problem);
    };

    const handleNextTutorial = () => {
        popupState.close();
        setTutorial(TutorialType.Editor);
    };

    return (
        <Stack
            py={1}
            px={2}
            direction="row"
            flexGrow={0}
            justifyContent="space-between"
        >
            <Stack direction="row" spacing={2} alignItems="center">
                {isCodeBlock ? (
                    <>
                        <FormControl
                            variant="filled"
                            ref={anchorRef(popupState)}
                        >
                            <Select
                                value={language}
                                onChange={(e) =>
                                    handleLanguageChange(e.target.value)
                                }
                                color="primary"
                                sx={{
                                    "& .MuiSelect-select": {
                                        py: 1,
                                    },
                                }}
                            >
                                {Object.keys(LangToCodeMirrorMode).map(
                                    (item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        <Popper
                            {...bindPopper(popupState)}
                            open={popupState.isOpen}
                            placement="right-start"
                            sx={{
                                zIndex: 1,
                                padding: 1,
                            }}
                        >
                            <TutorialCard
                                title="언어 선택"
                                body="선호하는 프로그래밍 언어를 선택할 수 있어요."
                                handlePrevTutorial={handlePrevTutorial}
                                handleNextTutorial={handleNextTutorial}
                            />
                        </Popper>
                    </>
                ) : null}
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            checked={isLightTheme}
                            onChange={handleModeChange}
                        />
                    }
                    sx={{
                        color: "#959595",
                    }}
                    label={isLightTheme ? "라이트 모드" : "다크 모드"}
                />
            </Stack>
            {isCodeBlock ? (
                <Button
                    onClick={handleReset}
                    sx={{
                        color: "#959595",
                    }}
                >
                    코드 초기화
                </Button>
            ) : null}
        </Stack>
    );
};
