import React from "react";
import { Stack } from "@mui/material";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../common/components/appBar/ResponsiveAppBar";
// import { DiscussionsPage } from "./Discussions/DiscussionsPage";
import { HomeworksPage } from "./Homeworks/HomeworksPage";
import { MissionsPage } from "./Missions/MissionsPage";
import { AboutPage } from "./About/AboutPage";
import { CurriculumsPage } from "./Curriculums/CurriculumsPage";
import { ResponsiveToolbar } from "../../common/components/toolbar/ResponsiveToolbar";
import { LogoButton } from "../../common/components/button/LogoButton";
import { UserComponent } from "../../common/templates/User/UserComponent";
import { AccountsPage } from "./Accounts/AccountsPage";
import { QuiztimePage } from "./Quiztime/QuiztimePage";
import { AssessmentPage } from "./Assessment/AssessmentPage";
import { observer } from "mobx-react-lite";
import { useStores } from "../../common/modules/useStores";
import { LoadingOverlay } from "../../common/templates/LoadingOverlay";
import { PolicyPage } from "./Policy/PolicyPage";
import { ClassroomsContainer } from "./Classrooms/ClassroomsContainer";
import { HelpPage } from "./Help/HelpPage";

const TAB_ITEMS = [
    { value: "about", label: "소개", position: "left" },
    { value: "curriculums", label: "커리큘럼", position: "left" },
    { value: "missions", label: "혼자 학습하기", position: "left" },
    { value: "homeworks", label: "나의 코딩 교실", position: "left" },
    { value: "quiztime", label: "퀴즈타임", position: "left" },
    // { value: "assessment", label: "수행평가", position: "left" },
    // { value: "discussions", label: "질문 게시판", position: "left" },
    { value: "classrooms", label: "학급 신청", position: "left" },
    // { value: "psfiddle", label: "퀴즈 제작", position: "left" },
];

export const MainTemplate = observer(() => {
    const navigate = useNavigate();

    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    const handleTabClick = (_activeName) => {
        navigate(`/${_activeName}`);
    };

    const activeName = TAB_ITEMS.find((tabItem) =>
        window.location.pathname.startsWith(`/${tabItem.value}`)
    )?.value;

    const filteredTabItems = TAB_ITEMS.filter((tabItem) => {
        if (tabItem.value === "curriculums" || tabItem.value === "about") {
            return true;
        }

        return isUserLoggedIn();
    });

    const DefaultPage = () => {
        if (isUserLoggedIn()) {
            return <Navigate to={"about"} />;
        }

        if (hasToken()) {
            return <LoadingOverlay active />;
        }

        return <Navigate to={"about"} />;
    };

    return (
        <Stack width="100%" minHeight="100vh">
            <ResponsiveAppBar
                tabItems={filteredTabItems}
                logoElement={<LogoButton />}
                onClick={handleTabClick}
                value={activeName}
                rightElement={<UserComponent />}
            />
            <ResponsiveToolbar />
            <Stack flexGrow={1} sx={{ backgroundColor: "background.default" }}>
                <Routes>
                    <Route path="/" element={DefaultPage()} />
                    <Route path="policy/*" element={<PolicyPage />} />
                    <Route path="curriculums/*" element={<CurriculumsPage />} />
                    {/* <Route
                        path="discussions/*"
                        element={<DiscussionsPage isLogin={isUserLoggedIn()} />}
                    /> */}
                    <Route path="about/*" element={<AboutPage />} />
                    <Route path="missions/*" element={<MissionsPage />} />
                    <Route
                        path="classrooms/*"
                        element={<ClassroomsContainer />}
                    />
                    <Route path="quiztime/*" element={<QuiztimePage />} />
                    <Route path="assessment/*" element={<AssessmentPage />} />
                    <Route path="homeworks/*" element={<HomeworksPage />} />
                    <Route path="accounts/*" element={<AccountsPage />} />
                    <Route path="help/*" element={<HelpPage />} />
                    <Route path="*" element={<Navigate to="/not-found" />} />
                </Routes>
            </Stack>
        </Stack>
    );
});
