import { Check } from "@mui/icons-material";
import { Stack, Typography, Box, Button, darken } from "@mui/material";
import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { FAQList } from "../Help/FAQ/FAQList";

export const ClassroomInfo = forwardRef((props, ref) => {
    const navigate = useNavigate();

    return (
        <Stack
            ref={ref}
            py={120 / 8}
            bgcolor={"#ffffff"}
            alignItems={"center"}
            width={"100%"}
            overflow={"hidden"}
            spacing={30 / 8}
        >
            <Stack direction={"row"}>
                <Typography variant="h2" fontWeight={800} color={"#3FCB7E"}>
                    코딩 수업
                </Typography>
                <Typography variant="h2" fontWeight={800}>
                    에 특화된 모든것을 하나로 해결하세요.
                </Typography>
            </Stack>
            <img
                width={80}
                height={80}
                src={"https://contents.codetree.ai/ooe/about/teacher.png"}
            />
            <FAQList
                FAQ={[
                    { key: "create_class", label: "학급을 만들고 싶어요." },
                    {
                        key: "create_hw",
                        label: "문제를 골라서 수업에 활용하고 싶어요.",
                    },
                    {
                        key: "copy_hw",
                        label: "만든 수업/과제를 복제하고 싶어요.",
                    },
                ]}
                onClick={(key) =>
                    window.open(
                        `${window.location.origin}/help/FAQ/${key}`,
                        "_blank"
                    )
                }
                swipe={true}
            />
            <MainTemplateWrapper>
                <Stack spacing={150 / 8}>
                    <Stack
                        sx={{
                            position: "relative",
                        }}
                    >
                        <Stack
                            sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                            }}
                            spacing={10 / 8}
                            alignItems={"flex-start"}
                        >
                            <Typography
                                variant="h2"
                                fontWeight={800}
                                textAlign={"left"}
                            >
                                손쉽게 학급을 만들고
                                <br />
                                필요한 내용만 골라
                                <br />
                                수업을 만들 수 있어요.
                            </Typography>
                            <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                width={"100%"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <Typography variant="body2">대상</Typography>
                                <Box
                                    sx={(theme) => ({
                                        px: 12 / 8,
                                        py: 6 / 8,
                                        color: "#fff",
                                        borderRadius: 8 / 4,
                                        bgcolor: "#18A0FB",
                                        fontSize:
                                            theme.typography.caption.fontSize,
                                    })}
                                >
                                    선생님
                                </Box>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        학급 개설
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        승인된 학생으로 수업 참여 제한
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        수업 콘텐츠 이수 현황 정보 제공
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <img
                            width={"100%"}
                            src={
                                "https://contents.codetree.ai/ooe/about/create_classroom.png"
                            }
                        />
                    </Stack>

                    <Stack
                        sx={{
                            position: "relative",
                        }}
                    >
                        <Stack
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                            }}
                            spacing={10 / 8}
                            alignItems={"flex-start"}
                        >
                            <Typography
                                variant="h2"
                                fontWeight={800}
                                textAlign={"left"}
                            >
                                코드 작성 없이도
                                <br />
                                퀴즈를 만들고
                                <br />
                                공유하고
                                <br />
                                채점할 수 있어요.
                            </Typography>
                            <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                width={"100%"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <Typography variant="body2">대상</Typography>
                                <Box
                                    sx={(theme) => ({
                                        px: 12 / 8,
                                        py: 6 / 8,
                                        color: "#fff",
                                        borderRadius: 8 / 4,
                                        bgcolor: "#18A0FB",
                                        fontSize:
                                            theme.typography.caption.fontSize,
                                    })}
                                >
                                    선생님
                                </Box>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        솔루션 코드 작성 없는 퀴즈 출제
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        무한한 가능성을 가진 뼈대 코드 작성 지원
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        공유 링크로 손쉬운 전달 (예: Padlet)
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        제출 및 채점 현황
                                    </Typography>
                                </Stack>
                            </Stack>
                            <FAQList
                                FAQ={[
                                    {
                                        key: "create_quiz",
                                        label: "흥미를 이끄는 퀴즈, 어떻게 만드나요?",
                                    },
                                ]}
                                onClick={(key) =>
                                    window.open(
                                        `${window.location.origin}/help/FAQ/${key}`,
                                        "_blank"
                                    )
                                }
                            />
                        </Stack>
                        <img
                            width={"100%"}
                            style={{
                                marginTop: "40px",
                            }}
                            src={
                                "https://contents.codetree.ai/ooe/about/quiz.png"
                            }
                        />
                    </Stack>

                    <Stack
                        sx={{
                            position: "relative",
                        }}
                    >
                        <Stack
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                            }}
                            spacing={10 / 8}
                            alignItems={"flex-start"}
                        >
                            <Typography
                                variant="h2"
                                fontWeight={800}
                                textAlign={"left"}
                            >
                                수업, 과제, 퀴즈의
                                <br />
                                채점 현황과
                                <br />
                                제출 코드를 확인할 수 있어요.
                            </Typography>
                            <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                width={"100%"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <Typography variant="body2">대상</Typography>
                                <Box
                                    sx={(theme) => ({
                                        px: 12 / 8,
                                        py: 6 / 8,
                                        color: "#fff",
                                        borderRadius: 8 / 4,
                                        bgcolor: "#18A0FB",
                                        fontSize:
                                            theme.typography.caption.fontSize,
                                    })}
                                >
                                    선생님
                                </Box>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        학급, 학생별 채점 현황 시각화
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={0.5}>
                                    <Check />{" "}
                                    <Typography variant="body1">
                                        제출 코드 상세 조회
                                    </Typography>
                                </Stack>
                            </Stack>
                            <FAQList
                                FAQ={[
                                    {
                                        key: "submissions",
                                        label: "학급 학생들의 제출 기록을 확인하고 싶어요.",
                                    },
                                ]}
                                onClick={(key) =>
                                    window.open(
                                        `${window.location.origin}/help/FAQ/${key}`,
                                        "_blank"
                                    )
                                }
                            />
                        </Stack>
                        <img
                            width={"150%"}
                            style={{
                                marginTop: "60px",
                            }}
                            src={
                                "https://contents.codetree.ai/ooe/about/lms.png"
                            }
                        />
                    </Stack>
                </Stack>
            </MainTemplateWrapper>

            <Button
                sx={{
                    flexDirection: "column",
                    width: 250,
                    height: 80,
                    borderRadius: 8 / 4,
                    borderBottom: "4px solid rgba(0,0,0,0.2)",
                    bgcolor: "#3FCB7E",
                    "&:hover": {
                        bgcolor: darken("#3FCB7E", 0.1),
                    },
                }}
                onClick={() => navigate("/homeworks")}
            >
                <Typography variant="body2" color={"#fff"}>
                    나의 코딩 교실
                </Typography>
                <Typography variant="h4" fontWeight={800} color={"#fff"}>
                    시작
                </Typography>
            </Button>
        </Stack>
    );
});
