import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ClassroomAddDialog } from "../templates/ClassroomAddDialog";

export const ClassroomAddButton = ({ sx }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <Button
                variant="contained"
                endIcon={<AddIcon />}
                sx={{
                    bgcolor: "common.white",
                    borderRadius: 2,
                    color: "common.black",
                    border: "1px solid #1A1D24",
                    width: 250,
                    py: 2,
                    justifyContent: "space-between",
                    "&:hover": {
                        bgcolor: "#C4C4C4",
                    },
                    ...sx,
                }}
                onClick={toggleOpen}
            >
                <Typography
                    variant="body1"
                    fontWeight={700}
                    children="새 학급 만들기"
                />
            </Button>
            <ClassroomAddDialog open={open} onClose={toggleOpen} />
        </>
    );
};
