import React from "react";
import { alpha, ListItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AdminChildListItem = ({ treeData }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(treeData.id, { replace: true });
    };

    const isSelected = window.location.pathname.includes(treeData.id);

    return (
        <ListItem
            onClick={handleClick}
            sx={{
                px: 1.5,
                py: 1,
                backgroundColor: isSelected ? alpha("#FFFFFF", 0.1) : "initial",
                cursor: "pointer",
                borderRadius: 2,
                "&:hover": {
                    backgroundColor: alpha("#FFFFFF", 0.1),
                },
            }}
        >
            <Typography variant="body2" children={treeData.name} />
        </ListItem>
    );
};
