import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react-lite";
import { Markdown } from "../../../../../../../../../common/components/markdown/Markdown";
import { IconButtonWithTooltip } from "../../../../../../../../../common/components/button/IconButtonWithTooltip";
import { Replies } from "./replies/Replies";

export const CommentView = observer(({ comment, toggleIsEditing, isReply }) => {
    const {
        username,
        isDiscussionAuthor,
        createdAt,
        content,
        isAuthor,
        replyNum,
        replies,
    } = comment;

    const fromNow = moment(createdAt).locale("ko").fromNow();

    return (
        <Stack
            direction="column"
            sx={{
                borderRadius: 1.5,
                bgcolor: "background.paper",
                border: "1px solid #f1f5f7",
                mb: 1.5,
            }}
        >
            <Stack direction="column" p={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <PermIdentityIcon fontSize="small" />
                        <Typography
                            variant="body2"
                            color="#B1B1B5"
                            children={username}
                        />
                        {isDiscussionAuthor ? (
                            <Chip
                                label="작성자"
                                size="small"
                                color="secondary"
                                sx={{
                                    fontSize: 8,
                                    fontWeight: 700,
                                }}
                            />
                        ) : null}
                        <Typography
                            variant="body2"
                            color="#B1B1B5"
                            children={fromNow}
                            style={{
                                marginLeft: 8,
                            }}
                        />
                    </Stack>
                    {isAuthor ? (
                        <IconButtonWithTooltip
                            tooltipTitle="수정"
                            Icon={EditIcon}
                            iconSize="small"
                            tooltipPlacement="left"
                            onClick={toggleIsEditing}
                        />
                    ) : null}
                </Stack>
                <Box mt={2} pl={0.25}>
                    <Markdown children={content} />
                </Box>
                {!isReply ? (
                    <Replies
                        commentId={comment.id}
                        replyNum={replyNum}
                        replies={replies}
                    />
                ) : null}
            </Stack>
        </Stack>
    );
});
