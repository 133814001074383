import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MiddleButton } from "../../../../../common/components/button/MiddleButton";
import { getLoginPageUrlFromThisLocation } from "../../../../../utils/url";

export const NeedToLoginTemplate = ({ text, sx }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const navigateToLoginPage = () =>
        navigate(getLoginPageUrlFromThisLocation());

    return (
        <Stack
            direction="column"
            alignItems="center"
            pt={12.5}
            sx={{
                ...sx,
            }}
            spacing={2.5}
        >
            <Typography
                variant="body1"
                whiteSpace="pre-line"
                textAlign="center"
            >
                {text}
            </Typography>
            <MiddleButton
                label="로그인하기"
                color={theme.palette.primary.main}
                backgroundColor="#FFFFFFFF"
                sx={{
                    border: `2px solid ${theme.palette.primary.main}`,
                }}
                onClick={navigateToLoginPage}
            />
        </Stack>
    );
};
