import { Button, darken, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";

export const Usage = ({ playgroundIntroRef, classroomInfoRef }) => {
    const navigate = useNavigate();

    const scrollIntoView = (ref) => {
        ref.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });
    };

    return (
        <Stack
            direction={"row"}
            bgcolor={"#fff"}
            py={80 / 8}
            justifyContent={"center"}
        >
            <MainTemplateWrapper>
                <Stack
                    direction={"row"}
                    bgcolor={"#fff"}
                    justifyContent={"space-between"}
                >
                    <Stack spacing={30 / 8} alignItems={"center"}>
                        <Typography variant="h3">
                            혼자서 공부해볼래요!
                        </Typography>
                        <img
                            width={80}
                            height={80}
                            src={
                                "https://contents.codetree.ai/ooe/about/soloplay.png"
                            }
                        />
                        <Button
                            sx={{
                                flexDirection: "column",
                                width: 250,
                                height: 80,
                                borderRadius: 8 / 4,
                                borderBottom: "4px solid rgba(0,0,0,0.2)",
                                bgcolor: "#18A0FB",
                                "&:hover": {
                                    bgcolor: darken("#18A0FB", 0.1),
                                },
                            }}
                            onClick={() => scrollIntoView(playgroundIntroRef)}
                        >
                            <Typography variant="body2" color={"#fff"}>
                                혼자 학습하기
                            </Typography>
                            <Typography
                                variant="h4"
                                fontWeight={800}
                                color={"#fff"}
                            >
                                알아보기
                            </Typography>
                        </Button>
                    </Stack>

                    <Stack spacing={30 / 8} alignItems={"center"}>
                        <Typography variant="h3">
                            수업에 활용하고 싶어요!
                        </Typography>
                        <img
                            width={80}
                            height={80}
                            src={
                                "https://contents.codetree.ai/ooe/about/teacher.png"
                            }
                        />
                        <Button
                            sx={{
                                flexDirection: "column",
                                width: 250,
                                height: 80,
                                borderRadius: 8 / 4,
                                borderBottom: "4px solid rgba(0,0,0,0.2)",
                                bgcolor: "#3FCB7E",
                                "&:hover": {
                                    bgcolor: darken("#3FCB7E", 0.1),
                                },
                            }}
                            onClick={() => scrollIntoView(classroomInfoRef)}
                        >
                            <Typography variant="body2" color={"#fff"}>
                                나의 코딩 교실
                            </Typography>
                            <Typography
                                variant="h4"
                                fontWeight={800}
                                color={"#fff"}
                            >
                                알아보기
                            </Typography>
                        </Button>
                    </Stack>

                    <Stack spacing={30 / 8} alignItems={"center"}>
                        <Typography variant="h3">
                            수업 들으러 왔어요!
                        </Typography>
                        <img
                            width={80}
                            height={80}
                            src={
                                "https://contents.codetree.ai/ooe/about/student.png"
                            }
                        />
                        <Button
                            sx={{
                                flexDirection: "column",
                                width: 250,
                                height: 80,
                                borderRadius: 8 / 4,
                                borderBottom: "4px solid rgba(0,0,0,0.2)",
                                bgcolor: "#FFBE00",
                                "&:hover": {
                                    bgcolor: darken("#FFBE00", 0.1),
                                },
                            }}
                            onClick={() => navigate("/classrooms")}
                        >
                            <Typography variant="body2" color={"#fff"}>
                                우리 학교
                            </Typography>
                            <Typography
                                variant="h4"
                                fontWeight={800}
                                color={"#fff"}
                            >
                                학급 찾기
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </MainTemplateWrapper>
        </Stack>
    );
};
