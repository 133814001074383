import { styled } from "@mui/material";

export const StderrBox = styled("code")(({ theme }) => ({
    whiteSpace: "pre-line",
    padding: theme.spacing(1.25),
    border: "1px solid #F8AA4E",
    borderRadius: theme.shape,
    letterSpacing: "0.6px",
    fontSize: 12,
    backgroundColor: "#FFF3CD",
    color: "#664D03",
}));
