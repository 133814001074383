import React from "react";
import { Stack, Typography } from "@mui/material";
import { ConceptBox } from "./ConceptBox";

export const MissionBox = ({ mission, homework }) => (
    <Stack direction="column" spacing={1.25}>
        <Typography
            variant="subtitle1"
            fontWeight={700}
            color="#999999"
            children={`${mission.name} - ${mission.copy}`}
        />
        <Stack direction="column" spacing={2}>
            {mission.concepts.map((concept) => (
                <ConceptBox
                    key={`concept-box-${concept.id}`}
                    concept={concept}
                    homework={homework}
                />
            ))}
        </Stack>
    </Stack>
);
