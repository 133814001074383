import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { PopUpType } from "../modules/ui/UIModel";
import { IndicatorDiv } from "../components/indicator/IndicatorDiv";
import { ExplanationDialog } from "./dialogs/ExplanationDialog";
import { AutoJudgeDialog } from "./dialogs/AutoJudgeDialog";
import { KindSolutionDialog } from "./dialogs/KindSolutionDialog";
import { VariousProblemsDialog } from "./dialogs/VariousProblemsDialog";
import { QnaDialog } from "./dialogs/QnaDialog";

export const PopUpBox = ({
    type,
    sx,
    titleVariant,
    contentVariant,
    indicatorSx,
}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    const TypeToObject = {
        [PopUpType.Explanation]: {
            background: "#000000",
            titleColor: "#5296BB",
            infoColor: "#F5F5F7",
            title: "개념 설명",
            info: "필수 개념, \n" + "풍부한 시각 정보로 \n" + "학습하기.",
        },
        [PopUpType.AutoJudge]: {
            background:
                "linear-gradient(90deg, rgba(185, 18, 35, 0.2) 0%, rgba(185, 18, 35, 0) 100%), #FF5373",
            titleColor: "#35293C",
            infoColor: "#F5F5F7",
            title: "자동 채점 환경",
            info: "설치 없는 \n" + "코드 실습 환경까지\n" + "하나로",
        },
        [PopUpType.KindSolution]: {
            background:
                "linear-gradient(270deg, rgba(176, 143, 236, 0.12) 0%, rgba(176, 143, 236, 0) 100%), #3B1653",
            titleColor: "#9C823F",
            infoColor: "#F5F5F7",
            title: "친절한 해설",
            info: "아는 것도 \n" + "또렷하게.",
        },
        [PopUpType.VariousProblems]: {
            background:
                "linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #102F56",
            titleColor: "#999999",
            infoColor: "#F5F5F7",
            title: "다양한 문제 유형",
            info: "코드 읽기\n" + "코드 채우기\n" + "코드 쓰기",
        },
        [PopUpType.Qna]: {
            background:
                "linear-gradient(90deg, rgba(230, 94, 10, 0.2) 0%, rgba(229, 94, 10, 0) 100%), #F9AA4E",
            titleColor: "#35293C",
            infoColor: "#F5F5F7",
            title: "질문 답변",
            info: "모르는 건\n" + "신속 정확\n" + "바로 바로.",
        },
        [PopUpType.Difficulty]: {
            background: "#F5F5F7",
            titleColor: "#88B337",
            infoColor: "#333333",
            title: "문항별 난이도",
            info: "난이도 정보로\n" + "더 효과적으로\n" + "학습하기.",
        },
    };

    const TypeToDialog = {
        [PopUpType.Explanation]: (
            <ExplanationDialog open={open} onClose={toggleOpen} />
        ),
        [PopUpType.AutoJudge]: (
            <AutoJudgeDialog open={open} onClose={toggleOpen} />
        ),
        [PopUpType.KindSolution]: (
            <KindSolutionDialog open={open} onClose={toggleOpen} />
        ),
        [PopUpType.VariousProblems]: (
            <VariousProblemsDialog open={open} onClose={toggleOpen} />
        ),
        [PopUpType.Qna]: <QnaDialog open={open} onClose={toggleOpen} />,
        [PopUpType.Difficulty]: (
            <ExplanationDialog open={open} onClose={toggleOpen} />
        ),
    };

    const { title, info, infoColor, titleColor, background } =
        TypeToObject[type];

    return (
        <>
            <Box
                sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    background,
                    width: {
                        xs: 256,
                        sm: "100%",
                        md: 340,
                    },
                    height: {
                        xs: 157,
                        md: 250,
                    },
                    p: {
                        xs: 2.5,
                        md: 5,
                    },
                    ...sx,
                }}
                position="relative"
                borderRadius={4}
                boxSizing="border-box"
                onClick={toggleOpen}
            >
                <Stack
                    direction="column"
                    spacing={{
                        xs: 1,
                        md: 2,
                    }}
                >
                    <Typography
                        children={title}
                        variant={titleVariant || "subtitle2"}
                        fontWeight={700}
                        color={titleColor}
                    />
                    <Typography
                        children={info}
                        variant={contentVariant || "h3"}
                        fontWeight={700}
                        color={infoColor}
                        whiteSpace="pre-line"
                    />
                </Stack>
                {type === PopUpType.AutoJudge ? (
                    <IndicatorDiv
                        sx={{
                            position: "absolute",
                            top: {
                                xs: 95,
                                md: 140,
                            },
                            left: {
                                xs: 74,
                                md: 109,
                            },
                            ...indicatorSx,
                        }}
                    />
                ) : null}
            </Box>
            {TypeToDialog[type]}
        </>
    );
};
