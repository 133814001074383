import React from "react";
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { CircularProgressWithLabel } from "../../../../common/templates/CircularProgressWithLabel";
import { TimerOutlined } from "@mui/icons-material";

export const StudyUnitAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
    "&:before": {
        display: "none",
    },
}));

const StudyUnitAccordionSummaryComponent = styled(AccordionSummary)(
    ({ theme }) => ({
        padding: 0,
        "& .MuiAccordionSummary-content": {
            margin: theme.spacing(1.875, 0),
        },
    })
);

export const StudyUnitAccordionSummary = ({
    studyUnit,
    expanded,
    forShowing,
    ...props
}) => {
    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

    const makeStudyUnitProblemString = (studyUnit) =>
        `기본 개념 ${studyUnit.lessonCount}개, 연습 문제 ${studyUnit.quizCount}개, 테스트 ${studyUnit.testCount}개`;

    return (
        <StudyUnitAccordionSummaryComponent {...props}>
            <Stack direction={"row"} alignItems={"center"} width="100%">
                {smBreakpoint ? (
                    !forShowing ? (
                        <Stack alignItems={"center"}>
                            <CircularProgressWithLabel
                                fontSizeBig={16}
                                value={studyUnit.passedProblemPercent}
                                size={50}
                                sx={{
                                    mr: 3,
                                }}
                            />
                            <Stack
                                direction={"row"}
                                width={50}
                                justifyContent={"center"}
                                sx={{
                                    mr: 3,
                                }}
                            >
                                <Typography
                                    variant={"caption"}
                                    color={"#3FCB7E"}
                                    children={`${
                                        !studyUnit.minuteLeft ||
                                        studyUnit.minuteLeft > 180
                                            ? "180+"
                                            : studyUnit.minuteLeft
                                    }${"분 남음"}`}
                                />
                            </Stack>
                        </Stack>
                    ) : (
                        <Box width={50} mr={3} />
                    )
                ) : null}
                <Stack
                    flex={1}
                    direction={"row"}
                    justifyContent="space-around"
                    alignItems={"center"}
                    sx={[
                        {
                            p: 2,
                            borderRadius: 2,
                            "&:hover": {
                                backgroundColor: "#F5F5F7",
                            },
                            ml: {
                                sm: 0,
                                xs: 3,
                            },
                        },
                        expanded && {
                            backgroundColor: "#F3F9F5",
                            "&:hover": {
                                backgroundColor: "#F3F9F5",
                            },
                        },
                    ]}
                >
                    <Stack direction="column" flex={1}>
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            color="#1A1A1A"
                        >
                            {studyUnit.name}
                        </Typography>
                        <Typography
                            variant="caption"
                            color={expanded ? "#4D4D4D" : "#808080"}
                        >
                            {makeStudyUnitProblemString(studyUnit)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={expanded ? "#1A1A1A" : "#666666"}
                            mt={1}
                        >
                            {studyUnit.description}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center">
                        <Typography
                            variant={"caption"}
                            children={"평균 소요 시간"}
                        />
                        <Stack direction={"row"} alignItems={"center"}>
                            <TimerOutlined
                                sx={{
                                    fontSize: 16,
                                }}
                            />
                            <Typography
                                variant={"body2"}
                                children={`${
                                    !studyUnit.solvedMinute ||
                                    studyUnit.solvedMinute > 180
                                        ? "180+"
                                        : studyUnit.solvedMinute
                                }${"분"}`}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </StudyUnitAccordionSummaryComponent>
    );
};

export const StudyUnitAccordionDetails = styled(AccordionDetails)(
    ({ theme }) => ({
        padding: 0,
        paddingLeft: theme.spacing(0),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(6),
        },
    })
);
