import { GET, PATCH, unauthorizedPOST } from "../../../utils/api";

class AuthRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    login({ body }) {
        return unauthorizedPOST("/api_token_auth/", body);
    }

    getUserFromToken() {
        return GET("/users/get_user_from_token/");
    }

    updateProfile({ profileId, body }) {
        return PATCH(`/users/profile/${profileId}/`, body);
    }

    getSocialUser({ body }) {
        return unauthorizedPOST("social-login/", body);
    }
}

export default new AuthRepository();
