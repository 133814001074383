import React from "react";
import ReactPlayer from "react-player";
import { LatestSolvedProblemHeader } from "./LatestSolvedProblemHeader";

export const Header = ({ latestSolvedProblemSet, isLogin, username }) => {
    if (!isLogin) {
        return (
            <ReactPlayer
                url="https://contents.codetree.ai/homepage/videos/company-intro-1080p.mp4"
                controls
                width="100%"
                height="56.25%"
            />
        );
    }

    return (
        <LatestSolvedProblemHeader
            username={username}
            latestSolvedProblemSet={latestSolvedProblemSet}
        />
    );
};
