import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { NotificationsTemplate } from "./NotificationsTemplate";
import { useStores } from "../../modules/useStores";

export const NotificationsContainer = observer(() => {
    const { notificationStore } = useStores();

    const { notifications, isFetching, unseenCount } = notificationStore;

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            notificationStore.findUnseenCount();
        }, 1000 * 60 * 5);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (open) {
            notificationStore.findUnseenCount();
            notificationStore.findAll();
        }
    }, [open]);

    return (
        <NotificationsTemplate
            isFetching={isFetching}
            open={open}
            toggleOpen={toggleOpen}
            handleClose={handleClose}
            notifications={notifications}
            unseenCount={unseenCount}
        />
    );
});
