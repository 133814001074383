import React, { useState } from "react";
import {
    ButtonBase,
    Collapse,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CodeIcon from "@mui/icons-material/Code";
import { TestCaseEdit } from "./templates/TestCaseEdit";
import { LanguageType } from "../../../common/modules/problems/ProblemModel";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { DefaultCodeEdit } from "./templates/DefaultCodeEdit";
import { v4 as uuidv4 } from "uuid";
import { FiddleButton } from "./components/FiddleButton";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { DefaultCodeDialog } from "./templates/DefaultCodeDialog";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

export const PSFiddleEditForm = ({
    inputs,
    handleChange,
    handleSave,
    isEdit,
    handleReset,
    handlePreview,
}) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);

    const [defaultCodeOpen, setDefaultCodeOpen] = useState(false);

    const addLanguage = (language) => {
        handleChange("defaultCodes", [
            ...inputs.defaultCodes,
            {
                language,
                code: "",
                key: uuidv4(),
            },
        ]);
        setAnchorEl(null);
    };

    const components = [
        {
            backgroundColor: "#3FCB7E",
            icon: <AddIcon fontSize={"small"} />,
            description: "채점 예제를 추가해보세요.",
            onClick: () =>
                handleChange("testCases", [
                    ...inputs.testCases,
                    {
                        stdin: "",
                        stdout: "",
                        isVisible: true,
                        key: uuidv4(),
                    },
                ]),
            list: inputs.testCases,
            ListComponent: TestCaseEdit,
            handleChange: (key, name, value) => {
                handleChange(
                    "testCases",
                    inputs.testCases.map((testCase) => {
                        if (testCase.key === key) {
                            return {
                                ...testCase,
                                [name]: value,
                            };
                        }

                        return testCase;
                    })
                );
            },
            handleDelete: (key) => {
                handleChange(
                    "testCases",
                    inputs.testCases.filter((testCase) => testCase.key !== key)
                );
            },
        },
        {
            backgroundColor: "#18A0FB",
            icon: <CodeIcon fontSize={"small"} />,
            description: "코드 작성에 도움이 될 뼈대 코드를 추가해보세요.",
            onClick: (e) => setAnchorEl(e.currentTarget),
            list: inputs.defaultCodes,
            ListComponent: DefaultCodeEdit,
            handleChange: (key, name, value) => {
                handleChange(
                    "defaultCodes",
                    inputs.defaultCodes.map((defaultCode) => {
                        if (defaultCode.key === key) {
                            return {
                                ...defaultCode,
                                [name]: value,
                            };
                        }

                        return defaultCode;
                    })
                );
            },
            handleDelete: (key) => {
                handleChange(
                    "defaultCodes",
                    inputs.defaultCodes.filter(
                        (defaultCode) => defaultCode.key !== key
                    )
                );
            },
            menu: (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    sx={{
                        color: "#202020",
                    }}
                >
                    {Object.values(LanguageType).map((language) => (
                        <MenuItem
                            key={`menu-${language}`}
                            onClick={() => addLanguage(language)}
                            disabled={inputs.defaultCodes.some(
                                (defaultCode) =>
                                    defaultCode.language === language
                            )}
                        >
                            {language}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={() => setDefaultCodeOpen(true)}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={0.5}
                        >
                            <HelpOutlineOutlinedIcon fontSize={"small"} />
                            <Typography
                                variant={"body1"}
                                children={"뼈대 코드란"}
                            />
                        </Stack>
                    </MenuItem>
                </Menu>
            ),
        },
    ];

    return (
        <Stack
            sx={{
                borderRadius: 2,
                border: "3px dashed #CDCDCD",
                px: 2.5,
                py: 3.75,
                m: 5,
            }}
            spacing={4.25}
            minWidth={700}
        >
            <TextField
                placeholder={"간단한 코드 작성 퀴즈를 작성해보세요."}
                disableUnderline
                value={inputs.title}
                onChange={(e) => handleChange("title", e.target.value)}
                InputProps={{
                    sx: {
                        ...(inputs.title.length < 10 &&
                        !/\r|\n/.exec(inputs.title)
                            ? theme.typography.h3
                            : theme.typography.body1),
                        ml: 1.25,
                    },
                }}
                helperText={
                    inputs.title.length > 0
                        ? `내용이 길거나 줄바꿈을하면 글씨 크기가 작아집니다.`
                        : ``
                }
                multiline
            />
            <Stack spacing={1.25}>
                {components.map((component, i) => (
                    <Stack px={1.25} key={`added-${i}`}>
                        {component.list.map((item) => (
                            <component.ListComponent
                                key={item.key}
                                item={item}
                                handleChange={component.handleChange}
                                handleDelete={component.handleDelete}
                            />
                        ))}
                        <ButtonBase
                            onClick={component.onClick}
                            sx={{
                                justifyContent: "flex-start",
                            }}
                        >
                            <Stack
                                direction={"row"}
                                spacing={0.75}
                                alignItems={"center"}
                                py={1}
                            >
                                <Stack
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        backgroundColor:
                                            component.backgroundColor,
                                        borderRadius: "50%",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    {component.icon}
                                </Stack>
                                <Typography
                                    children={component.description}
                                    variant={"subtitle1"}
                                    fontWeight={500}
                                />
                            </Stack>
                        </ButtonBase>
                        {component.menu}
                    </Stack>
                ))}
            </Stack>
            <DefaultCodeDialog
                open={defaultCodeOpen}
                onClose={() => setDefaultCodeOpen(false)}
            />
            <Collapse in={!!inputs.title}>
                <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    spacing={2.5}
                    sx={{
                        my: 1.25,
                        mx: 2.5,
                    }}
                >
                    {isEdit ? (
                        <FiddleButton
                            onClick={handleReset}
                            label={"수정취소"}
                            color={"#D24D57"}
                            Icon={ReplayOutlinedIcon}
                        />
                    ) : null}
                    <FiddleButton
                        label={"미리보기"}
                        color={"#666666"}
                        Icon={PlagiarismOutlinedIcon}
                        onClick={handlePreview}
                    />
                    <FiddleButton
                        label={"저장하기"}
                        color={"#666666"}
                        Icon={BackupOutlinedIcon}
                        onClick={handleSave}
                    />
                </Stack>
            </Collapse>
        </Stack>
    );
};
