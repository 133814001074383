import React from "react";
import { Box } from "@mui/material";
import { SectionTag } from "./SectionTag";
import { PopUpType } from "../../../../common/modules/ui/UIModel";
import { PopUpBox } from "../../../../common/templates/PopUpBox";

export const PopUpGrid = () => {
    const filteredTypes = Object.values(PopUpType).filter(
        (type) => type !== PopUpType.Difficulty
    );

    return (
        <Box
            display="grid"
            // direction={"row"}
            gap={2.5}
            sx={{
                ml: -2.5,
                mt: -2.5,
            }}
            gridTemplateColumns={{
                md: "repeat(3, 1fr)",
                sm: "repeat(2, 1fr)",
                xs: "repeat(6, 1fr)",
            }}
            gridTemplateRows={{
                md: "repeat(2, 1fr)",
                sm: "repeat(3, 1fr)",
                xs: "repeat(1, 1fr)",
            }}
        >
            <Box
                pt={5}
                sx={{
                    width: {
                        xs: 256,
                        md: 340,
                    },
                    height: {
                        xs: 157,
                        md: 250,
                    },
                }}
            >
                <SectionTag
                    title="공통 구성"
                    description={
                        "빈틈없는 구성으로\n한번에 확실하게 학습하세요."
                    }
                />
            </Box>
            {filteredTypes.map((type) => (
                <PopUpBox type={type} key={`pop-up-box-${type}`} />
            ))}
        </Box>
    );
};
