import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { ProblemAutoComplete } from "../../templates/ProblemAutoComplete";
import { useStores } from "../../../../../common/modules/useStores";

export const CuratedProblemAutoCompleteContainer = observer(
    ({ missionId, problemId, setProblemId }) => {
        const { curatedProblemStore } = useStores();
        const { curatedProblems, curatedProblem } = curatedProblemStore;

        const [loading, setLoading] = useState(false);

        useEffect(() => {
            curatedProblemStore.find({
                missionId,
                problemId,
            });
            curatedProblemStore.searchProblems({
                problemId,
                missionId,
            });
        }, [problemId, missionId]);

        const debouncedSearchProblems = _.debounce((_name) => {
            setLoading(true);
            curatedProblemStore
                .searchProblems({
                    name: _name,
                    missionId,
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 100);

        const getProblemOptions = (_name) => {
            debouncedSearchProblems(_name);
        };

        if (problemId && !curatedProblem) return null;

        return (
            <ProblemAutoComplete
                setProblemId={setProblemId}
                problems={curatedProblems}
                problem={curatedProblem}
                loading={loading}
                getProblemOptions={getProblemOptions}
            />
        );
    }
);
