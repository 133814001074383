import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../utils/immutableLodash";
import { camelizeKeys, snakelizeKeys } from "../../../utils/renameKeys";
import { LanguageType } from "../problems/ProblemModel";
import { v4 as uuidv4 } from "uuid";

export class TestCaseModel {
    stdin = "";

    stdout = "";

    order = 0;

    isVisible = true;

    key = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const testCase = pickCls(this, camelizeKeys(data));
        testCase.key = uuidv4();
        set(this, testCase);
    }
}

export class DefaultCodeModel {
    language = LanguageType.Python3;

    code = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const defaultCode = pickCls(this, camelizeKeys(data));
        defaultCode.key = uuidv4();
        set(this, defaultCode);
    }
}

export class FiddleModel {
    id = 0;

    title = "";

    defaultCodes = [];

    testCases = [];

    key = "";

    username = "";

    isPublished = true;

    isEdit = true;

    isOwner = false;

    isPrivate = true;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const fiddle = pickCls(this, camelizeKeys(data));
        fiddle.testCases = fiddle.testCases.map(
            (testCase) => new TestCaseModel(testCase)
        );
        fiddle.defaultCodes = fiddle.defaultCodes.map(
            (defaultCode) => new DefaultCodeModel(defaultCode)
        );
        set(this, fiddle);
    }
}

export class FiddleDAOModel {
    title = "";

    defaultCodes = [];

    testCases = [];

    isPublished = true;

    isPrivate = true;

    constructor(data) {
        const fiddle = pickCls(this, data);
        Object.assign(this, snakelizeKeys(fiddle));
    }
}

export class TestCaseDAOModel {
    id = 0;

    stdin = "";

    stdout = "";

    order = 0;

    isVisible = true;

    constructor(data) {
        const testCase = pickCls(this, data);
        Object.assign(this, snakelizeKeys(testCase));
    }
}

export class DefaultCodeDAOModel {
    code = "";

    language = LanguageType.Python3;

    constructor(data) {
        const defaultCode = pickCls(this, data);
        Object.assign(this, snakelizeKeys(defaultCode));
    }
}
