import React, { forwardRef } from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";
import { ConceptAccordionBox } from "../../Missions/templates/ConceptAccordionBox";

export const ContentsBox = forwardRef(({ mission, concepts }, ref) => {
    const { lessonCount, quizCount, testCount } = mission;

    return (
        <Paper
            ref={ref}
            sx={{
                boxShadow: 0,
                pt: 12.5,
            }}
        >
            <MainTemplateWrapper>
                <Stack
                    direction={{ md: "row", xs: "column" }}
                    justifyContent={{ md: "space-between", xs: "flex-start" }}
                    spacing={{ md: 0, xs: 4.25 }}
                >
                    <Stack
                        direction={{ md: "column", xs: "row" }}
                        spacing={{ md: 4.25, xs: 0 }}
                        justifyContent={{
                            md: "flex-start",
                            xs: "space-between",
                        }}
                    >
                        <Typography
                            variant="h1"
                            children="목차"
                            fontWeight={700}
                            sx={{
                                flexShrink: 0,
                            }}
                        />
                        <Stack
                            direction={{
                                md: "column",
                                sm: "row",
                                xs: "column",
                            }}
                            spacing={{ md: 4.25, sm: 5, xs: 1.5 }}
                            alignItems={{ sm: "initial", xs: "flex-end" }}
                        >
                            <Stack direction="column">
                                <Stack direction="row" spacing={0.75}>
                                    <CalendarTodayIcon
                                        sx={{
                                            color: "#666666",
                                            fontSize: 14,
                                        }}
                                    />
                                    <Typography
                                        variant="caption"
                                        children="학습 소요 시간"
                                        color="#666666"
                                    />
                                </Stack>
                                <Typography
                                    variant="body1"
                                    children="2개월 ~ 6개월"
                                    fontWeight={700}
                                />
                            </Stack>
                            <Stack
                                direction={{ md: "column", xs: "row" }}
                                spacing={{ md: 4.25, xs: 5 }}
                            >
                                <Stack direction="column">
                                    <Typography
                                        variant="caption"
                                        children="기본 개념"
                                        color="#666666"
                                    />
                                    <Typography
                                        variant="body1"
                                        children={`${lessonCount}문항`}
                                        fontWeight={700}
                                    />
                                </Stack>
                                <Stack direction="column">
                                    <Typography
                                        variant="caption"
                                        children="연습 문제"
                                        color="#666666"
                                    />
                                    <Typography
                                        variant="body1"
                                        children={`${quizCount}문항`}
                                        fontWeight={700}
                                    />
                                </Stack>
                                <Stack direction="column">
                                    <Typography
                                        variant="caption"
                                        children="테스트"
                                        color="#666666"
                                    />
                                    <Typography
                                        variant="body1"
                                        children={`${testCount}문항`}
                                        fontWeight={700}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <ConceptAccordionBox
                        concepts={concepts}
                        forShowing
                        sx={{
                            boxShadow: 0,
                            width: { md: 700, xs: "100%" },
                            p: 0,
                            border: "none",
                        }}
                    />
                </Stack>
                <Divider sx={{ mt: 12.5 }} />
            </MainTemplateWrapper>
        </Paper>
    );
});
