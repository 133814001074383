import React from "react";
import {
    Avatar,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";

export const StudentForm = ({ input, handleDelete, handleChange, isAdd }) => {
    const { id, username, name, phoneNumber } = input;

    return (
        <Stack
            direction="column"
            p={2.5}
            pt={1.25}
            width={600}
            sx={{
                bgcolor: "background.paper",
                borderRadius: 3,
                boxShadow: 2,
            }}
        >
            {isAdd ? (
                <IconButton
                    sx={{
                        alignSelf: "flex-end",
                        color: "#9CA8B9",
                        border: "1px solid #9CA8B9",
                        p: 0.5,
                    }}
                    onClick={() => handleDelete(id)}
                >
                    <CloseIcon sx={{ fontSize: 12 }} />
                </IconButton>
            ) : null}
            <Stack direction="row" spacing={5}>
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={1.25}
                    width={150}
                >
                    <Avatar
                        sx={{
                            width: 96,
                            height: 96,
                        }}
                    >
                        <PersonIcon sx={{ fontSize: 64 }} />
                    </Avatar>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="body2" children="이름" />
                        <TextField
                            inputProps={{
                                sx: {
                                    px: 2.5,
                                    py: 1.25,
                                    fontWeight: 700,
                                    fontSize: 16,
                                    lineHeight: "24px",
                                },
                            }}
                            sx={{
                                bgcolor: "background.paper",
                            }}
                            value={name}
                            onChange={(e) =>
                                handleChange(id, "name", e.target.value)
                            }
                        />
                    </Stack>
                </Stack>
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={4.5}
                    flexGrow={1}
                >
                    <Stack direction="column" spacing={1} width="100%">
                        <Typography variant="body2" children="아이디" />
                        <TextField
                            disabled={!isAdd}
                            fullWidth
                            inputProps={{
                                sx: {
                                    px: 2.5,
                                    py: 1.25,
                                    fontWeight: 700,
                                    fontSize: 16,
                                    lineHeight: "24px",
                                },
                            }}
                            value={username}
                            sx={{
                                bgcolor: "background.paper",
                            }}
                            onChange={(e) =>
                                handleChange(id, "username", e.target.value)
                            }
                        />
                    </Stack>
                    <Stack direction="column" spacing={1} width="100%">
                        <Typography variant="body2" children="휴대폰 번호" />
                        <TextField
                            fullWidth
                            placeholder="XXX-XXXX-XXXX"
                            inputProps={{
                                sx: {
                                    px: 2.5,
                                    py: 1.25,
                                    fontWeight: 700,
                                    fontSize: 16,
                                    lineHeight: "24px",
                                },
                            }}
                            sx={{
                                bgcolor: "background.paper",
                            }}
                            value={phoneNumber}
                            onChange={(e) =>
                                handleChange(id, "phoneNumber", e.target.value)
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
