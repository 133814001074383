import { GET } from "../../../../utils/api";
import { snakelizeKeys } from "../../../../utils/renameKeys";
import { objectToQueryString } from "../../../../utils/immutableLodash";

class CuratedProblemRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({ studyUnitId, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);

        return GET(
            `problems/study_units/${studyUnitId}/curated_problems/${queryParams}`
        );
    }

    searchProblems(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`problems/curated_problems/${queryParams}`);
    }

    find({ problemId, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`problems/curated_problems/${problemId}/${queryParams}`);
    }

    findDetail({ problemId, missionId }) {
        return GET(
            `problems/curated_problems_detail/${problemId}/?mission_id=${missionId}`
        );
    }
}

export default new CuratedProblemRepository();
