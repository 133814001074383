import React, { useState } from "react";
import {
    Chip,
    Divider,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { Markdown } from "../../../../../../common/components/markdown/Markdown";
import {
    MotherTongueIcon,
    MotherTongueLabel,
    MotherTongueType,
} from "../../../../../../common/modules/auth/UserModel";
import { Copyright } from "../Copyright";
import { getBetweenText } from "../../../../../../utils/string";
import { TestCasesBox } from "./templates/TestCasesBox";
import { JudgeSpecBox } from "./templates/JudgeSpecBox";

export const DescriptionTemplate = ({
    descriptionModel,
    defaultMotherTongue,
    changeMotherTongueAPI,
    stepInfo,
}) => {
    // const mdRaw = motherTongue === MotherTongueType.KOR ||
    //     !descriptionModel.descriptionEn ? descriptionModel.description.trim() : descriptionModel.descriptionEn.trim();
    // const mdMatch = mdRaw.match(
    //     /(#+)(\s+)([^\n#]+)(#*)([\r\n|\r|\n]*)([\S\s]*)/
    // );
    // const title = mdMatch[3];

    // const description = {
    //     title,
    //     body: {
    //         description: mdMatch[6].split("## 입출력 예제")[0],
    //         testCaseExplanation: getBetweenText(
    //             mdMatch[6],
    //             "## 예제 설명",
    //             "## 제한"
    //         ),
    //     },
    // }

    // const getDescription = (_descriptionModel) =>
    //     motherTongue === MotherTongueType.KOR ||
    //     !_descriptionModel.descriptionEn
    //         ? _descriptionModel.description.split("## 입출력 예제")[0]
    //         : _descriptionModel.descriptionEn.split("## Examples")[0];

    // const getTestCaseExplanation = (_descriptionModel) =>
    //     motherTongue === MotherTongueType.KOR ||
    //     !_descriptionModel.descriptionEn
    //         ? getBetweenText(
    //               _descriptionModel.description,
    //               "## 예제 설명",
    //               "## 제한"
    //           )
    //         : getBetweenText(
    //               _descriptionModel.descriptionEn,
    //               "## Description of Example",
    //               "## Constraints"
    //           );

    const [motherTongue, setMotherTongue] = useState(defaultMotherTongue);

    const changeMotherTongue = (_motherTongue) => {
        setMotherTongue(_motherTongue);
        changeMotherTongueAPI(_motherTongue);
    };

    const isCodeBlockProblem =
        descriptionModel.testCases && descriptionModel.testCases.length;

    const splitDescription = (_problem) => {
        if (motherTongue === MotherTongueType.KOR || !_problem.descriptionEn) {
            const mdRaw = _problem.description.trim();
            const mdMatch = mdRaw.match(
                /(#+)(\s+)([^\n#]+)(#*)([\r\n|\r|\n]*)([\S\s]*)/
            );
            const title = mdMatch[3];
            return {
                title,
                body: {
                    description: mdMatch[6].split("## 입출력 예제")[0],
                    testCaseExplanation: getBetweenText(
                        mdMatch[6],
                        "## 예제 설명",
                        "## 제한"
                    ),
                },
            };
        } else {
            const mdRaw = _problem.descriptionEn.trim();
            const mdMatch = mdRaw.match(
                /(#+)(\s+)([^\n#]+)(#*)([\r\n|\r|\n]*)([\S\s]*)/
            );
            const title = mdMatch[3];
            return {
                title,
                body: {
                    description: mdMatch[6].split("## Examples")[0],
                    testCaseExplanation: getBetweenText(
                        mdMatch[6],
                        "## Description of Example",
                        "## Constraints"
                    ),
                },
            };
        }
    };

    const { title, body } = splitDescription(descriptionModel);

    const indexSuffix = stepInfo
        ? `${stepInfo.curr}번째 문제 (총 ${stepInfo.total}개)`
        : null;

    return (
        <Stack direction="column" spacing={2}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={1.25}
            >
                <Stack alignItems="flex-start" spacing={0.75}>
                    {indexSuffix && <Chip size="small" label={indexSuffix} />}
                    <Typography variant="h1" fontWeight={800}>
                        {title}
                    </Typography>
                </Stack>
                {descriptionModel.descriptionEn ? (
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="body1"
                                children="다국어 지원 가능:"
                            />
                            <FormControl size={"small"}>
                                <Select value={motherTongue} autoWidth>
                                    {Object.values(MotherTongueType).map(
                                        (val) => (
                                            <MenuItem
                                                key={val}
                                                value={val}
                                                onClick={() =>
                                                    changeMotherTongue(val)
                                                }
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <img
                                                        src={
                                                            MotherTongueIcon[
                                                                val
                                                            ]
                                                        }
                                                        height={24}
                                                        width={24}
                                                    />
                                                    <Typography
                                                        children={
                                                            MotherTongueLabel[
                                                                val
                                                            ]
                                                        }
                                                        variant="body2"
                                                    />
                                                </Stack>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
            <Divider />
            <Markdown children={body.description} />
            {isCodeBlockProblem ? (
                <TestCasesBox
                    testCases={descriptionModel.testCases}
                    motherTongue={motherTongue}
                />
            ) : null}
            <Markdown children={body.testCaseExplanation || ""} />
            {isCodeBlockProblem ? (
                <JudgeSpecBox
                    motherTongue={motherTongue}
                    memoryLimit={descriptionModel.memoryLimit}
                    timeLimit={descriptionModel.timeLimit}
                />
            ) : null}
            <Divider />
            <Copyright />
        </Stack>
    );
};
