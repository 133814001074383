import React from "react";
import {
    Box,
    ButtonBase,
    keyframes,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ProblemStatusIcon } from "../../../../common/templates/ProblemStatusIcon";
import { getProblemTypeToLabel } from "../../../../common/modules/missions/curatedProblem/CuratedProblemModel";
// import moment from "moment";
import { TimerOutlined } from "@mui/icons-material";

export const CuratedProblemBox = ({
    curatedProblem,
    handleClick,
    handleFocusGroup,
    focusGroup,
    index,
    curatedProblems,
    isDense,
    isDark,
    selected,
    removeFocusGroup,
    bgcolor,
    selectable,
    getChecked,
}) => {
    const easeOutElastic = keyframes`
        0% {
            transform: translateY(100%)
        }
        16% {
            transform: translateY(-32.27%)
        }
        28% {
            transform: translateY(13.12%)
        }
        44% {
            transform: translateY(-4.63%)
        }
        59% {
            transform: translateY(1.64%)
        }
        73% {
            transform: translateY(-0.58%)
        }
        88% {
            transform: translateY(0.2%)
        }
        100% {
            transform: translateY(0%)
        }
    `;

    const theme = useTheme();

    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    const currProblemType = getProblemTypeToLabel(curatedProblem);

    const prevProblemType = getProblemTypeToLabel(curatedProblems[index - 1]);

    return (
        <Box
            onMouseOver={() => handleFocusGroup(currProblemType)}
            onMouseLeave={() => handleFocusGroup(-1)}
            bgcolor={bgcolor}
            sx={{
                cursor: "pointer",
                userSelect: "none",
            }}
        >
            <Stack direction={"row"} flexGrow={1}>
                {!removeFocusGroup && smBreakpoint ? (
                    <Stack
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                            width: 50,
                            position: "relative",
                            mr: isDense ? 1 : 3,
                        }}
                    >
                        {!index || currProblemType !== prevProblemType ? (
                            <>
                                <Typography
                                    variant={"caption"}
                                    sx={[
                                        {
                                            transition: "color 0.3s",
                                        },
                                        focusGroup === currProblemType && {
                                            color: theme.palette.primary.main,
                                        },
                                    ]}
                                >
                                    {currProblemType}
                                </Typography>
                                {index !== curatedProblems.length - 1 ? (
                                    <Box
                                        width={"0.5px"}
                                        height={"calc(30%)"}
                                        position={"absolute"}
                                        bottom={-10}
                                        sx={[
                                            {
                                                backgroundColor: "#666666",
                                                transition:
                                                    "background-color 0.3s",
                                            },
                                            focusGroup ===
                                                curatedProblem.focusGroup && {
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                            },
                                        ]}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Box
                                width={"0.5px"}
                                height={"calc(100% + 16px)"}
                                position={"absolute"}
                                bottom={-10}
                                sx={[
                                    {
                                        backgroundColor: "#666666",
                                        transition: "background-color 0.3s",
                                    },
                                    focusGroup === currProblemType && {
                                        backgroundColor:
                                            theme.palette.primary.main,
                                    },
                                ]}
                            />
                        )}
                    </Stack>
                ) : null}
                <Stack
                    component={ButtonBase}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    onClick={() => handleClick(curatedProblem)}
                    flexGrow={1}
                    p={2}
                    sx={[
                        {
                            ml: 0,
                            my: 0.5,
                            "&:hover, &:focus": {
                                background: isDark ? "#171716" : "#F5F5F7",
                                borderRadius: 2,
                                "& svg": {
                                    animation: !selectable
                                        ? `${easeOutElastic} 1s`
                                        : `none`,
                                },
                            },
                            transition: `all 250ms cubic-bezier(0.4, 0, 0.2, 1)`,
                        },
                        selected && {
                            background: isDark ? "#171716" : "#F5F5F7",
                            borderRadius: 2,
                        },
                    ]}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <ProblemStatusIcon
                            selectable={selectable}
                            curatedProblem={curatedProblem}
                            getChecked={getChecked}
                        />
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Typography variant={"body2"}>
                                {curatedProblem.name}
                            </Typography>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                height={24}
                            >
                                <Typography
                                    children={`#${curatedProblem.number}`}
                                    color="#6D7A9F"
                                    variant="caption"
                                    fontWeight={700}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                        <TimerOutlined
                            sx={{
                                fontSize: 14,
                            }}
                        />
                        <Typography
                            variant={"caption"}
                            children={`${
                                !curatedProblem.solvedMinute ||
                                curatedProblem.solvedMinute > 180
                                    ? "180+"
                                    : curatedProblem.solvedMinute
                            }${"분"}`}
                        />
                    </Stack>
                    {/* <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        {curatedProblem.isClickedDate ? (
                            <Typography color="#999999" variant="overline">
                                {moment(curatedProblem.isClickedDate).format(
                                    "YY.MM.DD"
                                )}
                            </Typography>
                        ) : null}
                    </Stack> */}
                </Stack>
            </Stack>
        </Box>
    );
};
