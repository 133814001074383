import {
    set,
    action,
    computed,
    override,
    makeObservable,
    observable,
} from "mobx";
import { camelizeKeys } from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";
import {
    LevelType,
    ProblemStatusType,
} from "../missions/curatedProblem/CuratedProblemModel";
import { TreeNodeModel } from "./TreeNodeModel";

export class ProblemTreeNodeModel extends TreeNodeModel {
    _id = 0;
    set id(_id) {
        this._id = _id;
    }
    get id() {
        return this._id;
    }

    _problemId = 0;
    set problemId(_problemId) {
        this._problemId = _problemId;
    }
    get problemId() {
        return this._problemId;
    }

    _alias = "";
    set alias(_alias) {
        this._alias = _alias;
    }
    get alias() {
        return this._alias;
    }

    _number = 0;
    set number(_number) {
        this._number = _number;
    }
    get number() {
        return this._number;
    }

    _level = LevelType.Easy;
    set level(_level) {
        this._level = _level;
    }
    get level() {
        return this._level;
    }

    _isIntro = false;
    set isIntro(_isIntro) {
        this._isIntro = _isIntro;
    }
    get isIntro() {
        return this._isIntro;
    }

    _isTest = false;
    set isTest(_isTest) {
        this._isTest = _isTest;
    }
    get isTest() {
        return this._isTest;
    }

    _progressStatus = ProblemStatusType.Ready;
    set progressStatus(_progressStatus) {
        this._progressStatus = _progressStatus;
    }
    get progressStatus() {
        if (!this._progressStatus) return ProblemStatusType.Ready;
        return this._progressStatus;
    }

    _isLock = false;
    set isLock(_isLock) {
        this._isLock = _isLock;
    }
    get isLock() {
        return this._isLock;
    }

    nodeLabel = "problem";

    updateProgressStatus(_progressStatus) {
        this._progressStatus = _progressStatus;
    }

    constructor(data) {
        super();
        makeObservable(this, {
            _id: observable,

            _problemId: observable,

            _alias: observable,

            _number: observable,

            _level: observable,

            _isIntro: observable,

            _isTest: observable,

            _progressStatus: observable,

            id: computed,

            problemId: computed,

            alias: computed,

            number: computed,

            level: computed,

            isIntro: computed,

            isTest: computed,

            isLock: computed,

            progressStatus: computed,

            name: override,

            nodeLabel: override,

            nodeId: override,

            parentNode: override,

            parentName: override,

            parentNodeLabel: override,

            parentNodeId: override,

            updateProgressStatus: action.bound,
        });
        const newData = pickCls(this, camelizeKeys(data));
        set(this, newData);
    }
}
