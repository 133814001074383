import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Badge, Box, Paper, Popper, Stack, Tab, Tabs } from "@mui/material";
import { anchorRef, bindPopper } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { EditorContainer } from "./templates/editor/EditorContainer";
import { ResizableDrawer } from "../../../../common/templates/ResizableDrawer";
import { IntroductionContainer } from "./templates/introduction/IntroductionContainer";
import { DescriptionContainer } from "./templates/description/DescriptionContainer";
import { ExplanationContainer } from "./templates/explanation/ExplanationContainer";
import { SubmissionsContainer } from "./templates/submissions/SubmissionsContainer";
import { DiscussionsPage } from "./templates/discussions/DiscussionsPage";
import { ProblemType } from "../../../../common/modules/missions/mission/MissionModel";
import { TutorialCard } from "./templates/TutorialCard";
import { useStores } from "../../../../common/modules/useStores";
import { TutorialType } from "../../../../common/modules/ui/tutorial/TutorialModel";
import moment from "moment";

export const PlaygroundPage = ({
    problem,
    objectId,
    solutionVisible,
    stepInfo,
    submitUntil = moment().add(10, "y"),
    discussionVisible = true,
    aiEnabled = false,
}) => {
    const PlaygroundDrawerWidthKey = "PlaygroundDrawerWidthKey";

    const navigate = useNavigate();

    const { tutorialStore } = useStores();
    const { currTutorial, setTutorial } = tutorialStore;

    const TAB_ITEMS = [
        {
            label: "기본개념",
            value: "introduction",
            element: (
                <IntroductionContainer
                    problem={problem}
                    objectId={objectId}
                    stepInfo={stepInfo}
                />
            ),
        },
        {
            label: "문제",
            value: "description",
            element: (
                <DescriptionContainer problem={problem} stepInfo={stepInfo} />
            ),
        },
        {
            label: "해설",
            value: "explanation",
            element: <ExplanationContainer problem={problem} />,
        },
        {
            label: "제출기록",
            value: "submissions",
            element: (
                <SubmissionsContainer problem={problem} objectId={objectId} />
            ),
        },
        {
            label: "토론",
            value: "discussions",
            element: <DiscussionsPage problem={problem} />,
        },
    ];

    const redirectToTab = (value) => {
        navigate(value, { replace: true });
    };

    const { pathname } = window.location;
    const activeTabItem = TAB_ITEMS.find((item) =>
        pathname.includes(item.value)
    );

    const filteredTabItems = TAB_ITEMS.filter((item) => {
        if (!problem.isIntro && item.value === "introduction") return false;

        if (item.value === "explanation" && !solutionVisible) return false;

        if (item.value === "discussions" && !discussionVisible) return false;

        if (
            problem.type !== ProblemType.CodeBlock &&
            item.value === "submissions"
        )
            return false;

        return true;
    });

    const getIcon = (_item) => {
        if (_item.value === "discussions" && problem.discussionsCount) {
            return (
                <Badge
                    sx={{
                        backgroundColor: "#FF5555",
                        color: "common.white",
                        borderRadius: "50%",
                        p: 0.5,
                        width: 20,
                        height: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    children={problem.discussionsCount}
                />
            );
        }
        return null;
    };

    const popupState = usePopupState({
        variant: "popover",
        popupId: "demoMenu",
    });

    useEffect(() => {
        if (popupState.anchorEl && currTutorial === TutorialType.Problem) {
            popupState.open();
        }
    }, [popupState.anchorEl, currTutorial]);

    const handleNextTutorial = () => {
        popupState.close();
        setTutorial(TutorialType.Language);
    };

    return (
        <Stack
            direction={{ md: "row", xs: "column" }}
            flexGrow={1}
            flexShrink={1}
            overflow={{ md: "hidden", xs: "auto" }}
        >
            <ResizableDrawer
                localStorageKey={PlaygroundDrawerWidthKey}
                minWidth={450}
            >
                <Stack
                    direction="column"
                    width="100%"
                    height="100%"
                    ref={anchorRef(popupState)}
                >
                    <Paper
                        sx={{
                            flexGrow: 0,
                            zIndex: 1,
                            position: {
                                xs: "sticky",
                            },
                            top: {
                                xs: 0,
                            },
                        }}
                        elevation={2}
                        square
                    >
                        <Tabs
                            variant="fullWidth"
                            value={activeTabItem && activeTabItem.value}
                        >
                            {filteredTabItems.map((item) => (
                                <Tab
                                    label={item.label}
                                    key={item.value}
                                    onClick={() => redirectToTab(item.value)}
                                    value={item.value}
                                    icon={getIcon(item)}
                                    iconPosition="end"
                                    sx={{
                                        minHeight: 48,
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Paper>
                    <Box
                        width="100%"
                        flexGrow={1}
                        p={2}
                        overflow={{
                            md: "auto",
                            xs: "visible",
                        }}
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Navigate
                                        to={
                                            problem.isIntro
                                                ? "introduction"
                                                : "description"
                                        }
                                        replace
                                    />
                                }
                            />
                            {filteredTabItems.map((item) => (
                                <Route
                                    path={`/${item.value}/*`}
                                    key={item.value}
                                    element={item.element}
                                />
                            ))}
                        </Routes>
                    </Box>
                </Stack>
                <Popper
                    {...bindPopper(popupState)}
                    open={popupState.isOpen}
                    placement="right"
                    sx={{
                        zIndex: 1,
                        padding: 1,
                    }}
                >
                    <TutorialCard
                        title="문제 / 입력 / 출력"
                        body="문제에 대한 상세한 설명과 입력 형식, 예제가 나와있습니다."
                        handleNextTutorial={handleNextTutorial}
                    />
                </Popper>
            </ResizableDrawer>
            <EditorContainer
                problem={problem}
                objectId={objectId}
                submitUntil={submitUntil}
                aiEnabled={aiEnabled}
            />
        </Stack>
    );
};
