import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { rootStore, StoresProvider } from "./common/modules/useStores";

const getEnvironment = () => {
    const apiUrl = process.env.REACT_APP_API_HOST;

    if (apiUrl.includes("local")) {
        return "Local";
    }

    if (apiUrl.includes("alpha")) {
        return "Test";
    }

    return "Production";
};

Sentry.init({
    dsn: "https://657ac889cfb64863b5c90222b1f941a2@o1057871.ingest.sentry.io/6265239",
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                "localhost:8000",
                "api-ooe.codetree.ai",
                "alpha.codetree.ai:8000",
            ],
        }),
    ],
    beforeSend(event) {
        if (getEnvironment() === "Production") {
            return event;
        }
        return null;
    },
    environment: getEnvironment(),
});

ReactDOM.render(
    <StoresProvider value={rootStore}>
        <CssBaseline />
        <App />
    </StoresProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
