import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { MissionTemplate } from "./MissionTemplate";

export const MissionContainer = observer(({ missionId }) => {
    const { missionStore, conceptStore } = useStores();

    const { mission } = missionStore;
    const { concepts } = conceptStore;
    const loading =
        Number(missionId) !== (mission && mission.id) ||
        Number(missionId) !==
            (concepts && concepts.length && concepts[0].missionId);

    useEffect(() => {
        missionStore.find({ missionId });
        conceptStore.findAll({ missionId });
    }, [missionId, missionStore, conceptStore]);

    if (loading) {
        return (
            <LoadingOverlay
                active={loading}
                text="문제집을 불러오는 중입니다."
            />
        );
    }

    return <MissionTemplate mission={mission} concepts={concepts} />;
});
