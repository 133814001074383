import { useRef, useState } from "react";

export default function useRefState(initialValue) {
    const [state, setState] = useState(initialValue);
    const ref = useRef(state);

    const setRefState = (value) => {
        ref.current = value;
        setState(value);
    };

    return [ref, setRefState];
}
