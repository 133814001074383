import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../../utils/immutableLodash";
import { camelizeKeys } from "../../../../utils/renameKeys";
import { StudyUnitModel } from "../studyUnit/StudyUnitModel";

export class ConceptModel {
    id = 0;

    missionId = 0;

    name = "";

    description = "";

    studyUnits = [];

    isFetched = false;

    level = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const concept = pickCls(this, camelizeKeys(data));
        concept.studyUnits =
            concept.studyUnits &&
            concept.studyUnits.map(
                (studyUnit) => new StudyUnitModel(studyUnit)
            );
        set(this, concept);
    }

    setStudyUnits(_studyUnits) {
        this.studyUnits = _studyUnits;
        this.isFetched = true;
    }
}
