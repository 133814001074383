import React, { useState } from "react";
import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const SortMenu = ({ sortOptions, sortOption, selectSortOption }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const changeOption = (option) => {
        selectSortOption(option);
        handleClose();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                sx={{
                    color: "#000000",
                    backgroundColor: "#ffffff",
                    borderRadius: 8 / 4,
                    border: "2px solid #e1e1e7",
                    padding: 8 / 8,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width={180}
                >
                    <Stack direction="column" alignItems="flex-start">
                        <Typography
                            children={sortOption.label}
                            variant="body2"
                            fontWeight={700}
                        />
                    </Stack>
                    <ArrowDropDownIcon fontSize="small" />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                sx={{
                    "& .MuiMenu-paper": {
                        width: 200,
                        borderRadius: 2,
                        boxShadow: 2,
                    },
                }}
            >
                {sortOptions.map((option, i) => (
                    <MenuItem
                        onClick={() => changeOption(option)}
                        selected={option.key === sortOption.key}
                        key={`mission-menu-${i}`}
                        sx={{
                            "& .Mui-selected": {
                                background: "#F5F5F7",
                                color: "#000000",
                            },
                        }}
                    >
                        <Stack direction="column">
                            <Typography
                                children={option.label}
                                variant="body2"
                                fontWeight={700}
                            />
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
