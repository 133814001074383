import React, { useEffect, useRef } from "react";
import { Drawer, Stack, styled } from "@mui/material";
import useRefState from "../hooks/useRefState";

const Dragger = styled("span")(({ theme }) => ({
    flexShrink: 0,
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    height: "100%",
    cursor: "column-resize",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    backgroundColor: theme.palette.background.default,
    whiteSpace: "wrap",
    "&:hover": {
        background: "#B0BEC5",
        cursor: "move",
    },
}));

export const ResizableDrawer = ({
    children,
    minWidth,
    localStorageKey,
    draggerWidth,
    ...props
}) => {
    const initialWidth = Number(localStorage.getItem(localStorageKey));
    const [widthRef, setWidth] = useRefState(initialWidth || minWidth);
    const [isResizeRef, setIsResize] = useRefState(false);

    const resizeHandlerRef = useRef(null);

    useEffect(() => {
        const handleMove = (e) => {
            if (isResizeRef.current) {
                const newWidth = Math.min(
                    window.innerWidth,
                    Math.max(minWidth, e.clientX)
                );
                setWidth(newWidth);
                localStorage.setItem(localStorageKey, newWidth.toString());
            }
        };

        const handleDown = (e) => {
            const handlerTriggered = e.target === resizeHandlerRef.current;
            setIsResize(handlerTriggered);
        };

        const handleUp = () => {
            setIsResize(false);
        };

        window.addEventListener("mousemove", handleMove);
        window.addEventListener("mousedown", handleDown);
        window.addEventListener("mouseup", handleUp);
        return () => {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mousedown", handleDown);
            window.removeEventListener("mouseup", handleUp);
        };
    }, []);

    return (
        <Stack
            direction="row"
            height={{ md: "100%", xs: "auto" }}
            sx={{
                flex: "1 1 0%",
            }}
        >
            <Drawer
                variant="persistent"
                open
                sx={{
                    flex: "1 1 auto",
                    maxWidth: `calc(100% - ${draggerWidth}px)`,
                    width: widthRef.current,
                    height: "100%",
                    "& .MuiDrawer-paper": {
                        width: "100%",
                        position: "initial",
                        overflowY: { md: "auto", xs: "visible" },
                    },
                }}
                {...props}
            >
                {children}
            </Drawer>
            <Dragger
                ref={resizeHandlerRef}
                sx={{
                    width: draggerWidth,
                    display: { md: "flex", xs: "none" },
                }}
            >
                .
                <br />
                .
                <br />
                .
                <br />.
            </Dragger>
        </Stack>
    );
};

ResizableDrawer.defaultProps = {
    minWidth: 300,
    localStorageKey: "resizableDrawerWidth",
    draggerWidth: 10,
};
