import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { StandaloneEditor } from "../../../../../../common/components/editor/StandaloneEditor";
import { LangToCodeMirrorMode } from "../../../../../../common/modules/problems/ProblemModel";
import { CopyButton } from "../../../../../../common/components/button/CopyButton";
import { OpenInNewTabButton } from "../../../../../../common/components/button/OpenInNewTabButton";
import { CodeResultBox } from "../../../../Missions/Playground/templates/submissions/templates/CodeResultBox";

export const SubmissionDetailBox = ({ submission, isFiddle = false }) => {
    const { code, language } = submission;

    return (
        <Stack
            direction="column"
            sx={{
                flexGrow: 1,
                overflowX: "hidden",
                overflowY: "auto",
            }}
        >
            <CodeResultBox
                isFiddle={isFiddle}
                submission={submission}
                codeBoxColor="#FFFFFF"
                sx={{
                    p: 0,
                    mb: 2.5,
                }}
            />
            <Box
                bgcolor="background.paper"
                px={2.5}
                py={1.25}
                borderRadius={3}
                position="relative"
            >
                <StandaloneEditor
                    value={code}
                    language={LangToCodeMirrorMode[language]}
                    options={{
                        readOnly: true,
                        scrollBeyondLastLine: false,
                        scrollbar: {
                            alwaysConsumeMouseWheel: false,
                            vertical: "hidden",
                        },
                        fontSize: 14,
                        minimap: {
                            enabled: false,
                        },
                        renderLineHighlight: "none",
                    }}
                />
                <Stack
                    direction="column"
                    spacing={1.25}
                    alignItems="center"
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                    }}
                >
                    <Typography
                        children={language}
                        sx={{
                            backgroundColor: "#F2F3F7",
                            borderRadius: 2,
                            p: 1.25,
                            color: "#6C7A89",
                        }}
                    />
                    <CopyButton
                        fontSize={14}
                        text={code}
                        tooltipPlacement="left"
                        sx={{
                            width: 30,
                            height: 30,
                            bgcolor: "common.white",
                            border: "1px solid #F1F1F7",
                        }}
                    />
                    <OpenInNewTabButton
                        fontSize={14}
                        text={code}
                        tooltipPlacement="left"
                        sx={{
                            width: 30,
                            height: 30,
                            bgcolor: "common.white",
                            border: "1px solid #F1F1F7",
                        }}
                    />
                </Stack>
            </Box>
        </Stack>
    );
};
