import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ChannelService from "../../../common/components/channel/ChannelService";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { FAQPage } from "./FAQ/FAQPage";

export const HelpPage = () => {
    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    return (
        <MainTemplateWrapper
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "center",
            }}
        >
            <Routes>
                <Route index path={"faq/*"} element={<FAQPage />} />
            </Routes>
        </MainTemplateWrapper>
    );
};
