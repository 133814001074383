import { makeAutoObservable, set } from "mobx";
import { snakelizeKeys } from "../../../utils/renameKeys";

const { camelizeKeys } = require("../../../utils/renameKeys");
const { pickCls } = require("../../../utils/immutableLodash");

export const LanguageType = {
    C: "C",
    CPP: "C++",
    Python3: "Python3",
    Java: "Java",
    Swift: "Swift",
};

export const LangToCodeMirrorMode = {
    [LanguageType.C]: "c",
    [LanguageType.CPP]: "cpp",
    [LanguageType.Python3]: "python",
    [LanguageType.Java]: "java",
    [LanguageType.Swift]: "swift",
};

export const CodeMirrorModeToLang = {
    c: LanguageType.C,
    cpp: LanguageType.CPP,
    python: LanguageType.Python3,
    java: LanguageType.Java,
    swift: LanguageType.Swift,
};

export const ProblemClickEvent = {
    Introduction: "Intro",
    Description: "Description",
    Solution: "Solution",
    Submissions: "Submissions",
    SubmissionDetail: "Submission Detail",
    Discussions: "Discussions",
};

export class ProblemModel {
    id = 0;

    name = "";

    studyUnitId = 0;

    conceptId = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const problem = pickCls(this, camelizeKeys(data));
        set(this, problem);
    }
}

export class TestCaseModel {
    order = -1;

    stdin = "";

    stdout = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const testCase = pickCls(this, camelizeKeys(data));
        set(this, testCase);
    }
}

export class DescriptionModel {
    problemId = 0;

    description = "";

    descriptionEn = "";

    testCases = [];

    timeLimit = 0;

    memoryLimit = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const description = pickCls(this, camelizeKeys(data));
        description.testCases =
            description.testCases &&
            description.testCases.map(
                (testCase) => new TestCaseModel(testCase)
            );
        set(this, description);
    }
}

export class IntroductionModel {
    language = "";

    description = "";

    problemId = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const introduction = pickCls(this, camelizeKeys(data));
        set(this, introduction);
    }
}

export class AnswerCodeModel {
    language = "";

    code = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const answerCode = pickCls(this, camelizeKeys(data));
        set(this, answerCode);
    }
}

export class SolutionModel {
    name = "";

    intuition = "";

    algorithm = "";

    complexity = "";

    category = "";

    isMain = false;

    order = -1;

    answerCodes = [];

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const solution = pickCls(this, camelizeKeys(data));
        solution.answerCodes =
            solution.answerCodes &&
            solution.answerCodes.map(
                (answerCode) => new AnswerCodeModel(answerCode)
            );
        set(this, solution);
    }
}

export class ExplanationModel {
    solutions = [];

    sideNote = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const explanation = pickCls(this, camelizeKeys(data));
        explanation.solutions =
            explanation.solutions &&
            explanation.solutions.map(
                (solution) => new SolutionModel(solution)
            );
        explanation.solutions.sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            return 0;
        });
        set(this, explanation);
    }
}

export class ProblemClickDAOModel {
    event = "";

    problem = 0;

    constructor(data) {
        const problemClick = pickCls(this, data);
        Object.assign(this, snakelizeKeys(problemClick));
    }
}
