import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LargeButton } from "../../../../common/components/button/LargeButton";
import { MissionNameTypeToColorCode } from "../../../../common/modules/missions/mission/MissionModel";
import { getMissionAndProblemId, paddingZero } from "../../../../utils/string";

export const LatestSolvedProblemHeader = ({
    latestSolvedProblemSet,
    username,
}) => {
    if (!latestSolvedProblemSet) {
        return null;
    }

    const { missionName, latestSolvedProblem } = latestSolvedProblemSet;

    const navigate = useNavigate();

    const navigateToProblem = (number) => {
        const { missionId, problemId } = getMissionAndProblemId(number);

        navigate(`/missions/${missionId}/problems/${problemId}`);
    };

    return (
        <Stack
            direction={{
                md: "row",
                xs: "column",
            }}
            sx={{
                borderRadius: 4,
                boxShadow: 1,
                backgroundColor: "#FFFFFF",
            }}
        >
            <Stack
                spacing={{ md: 2.5, xs: 1.25 }}
                sx={{
                    py: { md: 7.5, xs: 2.5 },
                    px: { md: 5, xs: 2.5 },
                    flexGrow: 1,
                    flexBasis: 0,
                }}
            >
                <Typography variant="h3" fontWeight={700}>
                    {username}님 <br />
                    {missionName} 문제집을 풀어보세요.
                </Typography>
                <Stack
                    direction={{ md: "column", sm: "row", xs: "column" }}
                    justifyContent={{
                        md: "inherit",
                        sm: "space-between",
                        xs: "inherit",
                    }}
                    alignItems={{ md: "inherit", sm: "center", xs: "inherit" }}
                    spacing={"inherit"}
                >
                    <Typography variant="body1">
                        코드트리는 개념부터 탄탄히 학습할 수 있게 설계되었어요.{" "}
                        <br />
                        체계적인 알고리즘 학습을 시작해볼까요?
                    </Typography>
                    <LargeButton
                        onClick={() =>
                            navigateToProblem(latestSolvedProblem.number)
                        }
                        label="학습 시작"
                        color="#FFFFFF"
                        backgroundColor="#000000"
                        sx={{
                            alignSelf: {
                                md: "flex-end",
                                sm: "inherit",
                                xs: "flex-end",
                            },
                        }}
                    />
                </Stack>
            </Stack>
            <Stack
                sx={{
                    backgroundColor: MissionNameTypeToColorCode[missionName],
                    px: 2.5,
                    py: { md: 0, xs: 2.5 },
                    flexGrow: 1,
                    flexBasis: 0,
                    borderTopRightRadius: { md: 16, xs: 0 },
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: { md: 0, xs: 16 },
                }}
                justifyContent="center"
            >
                <Stack
                    spacing={1.25}
                    sx={{
                        px: { md: 5, xs: 2.5 },
                        py: { md: 7, xs: 3.5 },
                        backgroundColor: "#FFFFFF",
                        borderRadius: 2.5,
                    }}
                >
                    <Stack>
                        <Typography
                            variant="caption"
                            children={
                                latestSolvedProblem.isIntro
                                    ? "개념 학습"
                                    : latestSolvedProblem.isTest
                                    ? "테스트"
                                    : "연습 문제"
                            }
                        />
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            children={latestSolvedProblem.name}
                        />
                    </Stack>
                    <Typography
                        variant="caption"
                        children={`
                        ${paddingZero(latestSolvedProblem.conceptLevel, 2)}. 
                        ${latestSolvedProblem.conceptName} / ${
                            latestSolvedProblem.studyUnitName
                        }`}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
