import React from "react";
import {
    AppBar,
    ButtonBase,
    IconButton,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ProblemIndexButton } from "./components/ProblemIndexButton";
// import { MissionTreeDrawer } from "./templates/MissionTreeDrawer";
import { MenuTreeDrawer } from "./templates/MenuTreeDrawer";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export const MenuTreeTemplate = ({
    problem,
    treeItem,
    open,
    selected,
    redirectToTreeProblemOrOpen,
    toggleOpen,
    redirectToNextProblemOrOpen,
    redirectToPrevProblemOrOpen,
    homePath,
    stepInfo,
}) => {
    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const indexSuffix = stepInfo ? `(${stepInfo.curr}/${stepInfo.total})` : ``;
    return (
        <AppBar color={"secondary"} sx={{ position: "initial", flexShrink: 0 }}>
            <MenuTreeDrawer
                problem={problem}
                selected={selected}
                open={open}
                treeItem={treeItem}
                handleClose={toggleOpen}
                handleClick={redirectToTreeProblemOrOpen}
                homePath={homePath}
            />
            <Toolbar
                variant={"dense"}
                sx={{
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{
                        position: "absolute",
                        left: 20,
                    }}
                >
                    <IconButton color="inherit" onClick={toggleOpen}>
                        <MenuIcon />
                    </IconButton>
                    {/* <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1.5}
                    >
                        <Typography
                            variant={"caption"}
                            children={`${problem.parentName[1]}`}
                        />
                        <Typography variant={"caption"} children={"/"} />
                        <Typography
                            variant={"caption"}
                            children={problem.parentName[2]}
                        />
                        <Typography variant={"caption"} children={"/"} />
                        <Typography
                            variant={"caption"}
                            children={problem.name}
                        />
                    </Stack> */}
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={6}>
                    <ProblemIndexButton
                        color={"primary"}
                        variant={"contained"}
                        onClick={redirectToPrevProblemOrOpen}
                        size={smBreakpoint ? "small" : "medium"}
                    >
                        {smBreakpoint ? <ArrowBackIos /> : "이전"}
                    </ProblemIndexButton>
                    <ButtonBase onClick={toggleOpen} sx={{ p: 1 }}>
                        <Typography
                            variant={"body1"}
                            children={`${problem.name} ${indexSuffix}`}
                        />
                    </ButtonBase>
                    <ProblemIndexButton
                        color={"primary"}
                        variant={"contained"}
                        onClick={redirectToNextProblemOrOpen}
                        size={smBreakpoint ? "small" : "medium"}
                    >
                        {smBreakpoint ? <ArrowForwardIos /> : "다음"}
                    </ProblemIndexButton>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
