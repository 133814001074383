import React, { useRef, useState } from "react";
import {
    Avatar,
    ClickAwayListener,
    Grow,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const UserPopper = ({ currUser, logout }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const settings = [
        {
            label: "학생 관리 대시보드",
            onClick: () => {
                navigate("/admin");
                handleClose();
            },
        },
        {
            label: "코드 제출 기록",
            onClick: () => {
                navigate("/accounts/my-submission");
                handleClose();
            },
        },
        {
            label: "자주 묻는 질문",
            onClick: () => {
                navigate("/help/faq");
                handleClose();
            },
        },
        {
            label: "로그아웃",
            onClick: () => {
                logout({});
            },
        },
    ];

    const filterSettings = settings.filter((_) => {
        return true;
    });

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleToggle}
                sx={{
                    p: 0,
                }}
            >
                <Avatar sx={{ color: "#264348" }} src={currUser.profileImage} />
            </IconButton>
            <Popper
                anchorEl={anchorRef.current}
                open={open}
                role={undefined}
                placement="bottom-start"
                transition
                style={{
                    zIndex: 1200,
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "left top",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar
                                                src={currUser.profileImage}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={currUser.username}
                                        />
                                    </ListItem>
                                    {filterSettings.map((setting) => (
                                        <MenuItem
                                            key={setting.label}
                                            onClick={setting.onClick}
                                        >
                                            <Typography textAlign="center">
                                                {setting.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
