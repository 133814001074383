import React from "react";
import { Stack } from "@mui/material";
import { ClassroomForm } from "../templates/ClassroomForm";
import { LargeButton } from "../../../../common/components/button/LargeButton";

export const ClassroomEditTemplate = ({
    inputs,
    handleChange,
    handleSubmit,
    handleCancel,
    handleDelete,
}) => (
    <Stack direction="column" mt={12} px={15}>
        <ClassroomForm inputs={inputs} handleChange={handleChange} />
        <Stack direction="row" spacing={2} alignSelf="flex-end" mr={2.5}>
            <LargeButton
                label="초기화"
                onClick={handleCancel}
                variant="outlined"
                sx={{
                    borderColor: "#808080",
                }}
                color="#808080"
                backgroundColor="#FFFFFF00"
            />
            <LargeButton
                label="변경사항 저장"
                onClick={() => handleSubmit(inputs)}
                sx={{
                    borderColor: "#808080",
                }}
                color="#FFFFFF"
                backgroundColor="#FF5555"
            />
            <LargeButton
                label="삭제"
                onClick={handleDelete}
                color="#FFFFFF"
                backgroundColor="#FF0000"
            />
        </Stack>
    </Stack>
);
