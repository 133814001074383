import { w3cwebsocket as W3CWebSocket } from "websocket";
import { getToken } from "../../../utils/cookies";
import { baseURL } from "../../../utils/webSocket";
import { WebSocketType } from "../../../utils/enum";
import SubmissionStore from "./SubmissionStore";

class SubmissionWebSocket {
    socket = null;

    open = ({ onMessage, problemId }) => {
        this.close();

        const client = new W3CWebSocket(`${baseURL}/submissions/`);
        client.onmessage = onMessage;

        client.onopen = () => {
            client.send(
                JSON.stringify({
                    type: WebSocketType.Authentication,
                    data: {
                        token: getToken(),
                        problem_id: problemId,
                    },
                })
            );
        };

        client.onclose = (e) => {
            if (e.code === 1006) {
                setTimeout(SubmissionStore.open, 5000);
            }
        };

        this.socket = client;
    };

    add = ({ body }) => {
        console.log(this.socket, this.socket.readyState, WebSocket.OPEN);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            return;
        }

        this.socket.send(JSON.stringify(body));
    };

    close = () => {
        if (this.socket) this.socket.close();
        this.socket = null;
    };
}

export default new SubmissionWebSocket();
