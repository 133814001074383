import React from "react";
import { Stack, Typography } from "@mui/material";
import { StyledLinearProgress } from "../../../../common/components/progress/StyledLinearProgress";

export const MissionProgress = ({ progress, sx, width, textColor }) => (
    <Stack direction="column" sx={{ ...sx }} width={width} spacing={1}>
        <Stack direction="row" justifyContent="space-between">
            <Typography color={textColor || "#666666"} variant="body1">
                학습 진행률
            </Typography>
            <Typography color="primary" variant="body1" fontWeight={700}>
                {`${progress}%`}
            </Typography>
        </Stack>
        <StyledLinearProgress value={progress} />
    </Stack>
);

MissionProgress.defaultProps = {
    width: 220,
};
