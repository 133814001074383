import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";

export const FiddleButton = ({ Icon, label, onClick, color }) => {
    return (
        <ButtonBase onClick={onClick}>
            <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                sx={{
                    color: color || "#202124",
                }}
            >
                <Icon />
                <Typography variant={"body2"} children={label} />
            </Stack>
        </ButtonBase>
    );
};
