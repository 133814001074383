import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";
import { HomeworkMarketTemplate } from "./HomeworkMarketTemplate";
import { useNavigate } from "react-router-dom";

export const HomeworkMarketContainer = observer(() => {
    const { homeworkStore, classroomStore, lessonTemplateStore } = useStores();
    const { lessonTemplates, isFetching, lessonTemplate, setLessonTemplate } =
        lessonTemplateStore;
    const { classroom } = classroomStore;

    const navigate = useNavigate();

    useEffect(() => {
        lessonTemplateStore.findAllSimple();
        return () => {
            lessonTemplateStore.setLessonTemplate(-1);
        };
    }, []);

    const fetchLessonTemplateDetail = (lessonTemplateId) => {
        lessonTemplateStore.find({ lessonTemplateId });
    };

    const redirectAddPage = () => {
        navigate("/admin/homework-edit/add");
    };

    const handleUseTemplate = (lessonTemplateId) => {
        lessonTemplateStore
            .folk({
                classroomId: classroom.id,
                lessonTemplateId,
            })
            .then(() => {
                snackActions.success("수업이 만들어졌습니다!");
                homeworkStore.findAll({
                    isPublic: false,
                });
                navigate("/admin/homework-edit");
            });
    };

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="숙제를 불러오는 중입니다."
            />
        );

    return (
        <HomeworkMarketTemplate
            lessonTemplate={lessonTemplate}
            lessonTemplates={lessonTemplates}
            setLessonTemplate={setLessonTemplate}
            handleUseTemplate={handleUseTemplate}
            fetchLessonTemplateDetail={fetchLessonTemplateDetail}
            redirectAddPage={redirectAddPage}
        />
    );
});
