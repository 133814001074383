import React from "react";
import { Stack, styled, Typography } from "@mui/material";
// import { CuratedProblemStatusIcon } from "../../Missions/templates/CuratedProblemStatusIcon";
import { observer } from "mobx-react-lite";
import { ProblemStatusIcon } from "../../../../common/templates/ProblemStatusIcon";

const StyledStack = styled(Stack)(({ selected, disabled }) => ({
    cursor: disabled ? "not-allowed" : "pointer",
    padding: selected ? "12px 10px" : "12px 0px",
    margin: selected ? "1px 0" : "1px 10px",
    borderRadius: 8,
    backgroundColor: selected ? "rgba(17, 17, 17, 0.5)" : "initial",
    transition: "all 0.3s ease 0s",
    opacity: disabled ? 0.5 : 1,
    "&:hover": {
        backgroundColor: disabled ? "initial" : "rgba(17, 17, 17, 1.0)", //theme.palette.primary.main,
        paddingLeft: 10,
        paddingRight: 10,
        margin: "1px 0",
    },
}));

export const ProblemTreeItem = observer(
    ({ problem, selected, handleClick }) => {
        return (
            <StyledStack
                selected={selected}
                direction="row"
                position="relative"
                alignItems="center"
                spacing={1}
                py={1}
                onClick={handleClick}
            >
                <ProblemStatusIcon curatedProblem={problem} textDisable />
                {/* <CuratedProblemStatusIcon
                    curatedProblem={problem}
                    textDisable
                /> */}
                <Typography variant="h4" pr={1.5}>
                    {problem.name}
                </Typography>
            </StyledStack>
        );
    }
);
