import { Box, styled } from "@mui/material";

export const ResizeHandlerBox = styled(Box)(({ theme }) => ({
    height: 18,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "row-resize",
    opacity: 0.5,
    "&::before": {
        display: "block",
        content: '""',
        width: 32,
        height: 4,
        borderRadius: 4,
        backgroundColor: "#dadce0",
    },
    "&:hover": {
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "light"
                ? "rgba(200, 200, 200, 0.1)"
                : "rgba(0, 0, 0, 0.1)",
    },
}));
