import React from "react";
import { styled } from "@mui/material";
import moment from "moment";
import { ProblemStatusType } from "../../modules/missions/curatedProblem/CuratedProblemModel";

const ProgressDivComponent = styled("div")(({ theme, ...props }) => ({
    minWidth: props.width,
    height: props.height,
    position: "relative",
    flexGrow: 1,
    backgroundColor: props.color,
    border: "1px solid #EBEBEB",
    cursor: "pointer",
    "&:hover": {
        border: "4px solid #000000",
        "&::after": {
            content: `"${
                props.isTried
                    ? `${moment(props.solvedAt).format("YY/M/D H:m")} \\A`
                    : ""
            }#${props.number} ${props.name}"`,
            position: "absolute",
            whiteSpace: "pre-wrap",
            width: "max-content",
            top: 44,
            backgroundColor: "#000000",
            color: "#FFFFFF",
            padding: theme.spacing(0.5, 0.75),
            borderRadius: theme.shape,
            fontSize: 12,
            fontWeight: 700,
            lineHeight: "16px",
            letterSpacing: "-0.2px",
        },
    },
}));

export const ProgressDiv = ({ progress, width, height, onClick }) => {
    const { status } = progress;

    const getColor = (_status) => {
        if (_status === ProblemStatusType.Passed) {
            return "#009D27";
        }
        if (_status === ProblemStatusType.InProgress) {
            return "#FFC433";
        }
        return "#FFFFFF";
    };

    return (
        <ProgressDivComponent
            {...progress}
            color={getColor(status)}
            isTried={status !== ProblemStatusType.Ready}
            width={width}
            height={height}
            onClick={onClick}
        />
    );
};

ProgressDiv.defaultProps = {
    width: 5,
    height: 40,
};
