import { makeAutoObservable, makeObservable, override, set } from "mobx";
import moment from "moment";
import { snakelizeKeys, camelizeKeys } from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";
import { MissionModel } from "../missions/mission/MissionModel";
import { ProblemTreeNodeModel } from "../treeNode/ProblemTreeNodeModel";
import { ProblemStatusType } from "../missions/curatedProblem/CuratedProblemModel";
import { LevelType } from "../missions/curatedProblem/CuratedProblemModel";
import { getMissionAndProblemId } from "../../../utils/string";

export class HomeworkSimpleModel {
    name = "";

    curatedProblems = [];

    startedAt = "";

    finishedAt = "";

    id = 0;

    isOwner = false;

    isDiscussionHide = false;

    isSubBlockedAfterFinish = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const homework = pickCls(this, camelizeKeys(data));
        homework.curatedProblems =
            homework.curatedProblems && homework.curatedProblems.length
                ? homework.curatedProblems.map(
                      (curatedProblem) =>
                          new HomeworkProblemModel(curatedProblem)
                  )
                : [];
        set(this, homework);
    }
}

export class HomeworkModel {
    name = "";

    curatedProblems = [];

    startedAt = moment();

    finishedAt = moment();

    missions = [];

    id = 0;

    isOwner = false;

    classroomId = 0;

    isPublic = false;

    isDiscussionHide = false;

    isSubBlockedAfterFinish = false;

    isHidden = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const homework = pickCls(this, camelizeKeys(data));
        homework.missions =
            homework.missions &&
            homework.missions.map((mission) => new MissionModel(mission));
        homework.curatedProblems =
            homework.curatedProblems && homework.curatedProblems.length
                ? homework.curatedProblems.map(
                      (curatedProblem) =>
                          new HomeworkProblemModel(curatedProblem)
                  )
                : [];
        set(this, homework);
    }
}

export class HomeworkDAOModel {
    name = "";

    startedAt = moment();

    finishedAt = moment();

    classroom = 0;

    curatedProblems = [];

    isDiscussionHide = false;

    isSubBlockedAfterFinish = false;

    isHidden = false;

    constructor(data) {
        const homework = pickCls(this, data);
        Object.assign(this, snakelizeKeys(homework));
    }
}

export class HomeworkProblemModel extends ProblemTreeNodeModel {
    id = 0;

    level = LevelType.Easy;

    number = 0;

    progressStatus = ProblemStatusType.Ready;

    name = "";

    nodeLabel = "problem";

    nodeId = "";

    curatedProblemId = 0;

    solutionVisible = false;

    score = 10;

    missionId = 0;

    solvedMinute = 0;

    aiEnabled = false;

    constructor(data) {
        super();
        makeObservable(this, {
            id: override,

            level: override,

            alias: override,

            progressStatus: override,

            name: override,

            number: override,

            nodeLabel: override,

            nodeId: override,

            parentNode: override,

            parentName: override,

            parentNodeLabel: override,

            parentNodeId: override,
        });
        const problem = pickCls(this, camelizeKeys(data));
        problem.progressStatus =
            problem.progressStatus || ProblemStatusType.Ready;
        problem.nodeId = `problem_${problem.number}`;
        problem.problemId = problem.id;
        const { missionId } = getMissionAndProblemId(problem.number);
        problem.missionId = missionId;
        set(this, problem);
    }
}
