export const TutorialType = {
    Editor: "Editor",
    Problem: "Problem",
    Language: "Language",
    Execute: "Execute",
    Submit: "Submit",
    TestCase: "TestCase",
    Timer: "Timer",
    Exit: "Exit",
    None: "None",
};

export const TutorialGuides = {
    [TutorialType.Editor]: "",
    [TutorialType.Problem]: "",
    [TutorialType.Language]: "",
    [TutorialType.Execute]: "",
    [TutorialType.Submit]: "",
    [TutorialType.TestCase]: "",
    [TutorialType.Timer]: "",
    [TutorialType.Exit]: "",
};
