import { snakelizeKeys } from "../../../../utils/renameKeys";
import { objectToQueryString } from "../../../../utils/immutableLodash";
import { GET } from "../../../../utils/api";

class FiddleProgressRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    find({ fiddleKey, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`fiddle_progresses/${fiddleKey}/${queryParams}`);
    }

    findAll({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`fiddle_progresses/${queryParams}`);
    }
}

export default new FiddleProgressRepository();
