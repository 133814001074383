import React from "react";
import { Route, Routes } from "react-router-dom";
import { DiscussionsContainer } from "./DiscussionsContainer";
import { DiscussionContainer } from "./discussion/DiscussionContainer";

export const DiscussionsPage = ({ problem }) => (
    <Routes>
        <Route path="/" element={<DiscussionsContainer problem={problem} />} />
        <Route
            path="/:discussionId"
            element={<DiscussionContainer problem={problem} />}
        />
    </Routes>
);
