import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { LangToCodeMirrorMode } from "../../../../common/modules/problems/ProblemModel";
import Editor from "@monaco-editor/react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export const DefaultCodeEdit = ({ item, handleChange, handleDelete }) => {
    return (
        <Stack
            mb={1.5}
            sx={{
                borderRadius: 2,
                border: "1px solid #E0E0E2",
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                px={2}
                py={1.25}
                sx={{
                    backgroundColor: "#F7F7F7",
                }}
            >
                <Typography
                    sx={{
                        flexGrow: 1,
                    }}
                    children={item.language}
                    textAlign={"center"}
                    color={"#666666"}
                />
                <IconButton
                    onClick={() => handleDelete(item.key)}
                    sx={{
                        color: "#D24D57",
                    }}
                >
                    <DeleteOutlinedIcon />
                </IconButton>
            </Stack>
            <Box height={300}>
                <Editor
                    value={item.code}
                    language={LangToCodeMirrorMode[item.language]}
                    onChange={(value) => handleChange(item.key, "code", value)}
                    options={{
                        theme: "light",
                        fontSize: 14,
                        scrollBeyondLastLine: false,
                        scrollbar: {
                            alwaysConsumeMouseWheel: false,
                            vertical: "hidden",
                        },
                        minimap: {
                            enabled: false,
                        },
                        renderLineHighlight: "none",
                    }}
                />
            </Box>
        </Stack>
    );
};
