export const defaultThemeOption = {
    palette: {
        primary: {
            main: "#00A71B",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#000000",
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#F5F5F7",
        },
        text: {},
    },
};
