import { GET, PATCH, POST } from "../../../utils/api";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";

class DiscussionRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAllByParams(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`discussions/${queryParams}`);
    }

    find({ discussionId }) {
        return GET(`discussions/${discussionId}/`);
    }

    findReplies({ commentId }) {
        return GET(`comments/${commentId}/replies/`);
    }

    add({ body, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return POST(`discussions/${queryParams}`, body);
    }

    addComment({ body, discussionId }) {
        return POST(`discussions/${discussionId}/comments/`, body);
    }

    addReply({ body, commentId }) {
        return POST(`comments/${commentId}/replies/`, body);
    }

    updateComment({ body, commentId }) {
        return PATCH(`comments/${commentId}/`, body);
    }

    update({ body, discussionId }) {
        return PATCH(`discussions/${discussionId}/`, body);
    }

    updateReply({ body, replyId }) {
        return PATCH(`replies/${replyId}/`, body);
    }

    discussionAnswer({ discussionId }) {
        return GET(`discussions/${discussionId}/gpt-answer/`);
    }
}

export default new DiscussionRepository();
