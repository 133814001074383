import React from "react";
import { Box, Checkbox, Stack } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import {
    LevelTypeToColor,
    LevelTypeToInt,
    ProblemStatusType,
    ProblemStatusTypeToColor,
} from "../modules/missions/curatedProblem/CuratedProblemModel";
import { observer } from "mobx-react-lite";

const PassedSymbol = () => {
    return (
        <Box
            sx={{
                width: 24,
                height: 24,
                backgroundColor: "#42D760",
                borderRadius: 8,
                position: "relative",
                overflow: "hidden",
                "&>svg": {
                    position: "absolute",
                    left: 6,
                    top: 9,
                },
            }}
        >
            <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 3.66667L4.33333 7L10.3333 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
};

export const ProblemStatusTypeToIcon = {
    [ProblemStatusType.Ready]: (
        <FiberManualRecordIcon sx={{ fontSize: 10, margin: "auto" }} />
    ),
    [ProblemStatusType.InProgress]: <HorizontalRuleRoundedIcon />,
    [ProblemStatusType.Passed]: <PassedSymbol />,
};

export const ProblemStatusIcon = observer(
    ({ curatedProblem, textDisable, selectable, getChecked, onChange }) => {
        return (
            <Stack
                position={"relative"}
                direction={"column"}
                alignItems={"center"}
            >
                {!textDisable ? (
                    <Stack
                        position={"absolute"}
                        direction={"row"}
                        top={0}
                        left={"50%"}
                        pb={"3px"}
                        spacing={0.25}
                        sx={{
                            transform: "translate(-50%, -100%)",
                        }}
                    >
                        {[1, 2, 3].map((item) => (
                            <span
                                key={`problem-status-dot-${item}`}
                                style={{
                                    width: 4,
                                    height: 4,
                                    borderRadius: "50%",
                                    opacity:
                                        LevelTypeToInt[curatedProblem.level] >=
                                        item
                                            ? 1
                                            : 0.5,
                                    backgroundColor:
                                        LevelTypeToInt[curatedProblem.level] >=
                                        item
                                            ? LevelTypeToColor[
                                                  curatedProblem.level
                                              ]
                                            : "#CCCCCC",
                                }}
                            />
                        ))}
                    </Stack>
                ) : null}
                {selectable ? (
                    <Checkbox
                        sx={{
                            p: 0,
                        }}
                        checked={getChecked(curatedProblem.curatedProblemId)}
                        color="secondary"
                        onChange={onChange}
                    />
                ) : (
                    <Box
                        width={24}
                        height={24}
                        borderRadius={"50%"}
                        backgroundColor={
                            ProblemStatusTypeToColor[
                                curatedProblem.progressStatus
                            ]
                        }
                        color={"common.white"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {ProblemStatusTypeToIcon[curatedProblem.progressStatus]}
                    </Box>
                )}
            </Stack>
        );
    }
);
