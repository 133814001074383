import { makeAutoObservable, set } from "mobx";
import { ProblemStatusType } from "../../missions/curatedProblem/CuratedProblemModel";
import { camelizeKeys } from "../../../../utils/renameKeys";
import { pickCls } from "../../../../utils/immutableLodash";

export class ProgressModel {
    problemId = 0;

    name = "";

    number = 0;

    status = "";

    solvedAt = "";

    score = 0;

    earnedScore = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const progress = pickCls(this, camelizeKeys(data));
        progress.status = progress.status || ProblemStatusType.Ready;
        set(this, progress);
    }
}

class HomeworkProgressModel {
    homeworkId = "";

    startedAt = "";

    finishedAt = "";

    name = "";

    progresses = [];

    solvedProblemCount = 0;

    problemCount = 0;

    earnedScore = 0;

    totalScore = 0;

    scores = [];

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const homeworkProgress = pickCls(this, camelizeKeys(data));
        homeworkProgress.progresses =
            homeworkProgress.progresses &&
            homeworkProgress.progresses.map(
                (progress) => new ProgressModel(progress)
            );
        set(this, homeworkProgress);
    }
}

export class UserHomeworkProgressModel {
    userId = 0;

    username = "";

    name = "";

    homeworkProgresses = [];

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const userHomeworkProgress = pickCls(this, camelizeKeys(data));
        userHomeworkProgress.homeworkProgresses =
            userHomeworkProgress.homeworkProgresses &&
            userHomeworkProgress.homeworkProgresses.map(
                (homeworkProgress) =>
                    new HomeworkProgressModel(homeworkProgress)
            );
        set(this, userHomeworkProgress);
    }
}
