import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { MainTemplate } from "./MainTemplate";
import { useStores } from "../../common/modules/useStores";
import { LoadingOverlay } from "../../common/templates/LoadingOverlay";
import ChannelService from "../../common/components/channel/ChannelService";

export const MainContainer = observer(() => {
    const { authStore, classroomStore } = useStores();
    const { isUserLoggedIn } = authStore;
    const { isFetching } = classroomStore;

    useEffect(() => {
        if (isUserLoggedIn()) classroomStore.findAll({});
    }, [isUserLoggedIn()]);

    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    if (isUserLoggedIn() && isFetching) {
        return (
            <LoadingOverlay
                active={isFetching}
                text="학급 정보를 불러오는 중입니다."
            />
        );
    }

    return <MainTemplate isLogin={isUserLoggedIn()} />;
});
