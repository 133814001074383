import React from "react";
import { Box, Container, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ConceptAccordionBox } from "../../../Missions/templates/ConceptAccordionBox";
import { MissionSelector } from "../../../../../common/templates/MissionSelector";
import { SmallButton } from "../../../../../common/components/button/SmallButton";
import { SearchField } from "../../../../../common/components/textField/SearchField";

export const CuratedProblemSelectorTemplate = ({
    concepts,
    missions,
    missionId,
    setMissionId,
    handleChange,
    curatedProblems,
    problemName,
    setProblemName,
    searchProblems,
}) => {
    const getChecked = (curatedProblemId) =>
        curatedProblems.some(
            (item) => item.curatedProblemId === curatedProblemId
        );

    const handleClickProblem = (curatedProblemId) => {
        if (getChecked(curatedProblemId)) {
            handleChange(
                "curatedProblems",
                curatedProblems.filter(
                    (item) => item.curatedProblemId !== curatedProblemId
                )
            );
        } else {
            handleChange("curatedProblems", [
                ...curatedProblems,
                { curatedProblemId, score: 10, solutionVisible: false },
                // curatedProblemId,
            ]);
        }
    };

    return (
        <Stack direction="column" alignItems="center" overflow="auto">
            <Container maxWidth="md">
                <Stack direction="column" spacing={1.25} py={9}>
                    <Stack direction="row" justifyContent="space-between">
                        <MissionSelector
                            missionId={missionId}
                            missions={missions}
                            setMissionId={setMissionId}
                        />
                        <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                        >
                            <Box width={200}>
                                <SearchField
                                    value={problemName}
                                    setValue={setProblemName}
                                    onSubmit={searchProblems}
                                />
                            </Box>
                            <SmallButton
                                label="문제, 유형 검색"
                                backgroundColor="#000000"
                                color="#FFFFFF"
                                endIcon={<SearchIcon />}
                                onClick={() => searchProblems(problemName)}
                            />
                        </Stack>
                    </Stack>
                    <ConceptAccordionBox
                        concepts={concepts}
                        selectable
                        getChecked={getChecked}
                        handleClickProblem={handleClickProblem}
                        missionId={missionId}
                        problemName={problemName}
                    />
                </Stack>
            </Container>
        </Stack>
    );
};
