import React from "react";
import { Box, Button, Chip, darken, Stack, Typography } from "@mui/material";
import { ProblemStatusType } from "../modules/missions/curatedProblem/CuratedProblemModel";
import { TimerOutlined } from "@mui/icons-material";

export const QuestProblemBox = ({ order, problem, isLast, onClick }) => {
    const isPassed = problem.progressStatus === ProblemStatusType.Passed;
    const problemCategoryText = problem.isIntro ? `기본 개념` : `연습 문제`;
    return (
        <Box py={0.75} px={0.75} position={"relative"}>
            <Stack alignItems={"center"} width={"100%"}>
                <Stack
                    py={1}
                    px={2}
                    bgcolor={isPassed ? `#009D27` : `#FFC433`}
                    color={isPassed ? "#FFFFFF" : "#111111"}
                    sx={{
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                >
                    {problemCategoryText}
                </Stack>
                <Stack
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    spacing={1.5}
                    sx={{
                        border: "6px solid #FFFFFF",
                        boxShadow: isPassed
                            ? "0 0 0 6px #009D27"
                            : "0 0 0 6px #FFC433",
                        background: isPassed ? "#D9EDB2" : "#FFFBD6",
                        borderRadius: 5,
                        p: 1.5,
                    }}
                >
                    <Stack direction={"row"} spacing={1}>
                        <Typography
                            variant={"h3"}
                            children={`${order}. `}
                            textAlign={"center"}
                            sx={{
                                userSelect: "none",
                                wordBreak: "keep-all",
                                opacity: 0.5,
                            }}
                        />
                        <Typography
                            variant={"h3"}
                            children={`${problem.name}`}
                            textAlign={"center"}
                            sx={{
                                wordBreak: "keep-all",
                            }}
                        />
                    </Stack>
                    {typeof problem.score === "number" && (
                        <Chip
                            label={`${problem.score}점`}
                            color={isPassed ? "success" : "default"}
                        />
                    )}

                    <Button
                        onClick={onClick}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            py: 10 / 8,
                            width: "100%",
                            borderBottom: isPassed
                                ? "4px solid #CCCCCC"
                                : "4px solid #0BAC54",
                            borderRadius: 2,
                            background: isPassed ? "#FFFFFF" : "#3FCB7E",
                            "&:hover": {
                                background: isPassed
                                    ? "#F0F0F0"
                                    : darken("#3FCB7E", 0.1),
                                borderBottom: isPassed
                                    ? "4px solid #CCCCCC"
                                    : `4px solid ${darken("#0BAC54", 0.1)}`,
                            },
                            transition: "all .15s",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            color={isPassed ? "#111111" : "#FFFFFF"}
                        >
                            <TimerOutlined
                                sx={{
                                    fontSize: 16,
                                }}
                            />
                            <Typography
                                variant={"body2"}
                                children={`${
                                    !problem.solvedMinute ||
                                    problem.solvedMinute > 180
                                        ? "180+"
                                        : problem.solvedMinute
                                }${"분"}`}
                            />
                        </Stack>
                        <Typography
                            variant={"h4"}
                            color={isPassed ? "#111111" : "#FFFFFF"}
                            children={isPassed ? "다시 풀기" : "시작하기"}
                        />
                    </Button>
                </Stack>
                {!isLast ? (
                    <Box
                        border={`3px solid ${isPassed ? "#009D27" : "#FFC433"}`}
                        top={"50%"}
                        position={"absolute"}
                        right={-40}
                        width={40}
                        sx={{
                            borderLeft: "none",
                            borderRight: "none",
                            background: isPassed ? "#009D27" : "#FFC433",
                        }}
                    />
                ) : null}
            </Stack>
        </Box>
    );
};
