import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    ConceptAccordion,
    ConceptAccordionDetails,
    ConceptAccordionSummary,
} from "../components/ConceptAccordion";
import { useStores } from "../../../../common/modules/useStores";
import { StudyUnitAccordionBox } from "./StudyUnitAccordionBox";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";

export const ConceptAccordionBox = observer(
    ({
        missionId,
        concepts,
        selectable,
        handleClickProblem,
        getChecked,
        sx,
        forShowing,
        defaultStudyUnitId,
        defaultConceptId,
        problemName,
    }) => {
        const [openedConceptIds, setOpenConceptIds] = useState({});

        const { studyUnitStore } = useStores();

        const rootRef = useRef();

        const handleOpenConceptId = (conceptId) => {
            setOpenConceptIds((prev) => ({
                ...prev,
                [conceptId]: !prev[conceptId],
            }));
            studyUnitStore.findAll({ conceptId, problemName });
        };

        useEffect(() => {
            if (defaultConceptId) {
                rootRef.current.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                });
                handleOpenConceptId(defaultConceptId);
            }
        }, [defaultConceptId]);

        return (
            <Paper
                ref={rootRef}
                sx={{
                    borderRadius: 3,
                    px: 3.75,
                    py: 2.5,
                    boxShadow: "none",
                    border: "2px solid #e1e1e7",
                    ...sx,
                }}
            >
                {concepts.map((concept, i) => (
                    <ConceptAccordion
                        key={`concept-accordion-${i}`}
                        expanded={!!openedConceptIds[concept.id]}
                        onChange={() => handleOpenConceptId(concept.id)}
                    >
                        <ConceptAccordionSummary
                            expandIcon={!forShowing && <ExpandMoreIcon />}
                            concept={concept}
                            order={i + 1}
                        />
                        <Divider
                            sx={{
                                bgcolor: "#808080",
                            }}
                        />
                        <ConceptAccordionDetails>
                            {concept.isFetched ? (
                                <StudyUnitAccordionBox
                                    studyUnits={concept.studyUnits}
                                    selectable={selectable}
                                    getChecked={getChecked}
                                    handleClickProblem={handleClickProblem}
                                    missionId={missionId}
                                    defaultConceptId={defaultConceptId}
                                    defaultStudyUnitId={defaultStudyUnitId}
                                    forShowing={forShowing}
                                    conceptId={concept.id}
                                    problemName={problemName}
                                />
                            ) : (
                                <Box height={100}>
                                    <LoadingOverlay active spinnerSize={40} />
                                </Box>
                            )}
                        </ConceptAccordionDetails>
                    </ConceptAccordion>
                ))}
            </Paper>
        );
    }
);
