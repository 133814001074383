import { makeAutoObservable } from "mobx";
import UserHomeworkProgressRepository from "./UserHomeworkProgressRepository";
import { UserHomeworkProgressModel } from "./UserHomeworkProgressModel";
import moment from "moment";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";

export default class UserHomeworkProgressStore {
    userHomeworkProgresses = [];

    isFetching = true;

    count = 0;

    isDownloading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    _replaceUserHomeworkProgresses(response) {
        const { results, count } = response.data;

        this.userHomeworkProgresses.replace(
            results.map(
                (userHomeworkProgress) =>
                    new UserHomeworkProgressModel(userHomeworkProgress)
            )
        );

        this.count = count;
    }

    *findAll(params) {
        this.isFetching = true;
        const response = yield UserHomeworkProgressRepository.findAll({
            classroomId: this.rootStore.classroomStore.classroom.id,
            ...params,
        });
        this._replaceUserHomeworkProgresses(response);
        this.isFetching = false;
    }

    *download(params) {
        this.isDownloading = true;
        try {
            const response = yield UserHomeworkProgressRepository.download({
                classroomId: this.rootStore.classroomStore.classroom.id,
                ...params,
            });
            const { name } = this.rootStore.classroomStore.classroom;
            const blob = new Blob([response.data], {
                type: "application/vnd.ms-excel",
            });

            const today = moment().format("YYYY-MM-DD_HH:mm:ss");
            const filename = `수업및과제_이수현황_${name}_${today}`;
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("target", "_blank");
            link.download = `${filename}.xls`;
            link.click();
            this.isDownloading = false;
            snackActions.success("정상적으로 다운로드 되었습니다.");
            return response;
        } catch (e) {
            snackActions.error(
                "다운로드 중 문제가 발생하였습니다. 지속 발생시 관리자에게 문의해주세요."
            );
        } finally {
            this.isDownloading = false;
        }
    }
}
