import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { TermsTemplate } from "./TermsTemplate";
import { PrivacyTemplate } from "./PrivacyTemplate";
import ChannelService from "../../../common/components/channel/ChannelService";

export const PolicyPage = () => {
    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    return (
        <Routes>
            <Route path={"/terms"} element={<TermsTemplate />} />
            <Route path={"/privacy"} element={<PrivacyTemplate />} />
        </Routes>
    );
};
