import React, { useState } from "react";
import { alpha, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { StudentForm } from "./StudentForm";
import { LargeButton } from "../../../../../common/components/button/LargeButton";
import { AlertDialog } from "../../../../../common/components/dialog/AlertDialog";
import { ConfirmDialog } from "../../../../../common/components/dialog/ConfirmDialog";

export const StudentFormsTemplate = ({
    isAdd,
    students,
    handleAdd,
    handleCancel,
    handleChange,
    handleDelete,
    handleSubmit,
    failedOpen,
    handleConfirm,
    failedStudents,
}) => {
    const [cancelOpen, setCancelOpen] = useState(false);
    const [failedListOpen, setFailedListOpen] = useState(false);

    const handleClose = () => {
        setCancelOpen(false);
    };

    const handleFailedClose = () => {
        handleConfirm();
        setFailedListOpen(false);
    };

    return (
        <Stack height="100%">
            <Stack
                direction="column"
                spacing={5}
                alignItems="center"
                flexGrow={1}
                overflow="auto"
                py={10}
            >
                {students.map((student) => (
                    <StudentForm
                        key={`student-form-${student.id}`}
                        input={student}
                        handleDelete={handleDelete}
                        handleChange={handleChange}
                        isAdd={isAdd}
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                    p: 2.5,
                    bgcolor: "background.paper",
                    position: "relative",
                }}
                spacing={2.5}
                alignItems="center"
            >
                {isAdd ? (
                    <Typography
                        variant="body2"
                        children={`${students.length}명 작성됨`}
                    />
                ) : null}
                <Stack direction="row" spacing={1.25}>
                    <LargeButton
                        onClick={() => setCancelOpen(true)}
                        variant="outlined"
                        color="#FFFFFF"
                        label="취소"
                        backgroundColor={alpha("#FFFFFF", 0)}
                        sx={{
                            borderColor: "#808080",
                            color: "#808080",
                        }}
                    />
                    <LargeButton
                        backgroundColor="#FF5555"
                        color="#FFFFFF"
                        label={isAdd ? "생성" : "변경 사항 저장"}
                        onClick={() => handleSubmit(students)}
                    />
                </Stack>
                {isAdd ? (
                    <IconButton
                        sx={{
                            position: "absolute",
                            left: "calc(50% - 20px)",
                            top: -20,
                            p: 1,
                            bgcolor: "common.black",
                            color: "common.white",
                        }}
                        onClick={handleAdd}
                    >
                        <AddIcon />
                    </IconButton>
                ) : null}
            </Stack>
            <AlertDialog
                title="정말로 학생 관리 화면으로 돌아가시겠습니까?"
                description="지금까지 작성한 내용은 사라집니다."
                handleConfirm={handleCancel}
                handleClose={handleClose}
                open={cancelOpen}
            />
            <ConfirmDialog
                open={failedOpen}
                onConfirm={handleFailedClose}
                onClose={handleFailedClose}
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: 750,
                    },
                }}
            >
                <Stack direction="column" spacing={2}>
                    <Typography
                        variant="h3"
                        children={`${students.length}명 중 ${failedStudents.length}명의 학생 등록이 실패했습니다.`}
                    />
                    {failedListOpen ? (
                        <Stack direction="column" spacing={1}>
                            <Typography
                                variant="body1"
                                children="- 등록 실패한 학생 목록"
                            />
                            {failedStudents &&
                                failedStudents.map((student) => (
                                    <StudentForm
                                        key={`failed-student-form-${student.id}`}
                                        input={student}
                                        isAdd={isAdd}
                                    />
                                ))}
                        </Stack>
                    ) : (
                        <LargeButton
                            onClick={() => setFailedListOpen(true)}
                            variant="outlined"
                            color="#808080"
                            label="등록에 실패한 학생 목록 확인"
                            backgroundColor={alpha("#FFFFFF", 0)}
                            sx={{
                                borderColor: "#808080",
                            }}
                        />
                    )}
                </Stack>
            </ConfirmDialog>
        </Stack>
    );
};
