import React, { useState } from "react";
import {
    alpha,
    Button,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const HomeworkSelector = ({ homeworkId, setHomeworkId, homeworks }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const changeHomeworkId = (homeworkId) => {
        setHomeworkId(homeworkId);
        handleClose();
    };

    const selectedHomework = homeworks.find(
        (homework) => homework.id === homeworkId
    );

    return (
        <>
            <Button
                onClick={handleClick}
                sx={{
                    minWidth: 180,
                    backgroundColor: alpha("#111111", 0.05),
                    color: "#000000",
                    p: 2,
                    height: "100%",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        variant="body2"
                        children={selectedHomework.name}
                    />
                    <ArrowDropDownIcon />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                sx={{
                    "& .MuiMenu-paper": {
                        width: 180,
                        borderRadius: 2,
                        boxShadow: 2,
                    },
                }}
            >
                {homeworks.map((homework, i) => (
                    <MenuItem
                        onClick={() => changeHomeworkId(homework.id)}
                        selected={homeworkId === homework.id}
                        key={`homework-menu-${i}`}
                    >
                        <Typography children={homework.name} variant="body2" />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
