import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Popper, Stack, Typography } from "@mui/material";
import Editor from "@monaco-editor/react";
import { LangToCodeMirrorMode } from "../../../../../../common/modules/problems/ProblemModel";
import { EditorHeader } from "./templates/EditorHeader";
import { useKeyDownRef } from "../../../../../../utils/ref";
import { ExecuteResultTemplate } from "./templates/ExecuteResultTemplate";
import { TabButton } from "../submissions/components/TabButton";
import { ProblemType } from "../../../../../../common/modules/missions/mission/MissionModel";
import { MultipleChoiceEditor } from "./templates/MultipleChoiceEditor";
import { ConfirmDialog } from "../../../../../../common/components/dialog/ConfirmDialog";
import { TutorialCard } from "../TutorialCard";
import { anchorRef, bindPopper } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { TutorialType } from "../../../../../../common/modules/ui/tutorial/TutorialModel";
import moment from "moment";

export const EditorTemplate = ({
    monacoTheme,
    handleModeChange,
    defaultLanguage,
    handleLanguageAPIChange,
    problem,
    handleSubmit,
    handleMcqSubmit,
    handleExecute,
    isExecuting,
    result,
    testCase,
    disabled,
    submitUntil,
    open,
    mcqResult,
    toggleOpen,
    currTutorial,
    setTutorial,
    tutorialList,
    sendChat,
    isGPTSummaryExecuting,
    gptSummaryMessage,
    aiEnabled,
}) => {
    const isLightTheme = monacoTheme === "light";
    const preventKeyDown = (e) => {
        if (e.key === "[") {
            e.stopPropagation();
        }
    };

    const editorRef = useRef(null);

    const CodeMirrorRef = useKeyDownRef(preventKeyDown);

    const [language, setLanguage] = useState(defaultLanguage);

    const LocalCodeKey = `problem-${problem && problem.id}-${language}`;

    const [code, setCode] = useState(localStorage.getItem(LocalCodeKey) || "");
    const [selections, setSelections] = useState([]);

    const [userInput, setUserInput] = useState("");

    const [offsetHeight, setOffsetHeight] = useState(400);

    const { mcAnswersCount, mcQuestions, mcQuestionsEn } = problem;

    const isCodeBlock = problem.type === ProblemType.CodeBlock;

    const initChat = [
        {
            role: "assistant",
            content:
                "어떻게 도와드릴까요? 아래의 추천 질문 중 하나를 선택하거나, 자유롭게 물어보세요.",
        },
        {
            role: "assistant",
            content: "1) 제 코드에서 어느 부분이 틀렸나요?",
            actionIdx: 0,
            action: true,
            actionText: "제 코드에서 어느 부분이 틀렸나요?",
        },
        {
            role: "assistant",
            content: "2) 제 코드에 대한 피드백을 받고 싶어요.",
            actionIdx: 1,
            action: true,
            actionText: "제 코드에 대한 피드백을 받고 싶어요.",
        },
    ];

    const [_assistantTempChats, setAssistantTempChats] = React.useState([]);

    const handleSelection = (id) => {
        if (problem.type === ProblemType.MultipleChoice) {
            if (selections.includes(id)) {
                setSelections((prev) => prev.filter((item) => item !== id));
            } else if (mcAnswersCount === 1 && selections.length === 1) {
                setSelections([id]);
            } else {
                setSelections((prev) => [...prev, id]);
            }
        } else if (selections.length < mcAnswersCount)
            setSelections((prev) => [...prev, id]);
        else setSelections([id]);
    };

    useEffect(() => {
        if (CodeMirrorRef.current) {
            setOffsetHeight(CodeMirrorRef.current.offsetHeight);
        }
    }, [CodeMirrorRef.current]);

    useEffect(() => {
        if (testCase) setUserInput(testCase.stdin);

        return () => {
            setUserInput("");
        };
    }, [testCase]);

    useEffect(() => {
        setLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    };

    useEffect(() => {
        setCode(localStorage.getItem(LocalCodeKey) || "");
    }, [problem, language]);

    const handleCodeChange = (value) => {
        localStorage.setItem(LocalCodeKey, value);
        setCode(value);
    };

    const handleLanguageChange = (language) => {
        handleLanguageAPIChange(language);
        setLanguage(language);
    };

    const TAB_ITEMS = [
        { key: "executeResult", label: "실행 결과 창", element: null },
        { key: "testCases", label: "테스트 케이스", element: null },
    ];

    const [activeTab, setActiveTab] = useState(null);

    const handleToggle = (key) => {
        if (activeTab === key) {
            setActiveTab(null);
        } else {
            setActiveTab(key);
        }
    };

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;
    };

    const handleEditorWillMount = (monaco) => {
        import("monaco-themes/themes/Monokai.json")
            .then((data) => {
                monaco.editor.defineTheme("monokai", data);
            })
            .then((_) => {
                if (isLightTheme) {
                    return;
                }
                monaco.editor.setTheme("monokai");
            });
    };

    const handleExecuteWrapper = (_code, _language, _userInput) => {
        handleExecute({
            code: _code,
            language: _language,
            userInput: _userInput,
        });
        setActiveTab("executeResult");
    };

    const validateAndSubmit = (_code, _language, _selections) => {
        if (isCodeBlock) {
            handleSubmit({ code: _code, language: _language });
            setAssistantTempChats([]);
            const promiseArray = initChat
                .filter((chat) => chat.action)
                .map((chat) => {
                    const newChatList = [
                        ...initChat,
                        { role: "assistant", content: chat.actionText },
                    ];
                    return sendChat(
                        newChatList.filter(
                            (chatData) => chatData.role === "user"
                        )
                    ).then((message) => {
                        return message;
                    });
                });
            Promise.all(promiseArray).then((msgs) =>
                setAssistantTempChats(msgs)
            );
        } else {
            handleMcqSubmit({ selections: _selections });
        }
    };

    const submitDisabled =
        isExecuting ||
        disabled ||
        (isCodeBlock ? !code.length : !selections.length);

    const popupEditorState = usePopupState({
        variant: "popover",
        popupId: "editor",
    });
    const popupExecuteState = usePopupState({
        variant: "popover",
        popupId: "execute",
    });
    const popupSubmitState = usePopupState({
        variant: "popover",
        popupId: "submit",
    });
    const popupTestCaseState = usePopupState({
        variant: "popover",
        popupId: "testcase",
    });

    useEffect(() => {
        if (CodeMirrorRef.current) {
            setOffsetHeight(CodeMirrorRef.current.offsetHeight);
            popupEditorState.setAnchorEl(CodeMirrorRef.current);
        }
    }, [CodeMirrorRef.current]);

    useEffect(() => {
        if (popupEditorState.anchorEl && currTutorial === TutorialType.Editor) {
            popupEditorState.open();
        }
    }, [popupEditorState.anchorEl, currTutorial]);

    useEffect(() => {
        if (
            popupExecuteState.anchorEl &&
            currTutorial === TutorialType.Execute
        ) {
            popupExecuteState.open();
        }
    }, [popupExecuteState.anchorEl, currTutorial]);

    useEffect(() => {
        if (popupSubmitState.anchorEl && currTutorial === TutorialType.Submit) {
            popupSubmitState.open();
        }
    }, [popupSubmitState.anchorEl, currTutorial]);

    useEffect(() => {
        if (
            popupTestCaseState.anchorEl &&
            currTutorial === TutorialType.TestCase
        ) {
            popupTestCaseState.open();
        }
    }, [popupTestCaseState.anchorEl, currTutorial]);

    const handlePrevTutorial = (popupState) => () => {
        popupState.close();
        setTutorial(
            tutorialList[
                Math.max(
                    Math.min(
                        tutorialList.indexOf(currTutorial) - 1,
                        tutorialList.length - 1
                    ),
                    0
                )
            ]
        );
    };

    const handleNextTutorial = (popupState) => () => {
        popupState.close();
        setTutorial(
            tutorialList[
                Math.max(
                    Math.min(
                        tutorialList.indexOf(currTutorial) + 1,
                        tutorialList.length - 1
                    ),
                    0
                )
            ]
        );
    };

    const handleReset = () => {
        setCode("");
    };

    return (
        <Stack
            direction="column"
            bgcolor="background.default"
            height={"100%"}
            sx={(theme) => ({
                overflow: "hidden",
                mx: {
                    md: 0,
                    xs: 1,
                },
                my: {
                    md: 0,
                    xs: 1,
                },
                border: {
                    md: "none",
                    xs: `1px solid ${theme.palette.common.black}`,
                },
            })}
        >
            <EditorHeader
                isLightTheme={isLightTheme}
                handleLanguageChange={handleLanguageChange}
                handleModeChange={handleModeChange}
                language={language}
                isCodeBlock={isCodeBlock}
                currTutorial={currTutorial}
                setTutorial={setTutorial}
                handleReset={handleReset}
            />
            <Box ref={CodeMirrorRef} flex="1 1 100%" minHeight={0}>
                {isCodeBlock ? (
                    <Editor
                        language={LangToCodeMirrorMode[language]}
                        theme={monacoTheme}
                        value={code}
                        onChange={handleCodeChange}
                        options={{
                            fontSize: 14,
                            minimap: {
                                enabled: false,
                            },
                            cursorSurroundingLines: false,
                            renderLineHighlight: "none",
                        }}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />
                ) : (
                    <MultipleChoiceEditor
                        mcAnswersCount={mcAnswersCount}
                        mcQuestions={mcQuestions}
                        mcQuestionsEn={mcQuestionsEn}
                        handleSelection={handleSelection}
                        selections={selections}
                        type={problem.type}
                    />
                )}
            </Box>
            <Stack direction="column" flex="1 1 0%">
                {activeTab ? (
                    <ExecuteResultTemplate
                        activeTab={activeTab}
                        isLightTheme={isLightTheme}
                        offsetHeight={offsetHeight}
                        onClose={() => setActiveTab(null)}
                        isExecuting={isExecuting}
                        result={result}
                        userInput={userInput}
                        isGPTSummaryExecuting={isGPTSummaryExecuting}
                        gptSummaryMessage={gptSummaryMessage}
                        handleChange={handleUserInputChange}
                        aiEnabled={aiEnabled}
                    />
                ) : null}
                <Stack
                    direction="row"
                    flexGrow={0}
                    pr={2}
                    justifyContent="space-between"
                    sx={{
                        bgcolor: "background.default",
                        borderTop: `1px solid ${
                            isLightTheme ? "#F1F1F7" : "#41444A"
                        }`,
                    }}
                >
                    <Stack
                        direction="row"
                        {...(isCodeBlock
                            ? { ref: anchorRef(popupTestCaseState) }
                            : {})}
                    >
                        {isCodeBlock
                            ? TAB_ITEMS.map((tab) => (
                                  <TabButton
                                      key={tab.key}
                                      onClick={() => handleToggle(tab.key)}
                                      isActive={activeTab === tab.key}
                                      isLightTheme={isLightTheme}
                                      children={tab.label}
                                  />
                              ))
                            : null}
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems={"center"}
                        my={1.125}
                        spacing={1}
                    >
                        {moment().add(1, "y").isAfter(submitUntil) ? (
                            moment().isBefore(submitUntil) ? (
                                <Typography
                                    variant="body2"
                                    color={"#999999"}
                                >{`${submitUntil.format(
                                    "LLL"
                                )}까지 제출할 수 있습니다.`}</Typography>
                            ) : (
                                <Typography
                                    variant="body2"
                                    color={"#999999"}
                                >{`제출 기한이 지났습니다.`}</Typography>
                            )
                        ) : null}
                        {isCodeBlock ? (
                            <Button
                                ref={anchorRef(popupExecuteState)}
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    handleExecuteWrapper(
                                        code,
                                        language,
                                        userInput
                                    )
                                }
                                disabled={submitDisabled}
                            >
                                코드 실행
                            </Button>
                        ) : null}
                        <Button
                            ref={anchorRef(popupSubmitState)}
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                                validateAndSubmit(code, language, selections)
                            }
                            disabled={submitDisabled}
                        >
                            제출 및 채점
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <ConfirmDialog
                open={open}
                onClose={toggleOpen}
                onConfirm={toggleOpen}
                children={
                    mcqResult ? "정답입니다." : "틀린 답을 입력하셨습니다."
                }
            />
            <Popper
                {...bindPopper(popupEditorState)}
                open={popupEditorState.isOpen}
                placement={"left-start"}
                sx={{
                    zIndex: 1,
                    padding: 1,
                }}
            >
                <TutorialCard
                    title={isCodeBlock ? `코드 작성` : `정답 선택`}
                    body={
                        isCodeBlock
                            ? `프로그램을 작성하는 공간이에요.`
                            : `선택지에서 고르는 문제에요. '모두 고르시오' 문제일 수 있어요!`
                    }
                    handlePrevTutorial={handlePrevTutorial(popupEditorState)}
                    handleNextTutorial={handleNextTutorial(popupEditorState)}
                />
            </Popper>
            <Popper
                {...bindPopper(popupExecuteState)}
                open={popupExecuteState.isOpen}
                placement={"top-end"}
                sx={{
                    zIndex: 1,
                    padding: 1,
                }}
            >
                <TutorialCard
                    title={`코드 실행`}
                    body={`현재 코드를 실행해볼 수 있어요.`}
                    handlePrevTutorial={handlePrevTutorial(popupExecuteState)}
                    handleNextTutorial={handleNextTutorial(popupExecuteState)}
                />
            </Popper>
            <Popper
                {...bindPopper(popupSubmitState)}
                open={popupSubmitState.isOpen}
                placement={"top-end"}
                sx={{
                    zIndex: 1,
                    padding: 1,
                }}
            >
                <TutorialCard
                    title={`제출 및 채점`}
                    body={`정답인거 같나요? 제출해보세요!`}
                    handlePrevTutorial={handlePrevTutorial(popupSubmitState)}
                    handleNextTutorial={handleNextTutorial(popupSubmitState)}
                />
            </Popper>
            <Popper
                {...bindPopper(popupTestCaseState)}
                open={popupTestCaseState.isOpen}
                placement={"top-end"}
                sx={{
                    zIndex: 1,
                    padding: 1,
                }}
            >
                <TutorialCard
                    title={`직접 테스트 케이스 만져보기`}
                    body={`다양한 테스트 케이스를 직접 검증해볼 수 있어요.`}
                    handlePrevTutorial={handlePrevTutorial(popupTestCaseState)}
                    handleNextTutorial={handleNextTutorial(popupTestCaseState)}
                />
            </Popper>
            {/* {aiEnabled ? (<ChatAIDialog assistantTempChats={assistantTempChats} sendChat={sendChat}/> ) : null} */}
        </Stack>
    );
};
