import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonIcon from "@mui/icons-material/Person";
import CommentIcon from "@mui/icons-material/Comment";
import moment from "moment";
import "moment/locale/ko";

export const DiscussionRow = ({ discussion, handleClick }) => {
    const { title, isResolved, username, createdAt, commentNum, description } =
        discussion;

    const fromNow = moment(createdAt).locale("ko").fromNow();

    const lan = ((description || "").split("```")[1] || "")
        .split("\n")[0]
        .replaceAll("`", "");

    return (
        <Stack
            onClick={handleClick}
            direction="column"
            sx={{
                p: 2,
                border: "1px solid #D0D7DE",
                borderBottom: "none",
                cursor: "pointer",
                bgcolor: "background.paper",
                "&:hover": {
                    backgroundColor: "#F6F8FA",
                },
            }}
        >
            <Stack direction="row">
                <Typography variant="h4" fontWeight={700} children={title} />
                {isResolved ? (
                    <CheckCircleIcon
                        sx={{
                            color: "#A2CF6E",
                            ml: 1,
                            fontSize: 20,
                        }}
                    />
                ) : null}
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <PersonIcon sx={{ fontSize: 12 }} />
                        <Typography variant="caption" children={username} />
                        <Typography variant="caption" children={fromNow} />
                    </Stack>
                    {lan ? (
                        <Chip
                            sx={{
                                width: "fit-content",
                            }}
                            label={lan}
                            size="small"
                            variant="outlined"
                        />
                    ) : null}
                </Stack>
                <Stack direction="row" spacing={0.25} alignItems="center">
                    <Box
                        sx={{
                            display: "flex",
                            color: "#FFFFFF",
                            backgroundColor: "#3FCB7E",
                            p: 0.75,
                            borderRadius: 7.5,
                            mr: 0.5,
                        }}
                    >
                        <CommentIcon
                            sx={{
                                fontSize: 16,
                            }}
                        />
                    </Box>
                    <Typography variant="body2" children={commentNum} />
                </Stack>
            </Stack>
        </Stack>
    );
};
