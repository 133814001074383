import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

export const TermsTemplate = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                wordBreak: "keep-all",
                p: 5,
                ...theme.typography.body2,
            }}
        >
            <Typography align="center" variant="h3" fontWeight={700}>
                브랜치앤바운드 서비스 이용약관
            </Typography>
            <br />
            <Typography variant="h4">제1장 총칙</Typography>
            <br />
            <strong>제1조 [목적]</strong>
            <br />
            이 약관은 주식회사 브랜치앤바운드(이하 &quot;회사&quot;라 합니다)가
            https://ooe.codetree.ai(이하 &quot;웹 사이트&quot;라 합니다)을
            통하여 제공하는 교육 정보 서비스(이하 &quot;서비스&quot;라 합니다)의
            이용과 관련하여 &quot;회사&quot;와 &quot;이용자&quot;의 권리 및 의무
            등에 관한 사항을 규정함을 목적으로 합니다.
            <br />
            <br />
            <strong>제2조 [정의]</strong>
            <br />
            1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />
            ㅤ① &quot;이용자&quot;라 함은 회사의 웹 사이트에 접속하여 본 약관에
            따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.
            <br />
            ㅤ② &quot;회원&quot;이라 함은 회사의 웹 사이트에 접속하여 본 약관에
            동의함으로써 회원가입을 하고 아이디(ID)를 부여받은
            &quot;이용자&quot;를 말합니다.
            <br />
            ㅤ③ &quot;콘텐츠&quot;라 함은 정보통신망이용촉진 및 정보보호 등에
            관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는
            부호・문자・음성음향・이미지 또는 영상 등으로 표현된 자료 또는
            정보를 말합니다.
            <br />
            ㅤ④ &quot;서비스&quot;라 함은 &quot;회사&quot;가 제작하여 &quot;웹
            사이트&quot;를 통해 제공 및 판매하는 콘텐츠 및 이에 부수하여
            제공되는 각종 학습자료, 질의응답 등을 말합니다.
            <br />
            ㅤ⑤ &quot;아이디(ID)&quot;라 함은 &quot;회원&quot;의 식별과 서비스
            이용을 목적으로 &quot;회원&quot;이 정하고 &quot;회사&quot;가
            승인하는 부호·문자·숫자 등의 조합을 말합니다.
            <br />
            ㅤ⑥ &quot;비밀번호(PASSWORD)&quot;라 함은 &quot;아이디&quot;와
            일치되는 &quot;회원&quot;임을 확인하고 충분한 비밀성을 유지할 수
            있도록 &quot;회원&quot;이 정한 부호·문자·숫자 등의 조합을 말합니다.
            <br />
            ㅤ⑦ &quot;이용내역&quot;이라 함은 회사의 서비스가 제공되는 &quot;웹
            사이트&quot;에서 &quot;회원&quot;이 &quot;서비스&quot;를 체험하는
            모든 행위를 통해 만들어진 결과 (문제 풀이 결과, 질문 내용, 문제 풀이
            시간 등) 에 대한 정보를 의미합니다.
            <br />
            2. 전항 각호에 해당하는 정의 이외의 기타 용어의 정의에 대하여는 거래
            관행 및 관계 법령에 따릅니다.
            <br />
            <br />
            <strong>제3조 [약관의 효력과 변경]</strong>
            <br />
            1. 이 약관은 &quot;이용자&quot;가 약관의 내용에 동의하며 회원가입을
            신청하고, &quot;회사&quot;가 그 신청에 대하여 승낙함으로써 효력이
            발생합니다.
            <br />
            2. &quot;회사&quot;는 합리적인 사유가 발생할 경우 &quot;약관의
            규제에 관한 법률&quot;, &quot;정보통신망 이용 촉진 및 정보보호 등에
            관한 법률&quot;(이하 &quot;정보통신망법&quot;)&quot; 등의 관련법령에
            위배되지 않는 범위 안에서 약관을 개정할 수 있습니다.
            <br />
            3. &quot;회사&quot;가 약관을 개정할 경우에는 그 적용일자 7일
            이전부터 적용일 후 상당한 기간 동안 공지합니다. 다만,
            &quot;이용자&quot;에게 불리하게 약관내용을 변경하는 경우에는 최소한
            30일 이상의 사전 유예기간을 두고 공지합니다.
            <br />
            4. &quot;회사&quot;가 약관을 개정할 경우에는 적용일자, 개정사유를
            설명하고, 약관의 개정 전·후를 비교하여 &quot;이용자&quot;가 알기
            쉽게 표시하여 서비스 초기화면에 공지합니다. 또한 기존
            &quot;회원&quot;에게는 개정약관을 전자우편주소로 발송합니다.
            <br />
            5. 전 항과 같이 약관의 변경에 대하여 통지한 경우, 그 때부터
            7일(이용자에게 불리하거나 중대한 사항의 변경인 경우 30일) 내에
            이용자가 거절의 의사를 표시하지 않으면 변경된 약관에 동의한 것으로
            간주됩니다. 단, 유료 서비스를 이용 중인 회원 중 변경될 약관에
            동의하지 아니하는 회원은 유료 서비스 이용이 종료될 때까지는 기존
            약관이 적용됩니다.
            <br />
            6. &quot;회원&quot;이 개정약관의 적용에 동의하지 않는 경우
            &quot;회사&quot; 또는 &quot;회원&quot;은 &quot;서비스&quot;
            회원가입계약을 해지할 수 있습니다.
            <br />
            <br />
            <strong>제4조 [회사의 약관의 게시 및 정보제공의무]</strong>
            <br />
            1. &quot;회사&quot;는 이 약관의 내용 및 &quot;회사&quot;의 정보 등을
            &quot;이용자&quot;가 쉽게 알 수 있도록 서비스 초기화면에 게시합니다.
            <br />
            2. &quot;회사&quot;는 &quot;이용자&quot;가 약관 전부를 인쇄하여 약관
            내용을 확인하고, 약관에 관해 질의·응답할 수 있도록 기술적 조치를
            취합니다.
            <br />
            <br />
            <strong>제5조 [약관 외 준칙]</strong>
            <br />
            2. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
            온라인디지털콘텐츠산업발전법, 전자상거래
            등에서의소비자보호에관한법률, 약관의규제에관한법률, 정보통신부장관이
            정하는 디지털콘텐츠 이용자보호지침, 기타 관계법령 또는 상관례에
            따릅니다.
            <br />
            3. &quot;회사&quot;는 「개인정보보호법」및「정보통신망 이용촉진 및
            정보보호 등에 관한 법률」에 따라 &quot;이용자&quot;의 개인정보를
            관리·보호합니다.
            <br />
            <br />
            <strong>제6조 [회원에 대한 통지]</strong>
            <br />
            1. &quot;회사&quot;는 &quot;회원&quot;에게 알려야 할 상황이 발생하는
            경우 전자적 수단(전자우편, 문자메시지, 서비스 내 전자쪽지발송,
            홈페이지 초기화면에 게시, 알림 메시지를 띄우는 등의 방법)으로
            통지하거나, &quot;서비스&quot; 내에 공지 또는 팝업창을 게시하는 방법
            등으로 통지할 수 있습니다.
            <br />
            2. 전 항과 같이 통지한 때로부터 7일 이내에 &quot;회원&quot;이
            &quot;회사&quot;가 정하는 방법으로 그 통지 내용에 동의하지 않음을
            표시하지 않을 경우 그 &quot;회원&quot;에게는 통지 내용이 도달하였고
            통지 내용에 동의하였다고 간주됩니다.
            <br />
            3. 통지 내용에 동의하지 아니하는 &quot;회원&quot;은
            &quot;회사&quot;에 회원탈퇴를 요청할 수 있습니다.
            <br />
            <br />
            <Typography variant="h6">
                제2장 회원가입 및 회원정보 관리
            </Typography>
            <br />
            <strong>제7조 [회원가입]</strong>
            <br />
            1. 회원가입은 서비스를 이용하려는 &quot;이용자&quot;가 약관 동의 및
            회원가입 신청을 한 후 &quot;회사&quot;가 이러한 신청을 승낙함으로써
            이루어집니다.
            <br />
            2. 전 항의 규정에 따라 회원가입을 할 때에는 &quot;회사&quot;가
            제공하는 &quot;서비스&quot;의 원활한 이용을 위해서 필요한 개인정보를
            제공해야 합니다. 이 경우 제공되는 개인정보는 &quot;회사&quot;가
            게시한 개인정보처리방침에서 확인할 수 있습니다.
            <br />
            3. &quot;회사&quot;는 &quot;이용자&quot;의 회원가입 신청에 대하여
            승낙함을 원칙으로 합니다. 다만, &quot;회사&quot;는 신청자가 허위의
            정보를 기재하거나 &quot;회사&quot;가 제시하는 내용을 기재하지 않은
            경우에는 회원가입 신청을 거절할 수 있습니다.
            <br />
            4. &quot;회사&quot;가 회원가입을 거절하는 경우 회원가입을 신청한
            &quot;이용자&quot;에게 이를 알립니다.
            <br />
            <br />
            <strong>제8조 [만 14세 미만 미성년자에 관한 특칙]</strong>
            <br />
            1. 만 14세 미만의 &quot;이용자&quot;는 개인정보처리방침을 충분히
            숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입 신청을 하고
            본인의 개인정보를 제공하여야 합니다.
            <br />
            2. &quot;회사&quot;는 부모 등 법정대리인의 동의에 대한 확인절차를
            거치지 않은 만 14세 미만 &quot;이용자&quot;에 대하여는 회원가입을
            불허 또는 취소할 수 있습니다.
            <br />
            3. 만 14세 미만의 &quot;이용자&quot;의 부모 등 법정대리인은 아동에
            대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를
            철회할 수 있으며, 이 경우 &quot;회사&quot;는 지체 없이 필요한 조치를
            취해야 합니다.
            <br />
            <br />
            <strong>제9조 [회원정보의 변경]</strong>
            <br />
            1. &quot;회원&quot;은 회원가입 신청 시 기재한 개인정보가 변경되었을
            경우 웹 사이트를 통해 변경사항을 수정하거나, 전자우편 등 기타
            방법으로 &quot;회사&quot;에 이를 알려야 합니다.
            <br />
            2. &quot;회사&quot;는 &quot;회원&quot;이 개인정보 변경사항을
            수정하지 않거나, &quot;회사&quot;에 알리지 않아 발생한 불이익에
            대해서는 책임지지 않습니다.
            <br />
            <br />
            <strong>
                제10조 [회원의 아이디와 비밀번호 관리에 관한 주의의무]
            </strong>
            <br />
            1. &quot;회원&quot;의 &quot;아이디&quot;와 &quot;비밀번호&quot;에
            관한 관리책임은 &quot;회원&quot;에게 있으며, 이를 제3자가 이용하도록
            해서는 안 됩니다.
            <br />
            2. &quot;회원&quot;은 &quot;아이디&quot; 및 &quot;비밀번호&quot;가
            도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시
            &quot;회사&quot;에 통지하고 &quot;회사&quot;의 안내에 따라야 합니다.
            <br />
            3. &quot;회원&quot;이 &quot;회사&quot;에 제3자의 도용사실을 통지하지
            않은 경우 또는 통지하였더라도 &quot;회사&quot;의 안내에 따르지 않은
            경우에는 &quot;회사&quot;는 이로 인해 발생한 불이익에 대하여
            책임지지 않습니다.
            <br />
            <br />
            <Typography variant="h6">제3장 서비스 제공 및 이용</Typography>
            <br />
            <strong>제11조 [서비스 이용 시간]</strong>
            <br />
            1. &quot;회사&quot;는 &quot;서비스&quot; 제공과 관련한
            &quot;회사&quot; 정책의 변경 등 기타 상당한 이유가 있는 경우 등
            운영상, 기술상의 필요에 따라 제공하고 있는 &quot;서비스&quot;의 전부
            또는 일부를 변경 또는 중단할 수 있습니다.
            <br />
            2. &quot;서비스&quot;는 &quot;회사&quot;의 업무상, 기술상 문제 등
            기타 특별한 사정이 없는 한 매일 24시간 운영함을 원칙으로 합니다.
            <br />
            3. &quot;회사&quot;는 일부 &quot;서비스&quot;의 이용 가능 시간을
            별도로 정할 수 있으며, 이 경우 사전에 &quot;서비스&quot; 제공화면에
            공지합니다.
            <br />
            4. &quot;회사&quot;는 &quot;서비스&quot;의 원활한 수행을 위하여
            필요한 기간을 정하여 사전에 공지하고 &quot;서비스&quot;를 중지할 수
            있습니다. 단, 불가피하게 긴급한 조치를 필요로 하는 경우 사후에
            통지할 수 있습니다.
            <br />
            5. &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot;의 일부 또는
            전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며,
            이에 대하여 관련법에 특별한 규정이 없는 한 &quot;회원&quot;에게
            별도의 보상을 하지 않습니다.
            <br />
            <br />
            <strong>제12조 [서비스 이용의 중지 및 제한]</strong>
            <br />
            1. &quot;회사&quot;는 다음 각 호 어느 하나에 해당하는 경우
            &apos;서비스&apos; 제공을 중지하거나 제한할 수 있습니다.
            <br />
            ㅤ① 서비스용 설비의 최적화를 위한 시스템 점검 또는 설비 보수로 인해
            부득이한 경우
            <br />
            ㅤ② 분산서비스거부(DDoS) 공격 등에 의해 발생한 서비스 장애 복구를
            위해 부득이한 경우
            <br />
            ㅤ③ 기타 서비스용 설비의 장애 또는 &apos;서비스&apos; 이용의 폭주
            등으로 &apos;서비스&apos; 이용에 지장이 있는 경우
            <br />
            ㅤ④ 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
            중지했을 경우
            <br />
            ㅤ⑤ 국가비상사태, 천재지변에 의해 부득이한 경우
            <br />
            ㅤ⑥ 기타 합리적인 이유가 있는 경우로서 &quot;회사&quot;가 필요하다고
            인정하는 경우
            <br />
            2. &quot;회사&quot;가 전 항들에 의하여 &quot;서비스&quot; 제공을
            중지하거나 제한하는 경우 &quot;회사&quot;는 이를 사전 또는 사후에
            &quot;회원&quot;에게 알려야 합니다. 다만, &quot;회사&quot;의
            귀책사유 없이 &quot;회원&quot;에게 통지할 수 없는 경우에는 예외로
            합니다.
            <br />
            3. &quot;회사&quot;의 귀책사유로 &quot;서비스&quot; 제공이
            중지되거나 그 이용에 장애가 발생하는 경우에는 회사의 규정에 따라
            이용이 중지되거나 장애가 발생한 시간만큼 이용 기간을 연장합니다.
            다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스 제공이
            중지되거나 그 이용에 장애가 발생하는 경우에는 예외로 합니다.
            <br />
            4. &quot;회사&quot;의 귀책사유로 &quot;콘텐츠&quot; 자체에 이용하기
            어려운 하자가 있는 경우에는 회사의 규정에 따라 그 하자가 복구되지
            못한 기간만큼 이용 기간을 연장합니다. 다만, 완전한 콘텐츠를 다시
            제공하는 것이 불가능한 경우에는 회사의 규정에 따라 다른 방법으로
            그에 대한 보상을 실시할 수 있습니다.
            <br />
            <br />
            <strong>제13조 [서비스의 변경]</strong>
            <br />
            1. &quot;회사&quot;는 상당한 운영상 또는 기술상의 필요가 있는 경우에
            &quot;이용자&quot;에게 제공되고 있는 &quot;서비스&quot;의
            이용방법․이용시간을 변경할 수 있습니다. 이 경우 &quot;회사&quot;는
            변경일시·내용·사유 등을 그 변경 전 7일 이상 해당 &quot;웹
            사이트&quot; 초기화면에 게시하고, &quot;이용자&quot;에게 통지합니다.
            <br />
            2. 제1항의 경우에 &quot;이용자&quot;는 &quot;회사&quot;에 대하여
            변경 이전의 서비스 제공을 요구할 수 있습니다. 다만, 이것이 불가능할
            경우 &quot;회사&quot; 또는 &quot;이용자&quot;는 계약을 해지할 수
            있습니다.
            <br />
            3. 상당한 이유 없이 수강 중인 강좌의 강사나 커리큘럼이 수강기간
            도중에 변경된 경우에는 &quot;이용자&quot;는 다른 강좌의 이용을
            요구할 수 있습니다. 다만, &quot;이용자&quot;가 변경을 원하지 않을
            때에는 이용계약을 해지할 수 있습니다.
            <br />
            <br />
            <strong>제14조 [유료서비스 이용계약의 성립과 절차]</strong>
            <br />
            1. 유료 &quot;서비스&quot; 이용계약은 &quot;이용자&quot;가
            &quot;회사&quot;에서 규정한 결제 방법을 사용하여 이용 요금을
            납입하고, &quot;회사&quot;가 이를 승인함으로써 체결됩니다.
            &quot;회사&quot;는 &quot;이용자&quot;가 이용 요금을 납입한 사실이
            확인되면 지체 없이 그 유료 &quot;서비스&quot; 이용 신청을
            승인합니다.
            <br />
            2. &quot;회사&quot;는 &quot;이용자&quot;의 신청이 다음 각 호에
            해당하는 경우에는 승낙을 거절하거나 유보할 수 있습니다.
            <br />
            ㅤ① 유료 &quot;서비스&quot; 이용 요금을 납입하지 않은 경우
            <br />
            ㅤ② 유료 &quot;서비스&quot; 신청 금액 총액과 납입 금액 총액이
            일치하지 않는 경우
            <br />
            ㅤ③ 기타 합리적인 이유가 있는 경우로서 &quot;회사&quot;가 필요하다고
            인정하는 경우
            <br />
            3. &quot;회사&quot;는 전 항에 의하여 유료 &quot;서비스&quot; 이용
            신청의 승인을 제한하거나 유보하는 경우 이를 그
            &quot;이용자&quot;에게 알려야 합니다.
            <br />
            4. &quot;회사&quot;의 승낙의 의사표시는 &quot;이용자&quot;의
            이용신청에 대한 확인, 서비스제공 가능여부 및 신청의 정정․취소 등에
            관한 정보 등을 포함합니다.
            <br />
            <br />
            <strong>제15조 [유료서비스 이용기간]</strong>
            <br />
            1. 유료 &quot;서비스&quot;의 이용기간은 &quot;회사&quot;가 미리
            공지한 기간으로 합니다.
            <br />
            2. 회사가 유료 &quot;서비스&quot; 이용 신청을 승인하고, 유료
            &quot;서비스&quot; 이용기간이 시작된 후 &quot;회원&quot;이 수강을
            시작하지 않았거나 수강 도중 정지하여 두었다 하더라도 수강 기간은
            자동 진행됩니다. 단, &quot;웹 사이트&quot;상에 별도의 내용이 기재된
            &quot;서비스&quot;에 대해서는 기재된 내용에 따릅니다.
            <br />
            <br />
            <strong>제16조 [회원의 청약철회와 계약해지·해제]</strong>
            <br />
            1. &quot;회사&quot;의 유료 &quot;서비스&quot;에 대하여 이용계약을
            체결한 &quot;회원&quot;은 이용계약이 성립한 날로부터 7일 이내에,
            체결된 이용계약이 다르게 이행된 경우에는 &quot;서비스&quot;를 이용할
            수 있는 날로부터 3개월 이내 또는 그 사실을 알거나 알 수 있었던
            날로부터 30일 이내에 청약을 철회 또는 계약해제(이하 &quot;청약철회
            등&quot;)를 할 수 있습니다.
            <br />
            2. &quot;회원&quot;은 다음 각호의 경우 본 조 제1항에 따른
            청약철회등을 할 수 없습니다. 다만, 회사가 제3항에 따른 조치를 취하지
            않은 경우에는 제2호부터 제5호까지의 규정에 해당하는 경우에도
            청약철회등을 할 수 있습니다.
            <br />
            ㅤ① &quot;회원&quot;에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
            경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한
            경우에는 제외됩니다.)
            <br />
            ㅤ② &quot;회원&quot;의 사용 또는 일부 소비로 재화등의 가치가 현저히
            감소한 경우
            <br />
            ㅤ③ 시간이 지나 다시 판매하기 곤란할 정도로 재화등의 가치가 현저히
            감소한 경우
            <br />
            ㅤ④ 복제가 가능한 재화등의 포장을 훼손한 경우
            <br />
            ㅤ⑤ 디지털콘텐츠(PDF, PPT 자료 등 전자적 형태 문서 일체 포함)의
            제공이 개시된 경우
            <br />
            3. &quot;회사&quot;는 제2항 제2호부터 제5호까지의 규정에 따라
            청약철회등이 불가능한 경우에는 다음 각 호 중 하나의 조치를 취합니다.
            다만, 제2항 제5호의 경우에는 청약철회등의 권리를 제한하기 위해
            제1호의 조치 외에 2호 또는 3호의 조치를 동시에 취합니다.
            <br />
            ㅤ① 청약의 철회가 불가능한 유료서비스에 대한 사실을 표시사항에 포함
            <br />
            ㅤ② 시험시용상품 제공
            <br />
            ㅤ③ 한시적 또는 일부 이용 등의 방법 제공
            <br />
            4. &quot;회원&quot;은 다음 각 호의 사유가 있을 때 &quot;서비스&quot;
            이용계약을 해지 또는 해제할 수 있습니다.
            <br />
            ㅤ① &quot;서비스&quot;가 제공되지 않거나, 제공된
            &quot;서비스&quot;가 표시․광고 등과 현저한 차이가 있는 경우
            <br />
            ㅤ② &quot;회사&quot;가 &quot;회원&quot;의 동의 없이 무료 서비스를
            유료 서비스로 전환한 경우
            <br />
            ㅤ③ &quot;회사&quot;가 제시한 최소한의 기술사양을 충족하였음에도
            불구하고 &quot;서비스&quot; 이용이 불가능한 경우
            <br />
            ㅤ④ &quot;회사&quot;가 제공하는 &quot;서비스&quot;의 결함으로 인하여
            정상적인 이용이 불가능한 경우
            <br />
            ㅤ⑤ 단순변심 등 기타 &quot;회원&quot; 측의 사유로 유료서비스 이용이
            어려운 경우
            <br />
            5. 청약철회, 계약해제·해지는 &quot;회원&quot;이 전화, 전자우편
            등으로 &quot;회사&quot;에 그 의사를 표시한 때에 효력이 발생합니다.
            <br />
            6. &quot;회사&quot;는 &quot;회원&quot;의 청약철회, 계약해제·해지의
            의사표시를 수신한 후 이러한 사실을 &quot;회원&quot;에게 회신합니다.
            <br />
            <br />
            <strong>
                제17조 [&quot;회원&quot;의 청약철회 및 계약해제·해지의 효과]
            </strong>
            <br />
            1. &quot;회사&quot;는 &quot;회원&quot;의 청약철회 및 계약해제·해지의
            의사표시를 받은 날로부터 3영업일 이내(상품 등 &quot;이용자&quot;가
            &quot;회사&quot;에 반환하여야 할 재화 등이 있는 경우에는 해당 재화
            등을 반환 받은 날로부터 3영업일 이내, 수납확인이 필요한 결제수단의
            경우에는 수납확인일로부터 3영업일 이내)에 &quot;회원&quot;의
            이용금액을 공제하고 대금결제와 동일한 방법으로 대금을 환불합니다.
            다만, 동일한 방법으로 대금의 환불이 불가능할 때에는 이를 고지합니다.
            <br />
            2. 청약을 철회할 때 교재 등 반환이 필요한 경우에는 해당 비용은
            &quot;회원&quot;이 부담합니다.
            <br />
            3. &quot;회사&quot;가 제1항에 따라 환불할 경우에 본 약관 제18조에서
            정한 바에 의하며 &quot;서비스&quot;에 대한 취소 및 환불 규정은
            전자상거래등에서의 소비자보호에 관한 법률 등 관련 법령을 준수합니다.
            <br />
            4. &quot;회사&quot;는 별도의 취소 및 환불 관련 규정을 정할 수
            있으며, 이 경우 별도 계약 및 이용조건상의 취소 및 환불 규정이 우선
            적용됩니다.
            <br />
            <br />
            <strong>제18조 [수강료의 환불]</strong>
            <br />
            1. &quot;회사&quot;는 환불 시 아래 환불 규정을 적용합니다. <br />
            <img
                style={{
                    width: "85vw",
                    maxWidth: "600px",
                }}
                src="https://contents.codetree.ai/homepage/images/policy/terms_210128.png"
            />
            <br />
            - &quot;서비스&quot; 수강료 환불시 소정의 금융 수수료 및 무료로
            제공된 &quot;서비스&quot;의 비용을 제외 후 환불될 수 있습니다.
            <br />
            - 이벤트, 프로모션 등의 &quot;서비스&quot;는 각 &quot;서비스&quot;에
            대해 별도의 취소, 변경 및 환불 규정이 있을 수 있으며, 이 경우 본
            약관보다 우선합니다.
            <br />
            - 취소와 환불은 &quot;회사&quot;의 확인 후 처리 하게 되며,
            신용카드결제, 실시간계좌이체 및 핸드폰결제는 신용카드사, 은행 및
            이동통신사의 환불기준에 의거하여 처리가 완료되는데 시일이 소요될 수
            있습니다.
            <br />
            <br />
            <strong>제19조 [과오금의 환불]</strong>
            <br />
            1. &quot;회사&quot;는 유료 &quot;서비스&quot; 결제와 관련하여
            과오금이 발생한 경우 대금결제와 동일한 방법으로 그 과오금을
            &quot;회원&quot;에게 환불합니다. 다만, 동일한 방법으로 환불이
            불가능할 때는 이를 사전에 고지합니다.
            <br />
            2. &quot;회사&quot;의 책임 있는 사유로 과오금이 발생한 경우
            &quot;회사&quot;는 계약비용, 수수료 등에 관계없이 과오금 전액을
            환불합니다. 다만, &quot;회원&quot;의 책임 있는 사유로 과오금이
            발생한 경우, 과오금의 환불에 소요되는 제반 비용은 합리적인 범위
            내에서 &quot;회원&quot;이 부담합니다.
            <br />
            3. &quot;회사&quot;는 &quot;서비스&quot; 사용료를 과소납입한
            &quot;회원&quot;에게 해당 금액에 해당하는 부분을 추가로 청구할 수
            있으며, 청구에 대해 불응시 &quot;서비스&quot; 중지 및 제공기간이
            축소될 수 있습니다.
            <br />
            <br />
            <Typography variant="h6">제4장 당사자의 권리와 의무</Typography>
            <br />
            <strong>제20조 [회사의 의무]</strong>조
            <br />
            1. &quot;회사&quot;는 지속적, 안정적으로 &quot;서비스&quot;를
            제공하기 위해 최선을 다합니다.
            <br />
            2. &quot;회사&quot;는 &quot;이용자&quot;가 안전하게
            &quot;서비스&quot;를 이용할 수 있도록 개인정보(신용정보 포함)보호를
            위해 개인정보취급방침을 수립하여 공시하고 준수합니다.
            <br />
            3. &quot;회사&quot;는 &quot;이용자&quot;의 &quot;개인정보&quot;를 그
            &quot;이용자&quot;의 동의 없이 제3자에게 누설, 배포하지 않는 것을
            원칙으로 합니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로
            합니다.
            <br />
            ㅤ① 전기통신기본법 등 법률의 규정에 의한 국가기관의 요구가 있는 경우
            <br />
            ㅤ② 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이
            있는 경우
            <br />
            ㅤ③ 기타 관계 법령이 정한 절차에 따른 요청이 있는 경우
            <br />
            4. &quot;회사&quot;는 &quot;서비스&quot; 이용과 관련하여
            &quot;이용자&quot;로부터 제기된 의견이나 불만이 정당하다고 인정할
            경우에는 이를 처리하여야 합니다. &quot;이용자&quot;가 제기한
            의견이나 불만사항에 대해서는 연락 가능한 수단을 통하여
            &quot;이용자&quot;에게 처리과정 및 결과를 전달합니다.
            <br />
            <br />
            <strong>제21조 [개인정보 보호 등]</strong>
            <br />
            1. &quot;회사&quot;는 &quot;회원&quot;의 개인정보 수집 시 필요한
            최소한의 정보를 수집합니다.
            <br />
            2. &quot;회사&quot;는 &quot;회원&quot;의 개인정보를 수집할 때 다음
            각 호의 1에 해당하는 경우를 제외하고는 그 &quot;회원&quot;의 동의를
            받습니다.
            <br />
            ㅤ① 법률에 특별한 규정이 있는 경우 ㅤ② 전자거래 계약의 이행을 위하여
            필요한 경우
            <br />
            ㅤ③ &quot;서비스&quot; 제공에 따른 요금 정산을 위하여 필요한 경우
            <br />
            3. &quot;회사&quot;는 &quot;회원&quot;의 개인정보를 그
            &quot;회원&quot;의 동의 없이 목적 외로 사용하거나 제3자에게 제공할
            수 없습니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로 합니다.
            <br />
            ㅤ① 법률에 특별한 규정이 있는 경우
            <br />
            ㅤ② &quot;서비스&quot; 제공에 따른 요금 정산을 위해 필요한 경우
            <br />
            ㅤ③ 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
            개인을 식별할 수 없는 형태로 제공되는 경우
            <br />
            4. &quot;회원&quot;은 &quot;웹 사이트&quot;상의 개인정보관리
            시스템을 이용하거나 &quot;회사&quot;에 전자우편 등으로 요청하여
            본인의 &quot;개인정보&quot;를 열람하고 수정할 수 있습니다. 다만,
            &quot;아이디&quot; 및 &quot;전자우편&quot; 주소는 변경할 수
            없습니다.
            <br />
            5. &quot;회원&quot;은 가입신청 시 기재한 사항이 변경되었을 경우 이를
            수정해야 하며, 이를 수정하지 아니하여 발생하는 문제의 책임은
            &quot;회원&quot;에게 있습니다.
            <br />
            6. 개인정보의 수집목적 또는 제공받은 목적을 달성하거나
            &quot;회원&quot;의 서비스 미이용기간이 1년을 초과한 경우에는 별도
            분리하여 보관합니다.
            <br />
            7. 전 항의 경우라도 &quot;회사&quot;가 사전에 개인정보처리방침에
            규정하여 &quot;회원&quot;의 동의를 받거나 관련 법령에 따라 일정기간
            개인정보를 보관해야 하는 경우 &quot;회사는 예외적으로 일정기간
            개인정보를 보관할 수 있습니다.
            <br />
            8. 개인정보의 수집, 보관, 이용, 위탁 등에 관한 제반 사항은
            &quot;회사&quot;가 게시한 개인정보처리방침에서 확인할 수 있습니다.
            <br />
            <br />
            <strong>제22조 [회원의 아이디와 비밀번호 관리에 대한 의무]</strong>
            1. &quot;아이디&quot;와 비밀번호에 관한 모든 관리책임은
            &quot;회원&quot;에게 있습니다.
            <br />
            2. &quot;회원&quot;의 &quot;아이디&quot; 및 &quot;비밀번호&quot;의
            관리에 관하여 &quot;회사&quot;의 고의 및 중과실이 없는 한,
            &quot;회원&quot;에게 부여된 &quot;아이디&quot;와
            &quot;비밀번호&quot;의 관리소홀, 부정사용에 의하여 발생하는 모든
            결과에 대한 책임은 &quot;회원&quot;에게 있습니다.
            <br />
            3. 자신의 &quot;아이디&quot;가 부정하게 사용된 경우,
            &quot;회원&quot;은 반드시 &quot;회사&quot;에 그 사실을 통보해야
            합니다.
            <br />
            4. &quot;회사&quot;는 전항의 경우 &quot;회원&quot;의 개인정보보호 및
            기타 서비스 부정이용행위 방지 등을 위하여 &quot;회원&quot;게
            비밀번호의 변경 등 필요한 조치를 요구할 수 있으며,
            &quot;회원&quot;은 &quot;회사&quot;의 요구가 있는 즉시
            &quot;회사&quot;의 요청에 성실히 응해야 합니다.
            <br />
            5. &quot;회사&quot;는 &quot;회원&quot;이 본 조에서 정하는 의무를
            성실히 이행하지 않아 발생한 불이익에 대하여 책임지지 않습니다.
            <br />
            <br />
            <strong>제23조 [회원의 의무]</strong>
            <br />
            1. &quot;회원&quot;은 &quot;서비스&quot;를 이용할 때 다음 각 호의
            행위를 해서는 안 됩니다.
            <br />
            ㅤ① 동일인이 중복하여 회원가입을 하는 경우
            <br />
            ㅤ② 다른 &quot;회원&quot;의 &quot;아이디&quot;를 부정하게 사용하는
            행위
            <br />
            ㅤ③ 자신의 &quot;아이디&quot; 및 &quot;서비스&quot;를 타인이
            사용하도록 하는 행위
            <br />
            ㅤ④ &quot;서비스&quot; 이용 시 영상, 사진, 텍스트, 음성 등의 정보를
            녹화나 캡쳐 또는 녹음할 수 있는 소프트웨어를 실행시키는 행위
            <br />
            ㅤ⑤ &quot;서비스&quot;에서 얻은 정보를 &quot;회사&quot;의 사전 승낙
            없이 복제하거나 이를 출판 및 방송 등에 사용하거나 제 3자에게
            제공하는 행위
            <br />
            ㅤ⑥ &quot;회사&quot; 또는 제 3자의 저작권 등 지적재산권 기타 권리를
            침해하거나 침해하려는 행위
            <br />
            ㅤ⑦ 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을
            타인에게 유포하는 행위
            <br />
            ㅤ⑧ 범죄와 결부된다고 객관적으로 판단되는 행위
            <br />
            ㅤ⑨ &quot;회원&quot;이 다른 이용자의 서비스 이용을 방해하거나
            &quot;회사&quot;의 서비스 운영을 고의 또는 과실로 방해하는 행위
            <br />
            ㅤ⑩ &quot;회사&quot; 및 타인의 명예를 훼손하거나 모욕하는 행위
            <br />
            ㅤ⑪ &quot;회원&quot;이 &quot;회사&quot;에서 제공하는 서비스를
            이용하여 상품 또는 용역 등을 판매하는 상행위
            <br />
            ㅤ⑫ 기타 관계법령에 위배되는 행위
            <br />
            2. &quot;회원&quot;은 본 약관에 규정하는 사항과 &quot;서비스&quot;
            이용안내 또는 주의사항을 준수해야 합니다.
            <br />
            3. &quot;회원&quot;은 사항 별로 &quot;회사&quot;가
            &quot;서비스&quot; 공지사항에 게시하거나 별도로 공지한 사항을
            준수해야 합니다.
            <br />
            4. &quot;회원&quot;은 &quot;회사&quot;의 명시적인 동의가 없는 한
            &quot;서비스&quot;의 이용권한 기타 이용 계약상의 지위를 타인에게
            양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
            <br />
            5. &quot;회사&quot;는 &quot;회원&quot;이 제1항 내지 제4항을
            위반하였을 경우 사전 통지 없이 계약을 해지하거나 또는
            &apos;서비스&apos; 이용을 제한할 수 있습니다.
            <br />
            6. &quot;회사&quot;가 전 항에 따라 계약을 해지하거나
            &quot;서비스&quot; 이용을 제한하는 경우 이를 &quot;회원&quot;에게
            알립니다. 다만, &quot;회사&quot;의 귀책사유 없이
            &quot;회원&quot;에게 알릴 수 없는 경우에는 예외로 합니다.
            <br />
            7. 본조 제1항의 사유로 로그인이 금지되는 기간 동안에도 해당 강의의
            수강기간은 그대로 경과됩니다.
            <br />
            8. 중복 가입된 계정으로 회사가 제공하는 혜택을 중복수령하는 경우,
            혜택을 받지 못하며 이미 제공받은 혜택은 회사에 반환하여야 합니다.
            <br />
            <br />
            <strong>제24조 [게시물의 삭제 등]</strong>
            <br />
            1. &quot;회사&quot;는 게시물이 다음 각 호의 1에 해당된다고 판단되는
            경우에는 게시물을 작성한 &quot;이용자&quot;의 동의 없이 그 게시물을
            삭제할 수 있습니다.
            <br />
            ㅤ① 다른 &quot;이용자&quot; 또는 제 3자를 비방하거나 중상
            모략함으로써 타인의 명예를 훼손시킨 경우
            <br />
            ㅤ② 허위 내용이 포함되어 있거나 공공질서 및 미풍양속에 위반되는 경우
            <br />
            ㅤ③ 「청소년 보호법」상 ‘청소년유해매체물’ 등이 게시되어 있는 경우
            <br />
            ㅤ④ 범죄적 행위에 결부된다고 인정되는 경우
            <br />
            ㅤ⑤&apos;회사&apos; 또는 제 3자의 저작권 등 지적재산권 기타 권리를
            침해하거나 침해할 우려가 있는 경우
            <br />
            ㅤ⑥ 기타 관계 법령이나 &quot;회사&quot;가 정한 규정에 위배되는 경우
            <br />
            2. &quot;회사&quot;가 운영하는 게시판 등에 게시된 정보로 인하여
            법률상․사회상 이익이 침해된 자는 &quot;회사&quot;에게 당해 정보의
            삭제 또는 반론 게시물의 게재를 요청할 수 있습니다. 이 경우
            &quot;회사&quot;는 지체 없이 필요한 조치를 취하고, 처리결과를 요청한
            &quot;이용자&quot;에게 통지합니다.
            <br />
            <br />
            <Typography variant="h6">제5장 회원 탈퇴 및 자격 상실</Typography>
            <br />
            <strong>제25조 [회원 탈퇴]</strong>조
            <br />
            1. &quot;회원&quot;이 &quot;웹 사이트&quot;의 회원 탈퇴를 하고자
            하는 경우에는 &quot;회원&quot; 본인 또는 &quot;회원&quot;의
            법정대리인이 &quot;회사&quot;에 웹 사이트·전자우편 등을 통해 회원
            탈퇴의 신청을 하여야 합니다.
            <br />
            2. &quot;회원&quot;의 회원 탈퇴 신청을 확인한 경우
            &quot;회사&quot;는 신속히 회원 탈퇴 절차를 처리하며, 관련법 및
            &quot;웹 사이트&quot;의 개인정보처리방침에 따라 &quot;회사&quot;가
            회원정보를 보유하는 경우를 제외하고는 탈퇴 완료 후 지체 없이
            &quot;회원&quot;의 개인정보를 파기합니다.
            <br />
            3. 본 조항에 따른 해지 시 &quot;회원&quot;이 &quot;서비스&quot;를
            사용하면서 만들어 낸 누적 데이터(게시물, 댓글 등)은 파기되지
            않습니다.
            <br />
            4. 본 조항에 따른 해지 시 이용계약이 종료됨과 함께 수강중인 유료
            &quot;서비스&quot; 등 보유하고 있는 권리는 소멸됩니다.
            <br />
            <br />
            <strong>제26조 [회원 자격 제한 및 상실]</strong>
            <br />
            1. &quot;회원&quot;이 다음 각 호의 부정이용 행위를 하는 경우,
            &quot;회사&quot;는 회원자격을 제한 또는 정지시킬 수 있습니다.
            <br />
            ㅤ① 동일한 &quot;아이디&quot;로 2대 이상의 PC, 모바일 등
            전자통신기기에서 동시접속이 발생하는 경우
            <br />
            ㅤ② 자신의 &quot;아이디&quot; 및 &quot;서비스&quot;를 타인이
            이용하도록 하는 경우
            <br />
            ㅤ③ 자신의 &quot;아이디&quot; 및 &quot;서비스&quot;를 타인에게
            판매·대여하거나 이를 광고하는 경우
            <br />
            ㅤ④ 다량의 정보를 전송하여 &quot;서비스&quot;의 안정적인 운영을
            방해하는 경우
            <br />
            ㅤ⑤ &quot;서비스&quot; 운영을 고의 또는 과실로 방해하는 경우
            <br />
            ㅤ⑥ 정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터
            바이러스 프로그램 등을 유포하는 경우
            <br />
            ㅤ⑦ 자동 접속 프로그램 등을 사용하는 등 정상적인 사용법과 다른
            방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의
            정상적인 서비스를 방해하는 경우
            <br />
            ㅤ⑧ &quot;서비스&quot;에서 얻은 정보를 &quot;회사&quot;의 사전 승낙
            없이 &quot;회원&quot;의 정당한 이용 이외의 목적으로 복제하거나 이를
            출판 및 방송 등에 사용하거나 온라인에 게시하거나 제3자에게 제공하는
            경우
            <br />
            ㅤ⑨ &quot;회사&quot; 또는 제3자의 저작권 등 지적 재산권을 침해하거나
            하려는 경우
            <br />
            ㅤ⑩ 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를
            통하여 &quot;서비스&quot;를 복제, 분해, 모방 및 기타 변형하는 경우
            <br />
            ㅤ⑪ 외설 또는 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에
            반하는 정보를 &quot;서비스&quot;에 공개 또는 게시하는 경우
            <br />
            ㅤ⑫ 범죄와 결부된다고 객관적으로 판단되는 행위를 하는 경우
            <br />
            ㅤ⑬ 타인의 명예를 손상시키거나 불이익을 주는 행위를 하는 경우
            <br />
            ㅤ⑭ 고객센터 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 경우
            <br />
            ㅤ⑮ &quot;회사&quot;의 운영정책 및 기타 관계 법령에 위배되는 경우
            <br />
            2. 회원자격의 제한·정지 후에도 &quot;회원&quot;이 동일한 행위를
            반복하거나 30일 이내에 그 사유를 시정하지 아니하는 경우,
            &quot;회사&quot;는 회원자격을 상실시킬 수 있습니다.
            <br />
            3. &quot;회사&quot;는 회원자격을 상실시키는 경우, 회원등록을
            말소하고 &quot;아이디&quot;를 삭제합니다. &quot;회사&quot;는
            &quot;회원&quot;에게 이를 통지하고, 회원등록 말소 전에 최소한 30일
            이상의 기간을 정하여 소명할 기회를 부여합니다.
            <br />
            4. &quot;회사&quot;가 제1항에 따라 &quot;회원&quot; 자격을
            상실시키거나 제한하는 경우 해당 &quot;회원&quot;에 대하여는
            &quot;회사&quot;가 제공하는 모든 &quot;서비스&quot;의 이용을
            중단시킬 수 있고, 해당 &quot;회원&quot;은 &quot;회사&quot;에 대하여
            중단 이후의 &quot;서비스&quot;에 대한 환불을 요구할 수 없습니다.
            <br />
            5. 제1항에 따라 이용제한이 되는 경우 서비스 이용을 통해 획득한 혜택
            등도 모두 이용 중단, 또는 소멸되며, &quot;회사&quot;는 이에 대해
            별도로 보상하지 않습니다.
            <br />
            6. &quot;회사&quot;는 &quot;회원&quot;이 계속해서 1년 이상
            로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을
            제한할 수 있습니다.
            <br />
            7. 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
            회사는 제 6조 [회원에 대한 통지]에 명시된 방침에 따라 통지합니다.
            <br />
            8. &quot;회원&quot;은 본 조에 따른 이용제한 등에 대해
            &quot;회사&quot;가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
            이의가 정당하다고 회사가 인정하는 경우 &quot;회사&quot;는 즉시
            서비스의 이용을 재개합니다.
            <br />
            <br />
            <Typography variant="h6">제6장 기타</Typography>
            <br />
            <strong>제27조 [저작권]</strong>
            <br />
            1. &quot;회사&quot;가 자체 제작한 &quot;콘텐츠&quot;에 대한 저작권은
            &quot;회사&quot;에 있습니다. 2. &quot;회사&quot;가 제3자로부터 이용
            허락을 받은 &quot;콘텐츠&quot;에 대한 저작권은 제3자에게 있고
            &quot;회사&quot;는 그에 대한 사용권을 가집니다.
            <br />
            3. &quot;이용자&quot;는 &quot;회사&quot;가 제공하는
            &quot;서비스&quot;를 이용함으로써 얻은 정보를 &quot;회사&quot;의
            사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리,
            비영리의 목적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.
            <br />
            4. &quot;회사&quot;는 &quot;회사&quot; 또는 제3자의 저작권을
            보호하기 위하여 &quot;회원&quot;이 &quot;서비스&quot;를 이용하는
            동안 &quot;회원&quot;의 유,무선 기기 상 소프트웨어가 실행되는 것을
            감지하여 자동으로 차단하는 프로그램을 사용할 수 있습니다.
            <br />
            5. &quot;이용자&quot;의 &quot;게시물&quot;에 대한 권리 및 책임은
            이를 게시한 &quot;이용자&quot;에게 있습니다.
            <br />
            6. &quot;회사&quot;는 &quot;이용자&quot;가 &quot;웹 사이트&quot;에
            게시한 수강 후기 등의 &quot;게시물&quot;을 사용, 복제, 전송, 수정,
            출판, 배포할 수 있습니다. 단, &quot;이용자&quot;의
            &quot;게시물&quot;을 마케팅 등에 활용하는 경우 별도의 동의를 받지
            않는 한 &quot;이용자&quot;의 개인정보를 비식별화 조치하여
            활용합니다.
            <br />
            <br />
            <strong>제28조 [손해배상]</strong>
            <br />
            &quot;이용자&quot;가 본 약관의 규정을 위반하여 &quot;회사&quot; 또는
            제3자에게 손해가 발생하게 되는 경우, 약관을 위반한
            &quot;이용자&quot;는 &quot;회사&quot; 및 제3자에게 발생한 손해를
            배상할 책임이 있습니다.
            <br />
            <br />
            <strong>제29조 [면책조항]</strong>
            <br />
            1. &quot;회사&quot;는 천재지변 또는 이에 따르는 불가항력으로 인하여
            &quot;서비스&quot;를 제공할 수 없는 경우에는 &quot;서비스&quot;
            제공에 관한 책임이 면제됩니다.
            <br />
            2. &quot;회사&quot;는 &quot;이용자&quot;의 귀책사유로 인한
            &quot;서비스&quot; 이용의 장애에 대하여 책임을 지지 않습니다.
            <br />
            3. &quot;회사&quot;는 &quot;이용자&quot;가 &quot;서비스&quot;에
            게재한 사실의 신뢰도, 정보나 자료의 정확성 등 내용에 관하여는 책임을
            지지 않습니다.
            <br />
            4. &quot;회사&quot;는 &quot;서비스&quot; 이용과 관련하여
            &quot;이용자&quot;에게 발생한 손해 가운데 신상정보 및 전자우편
            주소를 부실하게 기재하는 등 &quot;이용자&quot;의 고의, 과실에 의한
            손해에 대하여 책임을 지지 않습니다.
            <br />
            5. &quot;회사&quot;는 기간통신 사업자가 전기통신 서비스를 중지하거나
            정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
            <br />
            6. &quot;회사&quot;는 서비스용 설비의 보수, 교체, 정기점검, 공사 등
            부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
            <br />
            7. &quot;회사&quot;는 &quot;이용자&quot;이 &quot;서비스&quot;를
            이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을
            지지 않습니다.
            <br />
            8. &quot;회사&quot;는 &quot;이용자&quot;가 &quot;서비스&quot;를
            이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한
            &quot;회사&quot;는 &quot;이용자&quot;이 &quot;서비스&quot;를
            이용하며 타 이용자로 인해 입게 되는 정신적 피해에 대하여 보상할
            책임을 지지 않습니다.
            <br />
            9. &quot;회사&quot;는 이용자 상호간 및 이용자와 제 3자 상호 간에
            서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한
            손해를 배상할 책임도 없습니다.
            <br />
            10. &quot;회사&quot;에서 &quot;이용자&quot;에게 무료로 제공하는
            &quot;서비스&quot;의 이용과 관련해서는 어떠한 손해도 책임을 지지
            않습니다.
            <br />
            <br />
            <strong>제30조 [분쟁의 해결]</strong>
            <br />
            1. &quot;회사&quot;는 분쟁이 발생하였을 경우에 &quot;이용자&quot;가
            제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를
            취합니다. 다만, 신속한 처리가 곤란한 경우에 &quot;회사&quot;는
            &quot;이용자&quot;에게 그 사유와 처리일정을 통보합니다.
            <br />
            2. 본 약관 및 서비스이용 및 &quot;이용자&quot;와 &quot;회사&quot;,
            &quot;회원&quot;과 &quot;회사&quot; 관련 사항 등은 모두 대한민국
            법령의 적용을 받습니다.
            <br />
            3. &quot;회사&quot;의 &quot;서비스&quot; 이용으로 발생한 분쟁에 대해
            소송이 제기될 경우 &quot;회사&quot;의 본사 소재지를 관할하는 법원을
            관할법원으로 합니다.
            <br />
            <br />
            <br />
            ○ 본 약관은 2021년 1월 28일부터 시행됩니다.
            <br />
            <br />
        </Box>
    );
};
