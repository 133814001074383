import { alpha, Button, styled } from "@mui/material";
import React from "react";

const SelectionButtonComponent = styled(Button)(({ theme, ownerState }) => ({
    width: "100%",
    border: "2px solid #000000",
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
    background: ownerState.active ? theme.palette.primary.main : "transparent",
    "&:hover": {
        background: ownerState.active
            ? alpha(theme.palette.primary.main, 0.5)
            : "inherit",
    },
}));

export const SelectionButton = ({ active, ...props }) => (
    <SelectionButtonComponent ownerState={{ active }} {...props} />
);
