import { makeAutoObservable } from "mobx";
import { FiddleModel } from "./FiddleModel";
import FiddleRepository from "./FiddleRepository";

export default class FiddleStore {
    fiddles = [];

    fiddle = undefined;

    isFetching = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll(params) {
        this.isFetching = true;
        const response = yield FiddleRepository.findAll({
            classroomId: this.rootStore.classroomStore.classroom
                ? this.rootStore.classroomStore.classroom.id
                : 0,
            ...params,
        });
        this.fiddles.replace(
            response.data.map((fiddle) => new FiddleModel(fiddle))
        );
        this.isFetching = false;
        return this.fiddles;
    }

    *find({ fiddleKey, ...params }) {
        const response = yield FiddleRepository.find({ fiddleKey, ...params });
        this.fiddle = new FiddleModel(response.data);
        return this.fiddle;
    }

    *create({ body }) {
        const response = yield FiddleRepository.create({ body });
        this.fiddle = new FiddleModel(response.data);
        return this.fiddle;
    }

    *update({ fiddleId, fiddleKey, body, ...params }) {
        const response = yield FiddleRepository.update({
            fiddleId,
            body,
            ...params,
        });
        const newFiddle = new FiddleModel(response.data);
        this.fiddles = [
            ...this.fiddles.map((fiddle) => {
                if (fiddle.key === fiddleKey) {
                    return newFiddle;
                }

                return fiddle;
            }),
        ];
        if (this.fiddle && this.fiddle.key === newFiddle.key) {
            this.fiddle = newFiddle;
        }
        return newFiddle;
    }

    *simpleUpdate({ fiddleId, fiddleKey, body }) {
        const response = yield FiddleRepository.update({ fiddleId, body });
        const newFiddle = new FiddleModel(response.data);
        this.fiddles = [
            ...this.fiddles.map((fiddle) => {
                if (fiddle.key === fiddleKey) {
                    return newFiddle;
                }

                return fiddle;
            }),
        ];
        return newFiddle;
    }

    *checkFiddle({ fiddleKey, fiddleId }) {
        const response = yield FiddleRepository.checkFiddle({
            fiddleKey,
            fiddleId,
        });
        return response.data.check;
    }

    updateLocalFiddle(fiddle, force = false) {
        if (this.fiddles.some((_fiddle) => _fiddle.id === fiddle.id) || force) {
            this.fiddle = fiddle;
        }
        return this.fiddle;
    }

    updateLocalFiddles({ fiddleId, fiddle }) {
        this.fiddles.replace(
            this.fiddles.map((_fiddle) => {
                if (Number(fiddleId) === _fiddle.id) {
                    return fiddle;
                } else {
                    return _fiddle;
                }
            })
        );
        return this.fiddles;
    }

    *delete(fiddle) {
        yield FiddleRepository.delete({
            fiddleId: fiddle.id,
        });

        const fiddleKey = fiddle.key;

        this.fiddles.replace(
            this.fiddles.filter((fiddle) => fiddleKey !== fiddle.key)
        );
        if (this.fiddles.length) {
            this.fiddle = this.fiddles[0];
        } else {
            this.fiddle = undefined;
        }
    }
}
