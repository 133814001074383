import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProgressDiv } from "../../../../../common/components/div/ProgressDiv";
import { getPercentString } from "../../../../../utils/string";

export const HomeworkProgressBox = ({ homeworkProgress, userId }) => {
    const {
        progresses,
        solvedProblemCount,
        problemCount,
        homeworkId,
        earnedScore,
    } = homeworkProgress;

    const navigate = useNavigate();

    const redirectToDetailPage = (problemId) => {
        navigate(
            `users/${userId}/homeworks/${homeworkId}/problems/${problemId}`
        );
    };

    return (
        <Stack direction="row" position="relative" justifyContent="center">
            {progresses.map((progress, i) => (
                <ProgressDiv
                    key={`progress-${i}`}
                    progress={progress}
                    width={40}
                    onClick={() => redirectToDetailPage(progress.problemId)}
                />
            ))}
            <Stack
                alignItems={"center"}
                sx={{
                    position: "absolute",
                    top: 0,
                    transform: "translate(0, calc(-100% - 5px))",
                }}
            >
                <Typography
                    fontWeight={700}
                    children={`${earnedScore}점`}
                    variant="caption"
                />
                <Typography
                    color="primary"
                    fontWeight={700}
                    children={`${getPercentString(
                        solvedProblemCount,
                        problemCount
                    )} (${solvedProblemCount}/${problemCount})`}
                    variant="caption"
                />
            </Stack>
        </Stack>
    );
};
