import React from "react";
import { Stack, Typography, Paper, Slide, IconButton } from "@mui/material";
import { FAQList } from "./FAQList";
import { Close } from "@mui/icons-material";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";

export const FAQSpeedDial = ({ teacherFAQ, studentFAQ, faqIn, onClose }) => {
    return (
        <MainTemplateWrapper
            sx={{
                position: "fixed",
                left: "50%",
                bottom: 0,
                transform: "translateX(-50%)",
            }}
        >
            <Slide
                direction="up"
                in={faqIn}
                mountOnEnter
                unmountOnExit
                timeout={1000}
            >
                <Stack
                    component={Paper}
                    alignItems={"center"}
                    p={4}
                    elevation={1}
                    sx={{
                        position: "relative",
                        borderRadius: 0,
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                    }}
                >
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            m: 4,
                        }}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        variant={"h2"}
                        children={"도움이 필요하신가요?"}
                        color={"#000000"}
                        fontWeight={500}
                        mb={4}
                    />

                    <Stack direction={"row"} spacing={2.5}>
                        {teacherFAQ && teacherFAQ.length ? (
                            <Stack flex={1}>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <img
                                        width={80}
                                        height={80}
                                        src={
                                            "https://contents.codetree.ai/ooe/about/teacher.png"
                                        }
                                    />
                                    <Typography
                                        variant={"h4"}
                                        children={"선생님이신가요?"}
                                        color={"#434858"}
                                        fontWeight={500}
                                    />
                                </Stack>
                                <FAQList
                                    FAQ={teacherFAQ}
                                    onClick={(key) =>
                                        window.open(
                                            `${window.location.origin}/help/FAQ/${key}`,
                                            "_blank"
                                        )
                                    }
                                />
                            </Stack>
                        ) : null}

                        {studentFAQ && studentFAQ.length ? (
                            <Stack flex={1}>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <img
                                        width={80}
                                        height={80}
                                        src={
                                            "https://contents.codetree.ai/ooe/about/soloplay.png"
                                        }
                                    />
                                    <Typography
                                        variant={"h4"}
                                        children={"학생인가요?"}
                                        color={"#434858"}
                                        fontWeight={500}
                                    />
                                </Stack>
                                <FAQList
                                    FAQ={studentFAQ}
                                    onClick={(key) =>
                                        window.open(
                                            `${window.location.origin}/help/FAQ/${key}`,
                                            "_blank"
                                        )
                                    }
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
            </Slide>
        </MainTemplateWrapper>
    );
};
