import React from "react";
import { Stack } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionTag } from "./SectionTag";
import { PopUpBox } from "../../../../common/templates/PopUpBox";
import { PopUpType } from "../../../../common/modules/ui/UIModel";

export const PopUpSwiper = ({ noSection }) => {
    const filteredTypes = Object.values(PopUpType).filter(
        (type) => type !== PopUpType.Difficulty
    );

    return (
        <Stack direction="column" spacing={2.5}>
            {!noSection ? (
                <SectionTag
                    title="공통 구성"
                    description={
                        "빈틈없는 구성으로\n한번에 확실하게 학습하세요."
                    }
                />
            ) : null}
            <Swiper
                grabCursor
                style={{
                    overflow: "visible",
                }}
                slidesPerView={1}
                spaceBetween={10}
                width={264}
            >
                {filteredTypes.map((type, i) => (
                    <SwiperSlide key={`popup-type-${i}`}>
                        <PopUpBox type={type} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Stack>
    );
};
