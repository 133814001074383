import React from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const QuizAddButton = ({ sx }) => {
    const navigateToFiddleStudio = () => {
        window.open("/psfiddle/new", "_blank");
    };

    return (
        <>
            <Button
                variant="contained"
                endIcon={<AddIcon />}
                sx={{
                    bgcolor: "common.white",
                    borderRadius: 2,
                    color: "common.black",
                    border: "1px solid #1A1D24",
                    width: 250,
                    py: 2,
                    justifyContent: "space-between",
                    "&:hover": {
                        bgcolor: "#C4C4C4",
                    },
                    ...sx,
                }}
                onClick={navigateToFiddleStudio}
            >
                <Typography
                    variant="body1"
                    fontWeight={700}
                    children="새 퀴즈 만들기"
                />
            </Button>
        </>
    );
};
