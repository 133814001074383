import React, { useEffect } from "react";
import {
    Routes,
    Route,
    Navigate,
    useSearchParams,
    useLocation,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MissionsContainer } from "./MissionsContainer";
import { MissionPage } from "./MissionPage";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { LanguageType } from "../../../common/modules/problems/ProblemModel";
import { UserProfileDAOModel } from "../../../common/modules/auth/UserModel";

export const MissionsPage = observer(() => {
    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;
    const [searchParams] = useSearchParams();
    const setPlLan = searchParams.get("setPlLan");
    const location = useLocation();

    const getMissionsMain = () => {
        if (isUserLoggedIn()) {
            return <MissionsContainer />;
        }

        if (hasToken()) {
            return <LoadingOverlay active />;
        }

        const { pathname, search } = location;

        return (
            <Navigate
                to={`/accounts/loginWithMs?nextUrl=${encodeURIComponent(
                    `${pathname}${search}`
                )}`}
                replace={true}
            />
        );
    };

    useEffect(() => {
        if (Object.keys(LanguageType).includes(setPlLan)) {
            const body = new UserProfileDAOModel({
                language: LanguageType[setPlLan],
            });
            authStore.updateProfile({ body });
        }
    }, [setPlLan]);

    return (
        <Routes>
            <Route path="/" element={getMissionsMain()} />
            <Route path={"/:missionId/*"} element={<MissionPage />} />
        </Routes>
    );
});
