import { makeAutoObservable, set } from "mobx";
import { camelizeKeys, snakelizeKeys } from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";

export const ExcelUrl =
    "https://ooepublic.blob.core.windows.net/form/student_add_form.xlsx";

export const StudentFormsKey = "ooe-student-forms";

export const ClassroomType = {
    TEACHER: "teacher",
    STUDENT: "student",
    APPLIED: "applied",
    READY: "ready",
};

export const blankStudent = (id) => ({
    id,
    name: "",
    username: "",
    phoneNumber: "",
});

export class SchoolModel {
    name = "";

    id = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const school = pickCls(this, camelizeKeys(data));
        set(this, school);
    }
}

export class ClassroomModel {
    id = 0;

    name = "";

    school = 0;

    startedDate = "";

    finishedDate = "";

    teacher = "";

    status = ClassroomType.READY;

    isOwner = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const classroom = pickCls(this, camelizeKeys(data));
        classroom.school =
            classroom.school && new SchoolModel(classroom.school);
        set(this, classroom);
    }
}

export class StudentModel {
    id = 0;

    name = "";

    username = "";

    phoneNumber = "";

    status = ClassroomType.READY;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const student = pickCls(this, camelizeKeys(data));
        set(this, student);
    }
}

export class ClassroomDAOModel {
    name = "";

    startedDate = "";

    finishedDate = "";

    constructor(data) {
        const classroom = pickCls(this, data);
        Object.assign(this, snakelizeKeys(classroom));
    }
}

export class StudentDAOModel {
    id = 0;

    name = "";

    username = "";

    phoneNumber = "";

    constructor(data) {
        const student = pickCls(this, data);
        Object.assign(this, snakelizeKeys(student));
    }
}
