import React from "react";
import { IconButton, Tooltip } from "@mui/material";

export const IconButtonWithTooltip = ({
    onClick,
    tooltipPlacement,
    iconSize,
    tooltipTitle,
    Icon,
    sx,
    fontSize,
}) => (
    <Tooltip arrow title={tooltipTitle} placement={tooltipPlacement}>
        <IconButton onClick={onClick} sx={sx}>
            <Icon
                fontSize={iconSize}
                sx={{
                    fontSize,
                }}
            />
        </IconButton>
    </Tooltip>
);
