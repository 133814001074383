import { Button, styled } from "@mui/material";

export const ProblemIndexButton = styled(Button)(() => ({
    color: "#FFFFFF",
    minWidth: "auto",
    "&:hover": {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        borderColor: "#FFFFFF",
        boxShadow: "none",
    },
}));
