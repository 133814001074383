import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomeworkDashboardContainer } from "./HomeworkDashboardContainer";
import { HomeworkDashboardDetailContainer } from "./HomeworkDashboardDetailContainer";

export const HomeworkDashboardPage = () => (
    <Routes>
        <Route path="/" element={<HomeworkDashboardContainer />} />
        <Route
            path="/users/:userId/homeworks/:homeworkId/problems/:problemId"
            element={<HomeworkDashboardDetailContainer />}
        />
    </Routes>
);
