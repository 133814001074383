import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Paper, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { StudentAutoComplete } from "../../templates/StudentAutoComplete";
import { LargeButton } from "../../../../../common/components/button/LargeButton";
import { CuratedProblemAutoCompleteContainer } from "./CuratedProblemAutoCompleteContainer";
import { HomeworkSelector } from "./HomeworkSelector";

export const HomeworkDashboardDetailHeader = ({
    userId,
    homeworkId,
    problemId,
    homeworks,
    problem,
}) => {
    const navigate = useNavigate();

    const redirectToHomework = (_homeworkId) => {
        navigate(
            `/admin/homework-dashboard/users/${userId}/homeworks/${_homeworkId}/problems/0`,
            { replace: true }
        );
    };

    const redirectToUser = (_userId) => {
        navigate(
            `/admin/homework-dashboard/users/${_userId}/homeworks/${homeworkId}/problems/0`,
            { replace: true }
        );
    };

    const redirectToProblem = (_problemId) => {
        navigate(
            `/admin/homework-dashboard/users/${userId}/homeworks/${homeworkId}/problems/${_problemId}`,
            { replace: true }
        );
    };

    const redirectToPlaygroundPage = (number) => {
        if (!number) {
            return;
        }
        navigate(`/homeworks/${homeworkId}/${number}`);
    };

    return (
        <Paper
            sx={{
                p: 2.5,
            }}
        >
            <Stack direction="row" spacing={1.25} alignItems="center">
                <IconButton
                    children={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                />
                <StudentAutoComplete
                    userId={Number(userId)}
                    setUserId={redirectToUser}
                />
                <HomeworkSelector
                    homeworks={homeworks}
                    homeworkId={homeworkId}
                    setHomeworkId={redirectToHomework}
                />
                <CuratedProblemAutoCompleteContainer
                    homeworkId={homeworkId}
                    problemId={problemId}
                    setProblemId={redirectToProblem}
                />
                <LargeButton
                    label="문제보기"
                    color="#FFFFFF"
                    backgroundColor="#000000"
                    endIcon={<OpenInNewIcon />}
                    onClick={() =>
                        redirectToPlaygroundPage(problem && problem.number)
                    }
                />
            </Stack>
        </Paper>
    );
};
