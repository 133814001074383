import React from "react";
import { UserComponent } from "../templates/UserComponent";
import ResponsiveAppBar from "../../../../common/components/appBar/ResponsiveAppBar";
import { Routes, Route, useNavigate } from "react-router-dom";
import { PSFiddlePlaygroundContainer } from "./PSFiddlePlaygroundContainer";
import { useStores } from "../../../../common/modules/useStores";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/material";
import { AppBarLogo } from "../templates/AppBarLogo";
import { PSFiddleRedirect } from "./PSFiddleRedirect";
import { DescriptionTemplate } from "./templates/DescriptionTemplate";
import { SubmissionsContainer } from "./templates/submissions/SubmissionsContainer";
import { PreviewBanner } from "../templates/PreviewBanner";

export const PSFiddlePlaygroundPage = observer(() => {
    const navigate = useNavigate();

    const { fiddleStore, authStore } = useStores();
    const { fiddle } = fiddleStore;

    const TAB_ITEMS = [
        {
            value: "description",
            label: "퀴즈",
            position: "left",
            Element: DescriptionTemplate,
        },
        {
            value: "submissions",
            label: "채점기록",
            position: "left",
            Element: SubmissionsContainer,
        },
    ];

    const activeName = TAB_ITEMS.find((tabItem) =>
        window.location.pathname.includes(tabItem.value)
    )?.value;

    const handleTabClick = (_activeName) => {
        navigate(`${fiddle.id}/${_activeName}`);
    };

    const filteredTabItems = TAB_ITEMS.filter((item) => {
        if (item.value === "submissions") {
            return authStore.isUserLoggedIn();
        }

        return true;
    });

    return (
        <Stack height="calc(100vh - 54px)" direction="column">
            <ResponsiveAppBar
                logoElement={<AppBarLogo redirectUrl={"/psfiddle"} />}
                tabItems={filteredTabItems}
                value={activeName}
                onClick={handleTabClick}
                rightElement={<UserComponent fiddle={fiddle} />}
                bannerElement={<PreviewBanner fiddle={fiddle} />}
            />
            <Routes>
                <Route path={"/"} element={<PSFiddleRedirect />} />
                <Route
                    path={"/:fiddleId/*"}
                    element={
                        <PSFiddlePlaygroundContainer
                            fiddle={fiddle}
                            tabItems={filteredTabItems}
                            navigateToSubmissions={() =>
                                handleTabClick("submissions")
                            }
                        />
                    }
                />
            </Routes>
        </Stack>
    );
});
