import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { MiddleButton } from "../button/MiddleButton";

export const ConfirmDialog = ({
    open,
    confirmText,
    onClose,
    onConfirm,
    children,
    sx,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
        sx={{
            ...sx,
        }}
    >
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <MiddleButton
                label={confirmText || "확인"}
                color="#FFFFFF"
                backgroundColor="#000000"
                onClick={onConfirm}
            />
        </DialogActions>
    </Dialog>
);
