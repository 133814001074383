import React, { useEffect, useRef } from "react";
import ChannelService from "../../../common/components/channel/ChannelService";
import { ClassroomInfo } from "./ClassroomInfo";
import { Intro } from "./Intro";
import { PlaygroundIntro } from "./PlaygroundIntro";
import { Usage } from "./Usage";

export const AboutPage = () => {
    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    const playgroundIntroRef = useRef(null);
    const classroomInfoRef = useRef(null);

    return (
        <>
            <Intro />
            <Usage
                playgroundIntroRef={playgroundIntroRef}
                classroomInfoRef={classroomInfoRef}
            />
            <PlaygroundIntro ref={playgroundIntroRef} />
            <ClassroomInfo ref={classroomInfoRef} />
        </>
    );
};
