import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { paddingZero } from "../../../../utils/string";

export const ConceptAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
    "&:before": {
        display: "none",
    },
    "&:after": {
        height: 1,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#CCCCCC",
        content: '""',
    },
    "&:last-of-type:after": {
        display: "none",
    },
}));

const ConceptAccordionSummaryComponent = styled(AccordionSummary)(() => ({
    padding: 0,
    "& h4": {
        color: "#4D4D4D",
    },
    "&.Mui-expanded h4": {
        color: "#1A1A1A",
    },
}));

export const ConceptAccordionSummary = ({ concept, order, ...props }) => (
    <ConceptAccordionSummaryComponent {...props}>
        <Stack
            direction="row"
            sx={{
                pt: 2.5,
                pb: 1.875,
            }}
        >
            <Typography variant="subtitle1" minWidth={{ md: 90, xs: 30 }}>
                {paddingZero(order, 2)}
            </Typography>
            <Stack direction="column">
                <Typography variant="h4" fontWeight={700}>
                    {concept.name}
                </Typography>
                <Typography variant="caption" mt={0.75} color="#808080">
                    {concept.description}
                </Typography>
            </Stack>
        </Stack>
    </ConceptAccordionSummaryComponent>
);

export const ConceptAccordionDetails = styled(AccordionDetails)(
    ({ theme }) => ({
        padding: 0,
        // paddingLeft: theme.spacing(11.25),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0),
        },
    })
);
