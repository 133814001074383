import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { Menu, MenuItem, Stack } from "@mui/material";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { FiddleButton } from "../components/FiddleButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { getLoginPageUrlFromThisLocation } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { ShareLinkCopyDialog } from "./ShareLinkCopyDialog";
import { setIn } from "../../../../utils/immutableLodash";
import {
    FiddleDAOModel,
    TestCaseDAOModel,
} from "../../../../common/modules/fiddles/FiddleModel";
import _ from "lodash";

export const UserComponent = observer(({ fiddle }) => {
    const { authStore, fiddleStore } = useStores();
    const { isUserLoggedIn, currUser, logout } = authStore;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [shareLinkCopyOpen, setShareLinkCopyOpen] = useState(false);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openChannelTalk = () => {
        window.open(
            "https://codetree.channel.io/",
            "_blank",
            "noopener,noreferrer"
        );
    };

    const redirectLogin = () => {
        navigate(getLoginPageUrlFromThisLocation());
    };

    const openLinkCopyDialog = () => {
        setShareLinkCopyOpen(true);
    };

    const onCloseLinkCopyDialog = () => {
        setShareLinkCopyOpen(false);
    };

    const handleLinkAccess = _.debounce((fiddle, isPrivate) => {
        const inputs = { ...fiddle };
        const newInputs = setIn(
            "testCases",
            inputs.testCases.map(
                (testCase, i) =>
                    new TestCaseDAOModel({ ...testCase, order: i + 1 })
            ),
            inputs
        );
        const body = new FiddleDAOModel({
            ...newInputs,
            isPublished: true,
            isPrivate,
        });
        fiddleStore.update({
            fiddleId: fiddle.id,
            fiddleKey: fiddle.key,
            body,
            prevent_replace: true,
        });
    }, 250);

    return (
        <Stack direction={"row"} spacing={2.5}>
            {currUser &&
            fiddle &&
            fiddle.isPublished &&
            fiddle.username === currUser.username ? (
                <FiddleButton
                    label={"수정하기"}
                    Icon={ModeEditOutlineOutlinedIcon}
                    onClick={() => navigate("/psfiddle/list")}
                />
            ) : null}
            {fiddle && fiddle.isPublished ? (
                <FiddleButton
                    label={"링크복사"}
                    Icon={LinkOutlinedIcon}
                    // onClick={() => copyLink(fiddle)}
                    onClick={() => openLinkCopyDialog(fiddle)}
                />
            ) : null}
            <FiddleButton
                Icon={CampaignOutlinedIcon}
                label={"기능/버그 제안"}
                onClick={openChannelTalk}
            />
            {isUserLoggedIn() ? (
                <>
                    <FiddleButton
                        Icon={AccountCircleOutlinedIcon}
                        label={currUser.username}
                        onClick={handleClick}
                    />
                    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                        <MenuItem
                            children={"로그아웃"}
                            onClick={() =>
                                logout({ nextUrl: window.location.pathname })
                            }
                        />
                    </Menu>
                </>
            ) : (
                <FiddleButton
                    Icon={LogoutOutlinedIcon}
                    label={"로그인/회원가입"}
                    onClick={redirectLogin}
                />
            )}
            <ShareLinkCopyDialog
                open={shareLinkCopyOpen}
                onClose={onCloseLinkCopyDialog}
                fiddle={fiddle}
                handleLinkAccess={handleLinkAccess}
            />
        </Stack>
    );
});
