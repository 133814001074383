import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CuratedProblemSelectorTemplate } from "./CuratedProblemSelectorTemplate";
import { useStores } from "../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../common/templates/LoadingOverlay";

export const CuratedProblemSelectorContainer = observer(
    ({ handleChange, curatedProblems }) => {
        const { conceptStore, missionStore } = useStores();
        const { concepts, isFetching } = conceptStore;
        const { missions } = missionStore;

        const [missionId, setMissionId] = useState(0);
        const [problemName, setProblemName] = useState("");

        useEffect(() => {
            missionStore.findAll();
        }, []);

        useEffect(() => {
            if (missions.length && !missionId) {
                setMissionId(missions[0].id);
            }
        }, [missions.length]);

        useEffect(() => {
            if (missionId) {
                setProblemName("");
                conceptStore.findAll({ missionId });
            }
        }, [missionId]);

        const searchProblems = (value) => {
            conceptStore.findAll({ missionId, problemName: value });
        };

        const loading =
            !(missions && missions.length && missionId) || isFetching;

        if (loading)
            return (
                <LoadingOverlay
                    active={loading}
                    text="문제집 정보를 불러오고 있습니다."
                />
            );

        return (
            <CuratedProblemSelectorTemplate
                problemName={problemName}
                setProblemName={setProblemName}
                concepts={concepts}
                missions={missions}
                missionId={missionId}
                setMissionId={setMissionId}
                handleChange={handleChange}
                curatedProblems={curatedProblems}
                searchProblems={searchProblems}
            />
        );
    }
);
