import React, { useState } from "react";
import {
    Chip,
    Collapse,
    Paper,
    Stack,
    Typography,
    FormControlLabel,
    Button,
    Tooltip,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MissionBox } from "../CuratedProblems/MissionBox";
import { disableParentClick } from "../../../../../utils/usefulFunc";
import { AdminAlertDialog } from "../../../../../common/components/dialog/AdminAlertDialog";
import { HomeworkCopyDialog } from "./HomeworkCopyDialog";
import { CalendarMonth } from "@mui/icons-material";
import { AntSwitch } from "../../../../../common/components/antSwitch/AntSwitch";

export const HomeworkBox = ({ homework, handleDelete, handleHidden }) => {
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [isClosed, setIsClosed] = useState(homework.isHidden);

    const navigate = useNavigate();

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    const toggleDialogOpen = () => {
        setDialogOpen((prev) => !prev);
    };

    const redirectToEdit = () => {
        navigate(`${homework.id}/edit`);
    };

    const toggleCopyDialogOpen = () => {
        setCopyDialogOpen((prev) => !prev);
    };

    const { name, startedAt, finishedAt, missions } = homework;

    const getDateText = (_startedAt, _finishedAt) => {
        const today = moment();
        const finishedMoment = moment(_finishedAt);
        const startedMoment = moment(_startedAt);
        if (
            today.isSameOrBefore(finishedMoment) &&
            today.isSameOrAfter(startedMoment)
        ) {
            const finishedMoment = moment(_finishedAt);
            const remainDays = Math.ceil(finishedMoment.diff(today, "d", true));
            return `D-${remainDays} (${finishedMoment.format(
                "M[월] D[일까지]"
            )})`;
        }
        return `${startedMoment.format(
            "M[월] D[일]"
        )} ~ ${finishedMoment.format("M[월] D[일]")}`;
    };

    const num_total = homework.curatedProblems.length;
    const minute_total = homework.curatedProblems.reduce(
        (partialSum, a) => partialSum + a.solvedMinute,
        0
    );
    const num_is_intro = homework.curatedProblems.filter(
        ({ isIntro }) => isIntro
    ).length;
    const minutes_is_intro = homework.curatedProblems
        .filter(({ isIntro }) => isIntro)
        .reduce((partialSum, a) => partialSum + a.solvedMinute, 0);
    const num_training = num_total - num_is_intro;
    const minutes_training = minute_total - minutes_is_intro;

    return (
        <Stack direction="column">
            <Paper
                sx={{
                    boxShadow: 0,
                    borderRadius: 3,
                    p: 2.5,
                    cursor: "pointer",
                    "&:hover": {
                        boxShadow: 1,
                    },
                }}
                onClick={toggleOpen}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column" spacing={1.25}>
                        <Typography fontWeight={700} variant="h4">
                            {name}
                        </Typography>
                        <Typography variant="body1">{`총 ${num_total}문항 (${minute_total}분)`}</Typography>
                        <Stack spacing={0.5}>
                            <Typography variant="body2">
                                <Chip
                                    color="primary"
                                    label={"개념"}
                                    size="small"
                                />
                                {` - ${num_is_intro}개 (${minutes_is_intro}분)`}
                            </Typography>
                            <Typography variant="body2">
                                <Chip
                                    color="secondary"
                                    label={"연습"}
                                    size="small"
                                />
                                {` - ${num_training}개 (${minutes_training}분)`}
                            </Typography>
                        </Stack>
                        {!homework.isPublic && (
                            <Stack direction={"row"} alignItems={"center"}>
                                <CalendarMonth sx={{ color: "#666" }} />
                                <Typography
                                    variant="body2"
                                    children={getDateText(
                                        startedAt,
                                        finishedAt
                                    )}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={1.25}
                        alignItems="center"
                    >
                        {!homework.isPublic && (
                            <>
                                <Tooltip
                                    title={`'나의코딩교실'에서 수업/과제 목록에 해당 항목의 공개 여부를 정할 수 있어요 : 학생은 이 항목을 볼 수 ${
                                        isClosed ? `없` : `있`
                                    }습니다.`}
                                >
                                    <FormControlLabel
                                        sx={{ width: 60 }}
                                        labelPlacement="top"
                                        control={
                                            <AntSwitch checked={!isClosed} />
                                        }
                                        label={
                                            <Typography variant="caption">
                                                {isClosed ? `비공개` : `공개`}
                                            </Typography>
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsClosed((prev) => {
                                                handleHidden(
                                                    homework.id,
                                                    !prev
                                                );
                                                return !prev;
                                            });
                                        }}
                                    />
                                </Tooltip>

                                <Button
                                    // backgroundColor="#FFFFFF00"
                                    // color="#808080"
                                    size={"small"}
                                    // variant="contained"
                                    sx={{
                                        borderColor: "#808080",
                                    }}
                                    onClick={(e) =>
                                        disableParentClick(e, redirectToEdit)
                                    }
                                    children="수정"
                                />
                                <Button
                                    // backgroundColor="#FFFFFF00"
                                    // color="#808080"
                                    size={"small"}
                                    // variant="contained"
                                    sx={{
                                        borderColor: "#808080",
                                    }}
                                    color="secondary"
                                    onClick={(e) =>
                                        disableParentClick(
                                            e,
                                            toggleCopyDialogOpen
                                        )
                                    }
                                    children="복제"
                                />
                                <Button
                                    // backgroundColor="#FFFFFF00"
                                    // color="#D24D57"
                                    size={"small"}
                                    // variant="contained"
                                    onClick={(e) =>
                                        disableParentClick(e, toggleDialogOpen)
                                    }
                                    color="error"
                                    // sx={{
                                    //     borderColor: "#D24D57",
                                    // }}
                                    children="삭제"
                                />
                            </>
                        )}
                        {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
                    </Stack>
                </Stack>
            </Paper>
            <Collapse in={open}>
                <Stack direction="column" spacing={1.25} py={1.25} px={2}>
                    {missions.map((mission) => (
                        <MissionBox
                            key={`mission-box-${mission.id}`}
                            mission={mission}
                            homework={homework}
                        />
                    ))}
                </Stack>
            </Collapse>
            <AdminAlertDialog
                title={`${homework.name}이(가) 삭제됩니다.`}
                description={
                    "이 행동은 취소할 수 없습니다.\n정말 삭제하시겠습니까?"
                }
                open={dialogOpen}
                onConfirm={() => handleDelete(homework.id, toggleDialogOpen)}
                onClose={toggleDialogOpen}
            />

            <HomeworkCopyDialog
                homework={homework}
                open={copyDialogOpen}
                onClose={toggleCopyDialogOpen}
            />
        </Stack>
    );
};
