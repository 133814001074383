import React from "react";
import { Stack, Typography } from "@mui/material";
import { StandaloneEditor } from "../../../../common/components/editor/StandaloneEditor";
import {
    LangToCodeMirrorMode,
    LanguageType,
} from "../../../../common/modules/problems/ProblemModel";

export const DefaultCodeView = ({ item }) => {
    return (
        <Stack
            mb={1.5}
            sx={{
                borderRadius: 2,
                border: "1px solid #E0E0E2",
            }}
        >
            <Typography
                color={"#666666"}
                py={1.25}
                textAlign={"center"}
                sx={{
                    backgroundColor: "#F7F7F7",
                }}
            >
                {item.language}
            </Typography>
            <StandaloneEditor
                value={item.code}
                language={LangToCodeMirrorMode[LanguageType.Python3]}
                options={{
                    readOnly: true,
                    scrollBeyondLastLine: false,
                    scrollbar: {
                        alwaysConsumeMouseWheel: false,
                        vertical: "hidden",
                    },
                    fontSize: 14,
                    minimap: {
                        enabled: false,
                    },
                    scrollBeyondLastColumn: 5,
                }}
            />
        </Stack>
    );
};
