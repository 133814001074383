import React from "react";
import { observer } from "mobx-react-lite";
import { Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../modules/useStores";
import { getLoginPageUrlFromThisLocation } from "../../../utils/url";
import { UserPopper } from "./UserPopper";
import { NotificationsContainer } from "./NotificationsContainer";

export const UserComponent = observer(() => {
    const { authStore } = useStores();
    const { isUserLoggedIn, logout, currUser, userPlanSet } = authStore;

    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    const navigate = useNavigate();

    const redirectToLogin = () => {
        navigate(getLoginPageUrlFromThisLocation());
    };

    if (isUserLoggedIn()) {
        return (
            <Stack
                direction="row"
                spacing={3}
                sx={{
                    ml: 5,
                }}
            >
                {smBreakpoint ? <NotificationsContainer /> : null}
                <UserPopper
                    userPlanSet={userPlanSet}
                    currUser={currUser}
                    logout={logout}
                />
            </Stack>
        );
    }

    return (
        <Tabs
            sx={{
                ml: 2,
            }}
            value={false}
        >
            <Tab label={"로그인"} onClick={redirectToLogin} />
        </Tabs>
    );
});
