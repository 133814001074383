import { GET, PATCH, POST, unauthorizedPOST, DELETE } from "../../../utils/api";
import { getToken } from "../../../utils/cookies";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";

class FiddleRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`fiddles/${queryParams}`);
    }

    find({ fiddleKey, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`fiddles/${fiddleKey}/${queryParams}`);
    }

    create({ body }) {
        if (getToken()) return POST(`fiddles/`, body);
        return unauthorizedPOST(`fiddles/`, body);
    }

    update({ fiddleId, body, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return PATCH(`fiddles/${fiddleId}/${queryParams}`, body);
    }

    delete({ fiddleId }) {
        return DELETE(`fiddles/${fiddleId}/`);
    }

    checkFiddle({ fiddleKey, fiddleId }) {
        return GET(`check-fiddle/${fiddleKey}/${fiddleId}/`);
    }
}

export default new FiddleRepository();
