import React from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Page404 = () => {
    const navigate = useNavigate();

    return (
        <Stack
            direction="column"
            alignItems="center"
            sx={{
                mt: 10,
            }}
        >
            <Paper
                sx={{
                    p: 2.5,
                }}
            >
                <Stack direction="column" alignItems="center" spacing={1.25}>
                    <Typography variant="h5">
                        페이지를 찾을 수 없습니다.
                    </Typography>
                    <Typography variant="body1">
                        잘못된 페이지로 접근하셨습니다.
                    </Typography>
                    <Button
                        children="< 처음으로 돌아가기"
                        onClick={() => navigate("/")}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
};
