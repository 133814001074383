import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { HomeworksContainer } from "./HomeworksContainer";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { observer } from "mobx-react-lite";
import { HomeworksProblemContainer } from "./HomeworksProblem/HomeworksProblemContainer";

export const HomeworksPage = observer(() => {
    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    if (isUserLoggedIn()) {
        return (
            <Routes>
                <Route path="/:homeworkId?" element={<HomeworksContainer />} />
                <Route
                    path={"/:homeworkId/:problemNumber/*"}
                    element={<HomeworksProblemContainer />}
                />
            </Routes>
        );
    }

    if (hasToken()) {
        return <LoadingOverlay active />;
    }

    return <Navigate to={"/"} />;
});
