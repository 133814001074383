import React from "react";
import { Stack, Typography } from "@mui/material";
import { ExecuteResultTextField } from "./ExecuteResultTextField";
import { TextDownloadButton } from "../../../../../../../common/components/button/TextDownloadButton";
import { useStores } from "../../../../../../../common/modules/useStores";
import { downloadFileFromUrl } from "../../../../../../../utils/clipboard";

export const ExecuteStd = ({
    text,
    label,
    fileName,
    textUrl,
    submissionId,
}) => {
    const { blobStore, globalStateStore } = useStores();
    const { getUrlFromBlob } = blobStore;

    // Todo:
    const { monacoTheme } = globalStateStore;
    const isLightTheme = monacoTheme === "light";

    const downloadFullText = (_textUrl, _fileName) => {
        getUrlFromBlob({
            containerName: `submissions-${submissionId}`,
            blobName: _textUrl,
            fileName: _fileName,
        }).then((url) => {
            downloadFileFromUrl(url);
        });
    };

    return (
        <Stack direction="column" width="100%" height="100%">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={0.75}
            >
                <Typography
                    children={label}
                    variant="body2"
                    color={isLightTheme ? "#525252" : "#C4C4C4"}
                />
                <TextDownloadButton
                    iconSize="small"
                    text={text}
                    fileName={fileName}
                    tooltipPlacement="left"
                    downloadFile={() => downloadFullText(textUrl, fileName)}
                />
            </Stack>
            <ExecuteResultTextField value={text} readOnly />
        </Stack>
    );
};
