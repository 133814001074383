import { GET, POST } from "../../../utils/api";

class ProblemRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    find({ missionId, problemId }) {
        return GET(
            `/problems/missions/${missionId}/curated_problems/${problemId}/`
        );
    }

    findDescription({ problemId }) {
        return GET(`/problems/description/${problemId}/`);
    }

    findIntroductions({ missionId, problemId }) {
        return GET(
            `/problems/missions/${missionId}/introductions/${problemId}/`
        );
    }

    findExplanation({ problemId }) {
        return GET(`/problems/explanation/${problemId}/`);
    }

    addClickEvent({ body }) {
        return POST("/problems/clicks/", body);
    }

    mcqSubmission({ problemId, body }) {
        return POST(`/problems/${problemId}/mcq_submission/`, body);
    }

    sendChat({ problemId, body }) {
        return POST(`/problems/${problemId}/chat/completions/`, body);
    }
}

export default new ProblemRepository();
