import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MissionBook } from "../../../common/components/book/MissionBook";
import {
    MissionNameType,
    MissionNameTypeToColor,
} from "../../../common/modules/missions/mission/MissionModel";
import { BookThemeType, SizeType } from "../../../common/modules/ui/UIModel";

export const Intro = () => {
    const navigate = useNavigate();

    return (
        <Stack
            position={"relative"}
            alignItems={"center"}
            spacing={42 / 8}
            bgcolor={"#F3F5F7"}
            overflow={"hidden"}
            pt={90 / 8}
        >
            <Stack alignItems={"center"}>
                <Typography
                    variant="display"
                    fontSize={42}
                    letterSpacing={-0.6}
                    fontWeight={700}
                    color={"#264348"}
                >
                    우리 학교 프로그래밍 교육
                </Typography>
                <Stack direction={"row"}>
                    <Typography
                        variant="display"
                        fontSize={42}
                        letterSpacing={-0.6}
                        textAlign={"center"}
                        fontWeight={700}
                        color={"#3FCB7E"}
                    >
                        다양한 언어
                    </Typography>
                    <Typography
                        variant="display"
                        fontSize={42}
                        letterSpacing={-0.6}
                        fontWeight={400}
                        textAlign={"center"}
                        color={"#9CA8B9"}
                    >
                        를 배우고 익히는
                    </Typography>
                </Stack>
                <Stack direction={"row"}>
                    <Typography
                        variant="display"
                        fontSize={42}
                        letterSpacing={-0.6}
                        textAlign={"center"}
                        fontWeight={700}
                        color={"#A0D33F"}
                    >
                        수준별 교육
                    </Typography>
                    <Typography
                        variant="display"
                        fontSize={42}
                        letterSpacing={-0.6}
                        fontWeight={400}
                        textAlign={"center"}
                        color={"#9CA8B9"}
                    >
                        으로 시작하세요.
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={50 / 8}>
                <img
                    width={140}
                    height={140}
                    src="https://contents.codetree.ai/ooe/about/python.png"
                />
                <img
                    width={140}
                    height={140}
                    src="https://contents.codetree.ai/ooe/about/cpp.png"
                />
                <img
                    width={140}
                    height={140}
                    src="https://contents.codetree.ai/ooe/about/c.png"
                />
            </Stack>

            <Stack direction={"row"} spacing={80 / 8}>
                <Stack>
                    <Typography
                        fontFamily={"Pretendard"}
                        fontSize={20}
                        fontWeight={400}
                        lineHeight={"24px"}
                        letterSpacing={"-0.02em"}
                        textAlign={"left"}
                        color={"#6D7A9F"}
                    >
                        단원수
                    </Typography>
                    <Stack direction={"row"} alignItems={"flex-end"}>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={48}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#264348"}
                        >
                            34
                        </Typography>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={20}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#6D7A9F"}
                        >
                            단원
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        fontFamily={"Pretendard"}
                        fontSize={20}
                        fontWeight={400}
                        lineHeight={"24px"}
                        letterSpacing={"-0.02em"}
                        textAlign={"left"}
                        color={"#6D7A9F"}
                    >
                        유형수
                    </Typography>
                    <Stack direction={"row"} alignItems={"flex-end"}>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={48}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#264348"}
                        >
                            187
                        </Typography>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={20}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#6D7A9F"}
                        >
                            유형
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        fontFamily={"Pretendard"}
                        fontSize={20}
                        fontWeight={400}
                        lineHeight={"24px"}
                        letterSpacing={"-0.02em"}
                        textAlign={"left"}
                        color={"#6D7A9F"}
                    >
                        총 문항 수
                    </Typography>
                    <Stack direction={"row"} alignItems={"flex-end"}>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={48}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#264348"}
                        >
                            864
                        </Typography>
                        <Typography
                            fontFamily={"Pretendard"}
                            fontSize={20}
                            fontWeight={400}
                            lineHeight={1}
                            letterSpacing={"-0.02em"}
                            textAlign={"left"}
                            color={"#6D7A9F"}
                        >
                            문제
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                position={"relative"}
                width={"100%"}
                direction={"row"}
                justifyContent={"center"}
                spacing={80 / 8}
                sx={{
                    marginTop: "-50px !important",
                    bottom: -100,
                }}
            >
                <Stack alignItems={"center"} spacing={20 / 8}>
                    <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={4 / 8}
                    >
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                        <Box
                            sx={{
                                px: 16 / 8,
                                py: 6 / 8,
                                borderRadius: 8 / 4,
                                color: "#fff",
                                bgcolor: "#3FD3BC",
                                borderBottom: "3px solid rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            초급
                        </Box>
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                    </Stack>

                    <Stack
                        alignItems={"center"}
                        spacing={20 / 8}
                        sx={{
                            cursor: "pointer",
                            "&:hover": {
                                "& .missionBook": {
                                    transform: "translateY(-8%)",
                                },
                            },
                        }}
                        onClick={() => navigate("/curriculums/4")}
                    >
                        <Box
                            sx={{
                                px: 12 / 8,
                                py: 8 / 8,
                                borderRadius: 8 / 4,
                                color: "#42526E",
                                bgcolor: "#ffffff",
                                fontWeight: 800,
                            }}
                        >
                            프로그래밍 기초
                        </Box>
                        <MissionBook
                            className={"missionBook"}
                            theme={BookThemeType.Light}
                            color={
                                MissionNameTypeToColor[
                                    MissionNameType.NoviceLow
                                ]
                            }
                            size={SizeType.Large}
                            sx={{
                                transition: "all .25s",
                            }}
                        />
                    </Stack>
                </Stack>

                <Stack alignItems={"center"} spacing={20 / 8}>
                    <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={4 / 8}
                    >
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                        <Box
                            sx={{
                                px: 16 / 8,
                                py: 6 / 8,
                                borderRadius: 8 / 4,
                                color: "#fff",
                                bgcolor: "#3FD3BC",
                                borderBottom: "3px solid rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            중급
                        </Box>
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                    </Stack>

                    <Stack direction={"row"} spacing={80 / 8}>
                        <Stack
                            alignItems={"center"}
                            spacing={20 / 8}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    "& .missionBook": {
                                        transform: "translateY(-8%)",
                                    },
                                },
                            }}
                            onClick={() => navigate("/curriculums/5")}
                        >
                            <Stack alignItems={"center"} spacing={20 / 8}>
                                <Box
                                    sx={{
                                        px: 12 / 8,
                                        py: 8 / 8,
                                        borderRadius: 8 / 4,
                                        color: "#42526E",
                                        bgcolor: "#ffffff",
                                        fontWeight: 800,
                                    }}
                                >
                                    프로그래밍 연습
                                </Box>
                                <MissionBook
                                    className={"missionBook"}
                                    theme={BookThemeType.Light}
                                    color={
                                        MissionNameTypeToColor[
                                            MissionNameType.NoviceMid
                                        ]
                                    }
                                    size={SizeType.Large}
                                    sx={{
                                        transition: "all .25s",
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Stack
                            alignItems={"center"}
                            spacing={20 / 8}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    "& .missionBook": {
                                        transform: "translateY(-8%)",
                                    },
                                },
                            }}
                            onClick={() => navigate("/curriculums/6")}
                        >
                            <Stack alignItems={"center"} spacing={20 / 8}>
                                <Box
                                    sx={{
                                        px: 12 / 8,
                                        py: 8 / 8,
                                        borderRadius: 8 / 4,
                                        color: "#42526E",
                                        bgcolor: "#ffffff",
                                        fontWeight: 800,
                                    }}
                                >
                                    자료구조 알고리즘
                                </Box>
                                <MissionBook
                                    className={"missionBook"}
                                    theme={BookThemeType.Light}
                                    color={
                                        MissionNameTypeToColor[
                                            MissionNameType.NoviceHigh
                                        ]
                                    }
                                    size={SizeType.Large}
                                    sx={{
                                        transition: "all .25s",
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack alignItems={"center"} spacing={20 / 8}>
                    <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={4 / 8}
                    >
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                        <Box
                            sx={{
                                px: 16 / 8,
                                py: 6 / 8,
                                borderRadius: 8 / 4,
                                color: "#fff",
                                bgcolor: "#3FD3BC",
                                borderBottom: "3px solid rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            고급
                        </Box>
                        <Box
                            flex={1}
                            sx={{
                                width: "100%",
                                bgcolor: "#3FD3BC",
                                height: 3,
                            }}
                        />
                    </Stack>
                    <Stack
                        alignItems={"center"}
                        spacing={20 / 8}
                        sx={{
                            cursor: "pointer",
                            "&:hover": {
                                "& .missionBook": {
                                    transform: "translateY(-8%)",
                                },
                            },
                        }}
                        onClick={() => navigate("/curriculums/2")}
                    >
                        <Box
                            sx={{
                                px: 12 / 8,
                                py: 8 / 8,
                                borderRadius: 8 / 4,
                                color: "#42526E",
                                bgcolor: "#ffffff",
                                fontWeight: 800,
                            }}
                        >
                            알고리즘 입문
                        </Box>
                        <MissionBook
                            className={"missionBook"}
                            theme={BookThemeType.Light}
                            color={
                                MissionNameTypeToColor[
                                    MissionNameType.IntermediateLow
                                ]
                            }
                            size={SizeType.Large}
                            sx={{
                                transition: "all .25s",
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
