import React, { useState } from "react";
import {
    Autocomplete,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

export const ProblemAutoComplete = ({
    setProblemId,
    problems,
    problem,
    getProblemOptions,
    loading,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <Autocomplete
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(e, newValue) => {
                getProblemOptions(newValue);
            }}
            value={problem}
            onChange={(e, newValue) => {
                setProblemId(newValue.id);
            }}
            isOptionEqualToValue={(option, value) => {
                if (!value) {
                    return false;
                }
                return option.id === value.id;
            }}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
                <li {...props}>
                    <Stack direction="row" alignItems="center">
                        <Typography
                            fontWeight={700}
                            variant="caption"
                            children={option.number || ""}
                            color="#6D7A9F"
                            sx={{
                                width: 50,
                            }}
                        />
                        <Typography
                            fontWeight={700}
                            variant="body1"
                            children={option.name || ""}
                            color="#000000"
                        />
                    </Stack>
                </li>
            )}
            filterOptions={(x) => x}
            options={problems}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        backgroundColor: "#1111110F",
                        color: "#000000",
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                            color: "common.black",
                        },
                        "& .MuiOutlinedInput-input": {
                            fontWeight: 700,
                            fontSize: 16,
                            lineHeight: "150%",
                            p: 2.5,
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        startAdornment: (
                            <Typography
                                fontWeight={700}
                                variant="caption"
                                children={(problem && problem.number) || ""}
                                color="#6D7A9F"
                            />
                        ),
                    }}
                />
            )}
        />
    );
};
