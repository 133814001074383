import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

export const RecommendRow = ({ title, contents }) => {
    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Stack
            direction={smBreakpoint ? "row" : "column"}
            alignItems={smBreakpoint ? "flex-start" : "center"}
            sx={{
                width: { md: 700, xs: "100%" },
            }}
        >
            <Typography
                children={title}
                variant="h4"
                fontWeight={700}
                whiteSpace={smBreakpoint ? "pre-line" : "initial"}
                sx={{
                    minWidth: 160,
                }}
            />
            <Typography
                variant="body1"
                sx={{
                    wordBreak: "keep-all",
                }}
            >
                <ul
                    style={{
                        margin: 0,
                        marginLeft: smBreakpoint ? 0 : theme.spacing(-3),
                        marginTop: smBreakpoint ? 0 : theme.spacing(1),
                    }}
                >
                    {contents.map((content, i) => (
                        <li key={`content-${i}`}>{content}</li>
                    ))}
                </ul>
            </Typography>
        </Stack>
    );
};
