import React from "react";
import { Box, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { CommentEditor } from "./templates/CommentEditor";
import { useStores } from "../../../../../../../../common/modules/useStores";
import { CommentDAOModel } from "../../../../../../../../common/modules/discussions/DiscussionModel";
import { Comment } from "./templates/Comment";
import { snackActions } from "../../../../../../../../utils/SnackbarUtilsConfigurator";
import { errorToast } from "../../../../../../../../utils/errorToast";

export const CommentsBox = observer(({ comments, discussionId }) => {
    const { discussionStore } = useStores();

    const handleSubmit = (_inputs) => {
        const body = new CommentDAOModel(_inputs);

        discussionStore
            .addComment({
                body,
                discussionId,
            })
            .then(() => {
                snackActions.success("정상적으로 등록되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            });
    };

    const handleEdit = (_inputs, commentId) => {
        const body = new CommentDAOModel(_inputs);

        discussionStore
            .updateComment({
                body,
                commentId,
            })
            .then(() => {
                snackActions.success("정상적으로 수정되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            });
    };

    const fillGPTAnswer = () => {
        return new Promise((resolve, reject) =>
            discussionStore
                .discussionAnswer({
                    discussionId: discussionId,
                })
                .then((data) => {
                    resolve(data.message);
                })
                .catch((error) => {
                    reject(error);
                })
        );
    };

    return (
        <Stack direction="column" spacing={1} backgroundColor="#F6F8FA">
            {comments.length ? (
                <Box p={3}>
                    {comments.map((comment) => (
                        <Comment
                            key={`comment-${comment.id}`}
                            comment={comment}
                            handleEdit={handleEdit}
                        />
                    ))}
                </Box>
            ) : null}
            <Box px={4} py={2}>
                <CommentEditor
                    onSubmit={handleSubmit}
                    fillGPTAnswer={fillGPTAnswer}
                />
            </Box>
        </Stack>
    );
});
