import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { HomeworkFormTemplate } from "./HomeworkFormTemplate";
import {
    HomeworkDAOModel,
    HomeworkModel,
} from "../../../../../common/modules/homework/HomeworkModel";
import { setIn } from "../../../../../utils/immutableLodash";
import { useStores } from "../../../../../common/modules/useStores";
import { snackActions } from "../../../../../utils/SnackbarUtilsConfigurator";
import { LoadingOverlay } from "../../../../../common/templates/LoadingOverlay";
import { errorToast } from "../../../../../utils/errorToast";
import { snakelizeKeys } from "../../../../../utils/renameKeys";

export const HomeworkFormContainer = observer(({ isAdd }) => {
    const { homeworkId } = useParams();

    const { homeworkStore, classroomStore } = useStores();
    const { classroom } = classroomStore;

    const [loading, setLoading] = useState(false);

    const [inputs, setInputs] = useState({
        ...new HomeworkModel(),
    });

    const [homeworkTree, setHomeworkTree] = useState([]);

    useEffect(() => {
        homeworkStore
            .getHomeworkTree({
                body: {
                    curated_problems: snakelizeKeys(
                        inputs.curatedProblems.map(
                            ({ curatedProblemId }) => curatedProblemId
                        )
                    ),
                },
            })
            .then((homeworkTree) => {
                setHomeworkTree(homeworkTree);
            });
    }, [inputs.curatedProblems]);

    useEffect(() => {
        if (!isAdd) {
            setLoading(true);
            homeworkStore.find({ homeworkId }).then((_homework) => {
                const serializedHomework = {
                    ..._homework,
                    curatedProblems: _homework.curatedProblems.map(
                        ({
                            curatedProblemId,
                            score,
                            solutionVisible,
                            aiEnabled,
                        }) => ({
                            curatedProblemId,
                            score,
                            solutionVisible,
                            aiEnabled,
                        })
                    ),
                };
                setInputs({ ...serializedHomework });
            });
            setLoading(false);
        }
    }, []);

    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const validate = (_inputs) => {
        if (!_inputs.curatedProblems.length) {
            snackActions.error("문제가 선택되지 않았습니다.");
            return false;
        }
        return true;
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleSubmit = (_inputs) => {
        console.log(_inputs, inputs);
        if (!validate(_inputs)) {
            return;
        }

        if (isAdd) {
            const body = new HomeworkDAOModel({
                ..._inputs,
                classroom: classroom.id,
            });
            homeworkStore
                .add({ body })
                .then(() => {
                    snackActions.success("성공적으로 추가되었습니다.");
                    navigate(-1);
                })
                .catch((e) => {
                    errorToast(e);
                });
        } else {
            const body = new HomeworkDAOModel({
                ...inputs,
                classroom: classroom.id,
            });
            homeworkStore
                .update({ homeworkId, body })
                .then(() => {
                    snackActions.success("성공적으로 수정되었습니다.");
                    navigate(-1);
                })
                .catch((e) => {
                    errorToast(e);
                });
        }
    };

    if (loading)
        return (
            <LoadingOverlay
                active={loading}
                text="숙제 정보를 불러오는 중입니다."
            />
        );

    return (
        <HomeworkFormTemplate
            inputs={inputs}
            handleChange={handleChange}
            isAdd={isAdd}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            homeworkTree={homeworkTree}
        />
    );
});
