import React from "react";
import { Stack, Typography } from "@mui/material";

export const NoClassTemplate = () => (
    <Stack
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
    >
        <Typography
            variant="h3"
            children="등록된 학급이 존재하지 않습니다."
            mb={5}
        />
    </Stack>
);
