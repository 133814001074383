import { makeAutoObservable } from "mobx";
import { CommentModel, DiscussionModel, ReplyModel } from "./DiscussionModel";
import DiscussionRepository from "./DiscussionRepository";

export default class DiscussionStore {
    discussions = [];

    comments = [];

    discussion = undefined;

    isFetching = true;

    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    _replaceDiscussions(response) {
        const { results, count } = response.data;

        this.count = count;

        this.discussions.replace(
            results.map((discussion) => new DiscussionModel(discussion))
        );
    }

    *findAllByProblem({ problemId, page, pageSize }) {
        this.isFetching = true;

        const response = yield DiscussionRepository.findAllByParams({
            problemId,
            page,
            pageSize,
        });

        this._replaceDiscussions(response);

        this.isFetching = false;
    }

    *findAll({ page, pageSize, ...params }) {
        this.isFetching = true;

        const response = yield DiscussionRepository.findAllByParams({
            page,
            pageSize,
            classroomId: this.rootStore.classroomStore.classroom
                ? this.rootStore.classroomStore.classroom.id
                : 0,
            ...params,
        });

        this._replaceDiscussions(response);

        this.isFetching = false;
    }

    *add({ body, problemId }) {
        const response = yield DiscussionRepository.add({ body, problemId });
        this.count += 1;
        this.discussions.unshift(new DiscussionModel(response.data));
    }

    *addComment({ body, discussionId }) {
        const response = yield DiscussionRepository.addComment({
            body,
            discussionId,
        });
        this.comments.push(new CommentModel(response.data));
    }

    *updateComment({ body, commentId }) {
        const response = yield DiscussionRepository.updateComment({
            body,
            commentId,
        });
        const newComment = new CommentModel(response.data);
        this.comments.replace(
            this.comments.map((comment) => {
                if (comment.id === newComment.id) {
                    return newComment;
                }
                return comment;
            })
        );
    }

    *addReply({ body, commentId }) {
        const response = yield DiscussionRepository.addReply({
            body,
            commentId,
        });
        const comment = this.comments.find(
            (comment) => comment.id === Number(commentId)
        );
        const newReplies = [...comment.replies, new ReplyModel(response.data)];
        comment.setReplies(newReplies);
        comment.replyNum += 1;
    }

    *updateReply({ body, replyId, commentId }) {
        const response = yield DiscussionRepository.updateReply({
            body,
            replyId,
        });
        const newReply = new ReplyModel(response.data);
        const comment = this.comments.find(
            (comment) => comment.id === Number(commentId)
        );
        const newReplies = comment.replies.map((reply) => {
            if (reply.id === newReply.id) {
                return newReply;
            }
            return reply;
        });

        comment.setReplies(newReplies);
    }

    *update({ body, discussionId }) {
        const response = yield DiscussionRepository.update({
            body,
            discussionId,
        });
        this.discussion = new DiscussionModel(response.data);
    }

    *find({ discussionId }) {
        const response = yield DiscussionRepository.find({ discussionId });
        this.discussion = new DiscussionModel(response.data);
        this.comments = response.data.comments.map(
            (comment) => new CommentModel(comment)
        );
    }

    *findReplies({ commentId }) {
        const response = yield DiscussionRepository.findReplies({ commentId });
        const replies = response.data.map((reply) => new ReplyModel(reply));
        const comment = this.comments.find(
            (comment) => comment.id === Number(commentId)
        );
        comment.setReplies(replies);
    }

    *discussionAnswer({ discussionId }) {
        const response = yield DiscussionRepository.discussionAnswer({
            discussionId,
        });
        return response.data;
    }
}
