import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";
import { SubmissionsTemplate } from "../../Missions/Playground/templates/submissions/SubmissionsTemplate";
import { NeedToLoginTemplate } from "../../Missions/Playground/templates/NeedToLoginTemplate";

export const MySubmissionContainer = observer(() => {
    const { submissionStore, authStore } = useStores();
    const { isUserLoggedIn, currUser } = authStore;
    const { isFetching, submissions, count } = submissionStore;
    const [submissionCurrPage, setSubmissionCurrPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (!isUserLoggedIn()) return () => {};

        submissionStore.open({ problemId: 0 });

        updateSubmission();

        return () => {
            submissionStore.close();
        };
    }, [currUser]);

    useEffect(() => {
        updateSubmission();
    }, [submissionCurrPage, rowsPerPage]);

    const updateSubmission = () => {
        submissionStore.findAllByUser({
            page: submissionCurrPage,
            pageSize: rowsPerPage,
        });
    };

    const handlePageChange = (newPage) => {
        setSubmissionCurrPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    if (!isUserLoggedIn()) {
        return (
            <NeedToLoginTemplate text="제출 기록을 보기 위해 먼저 로그인 해주세요." />
        );
    }

    if (!(submissions && submissions.length)) {
        return <NoResultsTemplate text="제출 기록이 존재하지 않습니다." />;
    }

    return (
        <LoadingOverlay
            active={isFetching}
            text="제출 기록을 불러오는 중입니다."
            sx={{
                justifyContent: "center",
            }}
        >
            <SubmissionsTemplate
                submissions={submissions}
                rowsPerPage={rowsPerPage}
                page={submissionCurrPage}
                count={count}
                setPage={handlePageChange}
                setRowsPerPage={handleRowsPerPageChange}
                allowQuestionPosting={false}
            />
        </LoadingOverlay>
    );
});
