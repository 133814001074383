import React from "react";
import { Grid } from "@mui/material";
import { ComponentDialog } from "../../components/dialog/ComponentDialog";
import { ContentBox } from "./ContentBox";

export const KindSolutionDialog = ({ open, onClose }) => {
    const Contents = [
        {
            title: "다양한 발상과 접근",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/explanation_01_x4.png",
            description:
                "단순하게 떠올릴 수 있는 방법부터 가장 효율적인 방법까지 제시합니다.",
        },
        {
            title: "친절한 해설",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/explanation_02_x4.png",
            description:
                "바로 옆에서 알려주는 느낌의 친절한 해설이 자세하게 가르쳐드립니다.",
        },
        {
            title: "다양한 발상과 접근",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/explanation_03_x4.png",
            description:
                "효율적이고 간결하게 코딩하는 법을 국가대표 모범 코드로 익혀보세요.",
        },
    ];

    return (
        <ComponentDialog
            open={open}
            onClose={onClose}
            title="아는 것도 또렷하게."
            description="국가대표는 어떻게 생각할까요? 정확하고 효율적인 국가대표의 접근법을 최고 품질의 코드와 친절한 설명으로 배워보세요."
        >
            <Grid
                container
                direction="row"
                spacing={2.5}
                mt={5}
                flexWrap="wrap"
                justifyContent="center"
            >
                {Contents.map((content, i) => (
                    <Grid key={`kind-solution-content-${i}`} item>
                        <ContentBox width={230} imageWidth={216} {...content} />
                    </Grid>
                ))}
            </Grid>
        </ComponentDialog>
    );
};
