import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ComponentDialog } from "../../components/dialog/ComponentDialog";

export const ExplanationDialog = ({ open, onClose }) => (
    <ComponentDialog
        open={open}
        onClose={onClose}
        title="필수 개념, 풍부한 시각 정보로 학습하기"
        description="움직이는 이미지, 강의 영상으로 문제 해결 능력에 필요한 개념을 한 곳에서 학습해보세요."
        isDark
    >
        <Grid
            container
            direction="row"
            mt={5}
            flexWrap="wrap"
            justifyContent="center"
        >
            <Grid item>
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={2.5}
                    sx={{
                        width: 350,
                    }}
                >
                    <Typography
                        variant="body2"
                        children="모든 필수 개념"
                        fontWeight={700}
                    />
                    <img
                        style={{
                            width: 192,
                        }}
                        src="https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/viz_01_x4.png"
                    />
                    <Typography
                        sx={{
                            p: 1.25,
                        }}
                        variant="body2"
                        children="문제 해결 능력 향상에 필요한 모든 필수 개념을 우선순위에 따라 학습하세요."
                    />
                </Stack>
            </Grid>
            <Grid item>
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={2.5}
                    sx={{
                        width: 350,
                    }}
                >
                    <Typography
                        variant="body2"
                        children="이해를 돕는 시각화"
                        fontWeight={700}
                    />
                    <Box width={192}>
                        <video
                            src="https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/dfs.mp4"
                            autoPlay
                            loop
                            style={{
                                width: "100%",
                            }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            p: 1.25,
                        }}
                        variant="body2"
                        children="학습자의 상상력을 자극하고 이해를 돕는 (움직이는) 사진 또는 영상이 함께 제공됩니다."
                    />
                </Stack>
            </Grid>
        </Grid>
    </ComponentDialog>
);
