import { makeAutoObservable } from "mobx";
import {
    getCookie,
    getToken,
    removeRefreshToken,
    removeToken,
    setCookie,
    setRefreshToken,
    setToken,
} from "../../../utils/cookies";
import AuthRepository from "./AuthRepository";
import { AuthProviderType, UserModel } from "./UserModel";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { objectToQueryString } from "../../../utils/immutableLodash";
import { v4 as uuidv4 } from "uuid";

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {};

export const CookieUuidStateKey = "CT_UUID_STATE";

export default class AuthStore {
    currUser = undefined;
    microsoftTenantId = "common";
    token = "";

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.uuidState = getCookie(CookieUuidStateKey);
        this.microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_KEY;

        makeAutoObservable(this, {}, { autoBind: true });
        if (this.hasToken()) {
            this.getUserFromToken();
        }
    }

    setUuidState = (uuidState) => {
        setCookie(CookieUuidStateKey, uuidState, { path: "/" });

        return uuidState;
    };

    getUuidState = () => {
        const uuidState = uuidv4();

        return this.setUuidState(uuidState);
    };

    redirectToNextUrl({ nextUrl }) {
        if (nextUrl && nextUrl !== "/curriculums") {
            const decodedUrl = decodeURIComponent(nextUrl);
            window.location.href = decodedUrl;
        } else {
            window.location.href = "/";
        }
    }

    *microsoftLogin({ nextUrl }) {
        const links = nextUrl?.split("?");
        const pathname = links ? links[0] : "";
        const search = links ? links.length > 1 && links[1] : "";
        const originRedirectUri = `${
            window.location.origin + pathname
        }/microsoft/`;

        const redirectUri = encodeURIComponent(originRedirectUri);
        const state = this.setUuidState(search) || this.getUuidState();
        const queryDict = {
            response_type: "code",
            client_id: this.microsoftClientId,
            redirect_uri: redirectUri,
            state,
            scope: "openid email profile User.Read",
        };

        yield (window.location.href = `https://login.microsoftonline.com/${
            this.microsoftTenantId
        }/oauth2/v2.0/authorize${objectToQueryString(queryDict)}`);
    }

    *socialLogin({ provider, nextUrl }) {
        if (provider === AuthProviderType.MICROSOFT) {
            yield this.microsoftLogin({ nextUrl });
        }
    }

    *login({ body, nextUrl }) {
        try {
            const response = yield AuthRepository.login({ body });
            const { access, refresh } = response.data;
            setToken(access);
            setRefreshToken(refresh);
            this.redirectToNextUrl({ nextUrl });
        } catch (e) {
            snackActions.error(e.response.data);
        }
    }

    logout({ nextUrl }) {
        removeToken();
        removeRefreshToken();
        window.location.href = nextUrl ? nextUrl : "/";
    }

    *getUserFromToken() {
        try {
            const response = yield AuthRepository.getUserFromToken();
            this.currUser = new UserModel(response.data);
            if (this.currUser.classroom) {
                this.rootStore.classroomStore.classroom =
                    this.currUser.classroom;
            }
        } catch (e) {
            removeToken();
            alert(e);
            window.location.reload();
        }
    }

    *updateProfile({ body }) {
        const response = yield AuthRepository.updateProfile({
            profileId: this.currUser.profileId,
            body,
        });
        this.currUser.updateProfile(response.data);
    }

    *getSocialUser({ body, state }) {
        if (state !== decodeURIComponent(this.uuidState)) {
            snackActions.error("올바르지 않은 로그인 접근입니다.");
            throw new Error("Invalid Social Login");
        }

        const response = yield AuthRepository.getSocialUser({ body });
        return response;
    }

    setSocialUser({ access, refresh, nextUrl }) {
        if (access) {
            setToken(access);
            this.token = access;
        }
        if (refresh) setRefreshToken(refresh);
        this.redirectToNextUrl({ nextUrl });
    }

    hasToken() {
        const token = getToken() || this.token;
        return !!token;
    }

    isUserLoggedIn() {
        return this.hasToken() && !!this.currUser;
    }
}
