import React from "react";
import { Button, Stack, Typography } from "@mui/material";

export const DiscussionsHeader = ({ handleOpen, problemName }) => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" fontWeight={700} children={problemName} />
        <Button color="primary" onClick={handleOpen} variant="contained">
            새 글
        </Button>
    </Stack>
);
