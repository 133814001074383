import React from "react";
import { ClassroomType } from "../../../../common/modules/classroom/ClassroomModel";
import {
    Stack,
    Typography,
    Button,
    darken,
    Chip,
    Divider,
    Tooltip,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import moment from "moment";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";
import { copyToClipboard } from "../../../../utils/clipboard";

export const ClassroomRow = ({
    classroom,
    encodeInvitation,
    handleRequest,
}) => {
    const handleCopy = (classroom) =>
        copyToClipboard({
            text: encodeInvitation(classroom),
            onCopy: () => snackActions.success("클립보드에 복사 되었습니다."),
        });

    const getStatus = (classroom) => {
        if (classroom.status === ClassroomType.TEACHER) {
            return (
                <Stack spacing={0.5}>
                    <Chip label="내가 만든 학급입니다!" color="success" />
                    <Tooltip
                        title={"복사한 링크에서 학급 신청을 할 수 있어요."}
                        arrow
                    >
                        <Button
                            variant="outlined"
                            onClick={() => handleCopy(classroom)}
                        >
                            학급 초대 링크 복사하기
                        </Button>
                    </Tooltip>
                </Stack>
            );
        }

        if (classroom.status === ClassroomType.STUDENT) {
            return <Chip label="참가 중인 학급입니다." color="success" />;
        }

        if (classroom.status === ClassroomType.APPLIED) {
            return (
                <Chip label="참가 승인을 기다리고 있습니다." color="primary" />
            );
        }

        return (
            <Button
                onClick={() => handleRequest(classroom.id)}
                sx={{
                    py: 10 / 8,
                    px: 16 / 8,
                    borderBottom: "4px solid #1A64D3",
                    borderRadius: 2,
                    background: "#18A0FB",
                    "&:hover": {
                        background: darken("#18A0FB", 0.1),
                        borderBottom: `4px solid ${darken("#1A64D3", 0.1)}`,
                    },
                    transition: "all .15s",
                }}
            >
                <Typography
                    variant={"h4"}
                    color={"#FFFFFF"}
                    children={"학급 신청"}
                />
            </Button>
        );
    };

    return (
        <Stack
            alignItems={"center"}
            spacing={4}
            sx={{
                background: "#fff",
                p: 3,
                borderRadius: 8 / 4,
                border: "2px solid #e1e1e7",
            }}
        >
            <Stack alignItems={"center"}>
                <Typography>
                    {classroom.school
                        ? classroom.school.name
                        : "학교 정보 없음"}
                </Typography>
                <Typography variant="h3">{classroom.name}</Typography>
            </Stack>
            <Divider sx={{ alignSelf: "stretch" }} />
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
                <Stack alignItems={"center"}>
                    <Typography variant="body1">학급 시작일</Typography>
                    <Typography variant="caption">
                        {`${moment(classroom.startedDate).format("LLL")}`}
                    </Typography>
                </Stack>
                <Stack alignItems={"center"}>
                    <Typography variant="body1">학급 종료일</Typography>
                    <Typography variant="caption">
                        {`${moment(classroom.finishedDate).format("LLL")}`}
                    </Typography>
                </Stack>
            </Stack>
            <Chip icon={<FaceIcon />} label={classroom.teacher.name} />
            {getStatus(classroom)}
        </Stack>
    );
};
