import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { useStores } from "../modules/useStores";
import { LoadingOverlay } from "./LoadingOverlay";
import { MenuItem, MenuList, InputBase, Stack } from "@mui/material";
import _ from "lodash";
import createFuzzyMatcher from "../../utils/fuzzySearch";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    border: "2px solid #e1e1e7",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
        },
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:before": {
        display: "none",
    },
    padding: theme.spacing(0, 0, 10 / 8, 0),
    "&:last-of-type": {
        padding: 0,
    },
    border: "none",
    background: "transparent",
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<SchoolIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: theme.spacing(8 / 8, 12 / 8),
    borderRadius: 8,
    flexDirection: "row-reverse",
    opacity: 0.6,
    "&:hover ": {
        opacity: 1,
        background: "#ffffff",
    },
    "&.Mui-expanded": {
        opacity: 1,
        background: "#ffffff",
        borderBottom: "none",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0.5, 0),
}));

const SchoolClassroomAccordionTemplate = ({
    availableSchools,
    availableClassrooms,
    targetSchool,
    selectedClassroom,
    handleSelect,
}) => {
    const SchoolNoneKey = "(학교 정보 없음)";
    const initSchoolClassMap = { [SchoolNoneKey]: [] };
    const [schoolClassMap, setSchoolClassMap] = useState({
        ...initSchoolClassMap,
    });
    const [filteredSchoolClassMap, setFilteredSchoolClassMap] = useState({
        ...initSchoolClassMap,
    });

    useEffect(() => {
        setSchoolClassMap((prevSchoolMap) => {
            const schoolClassMapTmp = { ...prevSchoolMap };
            availableSchools.map((school) => {
                /* eslint-disable */
                if (!schoolClassMapTmp.hasOwnProperty(school.name)) {
                    schoolClassMapTmp[school.name] = [];
                }
            })
            setFilteredSchoolClassMap(schoolClassMapTmp);
            return schoolClassMapTmp
        })
    
    }, [availableSchools])

    useEffect(() => {
        setSchoolClassMap(prevSchoolMap => {
            const schoolClassMapTmp = { ...prevSchoolMap };

            Object.keys(schoolClassMapTmp).map(k => {
                schoolClassMapTmp[k] = [];
            })

            availableClassrooms.map((classroom) => {
                if (classroom.school) {
                    /* eslint-disable */
                    if (!schoolClassMapTmp.hasOwnProperty(classroom.school.name)) {
                        schoolClassMapTmp[classroom.school.name] = [];
                    }
                    schoolClassMapTmp[classroom.school.name].push(classroom);
                } else {
                    schoolClassMapTmp[SchoolNoneKey].push(classroom);
                }
            });
            setFilteredSchoolClassMap(schoolClassMapTmp);
            return schoolClassMapTmp
        });
        
        if (selectedClassroom) {
            handleSelect(
                availableClassrooms.filter(
                    (classroom) => classroom.id === selectedClassroom.id
                )[0]
            );
        }
    }, [availableClassrooms]);

    const [expanded, setExpanded] = useState([]);

    const handleChange = (school) => () => {
        setExpanded((prevExtended) => {
            const idx = prevExtended.indexOf(school);
            if (idx === -1) {
                return [...prevExtended, school];
            } else {
                return [
                    ...prevExtended.slice(0, idx),
                    ...prevExtended.slice(idx + 1),
                ];
            }
        });
    };

    const debounceSearchClassrooms = _.debounce((_keyword) => {
        const regex = createFuzzyMatcher(_keyword);
        const resultData = Object.entries(schoolClassMap).filter(
            ([school, classrooms]) => {
                return (
                    regex.test(school) ||
                    classrooms.some((classroom) => regex.test(classroom.name))
                );
            }
        );

        setFilteredSchoolClassMap(() => {
            return resultData.reduce((accumulator, [key, value]) => {
                return { ...accumulator, [key]: value };
            }, {});
        });
    }, 100);


    return (
        <div>
            <Stack sx={{position:'sticky', top: 54, zIndex:100, borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} bgcolor={'#F5F5F7'} spacing={1} py={1}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="학교/학급 검색"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => debounceSearchClassrooms(e.target.value)}
                />
            </Search>
            {filteredSchoolClassMap[targetSchool] && (
                <>
                    <Typography variant="caption">우리 학교</Typography>
                    <Accordion
                        expanded={expanded.indexOf(targetSchool) !== -1}
                        onChange={handleChange(targetSchool)}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <Typography>{targetSchool}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MenuList>
                                {filteredSchoolClassMap[targetSchool].map(
                                    (classroom) => (
                                        <MenuItem
                                            key={classroom.id}
                                            onClick={() =>
                                                handleSelect(classroom)
                                            }
                                            selected={
                                                selectedClassroom
                                                    ? selectedClassroom.id ===
                                                      classroom.id
                                                    : false
                                            }
                                        >
                                            {classroom.name}
                                        </MenuItem>
                                    )
                                )}
                            </MenuList>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
            </Stack>
            <Typography variant="caption">전체 학교</Typography>
            {Object.entries(filteredSchoolClassMap)
                .filter(([school]) => school !== targetSchool)
                .map(([school]) => (
                    <Accordion
                        key={school}
                        expanded={expanded.indexOf(school) !== -1}
                        onChange={handleChange(school)}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <Typography>{school}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>다른 학교의 학급 정보는 볼 수 없습니다.</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </div>
    );
};

export const SchoolClassroomAccordion = observer(
    ({ targetSchool, selectedClassroom, handleSelect }) => {
        const { classroomStore } = useStores();
        const { availableClassrooms, availableIsFetching, schools } = classroomStore;

        useEffect(() => {
            classroomStore.findAvailableClassrooms();
            classroomStore.findAllSchools();
        }, []);

        if (availableIsFetching) return <LoadingOverlay active />;

        return (
            <SchoolClassroomAccordionTemplate
                targetSchool={targetSchool}
                availableSchools={[...schools].sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)}
                availableClassrooms={availableClassrooms}
                selectedClassroom={selectedClassroom}
                handleSelect={handleSelect}
            />
        );
    }
);
