import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { PSFiddleViewForm } from "../PSFiddleViewForm";
import { PSFiddleEditContainer } from "../PSFiddleEditContainer";

const LocalEditedListKey = "fiddle-edited-keys";

export const PSFiddleListTemplate = ({ fiddles }) => {
    const [editedList, setEditedList] = useState([]);

    const startEdit = (fiddleId) => {
        const newEditedList = [...editedList, fiddleId];
        setEditedList(newEditedList);
        localStorage.setItem(LocalEditedListKey, JSON.stringify(newEditedList));
    };

    const finishEdit = (fiddleId) => {
        const newEditedList = editedList.filter((item) => item !== fiddleId);
        setEditedList(newEditedList);
        localStorage.setItem(LocalEditedListKey, JSON.stringify(newEditedList));
    };

    useEffect(() => {
        const localEditedList = localStorage.getItem(LocalEditedListKey);
        if (localEditedList) {
            setEditedList(JSON.parse(localEditedList));
        }
    }, []);

    return (
        <Stack flexGrow={1} justifyContent={"center"} alignItems={"center"}>
            {fiddles.map((fiddle) =>
                editedList.some((id) => fiddle.id === id) ? (
                    <PSFiddleEditContainer
                        key={fiddle.key}
                        fiddle={fiddle}
                        finishEdit={() => finishEdit(fiddle.id)}
                    />
                ) : (
                    <PSFiddleViewForm
                        key={fiddle.key}
                        inputs={fiddle}
                        startEdit={() => startEdit(fiddle.id)}
                    />
                )
            )}
        </Stack>
    );
};
