import React, { useEffect } from "react";
import { searchParamsToObject } from "../../../../../utils/url";
import {
    AuthProviderType,
    SocialUserDAOModel,
    SocialUserModel,
} from "../../../../../common/modules/auth/UserModel";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useStores } from "../../../../../common/modules/useStores";
import { errorToast } from "../../../../../utils/errorToast";

export const SocialLoginContainer = () => {
    const { authProvider } = useParams();

    const navigate = useNavigate();

    const location = useLocation();
    const params = searchParamsToObject(location.search);
    const { code, state } = params;
    const { authStore } = useStores();

    const getSocialUserDAO = () => {
        if (authProvider === AuthProviderType.MICROSOFT) {
            return new SocialUserDAOModel({
                authProvider,
                code,
                redirectUri: window.location.origin + window.location.pathname,
            });
        } else {
            throw Error("Undefined AuthProvider");
        }
    };

    useEffect(() => {
        const body = getSocialUserDAO();

        authStore
            .getSocialUser({ body, state })
            .then((response) => {
                const socialUser = new SocialUserModel(response.data);
                const { access, refresh } = socialUser;
                const { nextUrl } = searchParamsToObject(state);
                authStore.setSocialUser({ access, refresh, nextUrl });
            })
            .catch((e) => {
                errorToast(e);
                navigate("/accounts/login");
            });
    }, []);

    return <></>;
};
