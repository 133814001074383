import React from "react";
import { Box, Dialog, Stack, Typography } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { StandaloneEditor } from "../../../../../../../common/components/editor/StandaloneEditor";
import {
    SubmissionStatusToColor,
    SubmissionStatusToKorean,
} from "../../../../../../../common/modules/submission/SubmissionModel";
import { useStores } from "../../../../../../../common/modules/useStores";
import { LangToCodeMirrorMode } from "../../../../../../../common/modules/problems/ProblemModel";
import { CopyButton } from "../../../../../../../common/components/button/CopyButton";
import { CodeResultBox } from "./CodeResultBox";

export const CodeDialogTemplate = observer(
    ({ submission, open, handleClose, isFiddle }) => {
        const { globalStateStore } = useStores();
        const { monacoTheme } = globalStateStore;

        const isLightTheme = monacoTheme === "light";

        const { result, createdAt, problemName, code, language } = submission;
        const { status, runtime, memory } = result;

        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth={false}
                scroll="paper"
                PaperProps={{
                    sx: {
                        maxWidth: 960,
                        width: "100%",
                        maxHeight: "80vh",
                    },
                }}
            >
                <Stack
                    direction="row"
                    px={2.5}
                    py={1.25}
                    borderBottom="1px solid #DDDDDD"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack direction="column">
                        <Typography variant="body2" children={problemName} />
                        <Typography
                            color={SubmissionStatusToColor[status]}
                            children={SubmissionStatusToKorean[status]}
                            fontWeight={700}
                            variant="h4"
                        />
                    </Stack>
                    <Stack direction="column" alignItems="flex-end">
                        <Typography variant="caption">
                            Runtime:{" "}
                            <strong>
                                {runtime >= 0 ? `${runtime}ms` : "N/A"}
                            </strong>
                        </Typography>
                        <Typography variant="caption">
                            Memory:{" "}
                            <strong>
                                {memory >= 0 ? `${memory}MB` : "N/A"}
                            </strong>
                        </Typography>
                        <Typography
                            variant="caption"
                            children={moment(createdAt).format(
                                "YYYY[년] MM[월] DD[일] HH:mm:ss"
                            )}
                        />
                    </Stack>
                </Stack>
                <Box overflow="auto">
                    <CodeResultBox
                        submission={submission}
                        isFiddle={isFiddle}
                    />
                    <Box p={2.5} pb={8.75}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack direction="column" color="#525252">
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    children="제출한 코드"
                                />
                                <Typography
                                    mb={1.25}
                                    variant="body2"
                                    children={`언어: ${language}`}
                                />
                            </Stack>
                            <CopyButton
                                text={code}
                                iconSize="medium"
                                tooltipPlacement="left"
                            />
                        </Stack>
                        <Box
                            overflow="hidden"
                            py={1}
                            borderRadius={1}
                            border="1px solid #D8DEE4"
                            backgroundColor={
                                isLightTheme ? "#FFFFFE" : "#272822"
                            }
                        >
                            <StandaloneEditor
                                value={code}
                                language={LangToCodeMirrorMode[language]}
                                theme={monacoTheme}
                                options={{
                                    readOnly: true,
                                    scrollBeyondLastLine: false,
                                    scrollbar: {
                                        alwaysConsumeMouseWheel: false,
                                        vertical: "hidden",
                                    },
                                    fontSize: 14,
                                    minimap: {
                                        enabled: false,
                                    },
                                    scrollBeyondLastColumn: 5,
                                }}
                                height={code.split("\n").length * 21 + 30}
                            />
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        );
    }
);
