import React from "react";
import { Stack, TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DiscussionRow } from "./templates/DiscussionRow";

export const DiscussionsTemplate = ({
    discussions,
    count,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
}) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const navigate = useNavigate();

    const redirectToDiscussion = (discussionId) => {
        navigate(discussionId.toString());
    };

    return (
        <Stack direction="column" mt={2}>
            <Stack
                direction="column"
                sx={{
                    "& > :first-of-type": {
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                    },
                    "& > :last-of-type": {
                        borderBottom: "1px solid #D0D7DE",
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                    },
                }}
            >
                {discussions.slice(0, rowsPerPage).map((discussion) => (
                    <DiscussionRow
                        key={`discussion-row-${discussion.id}`}
                        discussion={discussion}
                        handleClick={() => redirectToDiscussion(discussion.id)}
                    />
                ))}
            </Stack>
            <table>
                <tbody>
                    <tr>
                        <TablePagination
                            sx={{
                                borderBottom: "none",
                            }}
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </tr>
                </tbody>
            </table>
        </Stack>
    );
};
