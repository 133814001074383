import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../../utils/immutableLodash";
import { camelizeKeys } from "../../../../utils/renameKeys";
import { CuratedProblemModel } from "../curatedProblem/CuratedProblemModel";

export class StudyUnitModel {
    id = 0;

    conceptId = 0;

    name = "";

    description = "";

    testCount = 0;

    quizCount = 0;

    lessonCount = 0;

    curatedProblems = [];

    isFetched = false;

    passedProblemPercent = 0;

    solvedMinute = 0;

    minuteLeft = 0;

    order = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const studyUnit = pickCls(this, camelizeKeys(data));
        studyUnit.curatedProblems =
            studyUnit.curatedProblems &&
            studyUnit.curatedProblems.map(
                (curatedProblem) => new CuratedProblemModel(curatedProblem)
            );
        set(this, studyUnit);
    }

    setCuratedProblems(_curatedProblems) {
        this.curatedProblems = _curatedProblems;
        this.isFetched = true;
    }
}
