import { GET } from "../../../../utils/api";
import { snakelizeKeys } from "../../../../utils/renameKeys";
import { objectToQueryString } from "../../../../utils/immutableLodash";

class ConceptRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({ missionId, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);

        return GET(`problems/missions/${missionId}/concepts/${queryParams}`);
    }
}

export default new ConceptRepository();
