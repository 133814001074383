import React from "react";
import {
    // Dialog,
    ThemeProvider,
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
} from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtilsConfigurator";
import { MainPage } from "./pages/Main/MainPage";
import { DefaultTheme } from "./style/theme";
import { Page404 } from "./pages/Page404";

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/not-found" element={<Page404 />} />
            <Route path={"/*"} element={<MainPage />} />
        </Routes>
    </BrowserRouter>
);

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={DefaultTheme}>
                <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
                    <SnackbarUtilsConfigurator />
                    {/* <Dialog
                        open={true}
                        sx={{
                            zIndex: 99999,
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"서비스 점검 안내"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                서비스 점검으로 인해 금일(23년 5월 29일
                                23:59까지) 서비스 이용이 불가합니다. 점검 후
                                다시 이용해주세요. 감사합니다.
                            </DialogContentText>
                        </DialogContent>
                    </Dialog> */}
                    <Router />
                </SnackbarProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
