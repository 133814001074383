import { makeAutoObservable } from "mobx";
import moment from "moment";
import { ProblemStatusType } from "../../missions/curatedProblem/CuratedProblemModel";
import { SubmissionStatusType } from "../../submission/SubmissionModel";
import {
    FiddleUserProgressesModel,
    FiddleUserProgressModel,
} from "./FiddleProgressModel";
import FiddleProgressRepository from "./FiddleProgressRepository";

export default class FiddleProgressStore {
    fiddleProgresses = [];

    fiddleProgress = undefined;

    isFetching = true;

    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *find({ fiddleKey, ...params }) {
        this.isFetching = true;
        const response = yield FiddleProgressRepository.find({
            fiddleKey,
            classroomId: this.rootStore.classroomStore.classroom.id,
            ...params,
        });

        const { fiddle_progresses, ...rest } = response.data;

        const fiddleUserProgresses = Object.entries(fiddle_progresses)
            .map(([userId, userProgress]) => ({ userId, ...userProgress }))
            .map((userProgress) => {
                return new FiddleUserProgressModel({
                    ...userProgress,
                    solvedAt: userProgress.created_at,
                });
            });

        const fiddleProgress = new FiddleUserProgressesModel({
            ...rest,
            fiddleUserProgresses,
        });

        const exist = this.fiddleProgresses.findIndex(
            (_fiddleProgress) => _fiddleProgress.key === fiddleProgress.key
        );
        if (exist !== -1) {
            this.fiddleProgresses = [
                ...this.fiddleProgresses.map((_fiddleProgress) => {
                    if (_fiddleProgress.key === fiddleProgress.key) {
                        return fiddleProgress;
                    } else {
                        return _fiddleProgress;
                    }
                }),
            ];
        }
        this.fiddleProgress = fiddleProgress;
        this.isFetching = false;
    }

    *findAll(params) {
        this.isFetching = true;
        const response = yield FiddleProgressRepository.findAll({
            classroomId: this.rootStore.classroomStore.classroom.id,
            ...params,
        });
        this.fiddleProgresses = response.data.results
            .filter(
                (fiddleProgress) =>
                    !fiddleProgress.is_private || fiddleProgress.is_owner
            )
            .map((fiddleProgress) => {
                const { fiddle_progresses, ...rest } = fiddleProgress;
                const fiddleUserProgresses = Object.entries(fiddle_progresses)
                    .map(([userId, userProgress]) => ({
                        userId,
                        ...userProgress,
                    }))
                    .sort((a, b) => {
                        if (a.status !== b.status) {
                            if (a.status === ProblemStatusType.Ready) return 1;
                            if (b.status === ProblemStatusType.Ready) return -1;
                            if (a.status === SubmissionStatusType.Passed)
                                return -1;
                            if (b.status === SubmissionStatusType.Passed)
                                return 1;
                        } else {
                            if (a.status !== ProblemStatusType.Ready) {
                                const ma = moment(a.createdAt);
                                const mb = moment(b.createdAt);
                                return ma.diff(mb);
                            }
                        }
                        return 0;
                    })
                    .map((userProgress) => {
                        return new FiddleUserProgressModel({
                            ...userProgress,
                            solvedAt: userProgress.created_at,
                        });
                    });

                return new FiddleUserProgressesModel({
                    ...rest,
                    fiddleUserProgresses,
                });
            });
        this.isFetching = false;
        return this.fiddleProgresses;
    }

    updateLocalFiddle(fiddle, force = false) {
        if (
            this.fiddleProgresses.some((_fiddle) => _fiddle.id === fiddle.id) ||
            force
        ) {
            this.fiddleProgress = fiddle;
        }
        return this.fiddleProgress;
    }

    updateLocalFiddles({ fiddleId, fiddle }) {
        this.fiddleProgresses.replace(
            this.fiddleProgresses.map((_fiddle) => {
                if (Number(fiddleId) === _fiddle.id) {
                    return fiddle;
                } else {
                    return _fiddle;
                }
            })
        );
        return this.fiddleProgresses;
    }

    deleteLocalFiddle(fiddle) {
        const fiddleKey = fiddle.key;

        this.fiddleProgresses.replace(
            this.fiddleProgresses.filter((fiddle) => fiddleKey !== fiddle.key)
        );
        if (this.fiddleProgresses.length) {
            this.fiddleProgress = this.fiddleProgresses[0];
        } else {
            this.fiddleProgress = undefined;
        }
    }
}
