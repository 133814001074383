import { makeAutoObservable } from "mobx";
import SubmissionWebSocket from "./SubmissionWebSocket";
import { WebSocketType } from "../../../utils/enum";
import {
    ExecuteResultModel,
    SubmissionModel,
    SubmissionStatusType,
} from "./SubmissionModel";
import ExecuteSubmissionWebSocket from "./ExecuteSubmissionWebSocket";
import SubmissionRepository from "./SubmissionRepository";

export default class SubmissionStore {
    submissions = [];

    result = undefined;

    isFetching = true;

    isExecuting = false;

    count = 0;

    page = 0;

    rowsPerPage = 10;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    subscribeSubmission({ submissionId }) {
        const body = {
            type: WebSocketType.SubscribeSubmission,
            data: {
                id: submissionId,
            },
        };

        SubmissionWebSocket.add({ body });
    }

    _onMessage(e) {
        const response = JSON.parse(e.data);
        const { type } = response;
        const { data } = response;

        if (type === WebSocketType.SubscribeSuccess) {
            // Todo:
        } else if (type === WebSocketType.SubscribeFailed) {
            // Todo:
        } else if (type === WebSocketType.PublishSubmission) {
            const newSubmission = new SubmissionModel(data);
            if (
                !this.submissions.some(
                    (submission) => submission.id === newSubmission.id
                )
            ) {
                this.count += 1;
                this.submissions.replace([newSubmission, ...this.submissions]);
            }
        } else if (type === WebSocketType.UpdataSubmission) {
            if ("process" in data.result) {
                this.submissions.replace(
                    this.submissions.map((submission) => {
                        if (Number(data.id) === submission.id) {
                            return new SubmissionModel({
                                ...submission,
                                result: {
                                    ...submission.result,
                                    status: SubmissionStatusType.InProgress,
                                    process: data.result.process,
                                },
                            });
                        }

                        return submission;
                    })
                );

                return;
            }

            const newSubmission = new SubmissionModel(data);
            this.submissions.replace(
                this.submissions.map((submission) => {
                    if (newSubmission.id === submission.id)
                        return newSubmission;

                    return submission;
                })
            );
        }
    }

    _executeOnMessage(e) {
        const response = JSON.parse(e.data);
        const { type } = response;
        const { data } = response;

        if (type === WebSocketType.SubscribeSuccess) {
            this.isExecuting = true;
        } else if (type === WebSocketType.SubscribeFailed) {
            // Todo:
        } else if (type === WebSocketType.UpdataSubmission) {
            const newResult = new ExecuteResultModel(data);
            this.result = newResult;
            this.isExecuting = false;
        }
    }

    executeOpen() {
        ExecuteSubmissionWebSocket.open({ onMessage: this._executeOnMessage });
    }

    executeSend({ body }) {
        const newBody = {
            type: WebSocketType.PublishSubmission,
            data: body,
        };

        ExecuteSubmissionWebSocket.add({ body: newBody });
    }

    executeClose() {
        ExecuteSubmissionWebSocket.close();
    }

    open({ problemId }) {
        SubmissionWebSocket.open({ onMessage: this._onMessage, problemId });
    }

    send({ body }) {
        const newBody = {
            type: WebSocketType.PublishSubmission,
            data: body,
        };

        SubmissionWebSocket.add({ body: newBody });
    }

    close() {
        SubmissionWebSocket.close();
    }

    _replaceSubmissions({ data }) {
        const { results, count } = data;
        this.count = count;
        this.submissions.replace(
            results.map((submission) => new SubmissionModel(submission))
        );
        results.forEach((submission) => {
            this.subscribeSubmission({ submissionId: submission.id });
        });
    }

    setPage = (_page) => {
        this.page = _page;
    };

    setRowsPerPage = (_rowsPerPage) => {
        this.rowsPerPage = _rowsPerPage;
    };

    *findAllByProblem({ problemId }) {
        this.isFetching = true;
        const response = yield SubmissionRepository.findAllByProblem({
            problemId,
            page: this.page + 1,
            pageSize: this.rowsPerPage,
        });
        this._replaceSubmissions({ data: response.data });
        this.isFetching = false;
    }

    *findAllByProblemAndUser({ problemId, userId }) {
        this.isFetching = true;
        const response = yield SubmissionRepository.findAllByProblemAndUser({
            problemId,
            userId,
        });
        this.submissions.replace(
            response.data.map((submission) => new SubmissionModel(submission))
        );
        this.isFetching = false;
    }

    *findAllByUser({ page, pageSize, ...params }) {
        this.isFetching = true;
        const response = yield SubmissionRepository.findAllByUser({
            ...params,
            page,
            pageSize,
        });
        this._replaceSubmissions({ data: response.data });
        this.isFetching = false;
    }

    *findAllByFiddleAndUser({ fiddleId, userId }) {
        this.isFetching = true;
        const response = yield SubmissionRepository.findAllByFiddleAndUser({
            fiddleId,
            userId,
        });
        this.submissions = response.data.map(
            (submission) => new SubmissionModel(submission)
        );
        this.isFetching = false;
    }

    *errorSummary({ submissionId }) {
        const response = yield SubmissionRepository.errorSummary({
            submissionId,
        });
        return response.data;
    }
}
