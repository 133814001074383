import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Menu, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { observer } from "mobx-react-lite";
import { useStores } from "../../modules/useStores";
import { ClassroomMenuItem } from "./ClassroomMenuItem";
import { ClassroomAddButton } from "../../../pages/Main/Admin/components/ClassroomAddButton";
import { ClassroomAddDialog } from "../../../pages/Main/Admin/templates/ClassroomAddDialog";
import AddIcon from "@mui/icons-material/Add";

export const ClassroomSelector = observer(
    ({ sx, allowAllSelection, allowAddClass = true }) => {
        const { classroomStore } = useStores();
        const { classroom, setClassroom, classrooms } = classroomStore;

        const [anchorEl, setAnchorEl] = useState(null);
        const [open, setOpen] = useState(false);

        const toggleOpen = () => {
            setOpen((prev) => !prev);
            handleClose();
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleMenuClick = (_classroom) => {
            if (_classroom.name === "모든 학급") {
                setClassroom(undefined);
            } else {
                setClassroom(_classroom);
            }
            handleClose();
        };

        const addedClassrooms = allowAllSelection
            ? [{ name: "모든 학급" }, ...classrooms]
            : classrooms;

        useEffect(() => {
            if (!classroom && !allowAllSelection && addedClassrooms.length) {
                setClassroom(addedClassrooms[0]);
            }
        }, [allowAllSelection]);

        if (!addedClassrooms.length) {
            return <ClassroomAddButton sx={sx} />;
        }

        return (
            <>
                <Button
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        ...sx,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box display="flex" flexDirection="column">
                            {classroom ? (
                                <Stack direction={"column"}>
                                    <Typography
                                        variant="body2"
                                        textAlign="left"
                                    >
                                        {classroom.startedDate} ~{" "}
                                        {classroom.finishedDate}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Typography
                                            variant="body1"
                                            textAlign="left"
                                            fontWeight={700}
                                        >
                                            {classroom.name}
                                        </Typography>
                                        {classroom.isOwner && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    backgroundColor: "#FFCF00",
                                                    color: "#000000",
                                                    px: 8 / 8,
                                                    py: 3 / 8,
                                                    borderRadius: 8 / 4,
                                                    fontSize: 8,
                                                    lineHeight: "12px",
                                                    fontWeight: 800,
                                                }}
                                            >
                                                내 학급
                                            </Box>
                                        )}
                                    </Stack>
                                </Stack>
                            ) : (
                                <Typography variant="body2" textAlign="left">
                                    모든 학급
                                </Typography>
                            )}
                        </Box>
                        <ArrowDropDownIcon />
                    </Box>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: { width: anchorEl && anchorEl.offsetWidth },
                    }}
                    PaperProps={{
                        style: {
                            maxHeight: 300,
                        },
                    }}
                >
                    {addedClassrooms.map((_classroom) => (
                        <ClassroomMenuItem
                            key={`classroom-menu-${_classroom.id}`}
                            onClick={() => handleMenuClick(_classroom)}
                            selected={
                                classroom
                                    ? classroom.id === _classroom.id
                                    : false
                            }
                        >
                            <Stack direction="column">
                                <Typography variant="body2" textAlign="left">
                                    {_classroom.startedDate} ~{" "}
                                    {_classroom.finishedDate}
                                </Typography>
                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Typography
                                        variant="body1"
                                        textAlign="left"
                                        fontWeight={700}
                                    >
                                        {_classroom.name}
                                    </Typography>
                                    {_classroom.isOwner && (
                                        <Box
                                            component="span"
                                            sx={{
                                                backgroundColor: "#FFCF00",
                                                color: "#000000",
                                                px: 8 / 8,
                                                py: 3 / 8,
                                                borderRadius: 8 / 4,
                                                fontSize: 8,
                                                lineHeight: "12px",
                                                fontWeight: 800,
                                            }}
                                        >
                                            내 학급
                                        </Box>
                                    )}
                                </Stack>
                            </Stack>
                        </ClassroomMenuItem>
                    ))}
                    {allowAddClass ? (
                        <>
                            <Divider />
                            <ClassroomMenuItem onClick={toggleOpen}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Typography
                                        variant="body1"
                                        textAlign="left"
                                        fontWeight={700}
                                        children="새 학급 만들기"
                                    />
                                    <AddIcon />
                                </Stack>
                            </ClassroomMenuItem>
                        </>
                    ) : null}
                </Menu>
                <ClassroomAddDialog open={open} onClose={toggleOpen} />
            </>
        );
    }
);
