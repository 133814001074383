import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    StudyUnitAccordion,
    StudyUnitAccordionDetails,
    StudyUnitAccordionSummary,
} from "../components/StudyUnitAccordion";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { CuratedProblemsBox } from "./CuratedProblemsBox";
import { useStores } from "../../../../common/modules/useStores";

export const StudyUnitAccordionBox = observer(
    ({
        missionId,
        studyUnits,
        selectable,
        handleClickProblem,
        getChecked,
        forShowing,
        defaultStudyUnitId,
        conceptId,
        defaultConceptId,
        problemName,
    }) => {
        const [openedStudyUnitIds, setOpenStudyUnitIds] = useState({});

        const { curatedProblemStore } = useStores();

        const rootRef = useRef();

        const handleOpenStudyUnitId = (studyUnitId, conceptId) => {
            if (forShowing) {
                return;
            }

            setOpenStudyUnitIds((prev) => ({
                ...prev,
                [studyUnitId]: !prev[studyUnitId],
            }));

            curatedProblemStore.findAll({
                studyUnitId,
                conceptId,
                problemName,
            });
        };

        useEffect(() => {
            if (conceptId === defaultConceptId && defaultStudyUnitId) {
                rootRef.current.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                });
                handleOpenStudyUnitId(defaultStudyUnitId, conceptId);
            }
        }, [defaultStudyUnitId]);

        return (
            <Box ref={rootRef}>
                {studyUnits.map((studyUnit, i) => (
                    <StudyUnitAccordion
                        key={`studyUnit_${i}`}
                        expanded={!!openedStudyUnitIds[studyUnit.id]}
                        onChange={() =>
                            handleOpenStudyUnitId(studyUnit.id, conceptId)
                        }
                    >
                        <StudyUnitAccordionSummary
                            expanded={!!openedStudyUnitIds[studyUnit.id]}
                            studyUnit={studyUnit}
                            expandIcon={
                                !forShowing && (
                                    <ExpandMoreIcon fontSize="small" />
                                )
                            }
                            forShowing={forShowing}
                        />
                        <StudyUnitAccordionDetails>
                            {studyUnit.isFetched ? (
                                <CuratedProblemsBox
                                    missionId={missionId}
                                    curatedProblems={studyUnit.curatedProblems}
                                    selectable={selectable}
                                    getChecked={getChecked}
                                    handleClickProblem={handleClickProblem}
                                />
                            ) : (
                                <Box height={100}>
                                    <LoadingOverlay active spinnerSize={40} />
                                </Box>
                            )}
                        </StudyUnitAccordionDetails>
                    </StudyUnitAccordion>
                ))}
            </Box>
        );
    }
);
