import React from "react";
import { Box, styled } from "@mui/material";

const SelectionBoxComponent = styled(Box)(({ theme, ownerState }) => ({
    width: 50,
    height: 50,
    borderWidth: 3,
    borderColor: theme.palette.common.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: ownerState.isEmpty ? "dotted" : "solid",
}));

export const SelectionBox = ({ label, isEmpty, ...props }) => (
    <SelectionBoxComponent ownerState={{ isEmpty }} {...props}>
        {label}
    </SelectionBoxComponent>
);
