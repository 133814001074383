import React from "react";
import { Button, Paper, Stack, TextField } from "@mui/material";

export const LoginTemplate = ({
    inputs,
    handleChange,
    handleKeyPress,
    handleSubmit,
    socialLogin,
}) => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <Paper
                variant="outlined"
                sx={{
                    p: 5,
                    width: 330,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img
                        style={{
                            width: 200,
                        }}
                        src="/smooc.png"
                    />
                    <Stack
                        direction="column"
                        spacing={2.5}
                        sx={{
                            mt: 2.5,
                            width: "100%",
                        }}
                    >
                        <TextField
                            name="username"
                            label="아이디"
                            variant="outlined"
                            value={inputs.username}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            id="password"
                            label="비밀번호"
                            name="password"
                            value={inputs.password}
                            type="password"
                            onChange={handleChange}
                            variant="outlined"
                            onKeyPress={handleKeyPress}
                            fullWidth
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            로그인
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={socialLogin}
                        >
                            Microsoft 로그인
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};
