import { makeAutoObservable } from "mobx";
import BlobRepository from "./BlobRepository";

export default class BlobStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *getTextFromBlob({ containerName, blobName }) {
        const response = yield BlobRepository.getTextFromBlob({
            containerName,
            blobName,
        });
        return response.data.text;
    }

    *getUrlFromBlob({ containerName, blobName, fileName }) {
        const response = yield BlobRepository.getUrlFromBlob({
            containerName,
            blobName,
            fileName,
        });
        return response.data.url;
    }

    *getTextFromPrivateUrl({ url }) {
        const response = yield BlobRepository.getTextFromPrivateUrl({ url });
        return response.data.text;
    }
}
