import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReplyIndicator } from "./ReplyIndicator";

export const DiscussionBox = ({ discussion }) => {
    const { description, problemName, isReplied, createdAt, linkUrl, title } =
        discussion;

    const navigate = useNavigate();

    const redirectToDiscussionPage = () => {
        navigate(linkUrl);
    };

    return (
        <Stack
            spacing={1.25}
            padding={2.5}
            sx={{
                bgcolor: "background.paper",
                borderRadius: 2.5,
                cursor: "pointer",
            }}
            onClick={redirectToDiscussionPage}
        >
            <Typography variant={"body1"} fontWeight={700} children={title} />
            <Box height={68} mb={1.25}>
                <Typography
                    whiteSpace="pre-wrap"
                    variant="body1"
                    children={description}
                    noWrap
                    height="100%"
                />
            </Box>
            <Divider />
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop={1.25}
                alignItems="center"
            >
                <Box display="flex" flexDirection="row">
                    <Typography
                        variant="caption"
                        sx={{
                            color: "#6D8A9F",
                            fontWeight: 700,
                            marginRight: 1.25,
                        }}
                        children={problemName}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            color: "#9CA8B9",
                            fontWeight: 700,
                        }}
                        children={moment(createdAt).locale("ko").format("LLL")}
                    />
                </Box>
                <ReplyIndicator isReplied={isReplied} />
            </Box>
        </Stack>
    );
};
