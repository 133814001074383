import React from "react";
import MDEditor from "@uiw/react-md-editor";
import style from "./styles.module.css";
import hintStyle from "./hint.styles.module.css";

import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

import "katex/dist/katex.min.css";
import { Box } from "@mui/material";

export const Markdown = ({ children, colorMode = "light", ...props }) => {
    return (
        <Box data-color-mode={"light"}>
            <div
                className={
                    colorMode === "hint"
                        ? hintStyle["wmde-markdown-var"]
                        : style["wmde-markdown-var"]
                }
            />
            <MDEditor.Markdown
                source={children}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
                {...props}
            />
        </Box>
    );
};
