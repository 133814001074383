import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Slide, Stack } from "@mui/material";
import { useStores } from "../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../NoResultsTemplate";
import { DiscussionsTemplate } from "./DiscussionsTemplate";
import { DiscussionsHeader } from "./templates/DiscussionsHeader";
import { DiscussionForm } from "./templates/DiscussionForm";
import { DiscussionDAOModel } from "../../../../../../common/modules/discussions/DiscussionModel";
import { snackActions } from "../../../../../../utils/SnackbarUtilsConfigurator";
import {
    ProblemClickDAOModel,
    ProblemClickEvent,
} from "../../../../../../common/modules/problems/ProblemModel";

export const DiscussionsContainer = observer(({ problem }) => {
    const { discussionStore, problemStore } = useStores();
    const { discussions, isFetching, count } = discussionStore;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        const body = new ProblemClickDAOModel({
            event: ProblemClickEvent.Introduction,
            problem: problem.id,
        });
        problemStore.addClickEvent({ body });
        setPage(0);
    }, [problem]);

    useEffect(() => {
        discussionStore.findAllByProblem({
            problemId: problem.id,
            page: page + 1,
            pageSize: rowsPerPage,
        });
    }, [problem, page, rowsPerPage]);

    const handleSubmit = (inputs) => {
        const body = new DiscussionDAOModel({
            ...inputs,
            linkUrl: window.location.pathname,
        });
        discussionStore.add({ body, problemId: problem.id }).then(() => {
            snackActions.success("정상적으로 등록되었습니다.");
        });
    };

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="토론을 불러오는 중입니다."
            />
        );

    return (
        <Stack direction="column">
            <DiscussionsHeader
                problemName={problem.name}
                handleOpen={toggleOpen}
            />
            {discussions && discussions.length ? (
                <DiscussionsTemplate
                    discussions={discussions}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            ) : (
                <NoResultsTemplate text="아직 올라온 토론이 존재하지 않습니다." />
            )}
            <Slide in={open} direction="up">
                <DiscussionForm
                    handleCancel={toggleOpen}
                    handleSubmit={handleSubmit}
                />
            </Slide>
        </Stack>
    );
});
