import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { EditorTemplate } from "./EditorTemplate";
import { useStores } from "../../../../../../common/modules/useStores";
import { DarkTheme, DefaultTheme } from "../../../../../../style/theme";
import { UserProfileDAOModel } from "../../../../../../common/modules/auth/UserModel";
import {
    McqSubmissionDAOModel,
    SubmissionDAOModel,
    SubmissionStatusType,
} from "../../../../../../common/modules/submission/SubmissionModel";
import { snackActions } from "../../../../../../utils/SnackbarUtilsConfigurator";
import { arrayToText } from "../../../../../../utils/string";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import moment from "moment";
import { errorToast } from "../../../../../../utils/errorToast";

export const EditorContainer = observer(
    ({ problem, objectId, submitUntil, aiEnabled }) => {
        const {
            globalStateStore,
            authStore,
            submissionStore,
            problemStore,
            tutorialStore,
        } = useStores();
        const { setMonacoTheme, monacoTheme } = globalStateStore;
        const { currUser } = authStore;
        const { send, executeSend, isExecuting, result } = submissionStore;
        const { descriptionModel } = problemStore;
        const { currTutorial, setTutorial, tutorialList } = tutorialStore;

        const navigate = useNavigate();

        const isLightTheme = monacoTheme === "light";

        const checkPermanentDisabled = () => moment().isAfter(submitUntil);

        const [disabled, setDisabled] = useState(checkPermanentDisabled());
        const [mcqResult, setMcqResult] = useState(false);
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);

        const toggleOpen = () => {
            setOpen((prev) => !prev);
        };

        const handleModeChange = () => {
            setMonacoTheme(isLightTheme ? "monokai" : "light");
        };

        const handleLanguageAPIChange = _.debounce((language) => {
            const body = new UserProfileDAOModel({ language });
            authStore.updateProfile({ body });
        }, 1000);

        const handleSubmit = ({ code, language }) => {
            if (checkPermanentDisabled()) {
                setDisabled(true);
                snackActions.info("기한이 지나 제출할 수 없습니다.");
                return;
            }
            const body = new SubmissionDAOModel({
                code,
                language,
                contentTypeName: "mission",
                objectId,
                problemId: problem.id,
            });

            submissionStore.setPage(0);
            navigate("submissions");

            send({ body });
            setDisabled(true);
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
        };

        const handleMcqSubmit = ({ selections }) => {
            if (checkPermanentDisabled()) {
                setDisabled(true);
                snackActions.info("기한이 지나 제출할 수 없습니다.");
                return;
            }
            const body = new McqSubmissionDAOModel({
                selections: arrayToText(selections),
                contentTypeName: "mission",
                objectId,
            });
            setLoading(true);
            problemStore
                .mcqSubmission({
                    problemId: problem.id,

                    body,
                })
                .then((data) => {
                    setMcqResult(data.result);
                    toggleOpen();
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const handleExecute = ({ code, language, userInput }) => {
            const body = new SubmissionDAOModel({
                code,
                language,
                contentTypeName: "mission",
                objectId,
                problemId: problem.id,
                userInput,
            });

            executeSend({ body });

            snackActions.success("정상적으로 제출되었습니다.");
        };

        const sendChat = (userChats) => {
            return new Promise((resolve, reject) =>
                problemStore
                    .sendChat({
                        problemId: problem.id,
                        body: {
                            message: userChats,
                        },
                    })
                    .then((data) => {
                        resolve(data.message);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            );
        };

        const [isGPTSummaryExecuting, setIsGPTSummaryExecuting] =
            useState(false);
        const [gptSummaryMessage, setGPTSummaryMessage] = useState(undefined);

        useEffect(() => {
            if (!result || !aiEnabled) return;

            const status = result?.status;
            const isError =
                status === SubmissionStatusType.RuntimeError ||
                status === SubmissionStatusType.CompileError;
            if (!isError) return;

            setIsGPTSummaryExecuting(true);
            submissionStore
                .errorSummary({
                    submissionId: result.submissionId,
                })
                .then((data) => {
                    setIsGPTSummaryExecuting(false);
                    setGPTSummaryMessage(data.message);
                })
                .catch((e) => {
                    errorToast(e);
                });
        }, [result?.submissionId, aiEnabled]);

        return (
            <ThemeProvider theme={isLightTheme ? DefaultTheme : DarkTheme}>
                <LoadingOverlay
                    active={loading}
                    sx={{
                        height: "100%",
                        width: "auto",
                        display: "flex",
                        flex: {
                            md: "10 1 0%",
                            xs: "0 0 100%",
                        },
                        overflow: "hidden",
                        color: "#FFFFFF",
                    }}
                >
                    <EditorTemplate
                        currTutorial={currTutorial}
                        setTutorial={setTutorial}
                        handleModeChange={handleModeChange}
                        monacoTheme={monacoTheme}
                        handleLanguageAPIChange={handleLanguageAPIChange}
                        defaultLanguage={currUser.language}
                        problem={problem}
                        handleSubmit={handleSubmit}
                        handleExecute={handleExecute}
                        isExecuting={isExecuting}
                        result={result}
                        disabled={disabled}
                        submitUntil={submitUntil}
                        testCase={
                            descriptionModel.testCases.length &&
                            descriptionModel.testCases[0]
                        }
                        mcqResult={mcqResult}
                        open={open}
                        toggleOpen={toggleOpen}
                        handleMcqSubmit={handleMcqSubmit}
                        loading={loading}
                        tutorialList={tutorialList}
                        sendChat={sendChat}
                        isGPTSummaryExecuting={isGPTSummaryExecuting}
                        gptSummaryMessage={gptSummaryMessage}
                        aiEnabled={aiEnabled}
                    />
                </LoadingOverlay>
            </ThemeProvider>
        );
    }
);
