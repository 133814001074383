import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../common/modules/useStores";
import { ClassroomDashboardDetailTemplate } from "./ClassroomDashboardDetailTemplate";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";

export const ClassroomDashboardDetailContainer = observer(() => {
    const { missionStore, curatedProblemStore } = useStores();
    const { missionId, problemId, userId } = useParams();

    const { missions } = missionStore;
    const { curatedProblem } = curatedProblemStore;

    useEffect(() => {
        if (!missions.length) {
            missionStore.findAll();
        }
    }, []);

    if (!(missionId && missions.length)) return <LoadingOverlay active />;

    return (
        <ClassroomDashboardDetailTemplate
            missions={missions}
            missionId={missionId}
            problemId={problemId}
            userId={userId}
            problem={curatedProblem}
        />
    );
});
