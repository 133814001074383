export const baseThemeOption = {
    typography: {
        fontFamily: "Spoqa Han Sans Neo, Roboto, Oxygen",
        display: {
            fontSize: 42,
            lineHeight: 1.25,
            letterSpacing: -0.6,
            "@media (max-width:991px)": {
                fontSize: 36,
            },
        },
        h1: {
            fontSize: 32,
            lineHeight: 1.25,
            letterSpacing: -0.6,
            "@media (max-width:991px)": {
                fontSize: 25,
            },
        },
        h2: {
            fontSize: 28,
            lineHeight: 1.5,
            letterSpacing: -0.2,
            "@media (max-width:991px)": {
                fontSize: 24,
            },
        },
        h3: {
            fontSize: 24,
            lineHeight: 1.25,
            letterSpacing: -0.6,
            "@media (max-width:991px)": {
                fontSize: 19,
            },
        },
        h4: {
            fontSize: 20,
            lineHeight: 1.2,
            letterSpacing: -0.6,
            "@media (max-width:991px)": {
                fontSize: 16,
            },
        },
        subtitle1: {
            fontSize: 18,
            lineHeight: 1.33,
            letterSpacing: -0.2,
            "@media (max-width:991px)": {
                fontSize: 14,
            },
        },
        subtitle2: {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: -0.2,
            "@media (max-width:991px)": {
                fontSize: 13,
            },
        },
        body1: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 1.5,
            "@media (max-width:991px)": {
                fontSize: 13,
            },
        },
        body2: {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 1.5,
            "@media (max-width:991px)": {
                fontSize: 12,
            },
        },
        caption: {
            fontSize: 12,
            lineHeight: 1.33,
            letterSpacing: -0.2,
            "@media (max-width:991px)": {
                fontSize: 10,
            },
        },
        overline: {
            fontSize: 10,
            lineHeight: 1.2,
            letterSpacing: -0.2,
            "@media (max-width:991px)": {
                fontSize: 8,
            },
        },
    },
    shadows: [
        "none",
        "0px 4px 30px rgba(0, 0, 0, 0.08)",
        "0px 0px 30px rgba(0, 0, 0, 0.08)",
        "4px 4px 30px rgba(0, 0, 0, 0.18)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "2px 4px 16px rgba(0, 0, 0, 0.08)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
        "0px 0px 20px rgba(33, 33, 33, 0.05)",
    ],
};
