import React from "react";
import { Stack, Typography } from "@mui/material";

export const Copyright = () => (
    <Stack direction="column">
        <Typography variant="body2">
            저작권자 © 브랜치앤바운드
            <br />
            <br />
            코드트리 사이트의 모든 교육자료는 저작권법의 보호를 받습니다.
            작성자의 동의 없는 무단 전재/복사/배포 등을 금지합니다.
        </Typography>
    </Stack>
);
