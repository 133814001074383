import React from "react";
import { Box, Stack } from "@mui/material";
import { SubmissionsContainer } from "../templates/Submission/SubmissionsContainer";
import { HomeworkDashboardDetailHeader } from "./templates/HomeworkDashboardDetailHeader";

export const HomeworkDashboardDetailTemplate = ({
    userId,
    homeworkId,
    homeworks,
    problemId,
    problem,
}) => (
    <Stack direction="column" height="100%">
        <HomeworkDashboardDetailHeader
            userId={userId}
            homeworkId={homeworkId}
            homeworks={homeworks}
            problemId={problemId}
            problem={problem}
        />
        <Box p={2.5} flexGrow={1} overflow="hidden">
            <SubmissionsContainer
                contentTypeName="mission"
                objectId={problemId}
                userId={userId}
            />
        </Box>
    </Stack>
);
