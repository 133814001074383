import Cookies from "js-cookie";

const CT_TOKEN_KEY = "CODETREE_OOE_TOKEN";
const CT_REFRESH_TOKEN_KEY = "CODETREE_OOE_REFRESH_TOKEN";

export const getCookie = (key) => Cookies.get(key);

export const removeCookie = (key) => Cookies.remove(key);

export const setCookie = (key, value, config) =>
    Cookies.set(key, value, config);

export const getToken = () => getCookie(CT_TOKEN_KEY);

export const getRefreshToken = () => {
    return getCookie(CT_REFRESH_TOKEN_KEY);
};

export const removeToken = () => removeCookie(CT_TOKEN_KEY);

export const removeRefreshToken = () => {
    return removeCookie(CT_REFRESH_TOKEN_KEY);
};

export const setToken = (value) =>
    setCookie(CT_TOKEN_KEY, value, { expires: new Date(2038, 1, 1) });

export const setRefreshToken = (value) => {
    return setCookie(CT_REFRESH_TOKEN_KEY, value, {
        expires: new Date(2038, 1, 1),
    });
};
