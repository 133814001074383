import React, { useState } from "react";
import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import { useNavigate } from "react-router-dom";
import {
    blankStudent,
    StudentFormsKey,
} from "../../../../../common/modules/classroom/ClassroomModel";
import { MiddleButton } from "../../../../../common/components/button/MiddleButton";
import { SearchField } from "../../../../../common/components/textField/SearchField";

export const Header = () => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    // const handleOpen = (e) => {
    //     setAnchorEl(e.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToExcelPage = () => {
        navigate("excel");
    };

    const redirectToAddPage = () => {
        localStorage.setItem(
            StudentFormsKey,
            JSON.stringify([blankStudent(0)])
        );
        navigate("add");
    };

    return (
        <Box p={2.5} boxShadow={1}>
            <Stack spacing={1.25} direction="row">
                <SearchField />
                <MiddleButton
                    color="#FFFFFF"
                    label="검색"
                    backgroundColor="#000000"
                    endIcon={<SearchIcon fontSize="small" />}
                />
                {/*<MiddleButton*/}
                {/*    color="#FFFFFF"*/}
                {/*    onClick={handleOpen}*/}
                {/*    label="학생 추가"*/}
                {/*    backgroundColor="#FF5555"*/}
                {/*    endIcon={<AddCircleIcon fontSize="small" />}*/}
                {/*/>*/}
                {/*<MiddleButton*/}
                {/*    color="#FFFFFF"*/}
                {/*    label="엑셀 양식 다운"*/}
                {/*    backgroundColor="#016F39"*/}
                {/*    endIcon={<DownloadIcon fontSize="small" />}*/}
                {/*    onClick={() => downloadFileFromUrl(ExcelUrl)}*/}
                {/*/>*/}
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiMenu-paper": {
                        width: 200,
                    },
                }}
            >
                <MenuItem
                    sx={{
                        p: 2,
                    }}
                    onClick={redirectToAddPage}
                >
                    <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            variant="body2"
                            children="수기로 추가하기"
                        />
                        <EditIcon />
                    </Stack>
                </MenuItem>
                <MenuItem
                    sx={{
                        p: 2,
                    }}
                    onClick={redirectToExcelPage}
                >
                    <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            variant="body2"
                            children="엑셀로 추가하기"
                        />
                        <UploadIcon />
                    </Stack>
                </MenuItem>
            </Menu>
        </Box>
    );
};
