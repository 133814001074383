import { GET, PATCH } from "../../../utils/api";

class NotificationRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll() {
        return GET(`notifications/?page_size=10`);
    }

    findUnseenCount() {
        return GET(`unseen-notifications/`);
    }

    readNotification({ notificationId }) {
        return PATCH(`read-notification/${notificationId}`);
    }
}

export default new NotificationRepository();
