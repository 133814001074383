import React from "react";
import { Box } from "@mui/material";
import { SizeType } from "../../modules/ui/UIModel";

export const OpenBook = ({ size, color, sx }) => {
    const sizeDict = {
        [SizeType.Large]: {
            width: 273,
            height: 182,
        },
        [SizeType.Medium]: {
            width: 182,
            height: 121,
        },
        [SizeType.Small]: {
            width: 121,
            height: 80,
        },
    };

    const getBookImg = (_size, _color) =>
        "https://contents.codetree.ai/" +
        `homepage/images/templates/books/${_size}_open_${_color}.png`;

    const { width, height } = sizeDict[size];

    return (
        <Box
            width={width}
            height={height}
            overflow="visible"
            position="relative"
            sx={{
                userSelect: "none",
                userDrag: "none",
                ...sx,
            }}
        >
            <img
                src={getBookImg(size, color)}
                style={{
                    position: "absolute",
                    left: -1 * width,
                    top: -100,
                }}
            />
        </Box>
    );
};
