import React from "react";
import { Box, Stack } from "@mui/material";
import { CommentEditor } from "../CommentEditor";
import { Reply } from "./Reply";
import { useStores } from "../../../../../../../../../../common/modules/useStores";
import { ReplyDAOModel } from "../../../../../../../../../../common/modules/discussions/DiscussionModel";
import { snackActions } from "../../../../../../../../../../utils/SnackbarUtilsConfigurator";

export const RepliesBox = ({ replies, commentId }) => {
    const { discussionStore } = useStores();

    const handleSubmit = (inputs) => {
        const body = new ReplyDAOModel(inputs);
        discussionStore.addReply({ body, commentId }).then(() => {
            snackActions.success("정상적으로 등록되었습니다.");
        });
    };

    const handleEdit = (inputs, replyId) => {
        const body = new ReplyDAOModel(inputs);
        discussionStore.updateReply({ body, replyId, commentId }).then(() => {
            snackActions.success("정상적으로 수정되었습니다.");
        });
    };

    return (
        <Stack direction="column" spacing={1} backgroundColor="#F6F8FA">
            {replies.length ? (
                <Box p={2}>
                    {replies.map((reply) => (
                        <Reply
                            key={`reply-${reply.id}`}
                            reply={reply}
                            handleEdit={handleEdit}
                        />
                    ))}
                </Box>
            ) : null}
            <Box px={2} py={2}>
                <CommentEditor onSubmit={handleSubmit} />
            </Box>
        </Stack>
    );
};
