import React from "react";
import { Box } from "@mui/material";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";
import { CatalogList } from "./CatalogList";

export const CatalogListBox = ({ missions }) => (
    <Box overflow="hidden">
        <MainTemplateWrapper
            sx={{
                py: 12.5,
            }}
        >
            <CatalogList missions={missions} />
        </MainTemplateWrapper>
    </Box>
);
