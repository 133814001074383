import React from "react";
import { AdminDataGrid } from "../components/AdminDataGrid";
import { SmallButton } from "../../../../../common/components/button/SmallButton";

export const StudentGrid = ({
    students,
    count,
    rowsPerPage,
    page,
    selectionModel,
    setSelectionModel,
    handleApprove,
    isQueue,
}) => {
    const handleClick = (e, studentId) => {
        e.preventDefault();
        e.stopPropagation();
        handleApprove([studentId]);
    };

    const columns = [
        {
            field: "username",
            headerName: "아이디",
            width: 180,
            sortable: false,
        },
        {
            field: "name",
            headerName: "이름",
            width: 180,
            sortable: false,
        },
        {
            field: "phoneNumber",
            headerName: "휴대폰 번호",
            width: 180,
            sortable: false,
        },
        {
            field: "editButton",
            flex: 1,
            headerName: "",
            type: "number",
            sortable: false,
            renderCell: (params) => {
                if (isQueue) {
                    return (
                        <SmallButton
                            backgroundColor="#FFFFFF00"
                            color="#808080"
                            label="참가 신청 승인"
                            variant="outlined"
                            sx={{
                                borderColor: "#808080",
                            }}
                            onClick={(e) => handleClick(e, params.id)}
                        />
                    );
                }

                return null;
            },
        },
    ];

    return (
        <AdminDataGrid
            columns={columns}
            rowsPerPageOptions={[5, 10]}
            checkboxSelection
            rows={students}
            page={page}
            pageSize={rowsPerPage}
            rowCount={count}
            getRowClassName={(params) =>
                `data-grid-row--${params.row.order % 2 ? "odd" : "even"}`
            }
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
            }}
            disableColumnFilter
            componentsProps={{
                baseCheckbox: {
                    sx: {
                        color: "#D24D57",
                        "&.Mui-checked": {
                            color: "#D24D57",
                        },
                    },
                },
            }}
        />
    );
};
