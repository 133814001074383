import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { HomeworkBox } from "./templates/HomeworkBox";
import { HomeworkEditHeader } from "./templates/HomeworkEditHeader";
import { HomeworkMarketContainer } from "./HomeworkMarketContainer";

export const HomeworkEditTemplate = ({
    homeworks,
    handleDelete,
    handleHidden,
    setSearchWord,
    searchHomeworks,
    searchWord,
}) => (
    <Stack direction="column" height="100%">
        {homeworks.length ? (
            <>
                <HomeworkEditHeader
                    searchHomeworks={searchHomeworks}
                    searchWord={searchWord}
                    setSearchWord={setSearchWord}
                />
                <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                    <Container maxWidth="md">
                        <Stack spacing={5} py={3}>
                            {homeworks.map((homework) => (
                                <HomeworkBox
                                    homework={homework}
                                    key={`homework-box-${homework.id}`}
                                    handleDelete={handleDelete}
                                    handleHidden={handleHidden}
                                />
                            ))}
                        </Stack>
                    </Container>
                </Box>
            </>
        ) : (
            <HomeworkMarketContainer />
            // <NoResultsTemplate text="수업/과제가 아직 없네요. 오른쪽 위 '수업/과제 추가' 버튼을 눌러 만들어보세요!" />
        )}
    </Stack>
);
