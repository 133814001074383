import React from "react";
import { alpha, Stack, Typography } from "@mui/material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { MiddleButton } from "../../../../common/components/button/MiddleButton";
import { useNavigate } from "react-router-dom";

export const PreviewBanner = ({ fiddle }) => {
    if (!fiddle || fiddle.isPublished) return null;

    const navigate = useNavigate();

    const navigateToBefore = () => {
        if (fiddle.isEdit) {
            navigate("/psfiddle/list");
            return;
        }

        navigate("/psfiddle/new");
    };

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            sx={{
                position: "absolute",
                bottom: 5,
                left: "calc(50% - 172px)",
                zIndex: 1200,
                px: 1.25,
                py: 0.5,
                backgroundColor: "#E5F6FD",
                borderRadius: 1.5,
            }}
            spacing={1.25}
        >
            <PlagiarismOutlinedIcon
                sx={{
                    color: "#03A9F4",
                }}
            />
            <Typography children={"미리보기 화면입니다."} />
            <MiddleButton
                onClick={navigateToBefore}
                label={"편집기로 돌아가기"}
                color={"#014361"}
                backgroundColor={alpha("#014361", 0.08)}
                typographySx={{
                    fontWeight: 700,
                }}
            />
        </Stack>
    );
};
