import React from "react";
import { Stack } from "@mui/material";
import { SubmissionStatusType } from "../../../../../../../common/modules/submission/SubmissionModel";
import { ExecuteResultGrid } from "./ExecuteResultGrid";
import { ExecuteResultTextField } from "./ExecuteResultTextField";
import { ExecuteResultHeader } from "./ExecuteResultHeader";
import { FriendlyOneLineSummary } from "./FriendlyOneLineSummary";

export const ExecuteResultBox = ({
    result,
    onClose,
    isGPTSummaryExecuting,
    gptSummaryMessage,
    aiEnabled,
}) => {
    const { status, compileStderr, executeStderr } = result;

    const hasRuntime =
        status === SubmissionStatusType.Passed ||
        status === SubmissionStatusType.WrongAnswer;

    const isError =
        status === SubmissionStatusType.RuntimeError ||
        status === SubmissionStatusType.CompileError;

    const getMessage = (_status, _compileStderr, _executeStderr) => {
        if (_status === SubmissionStatusType.CompileError) {
            return _compileStderr;
        }

        if (_status === SubmissionStatusType.InternalError) {
            return `Internal Error의 경우 다음과 같은 이유로 발생할 수 있습니다.

1. 제출한 테스트 케이스의 형식을 확인해주세요. 문제에서 제시한 input 조건과 맞지 않을 경우 채점기가 제대로 작동하지 못합니다.

2. 테스트 케이스 형식의 문제가 아님에도 문제가 지속될 경우 토론 탭에 입력하신 테스트 케이스를 첨부하여 질문을 남겨주세요.`;
        }

        return _executeStderr;
    };

    return (
        <Stack direction="column" overflow="hidden" height="100%">
            <ExecuteResultHeader result={result} onClose={onClose} />
            {hasRuntime ? (
                <ExecuteResultGrid result={result} />
            ) : (
                <Stack direction={"column"} overflow={"hidden"} height={"100%"}>
                    {aiEnabled && isError ? (
                        <FriendlyOneLineSummary
                            isExecuting={isGPTSummaryExecuting}
                            message={gptSummaryMessage}
                        />
                    ) : null}

                    <ExecuteResultTextField
                        readOnly
                        value={getMessage(status, compileStderr, executeStderr)}
                    />
                </Stack>
            )}
        </Stack>
    );
};
