import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ComponentDialog } from "../../components/dialog/ComponentDialog";

export const AutoJudgeDialog = ({ open, onClose }) => {
    const Contents = [
        {
            title: "클라우드 코딩",
            icon: <CloudQueueIcon />,
            description:
                "설치 없이 네트워크만 있으면 어느 기기에서든 바로 코딩 할 수 있습니다.",
        },
        {
            title: "바로 실행",
            icon: <PlayCircleOutlineIcon />,
            description:
                "작성한 코드를 즉석에서 실행해보고 테스트케이스도 바꿔볼 수 있습니다.",
        },
        {
            title: "자동 채점",
            icon: <CheckIcon />,
            description:
                "완성된 코드를 제출하면 세계 대회 수준의 안정적인 자동 채점을 통해 평가받을 수 있습니다.",
        },
    ];

    return (
        <ComponentDialog
            open={open}
            onClose={onClose}
            title="설치 없는 코드 실습 환경까지 하나로."
            description="편리한 코드 편집기와 세계적인 대회 수준의 자동 채점 환경으로 학습에만 몰입해보세요."
        >
            <Stack direction="column" spacing={1.25}>
                <Grid
                    container
                    direction="row"
                    spacing={2.5}
                    mt={5}
                    flexWrap="wrap"
                    justifyContent="center"
                >
                    {Contents.map((content, i) => (
                        <Grid key={`auto-judge-content-${i}`} item>
                            <Stack
                                direction="column"
                                alignItems="center"
                                spacing={2.5}
                                sx={{
                                    width: 230,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={1.25}
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="body2"
                                        fontWeight={700}
                                        children={content.title}
                                    />
                                    {content.icon}
                                </Stack>
                                <Typography
                                    variant="body2"
                                    children={content.description}
                                    sx={{
                                        p: 1.25,
                                    }}
                                />
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction="column">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            px: 2.5,
                            py: 1.25,
                            borderRadius: "8px 8px 0px 0px",
                            borderBottom: "1px solid #41444A",
                            color: "#FFFFFF",
                            background:
                                "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #23241F",
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1.5,
                                py: 0.75,
                                background: "#000000",
                            }}
                        >
                            <Typography variant="caption" children="Python3" />
                            <ArrowDropDownIcon fontSize="small" />
                        </Stack>
                        <Typography
                            sx={{
                                background: "#000000",
                                px: 1.5,
                                py: 0.75,
                            }}
                            variant="caption"
                            children="코드 실행"
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        sx={{
                            background: "#23241F",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{
                                p: 2.5,
                            }}
                        >
                            <Typography
                                children={"1\n2\n3\n4"}
                                fontFamily="Menlo"
                                whiteSpace="pre-line"
                                variant="body2"
                                color="#959595"
                            />
                            <Stack direction="column">
                                <Stack direction="row">
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="name"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#D53400"
                                        children=" = "
                                        whiteSpace="pre"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="input()"
                                        fontFamily="Menlo"
                                    />
                                </Stack>
                                <Stack direction="row">
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="out"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#D53400"
                                        children=" = “"
                                        whiteSpace="pre"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="Hello"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#D53400"
                                        children=" ” + "
                                        whiteSpace="pre"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="name"
                                        fontFamily="Menlo"
                                    />
                                </Stack>
                                <Typography
                                    variant="body2"
                                    color="#FFFFFF"
                                    children={" "}
                                    whiteSpace="pre"
                                    fontFamily="Menlo"
                                />
                                <Stack direction="row">
                                    <Typography
                                        variant="body2"
                                        color="#6D40BD"
                                        children="print"
                                        fontFamily="Menlo"
                                    />
                                    <Typography
                                        variant="body2"
                                        color="#FFFFFF"
                                        children="(out)"
                                        fontFamily="Menlo"
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2.25}
                            sx={{
                                minWidth: 200,
                                maxWidth: 360,
                                flexGrow: 1,
                                p: 2.5,
                                pb: 5,
                                borderLeft: "1px solid #41444A",
                                borderTop: {
                                    xs: "1px solid #41444A",
                                    sm: "none",
                                },
                            }}
                        >
                            <Stack direction="column" spacing={1}>
                                <Typography
                                    color="#959595"
                                    children="테스트 케이스"
                                    variant="caption"
                                />
                                <Typography
                                    sx={{
                                        background:
                                            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #23241F",
                                        borderRadius: 1,
                                        border: "1px solid #37352D",
                                        p: 1.25,
                                    }}
                                    color="#FFFFFF"
                                    children="world"
                                />
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography
                                    color="#959595"
                                    children="정답"
                                    variant="caption"
                                />
                                <Typography
                                    sx={{
                                        background:
                                            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #23241F",
                                        borderRadius: 1,
                                        p: 1.25,
                                    }}
                                    color="#959595"
                                    children="Hello world"
                                />
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography
                                    color="#959595"
                                    children="실행 결과"
                                    variant="caption"
                                />
                                <Typography
                                    sx={{
                                        background:
                                            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #23241F",
                                        borderRadius: 1,
                                        p: 1.25,
                                    }}
                                    color="#959595"
                                    children="Hello world"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </ComponentDialog>
    );
};
