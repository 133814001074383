import { DELETE, GET, PATCH, POST } from "../../../utils/api";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";

class HomeworkRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`homeworks/${queryParams}`);
    }

    findAllSimple({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`homeworks_simple/${queryParams}`);
    }

    find({ homeworkId }) {
        return GET(`homeworks/${homeworkId}`);
    }

    add({ body }) {
        return POST("homeworks/", body);
    }

    remove({ homeworkId }) {
        return DELETE(`homeworks/${homeworkId}/`);
    }

    update({ homeworkId, body }) {
        return PATCH(`homeworks/${homeworkId}/`, body);
    }

    getTree({ body }) {
        return POST(`homework_tree/`, body);
    }

    toggleHidden({ homeworkId, body }) {
        return PATCH(`homeworks/${homeworkId}/toggle_hidden/`, body);
    }
}

export default new HomeworkRepository();
