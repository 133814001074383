import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { CurriculumTemplate } from "./CurriculumTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";

export const CurriculumContainer = observer(() => {
    const { missionId } = useParams();

    const { missionStore, conceptStore, authStore } = useStores();

    const { isUserLoggedIn, isUserPaid } = authStore;

    const { missions, mission } = missionStore;
    const { concepts } = conceptStore;

    useEffect(() => {
        missionStore.findAll();
        missionStore.findDetail({ missionId });
        conceptStore.findAll({ missionId });
    }, [missionId]);

    if (
        !(
            missions &&
            missions.length &&
            mission &&
            mission.id === Number(missionId) &&
            concepts &&
            concepts.length &&
            concepts[0].missionId === Number(missionId)
        )
    ) {
        return <LoadingOverlay active />;
    }

    return (
        <CurriculumTemplate
            missions={missions}
            mission={mission}
            concepts={concepts}
            isUserLoggedIn={isUserLoggedIn}
            isUserPaid={isUserPaid}
        />
    );
});
