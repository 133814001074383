import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import { ClassroomRow } from "./templates/ClassroomRow";
import { observer } from "mobx-react-lite";
import { SchoolClassroomAccordion } from "../../../common/templates/SchoolClassroomAccordion";

export const ClassroomsTemplate = observer(
    ({
        targetSchool,
        handleRequest,
        loading,
        handleSelect,
        selectedClassroom,
        encodeInvitation,
    }) => {
        return (
            <Container maxWidth="lg">
                <Stack direction={"row"} alignItems={"flex-start"} py={5}>
                    <Stack>
                        <SchoolClassroomAccordion
                            targetSchool={targetSchool}
                            handleSelect={handleSelect}
                            selectedClassroom={selectedClassroom}
                        />
                    </Stack>
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexGrow={1}
                        sx={{ position: "sticky", top: 94, zIndex: 100 }}
                    >
                        {selectedClassroom ? (
                            <ClassroomRow
                                classroom={selectedClassroom}
                                handleRequest={handleRequest}
                                encodeInvitation={encodeInvitation}
                            />
                        ) : (
                            <Stack alignItems={"center"} spacing={2}>
                                <Stack
                                    px={16 / 8}
                                    py={8 / 8}
                                    borderRadius={8 / 8}
                                    flexDirection={"row"}
                                    gap={1}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                                        position: "relative",
                                        "&:before": {
                                            content: `""`,
                                            position: "absolute",
                                            bottom: -2,
                                            left: "50%",
                                            transform: "translate(-50%, 100%)",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            borderStyle: "solid",
                                            borderWidth: "10px 10px 0 10px",
                                            borderColor:
                                                "rgba(0, 123, 255, .5) transparent transparent transparent",
                                        },
                                        "&:after": {
                                            content: `""`,
                                            position: "absolute",
                                            bottom: 1,
                                            left: "50%",
                                            transform: "translate(-50%, 100%)",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            borderStyle: "solid",
                                            borderWidth: "10px 10px 0 10px",
                                            borderColor:
                                                "#ffffff transparent transparent transparent",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        color={"#264348"}
                                    >
                                        {loading
                                            ? "학교/학급 목록을 가져오는 중입니다."
                                            : "왼쪽에서 학급을 선택해주세요."}
                                    </Typography>
                                </Stack>

                                <img
                                    width={80}
                                    height={80}
                                    src={
                                        "https://contents.codetree.ai/ooe/about/teacher.png"
                                    }
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Container>
        );
    }
);
