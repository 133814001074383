import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ChannelService from "../../../common/components/channel/ChannelService";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { MySubmissionContainer } from "./MySubmission/MySubmissionContainer";
import { LoginPage } from "./Login/LoginPage";
import { LoginWithMsTemplate } from "./Login/LoginWithMsTemplate";

export const AccountsPage = () => {
    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    return (
        <MainTemplateWrapper
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Routes>
                <Route path={"login/*"} element={<LoginPage />} />
                <Route
                    path={"loginWithMs/*"}
                    element={<LoginWithMsTemplate />}
                />
                <Route
                    path="my-submission"
                    element={<MySubmissionContainer />}
                />
            </Routes>
        </MainTemplateWrapper>
    );
};
