import React, { useEffect, useRef } from "react";
import * as monaco from "monaco-editor";

export const StandaloneEditor = ({ value, options, language }) => {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);

    useEffect(() => {
        const editor = monaco.editor.create(editorContainerRef.current, {
            value,
            language,
            automaticLayout: true,
        });
        editor.updateOptions(options);

        import("monaco-themes/themes/Monokai.json").then((data) => {
            monaco.editor.defineTheme("monokai", data);
        });

        editorRef.current = editor;
        return () => {
            editor.dispose();
        };
    }, []);

    useEffect(() => {
        editorRef.current.setValue(value);
    }, [value]);

    useEffect(() => {
        const model = editorRef.current.getModel();
        monaco.editor.setModelLanguage(model, language);
    }, [language]);

    return (
        <div
            ref={editorContainerRef}
            style={{
                display: "flex",
                position: "relative",
                textAlign: "initial",
                width: "100%",
                height: value.split("\n").length * 21 + 30,
            }}
        />
    );
};
