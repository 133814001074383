export const darkThemeOption = {
    palette: {
        mode: "dark",
        primary: {
            main: "#00A71B",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#FFFFFF",
            contrastText: "#252525",
        },
        background: {
            default: "#1D1D1B",
        },
        common: {
            white: "#000000",
            black: "#FFFFFF",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#959595",
        },
    },
};
