import { snakelizeKeys } from "../../../../utils/renameKeys";
import { objectToQueryString } from "../../../../utils/immutableLodash";
import { GET, downloadGET } from "../../../../utils/api";

class UserHomeworkProgressRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`homework_progresses/${queryParams}`);
    }

    download({ ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return downloadGET(`homework_progresses/download/${queryParams}`);
    }
}

export default new UserHomeworkProgressRepository();
