import React, { useEffect, useState } from "react";
import { PSFiddleEditForm } from "./PSFiddleEditForm";
import { setIn } from "../../../utils/immutableLodash";
import { useStores } from "../../../common/modules/useStores";
import {
    FiddleDAOModel,
    TestCaseDAOModel,
} from "../../../common/modules/fiddles/FiddleModel";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { useNavigate } from "react-router-dom";

export const PSFiddleEditContainer = ({ fiddle, finishEdit }) => {
    const LocalFiddleKey = `codetree-fiddle-${fiddle.id}`;

    const [inputs, setInputs] = useState(fiddle);
    const navigate = useNavigate();

    const finishEditWrapper = () => {
        finishEdit();
        localStorage.removeItem(LocalFiddleKey);
    };

    useEffect(() => {
        const localFiddle = localStorage.getItem(LocalFiddleKey);
        if (localFiddle) {
            setInputs(JSON.parse(localFiddle));
        }
    }, []);

    const { fiddleStore } = useStores();

    const handleChange = (name, value) => {
        const newInputs = setIn(name, value, inputs);
        setInputs(newInputs);
        localStorage.setItem(LocalFiddleKey, JSON.stringify(newInputs));
    };

    const saturateInputs = () => {
        return setIn(
            "testCases",
            inputs.testCases.map(
                (testCase, i) =>
                    new TestCaseDAOModel({ ...testCase, order: i + 1 })
            ),
            inputs
        );
    };

    const handleSave = () => {
        const newInputs = saturateInputs();

        const body = new FiddleDAOModel({ ...newInputs, isPublished: true });

        fiddleStore
            .update({
                fiddleId: fiddle.id,
                fiddleKey: fiddle.key,
                body,
            })
            .then(() => {
                finishEditWrapper();
                snackActions.success("정상적으로 수정되었습니다.");
            });
    };

    const handlePreview = () => {
        const newInputs = saturateInputs();

        const body = new FiddleDAOModel({ ...newInputs, isPublished: false });

        fiddleStore
            .update({
                fiddleId: fiddle.id,
                fiddleKey: fiddle.key,
                body,
            })
            .then((fiddle) => {
                navigate(`/psfiddle/${fiddle.key}/${fiddle.id}`);
            });
    };

    const handleReset = () => {
        setInputs(fiddle);
        finishEditWrapper();
    };

    return (
        <PSFiddleEditForm
            inputs={inputs}
            handleChange={handleChange}
            handleSave={handleSave}
            handlePreview={handlePreview}
            handleReset={handleReset}
            isEdit
        />
    );
};
