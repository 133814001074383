import React from "react";
import { Stack, Typography } from "@mui/material";
import { MissionSelector } from "../../../../common/templates/MissionSelector";
import { NavigateProblemNumberBox } from "./NavigateProblemNumberBox";

export const MissionHeader = ({
    missions,
    missionId,
    setMissionId,
    totalProblemCount,
    passedProblemCount,
}) => {
    return (
        <Stack spacing={30 / 8}>
            <Stack
                direction={"row"}
                width={340}
                flexShrink={0}
                justifyContent={"space-between"}
            >
                <MissionSelector
                    missionId={missionId}
                    missions={missions}
                    setMissionId={setMissionId}
                />
                <Stack
                    direction="column"
                    justifyContent="space-around"
                    alignItems={"center"}
                    flex={1}
                >
                    <Stack direction="row" alignItems="flex-end">
                        <Typography
                            children={passedProblemCount}
                            fontWeight={700}
                            variant="h4"
                        />
                        <Typography
                            children={`/${totalProblemCount}`}
                            fontWeight={700}
                            variant="body2"
                            color="#9A9A9A"
                        />
                    </Stack>
                    <Typography
                        children="완료/전체 항목"
                        color="#808080"
                        variant="caption"
                    />
                </Stack>
            </Stack>

            <NavigateProblemNumberBox />
        </Stack>
    );
};
