import React from "react";
import { Stack } from "@mui/material";
import { MissionSelector } from "../../../../common/templates/MissionSelector";
import { MissionProgressBox } from "./templates/MissionProgressBox";
import { DataGridPagination } from "../../../../common/components/pagination/DataGridPagination";
import { SortMenu } from "../components/SortMenu";

export const ClassroomDashboardTemplate = ({
    missionProgresses,
    missions,
    missionId,
    setMissionId,
    sortOptions,
    sortOption,
    selectSortOption,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
}) => (
    <>
        <Stack
            direction="column"
            py={2.5}
            alignItems="flex-start"
            sx={{
                overflowX: "auto",
                flexGrow: 1,
            }}
        >
            <Stack ml={2.5} direction={"row"} spacing={1}>
                <SortMenu
                    sortOptions={sortOptions}
                    sortOption={sortOption}
                    selectSortOption={selectSortOption}
                />
                <MissionSelector
                    missions={missions}
                    missionId={missionId}
                    setMissionId={setMissionId}
                />
            </Stack>
            {missionProgresses.map((missionProgress, order) => (
                <MissionProgressBox
                    key={`mission-progress-${order}`}
                    missionProgress={missionProgress}
                    order={order}
                />
            ))}
        </Stack>
        <Stack
            direction="row"
            justifyContent="center"
            p={2.5}
            bgcolor="common.white"
        >
            <DataGridPagination
                page={page}
                count={count}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </Stack>
    </>
);
