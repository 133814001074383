import React from "react";
import { Stack, Typography } from "@mui/material";
import { MotherTongueType } from "../../../../../../../common/modules/auth/UserModel";
import { Markdown } from "../../../../../../../common/components/markdown/Markdown";

export const TestCasesBox = ({ testCases, motherTongue }) => {
    const isKorean = MotherTongueType.KOR === motherTongue;

    const codeWrapper = (text) => `\`\`\`\n${text}\n\`\`\``;

    return (
        <Stack direction="column" spacing={2}>
            <Typography
                variant="h3"
                fontWeight={700}
                children={isKorean ? "입출력 예제" : "Examples"}
            />
            {testCases.map((testCase, i) => (
                <Stack
                    key={`testCase-${testCase.id}`}
                    direction="column"
                    spacing={1.5}
                >
                    <Typography
                        variant="h4"
                        children={isKorean ? `예제${i + 1}` : `Example${i + 1}`}
                        fontWeight={700}
                    />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            variant="body1"
                            children={isKorean ? "입력:" : "Input:"}
                        />
                    </Stack>
                    <Markdown children={codeWrapper(testCase.stdin)} />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            variant="body1"
                            children={isKorean ? "출력:" : "Output:"}
                        />
                    </Stack>
                    <Markdown children={codeWrapper(testCase.stdout)} />
                </Stack>
            ))}
        </Stack>
    );
};
