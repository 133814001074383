import React, { useEffect, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { RepliesBox } from "./RepliesBox";
import { useStores } from "../../../../../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../../../../../common/templates/LoadingOverlay";
import { ReplyOpenButton } from "./templates/ReplyOpenButton";

export const Replies = ({ commentId, replyNum, replies }) => {
    const { discussionStore } = useStores();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen && replyNum && !replies.length) {
            discussionStore.findReplies({ commentId });
        }
    }, [commentId, isOpen]);

    const toggleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };

    if (!isOpen) {
        return (
            <ReplyOpenButton
                toggleIsOpen={toggleIsOpen}
                isOpen={isOpen}
                replyNum={replyNum}
            />
        );
    }

    const loading = replies.length !== replyNum;

    if (loading) {
        return (
            <Box height={200}>
                <LoadingOverlay active={loading} spinnerSize={40} />
            </Box>
        );
    }

    return (
        <Stack direction="column">
            <Divider mt={2} />
            <RepliesBox commentId={commentId} replies={replies} />
            <ReplyOpenButton
                toggleIsOpen={toggleIsOpen}
                isOpen={isOpen}
                replyNum={replyNum}
            />
        </Stack>
    );
};
