import {
    action,
    makeAutoObservable,
    makeObservable,
    observable,
    override,
    set,
} from "mobx";
import { pickCls } from "../../../../utils/immutableLodash";
import { camelizeKeys } from "../../../../utils/renameKeys";
import {
    CuratedProblemModel,
    LevelType,
    ProblemStatusType,
} from "../curatedProblem/CuratedProblemModel";
import { ConceptModel } from "../concept/ConceptModel";
import { BookColorType } from "../../ui/UIModel";
import { MotherTongueType } from "../../auth/UserModel";
import { ProblemTreeNodeModel } from "../../treeNode/ProblemTreeNodeModel";
import { TreeNodeModel } from "../../treeNode/TreeNodeModel";

export const MissionNameType = {
    NoviceLow: "Novice Low",
    NoviceMid: "Novice Mid",
    NoviceHigh: "Novice High",
    IntermediateLow: "Intermediate Low",
    IntermediateMid: "Intermediate Mid",
    IntermediateHigh: "Intermediate High",
};

export const MissionNameTypeToColorCode = {
    [MissionNameType.NoviceLow]: "#DEA61D",
    [MissionNameType.NoviceMid]: "#AE2B35",
    [MissionNameType.NoviceHigh]: "#763568",
    [MissionNameType.IntermediateLow]: "#264348",
    [MissionNameType.IntermediateMid]: "#3A5A33",
    [MissionNameType.IntermediateHigh]: "#114FAC",
};

export const MissionNameTypeToColor = {
    [MissionNameType.NoviceLow]: [BookColorType.Yellow],
    [MissionNameType.NoviceMid]: [BookColorType.Red],
    [MissionNameType.NoviceHigh]: [BookColorType.Purple],
    [MissionNameType.IntermediateLow]: [BookColorType.Indigo],
    [MissionNameType.IntermediateMid]: [BookColorType.Green],
    [MissionNameType.IntermediateHigh]: [BookColorType.Blue],
};

export const MissionNameTypeToGradient = {
    [MissionNameType.NoviceLow]:
        "linear-gradient(180deg, #FFC433 0%, #DEA61D 100%)",
    [MissionNameType.NoviceMid]:
        "linear-gradient(180deg, #D24D57 0%, #AE2B35 100%)",
    [MissionNameType.NoviceHigh]:
        "linear-gradient(180deg, #964985 0%, #763568 100%)",
    [MissionNameType.IntermediateLow]:
        "linear-gradient(180deg, #5D7377 0%, #264348 100%)",
    [MissionNameType.IntermediateMid]:
        "linear-gradient(180deg, #6B9362 0%, #3A5A33 100%)",
    [MissionNameType.IntermediateHigh]:
        "linear-gradient(360deg, #114FAC 0%, #1A64D3 100%)",
};

export const MissionNameTypeToInfo = {
    [MissionNameType.NoviceLow]: "확실한 첫 단추",
    [MissionNameType.NoviceMid]: "생각을 코드로",
    [MissionNameType.NoviceHigh]: "개념을 탄탄히",
    [MissionNameType.IntermediateLow]: "실수 없는 코드 작성",
    [MissionNameType.IntermediateMid]: "정확하게 효율적으로",
    [MissionNameType.IntermediateHigh]: "빈틈 없는 실력",
};

export const ProblemType = {
    CodeBlock: "Code block",
    MultipleChoice: "Multiple choice",
    SequentialChoice: "Sequential choice",
};

export class MissionModel {
    id = 0;

    name = "";

    description = "";

    copy = "";

    order = 0;

    progress = 0;

    concepts = [];

    testCount = 0;

    quizCount = 0;

    lessonCount = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const mission = pickCls(this, camelizeKeys(data));
        mission.concepts =
            mission.concepts &&
            mission.concepts.map((concept) => new ConceptModel(concept));
        set(this, mission);
    }
}

export class MissionProblemModel extends ProblemTreeNodeModel {
    constructor(data) {
        super(data);
        set(this, pickCls(this, camelizeKeys(data)));
    }
}

export class MissionTreeModel extends TreeNodeModel {
    id = 0;

    name = "";

    children = [];

    nodeId = "root";

    nodeLabel = "mission";

    parentNode = "";

    parentName = "";

    parentNodeLabel = "";

    parentNodeId = "";

    progress = 0;

    alias = "";

    level = LevelType.Easy;

    motherTongue = MotherTongueType.KOR;

    constructor(data) {
        super(data);
        makeObservable(this, {
            id: observable,

            children: observable,

            level: observable,

            name: override,

            nodeLabel: override,

            nodeId: override,

            parentNode: override,

            parentName: override,

            parentNodeLabel: override,

            parentNodeId: override,

            progress: observable,

            updateProgress: action,
        });
        const missionTree = pickCls(this, camelizeKeys(data));

        if (missionTree.children && missionTree.children.length) {
            missionTree.children = missionTree.children.map((tree) => {
                const body = {
                    ...tree,
                    nodeId: tree.nodeLabel + "_" + tree.id,
                    parentNode: missionTree.parentNode
                        ? [...missionTree.parentNode, missionTree.id]
                        : [missionTree.id],
                    parentName: missionTree.parentName
                        ? [...missionTree.parentName, missionTree.name]
                        : [missionTree.name],
                    parentNodeId: missionTree.parentNodeId
                        ? [
                              ...missionTree.parentNodeId,
                              `${missionTree.nodeLabel}_${missionTree.id}`,
                          ]
                        : [`${missionTree.nodeLabel}_${missionTree.id}`],
                    parentNodeLabel: missionTree.parentNodeLabel
                        ? [
                              ...missionTree.parentNodeLabel,
                              missionTree.nodeLabel,
                          ]
                        : [missionTree.nodeLabel],
                };
                if (tree.nodeLabel === "problem") {
                    return new MissionProblemModel({
                        ...body,
                        problemId: tree.id,
                        progressStatus:
                            tree.progressStatus || ProblemStatusType.Ready,
                    });
                } else {
                    return new MissionTreeModel(body);
                }
            });
        }
        set(this, missionTree);
    }

    updateProgress(newProgress) {
        this.progress = newProgress;
    }
}

export class LatestSolvedProblemSetModel {
    latestSolvedProblem = undefined;

    missionId = -1;

    totalProblemCount = 0;

    passedProblemCount = 0;

    missionName = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const latestSolvedProblemSet = pickCls(this, camelizeKeys(data));
        latestSolvedProblemSet.latestSolvedProblem =
            latestSolvedProblemSet.latestSolvedProblem &&
            new CuratedProblemModel(latestSolvedProblemSet.latestSolvedProblem);
        set(this, latestSolvedProblemSet);
    }
}
