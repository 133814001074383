import React from "react";
import { Box, Typography } from "@mui/material";

export const ReplyIndicator = ({ isReplied }) => (
    <Box
        borderRadius={2}
        padding={1.25}
        bgcolor={isReplied ? "#F2F7F3" : "#FFFAEE"}
        color={isReplied ? "#009D27" : "#FFC433"}
    >
        <Typography variant="body2" fontWeight={700}>
            {isReplied ? "답이 달렸어요!" : "아직 답이 달리지 않았어요."}
        </Typography>
    </Box>
);
