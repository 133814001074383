import { makeAutoObservable } from "mobx";
import { ConceptModel } from "./ConceptModel";
import ConceptRepository from "./ConceptRepository";

export default class ConceptStore {
    concepts = [];
    isFetching = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll({ missionId, problemName }) {
        if (
            problemName ||
            !(
                this.concepts.length &&
                this.concepts[0].missionId === Number(missionId)
            )
        ) {
            this.isFetching = true;
            const response = yield ConceptRepository.findAll({
                missionId,
                problemName,
            });
            this.concepts.replace(
                response.data.map((concept) => new ConceptModel(concept))
            );
            this.isFetching = false;
        }
    }
}
