import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Stack,
    Switch,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { CloseDialog } from "../../../../common/components/dialog/CloseDialog";
import { copyToClipboard } from "../../../../utils/clipboard";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";
import { LinkOutlined } from "@mui/icons-material";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { useEffect } from "react";
import { DefaultTheme } from "../../../../style/theme";

export const ShareLinkCopyDialog = ({
    fiddle,
    handleLinkAccess,
    open,
    onClose,
}) => {
    const [isPrivate, setIsPrivate] = useState(
        fiddle ? fiddle.isPrivate : true
    );

    useEffect(() => {
        if (fiddle) {
            setIsPrivate(fiddle.isPrivate);
        }
    }, [fiddle]);

    const onSwitchChange = (e) => {
        setIsPrivate(!e.target.checked);
        handleLinkAccess(fiddle, !e.target.checked);
    };

    const copyLink = (_fiddle) => {
        copyToClipboard({
            text: window.location.host + `/psfiddle/${_fiddle.key}`,
            onCopy: () => {
                snackActions.success("성공적으로 복사되었습니다.");
            },
        });
    };

    return (
        <CloseDialog open={open} onClose={onClose}>
            <Stack spacing={3}>
                <Typography
                    fontWeight={700}
                    variant={"h4"}
                    children={"링크 복사"}
                    textAlign={"center"}
                />

                {fiddle ? (
                    <>
                        <ThemeProvider theme={DefaultTheme}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    background: "#f1f5f9",
                                    px: 1.5,
                                    py: 1,
                                    borderRadius: 2,
                                }}
                                spacing={2}
                            >
                                <Typography
                                    variant={"body1"}
                                    children={
                                        "퀴즈를 '공개' 설정해야 링크를 전달받은 사람이 퀴즈를 볼 수 있습니다."
                                    }
                                    whiteSpace={"pre-line"}
                                />
                                <FormControl
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                onChange={onSwitchChange}
                                                checked={!isPrivate}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                {isPrivate ? "비공개" : "공개"}
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            </Stack>
                        </ThemeProvider>
                        <Button
                            sx={{
                                background: "#000000",
                                "&:hover": {
                                    background: "#aaaaaa",
                                },
                            }}
                            onClick={() => copyLink(fiddle)}
                        >
                            {" "}
                            <LinkOutlined /> 링크 복사{" "}
                        </Button>
                    </>
                ) : (
                    <LoadingOverlay active />
                )}

                {/* {fiddle.title} */}
            </Stack>
        </CloseDialog>
    );
};
