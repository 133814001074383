import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

export const CircularProgressWithLabel = ({
    value,
    isFractal,
    size,
    fontSizeBig,
    fontSizeSmall,
    sx,
}) => {
    return (
        <Box position="relative" display="inline-flex" sx={sx}>
            <Box position="relative">
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: "#e1e1e1",
                    }}
                    size={size}
                    thickness={3}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    sx={{
                        position: "absolute",
                        left: 0,
                        color: "#3FCB7E",
                    }}
                    size={size}
                    thickness={3}
                    value={isFractal ? eval(value) * 100 : value}
                />
            </Box>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                    width: size,
                    height: size,
                }}
            >
                {isFractal ? (
                    <Box
                        display="flex"
                        alignItems="baseline"
                        justifyContent="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color={"#3FCB7E"}
                            fontWeight={700}
                            style={{ fontSize: fontSizeBig }}
                        >
                            {value.split("/")[0]}
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            color={"#999999"}
                            sx={{
                                fontSize: fontSizeSmall,
                            }}
                        >
                            {`/${value.split("/")[1]}`}
                        </Typography>
                    </Box>
                ) : (
                    <Typography
                        variant="caption"
                        component="div"
                        color={"#3FCB7E"}
                        sx={{ fontSize: fontSizeBig, letterSpacing: "-1px" }}
                    >
                        {`${value}%`}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

CircularProgressWithLabel.defaultProps = {
    isFractal: false,
};
