import { makeAutoObservable } from "mobx";
import ProblemRepository from "./ProblemRepository";
import {
    DescriptionModel,
    ExplanationModel,
    IntroductionModel,
    ProblemModel,
} from "./ProblemModel";

export default class ProblemStore {
    problem = undefined;

    descriptionModel = "";

    introductions = [];

    introLoading = true;

    explanation = undefined;

    explanationLoading = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *find({ missionId, problemId }) {
        const response = yield ProblemRepository.find({ missionId, problemId });
        this.problem = new ProblemModel(response.data);
    }

    *getDescription({ problemId }) {
        const response = yield ProblemRepository.findDescription({ problemId });
        this.descriptionModel = new DescriptionModel(response.data);
    }

    *getIntroductions({ missionId, problemId }) {
        this.introLoading = true;
        const response = yield ProblemRepository.findIntroductions({
            missionId,
            problemId,
        });
        this.introductions.replace(
            response.data.map(
                (introduction) => new IntroductionModel(introduction)
            )
        );
        this.introLoading = false;
    }

    *getExplanation({ problemId }) {
        this.explanationLoading = true;
        const response = yield ProblemRepository.findExplanation({ problemId });
        this.explanation = new ExplanationModel(response.data);
        this.explanationLoading = false;
    }

    *addClickEvent({ body }) {
        return yield ProblemRepository.addClickEvent({ body });
    }

    *mcqSubmission({ problemId, body }) {
        const response = yield ProblemRepository.mcqSubmission({
            problemId,
            body,
        });
        return response.data;
    }

    *sendChat({ problemId, body }) {
        const response = yield ProblemRepository.sendChat({
            problemId,
            body,
        });
        return response.data;
    }
}
