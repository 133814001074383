import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { QuizDashboardDetailTemplate } from "./QuizDashboardDetailTemplate";

export const QuizDashboardDetailContainer = observer(() => {
    const { userId, fiddleId } = useParams();

    return <QuizDashboardDetailTemplate userId={userId} fiddleId={fiddleId} />;
});
