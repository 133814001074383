import { makeAutoObservable } from "mobx";
import HomeworkRepository from "./HomeworkRepository";
import { HomeworkModel, HomeworkSimpleModel } from "./HomeworkModel";
import { MissionModel } from "../missions/mission/MissionModel";

const LocalHomeworkKey = "CT-OOE-HOMEWORK";

export default class HomeworkStore {
    homeworks = [];

    isFetching = false;

    homework = undefined;

    simpleHomeworks = [];

    currProblem = undefined;

    currProblemIdx = -1;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll(params) {
        this.isFetching = true;
        if (!this.rootStore.classroomStore.classroom) {
            this.isFetching = false;
            this.homeworks = [];
            return;
        }
        const response = yield HomeworkRepository.findAll({
            classroomId: this.rootStore.classroomStore.classroom.id,
            ...params,
        });
        this.homeworks = response.data.map(
            (homework) => new HomeworkModel(homework)
        );
        this.isFetching = false;
    }

    *findAllSimple({ ...params }) {
        this.isFetching = true;
        const response = yield HomeworkRepository.findAllSimple({
            classroomId: this.rootStore.classroomStore.classroom
                ? this.rootStore.classroomStore.classroom.id
                : 0,
            ...params,
        });
        this.simpleHomeworks = response.data.map(
            (homework) => new HomeworkSimpleModel(homework)
        );
        this.isFetching = false;
    }

    *find({ homeworkId }) {
        const response = yield HomeworkRepository.find({
            homeworkId,
            classroomId: this.rootStore.classroomStore.classroom.id,
        });
        this.homework = new HomeworkModel(response.data);
        return this.homework;
    }

    *add({ body }) {
        const response = yield HomeworkRepository.add({ body });
        const data = Array.isArray(response.data)
            ? response.data.map((h) => new HomeworkModel(h))
            : [new HomeworkModel(response.data)];

        const currClassroomId = this.homeworks.length
            ? this.homeworks[0].classroomId
            : -1;

        this.homeworks = [
            ...(currClassroomId === -1
                ? data
                : data.filter((h) => h.classroomId === currClassroomId)),
            ...this.homeworks,
        ];
    }

    *remove({ homeworkId }) {
        const response = yield HomeworkRepository.remove({ homeworkId });
        this.homeworks = [
            ...this.homeworks.filter(
                (homework) => homework.id !== Number(homeworkId)
            ),
        ];
        return response;
    }

    *update({ homeworkId, body }) {
        const response = yield HomeworkRepository.update({ homeworkId, body });
        this.homeworks = [
            ...this.homeworks.map((homework) => {
                if (homework.id === Number(homeworkId)) {
                    return new HomeworkModel(response.data);
                }
                return homework;
            }),
        ];
    }

    *getHomeworkTree({ body }) {
        const response = yield HomeworkRepository.getTree({ body });
        return response.data.map((mission) => new MissionModel(mission));
    }

    *toggleHidden({ homeworkId, body }) {
        const response = yield HomeworkRepository.toggleHidden({
            homeworkId,
            body,
        });
        this.homeworks = [
            ...this.homeworks.map((homework) => {
                if (homework.id === Number(homeworkId)) {
                    return new HomeworkModel(response.data);
                }
                return homework;
            }),
        ];
    }

    setHomework(_homework) {
        this.homework = _homework;
        if (_homework) {
            localStorage.setItem(LocalHomeworkKey, _homework.id);
        }
    }

    findProblem({ problemId }) {
        return this.homework.curatedProblems.findIndex(
            (homework) => homework.problemId === problemId
        );
    }

    problemExist({ problemId }) {
        return this.findProblem({ problemId }) !== -1;
    }

    changeToTreeProblem({ problemId }) {
        this.currProblemIdx = this.findProblem({ problemId });
        this.currProblem = this.homework.curatedProblems[this.currProblemIdx];
        return this.currProblem.number;
    }

    changeToNextProblem() {
        if (this.homework.curatedProblems.length - 1 === this.currProblemIdx) {
            return this.currProblem.number;
        }
        this.currProblem =
            this.homework.curatedProblems[this.currProblemIdx + 1];
        this.currProblemIdx = this.currProblemIdx + 1;
        return this.currProblem.number;
    }

    changeToPrevProblem() {
        if (this.currProblemIdx === 0) {
            return this.currProblem.number;
        }
        this.currProblem =
            this.homework.curatedProblems[this.currProblemIdx - 1];
        this.currProblemIdx = this.currProblemIdx - 1;
        return this.currProblem.number;
    }
}
