import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    getMissionAndProblemId,
    getPercentString,
} from "../../../../../utils/string";
import { ProblemStatusType } from "../../../../../common/modules/missions/curatedProblem/CuratedProblemModel";
import { ProgressDiv } from "../../../../../common/components/div/ProgressDiv";

export const MissionProgressBox = ({ missionProgress, order }) => {
    const { username, name, progresses, userId } = missionProgress;

    const navigate = useNavigate();

    const solvedProblemCount = progresses.reduce((acc, curr) => {
        if (curr.status === ProblemStatusType.Passed) {
            return acc + 1;
        }
        return acc;
    }, 0);
    const problemCount = progresses.length;

    const handleClick = (_progress) => {
        const { number } = _progress;
        const { missionId, problemId } = getMissionAndProblemId(number);
        navigate(`users/${userId}/missions/${missionId}/problems/${problemId}`);
    };

    return (
        <Stack
            direction="row"
            alignItems="center"
            height={150}
            spacing={2.5}
            minWidth="100%"
            sx={{
                backgroundColor: order % 2 ? "#E7ECF0" : "#F5F5F7",
            }}
        >
            <Stack
                direction="column"
                width={200}
                alignItems="center"
                spacing={0.5}
            >
                <Stack direction="row" alignItems="center">
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                        }}
                    />
                    <Typography
                        ml={0.5}
                        color="#6D7A9F"
                        fontWeight={700}
                        variant="caption"
                        children={`${name}(${username})`}
                    />
                </Stack>
                <Typography
                    color="primary"
                    fontWeight={700}
                    variant="body2"
                    children={`${getPercentString(
                        solvedProblemCount,
                        problemCount
                    )} (${solvedProblemCount}/${problemCount})`}
                />
            </Stack>
            <Stack
                direction="row"
                sx={{
                    flexGrow: 1,
                    pr: 2.5,
                }}
            >
                {progresses.map((progress, i) => (
                    <ProgressDiv
                        key={`progress-div-${i}`}
                        progress={progress}
                        onClick={() => handleClick(progress)}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
