import React, { useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { SubmissionTableCell } from "./components/SubmissionTableCell";
import { SubmissionTableRow } from "./templates/SubmissionTableRow";
import { CodeDialogTemplate } from "./templates/CodeDialogTemplate";

export const SubmissionsTemplate = ({
    submissions,
    rowsPerPage,
    page,
    count,
    setPage,
    setRowsPerPage,
    isFiddle,
}) => {
    const [selectedSubmission, setSelectedSubmission] = useState(
        submissions[0]
    );
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const handleClick = (submission) => {
        setSelectedSubmission(submission);
        setDialogOpen(true);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SubmissionTableCell
                            align="center"
                            children="제출 일시"
                        />
                        <SubmissionTableCell align="center" children="문제" />
                        <SubmissionTableCell align="center" children="결과" />
                        <SubmissionTableCell
                            align="center"
                            children="수행 시간"
                        />
                        <SubmissionTableCell align="center" children="메모리" />
                        <SubmissionTableCell align="center" children="언어" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {submissions.slice(0, rowsPerPage).map((submission) => (
                        <SubmissionTableRow
                            key={`submisison-table-row-${submission.id}`}
                            submission={submission}
                            handleClick={handleClick}
                        />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 20]}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            <CodeDialogTemplate
                isFiddle={isFiddle}
                submission={selectedSubmission}
                open={dialogOpen}
                handleClose={handleClose}
                isTest={selectedSubmission.isTest}
            />
        </TableContainer>
    );
};
