import { DELETE, GET, PATCH, POST, PUT } from "../../../utils/api";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";

class ClassroomRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAllSchools() {
        return GET(`schools/`);
    }

    searchSchools({ name }) {
        return GET(`schools/?name=${name}`);
    }

    addClassroom({ body }) {
        return POST("classrooms/", body);
    }

    findAll(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`classrooms/${queryParams}`);
    }

    findAvailableClassrooms() {
        return GET(`available-classrooms/`);
    }

    changeClassroom({ body, classroomId }) {
        return PUT(`classrooms/${classroomId}/`, body);
    }

    deleteClassroom({ classroomId }) {
        return DELETE(`classrooms/${classroomId}/`);
    }

    requestClassroom({ classroomId }) {
        return PATCH(`request-classroom/${classroomId}/`, {});
    }

    approveStudents({ classroomId, body }) {
        return POST(`classrooms/${classroomId}/approve_students/`, body);
    }

    removeQueues({ body, classroomId }) {
        return PATCH(`classrooms/${classroomId}/approve_students/`, body);
    }

    getStudents({ classroomId, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`classrooms/${classroomId}/students/${queryParams}`);
    }

    getQueues({ classroomId, ...params }) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`classrooms/${classroomId}/approve_students/${queryParams}`);
    }

    addStudents({ body, classroomId }) {
        return POST(`classrooms/${classroomId}/students/`, body);
    }

    removeStudents({ body, classroomId }) {
        return PATCH(`classrooms/${classroomId}/students/`, body);
    }

    findStudent({ studentId }) {
        return GET(`students/${studentId}/`);
    }

    updateStudent({ studentId, body }) {
        return PATCH(`students/${studentId}/`, body);
    }
}

export default new ClassroomRepository();
