import React from "react";
import { Stack } from "@mui/material";
import { ConceptAccordionBox } from "./ConceptAccordionBox";

export const ContentsBox = ({ missionId, concepts, latestSolvedProblem }) => {
    return (
        <Stack direction="column" spacing={2.5} flex={1}>
            <ConceptAccordionBox
                concepts={concepts}
                missionId={missionId}
                defaultConceptId={latestSolvedProblem.conceptId}
                defaultStudyUnitId={latestSolvedProblem.studyUnitId}
            />
        </Stack>
    );
};
