import React from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";

export const HomeworkHeaderBox = ({ homeworkProgress }) => {
    const { startedAt, finishedAt, progresses } = homeworkProgress;

    return (
        <Stack
            direction="column"
            alignItems="center"
            width={40 * progresses.length}
        >
            <Typography
                variant="caption"
                gutterBottom
                children={`${moment(startedAt).format(
                    "M[월] D[일]"
                )} ~ ${moment(finishedAt).format("M[월] D[일]")}`}
            />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height={24}
                sx={{
                    position: "relative",
                    color: "common.white",
                    bgcolor: "common.black",
                    borderRadius: "4px 4px 0px 0px",
                }}
            >
                <Typography
                    variant="overline"
                    fontWeight={700}
                    children={`${homeworkProgress.name} (${homeworkProgress.totalScore}점)`}
                />
            </Stack>
            <Stack
                direction={"row"}
                sx={{
                    width: "100%",
                }}
            >
                {homeworkProgress.scores.map((score, idx) => (
                    <Stack
                        key={idx}
                        flex={1}
                        sx={{
                            width: "100%",
                            bgcolor: idx % 2 === 0 ? "#959595" : "#c0c0c0",
                        }}
                        alignItems={"center"}
                    >
                        <Typography variant="caption">{score}</Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
