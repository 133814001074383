import { makeAutoObservable } from "mobx";
import { NotificationModel } from "./NotificationModel";
import NotificationRepository from "./NotificationRepository";

export default class NotificationStore {
    notifications = [];

    isFetching = false;

    unseenCount = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll() {
        this.isFetching = true;
        const response = yield NotificationRepository.findAll();
        this.notifications.replace(
            response.data.results.map(
                (notification) => new NotificationModel(notification)
            )
        );
        this.unseenCount = 0;
        this.isFetching = false;
    }

    *findUnseenCount() {
        const response = yield NotificationRepository.findUnseenCount();
        this.unseenCount = response.data;
    }

    *readNotification({ notificationId }) {
        const response = yield NotificationRepository.readNotification({
            notificationId,
        });
        this.notifications.replace(
            this.notifications.map((notification) => {
                if (notification.id === notificationId) {
                    notification.isRead = true;
                    return notification;
                }
                return notification;
            })
        );
        return response;
    }
}
