import React from "react";
import { Box } from "@mui/material";
import { ExecuteStd } from "./ExecuteStd";

export const ExecuteResultGrid = ({ result }) => {
    const {
        userInput,
        correctStdout,
        actualStdout,
        submissionId,
        userInputUrl,
        correctStdoutUrl,
        actualStdoutUrl,
    } = result;

    return (
        <Box
            height="100%"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            overflow="hidden"
            gridAutoRows="minmax(100px, auto)"
            sx={{
                gridGap: 20,
            }}
        >
            <Box gridColumn="1 / 3" gridRow={1}>
                <ExecuteStd
                    text={userInput}
                    textUrl={userInputUrl}
                    submissionId={submissionId}
                    fileName="input.txt"
                    label="테스트 케이스"
                />
            </Box>
            <Box gridColumn="1 / 2" gridRow={2}>
                <ExecuteStd
                    text={correctStdout}
                    textUrl={correctStdoutUrl}
                    submissionId={submissionId}
                    fileName="correct_output.txt"
                    label="정답"
                />
            </Box>
            <Box gridColumn="2 / 3" gridRow={2} ml={2}>
                <ExecuteStd
                    text={actualStdout}
                    textUrl={actualStdoutUrl}
                    submissionId={submissionId}
                    fileName="actual_output.txt"
                    label="나의 출력"
                />
            </Box>
        </Box>
    );
};
