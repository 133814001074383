import { styled, TextField } from "@mui/material";

export const ProblemNumberTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F1F5F9",
        borderRadus: 8 / 4,
        "& fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-error fieldset": {
            borderColor: "#d32f2f",
        },
    },
}));
