import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../utils/immutableLodash";
import { camelizeKeys, snakelizeKeys } from "../../../utils/renameKeys";
import { LanguageType } from "../problems/ProblemModel";
import { ClassroomModel } from "../classroom/ClassroomModel";

export const MotherTongueType = {
    KOR: "ko-kr",
    ENG: "en-us",
};

export const MotherTongueLabel = {
    [MotherTongueType.KOR]: "한국어",
    [MotherTongueType.ENG]: "English",
};

export const MotherTongueIcon = {
    [MotherTongueType.KOR]:
        "https://contents.codetree.ai/homepage/images/icons/flags/kr.svg",
    [MotherTongueType.ENG]:
        "https://contents.codetree.ai/homepage/images/icons/flags/us.svg",
};

export const AuthProviderType = {
    MICROSOFT: "microsoft",
};

export class UserModel {
    id = 0;

    username = "";

    name = "";

    motherTongue = "";

    chatAvailable = false;

    profileId = 0;

    language = LanguageType.Python3;

    classroom = undefined;

    school = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const user = pickCls(this, camelizeKeys(data));
        user.classroom = user.classroom && new ClassroomModel(user.classroom);
        set(this, user);
    }

    updateProfile(data) {
        const user = pickCls(this, camelizeKeys(data));
        set(this, user);
    }
}

export class UserProfileDAOModel {
    constructor(data) {
        Object.assign(this, snakelizeKeys(data));
    }
}

export class SocialUserDAOModel {
    code = "";

    redirectUri = "";

    authProvider = "";

    state = "";

    constructor(data) {
        const user = pickCls(this, data);
        Object.assign(this, snakelizeKeys(user));
    }
}

export class SocialUserModel {
    id = 0;

    email = "";

    phoneNumber = "";

    access = "";

    refresh = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const socialUser = pickCls(this, camelizeKeys(data));
        set(this, socialUser);
    }
}
