import { makeAutoObservable } from "mobx";
import * as monaco from "monaco-editor";

export default class GlobalStateStore {
    monacoTheme = "light";

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setMonacoTheme(theme) {
        this.monacoTheme = theme;
        monaco.editor.setTheme(theme);
    }
}
