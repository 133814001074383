import React, { useEffect } from "react";
import { PSFiddleListTemplate } from "./PSFiddleListTemplate";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";

export const PSFiddleListContainer = observer(() => {
    const { fiddleStore } = useStores();
    const { fiddles, isFetching } = fiddleStore;

    useEffect(() => {
        fiddleStore.findAll();
    }, []);

    if (isFetching) return <LoadingOverlay active />;

    if (!fiddles.length) {
        return <NoResultsTemplate text={"아직 등록된 퀴즈가 없습니다."} />;
    }

    return <PSFiddleListTemplate fiddles={fiddles} />;
});
