import React, { useState } from "react";
import { Stack, Typography, Button, darken } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { NoResultsTemplate } from "../Missions/Playground/templates/NoResultsTemplate";
import { ClassroomSelector } from "../../../common/components/menu/ClassroomSelector";
import { HomeworkSelector } from "../../../common/components/menu/HomeworkSelector";
import { QuestIndicator } from "../../../common/templates/QuestIndicator";
import { useEffect } from "react";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { FAQSpeedDial } from "../Help/FAQ/FAQSpeedDial";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";

export const HomeworksTemplate = ({ homework, isFetching }) => {
    const navigate = useNavigate();
    const { homeworkId } = useParams();
    const navigateToDashboard = () => {
        navigate("/admin/homework-dashboard");
    };

    const [faqIn, setFaqIn] = useState(false);

    const teacherFAQ = [
        { key: "create_class", label: "학급을 만들고 싶어요." },
        {
            key: "create_hw",
            label: "문제를 골라서 수업에 활용하고 싶어요(수업/과제 만들기).",
        },
        { key: "accept_apply", label: "학급 신청 승인은 어떻게 하나요." },
    ];

    const studentFAQ = [
        { key: "enroll_class", label: "학급 신청은 어떻게 하나요." },
    ];

    useEffect(() => {
        setTimeout(() => {
            setFaqIn(true);
        }, 500);
    }, []);

    const closeFAQ = () => {
        setFaqIn(false);
        snackActions.info(
            "'프로필 아이콘 > 자주묻는질문'에서 다시 보실 수 있습니다."
        );
    };

    useEffect(() => {
        if (!isFetching && homework) {
            navigate(`/homeworks/${homework.id}`, { replace: true });
        }
    }, [isFetching, homework]);

    return (
        <MainTemplateWrapper
            sx={{
                pt: 5,
            }}
        >
            <Stack direction="column" spacing={52 / 8}>
                <Stack direction={"row"} spacing={20 / 8}>
                    <ClassroomSelector
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 8 / 4,
                            border: "2px solid #e1e1e7",
                            padding: 16 / 8,
                            height: 76,
                            marginRight: 0,
                            width: 250,
                            color: "#000000",
                            boxShadow: "none",
                        }}
                    />
                    <HomeworkSelector
                        allowCreate={homework ? homework.isOwner : false}
                        defaultHomeworkId={homeworkId}
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 8 / 4,
                            border: "2px solid #e1e1e7",
                            padding: 16 / 8,
                            height: 76,
                            marginRight: 0,
                            color: "#000000",
                            flex: 1,
                            boxShadow: "none",
                        }}
                    />
                </Stack>
                {isFetching ? (
                    <LoadingOverlay
                        text={"숙제를 가져오는 중입니다."}
                        spinnerProps={{ sx: { color: "#3FCB7E" } }}
                        spinnerTextProps={{ sx: { color: "#000" } }}
                        active
                    />
                ) : homework ? (
                    <Stack alignItems={"center"} spacing={24 / 8}>
                        <Stack alignItems={"center"}>
                            <Typography variant="display" fontWeight={800}>
                                {homework.name}
                            </Typography>
                            <Typography variant="h3">
                                총 {homework.curatedProblems.length}
                                문제
                            </Typography>
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={3}
                        >
                            <Stack alignItems={"center"}>
                                <Typography variant="body1">
                                    {`${moment(homework.startedAt).format(
                                        "LL"
                                    )}`}
                                </Typography>
                                <Typography variant="h4">
                                    {`${moment(homework.startedAt).format(
                                        "a h:mm"
                                    )}`}
                                </Typography>
                            </Stack>
                            <span>~</span>
                            <Stack alignItems={"center"}>
                                <Typography variant="body1">
                                    {`${moment(homework.finishedAt).format(
                                        "LL"
                                    )}`}
                                </Typography>
                                <Typography variant="h4">
                                    {`${moment(homework.finishedAt).format(
                                        "a h:mm"
                                    )}`}
                                </Typography>
                            </Stack>
                        </Stack>
                        {homework.isOwner && (
                            <Button
                                onClick={navigateToDashboard}
                                sx={{
                                    py: 10 / 8,
                                    px: 16 / 8,
                                    borderBottom: "4px solid #1A64D3",
                                    borderRadius: 2,
                                    background: "#18A0FB",
                                    "&:hover": {
                                        background: darken("#18A0FB", 0.1),
                                        borderBottom: `4px solid ${darken(
                                            "#1A64D3",
                                            0.1
                                        )}`,
                                    },
                                    transition: "all .15s",
                                }}
                            >
                                <Typography
                                    variant={"h4"}
                                    color={"#FFFFFF"}
                                    children={"현황 보기(선생님 전용)"}
                                />
                            </Button>
                        )}

                        <QuestIndicator
                            questName={"homeworks"}
                            questId={homework.id}
                            quest={homework.curatedProblems}
                        />
                    </Stack>
                ) : (
                    <>
                        <NoResultsTemplate
                            sx={{
                                pt: 3,
                            }}
                            text="수업/과제를 선택해주세요."
                        />
                        <FAQSpeedDial
                            teacherFAQ={teacherFAQ}
                            studentFAQ={studentFAQ}
                            onClose={closeFAQ}
                            faqIn={faqIn}
                        />
                    </>
                )}
            </Stack>
        </MainTemplateWrapper>
    );
};
