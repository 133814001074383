import React from "react";
import { CardActionArea, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/usefulFunc";

export const LogoButton = ({ sx }) => {
    const navigate = useNavigate();

    const redirectToMainPage = () => {
        scrollToTop(true);
        navigate("/");
    };

    return (
        <CardActionArea
            onClick={redirectToMainPage}
            sx={{
                width: 130,
                ...sx,
            }}
        >
            <CardMedia
                height={32}
                component="img"
                image="/smooc.png"
                title="Logo Image"
            />
        </CardActionArea>
    );
};
