import React from "react";
import { Stack } from "@mui/material";
import { SubmissionBox } from "./templates/SubmissionBox";
import { SubmissionDetailBox } from "./templates/SubmissionDetailBox";

export const SubmissionsTemplate = ({
    submissions,
    submissionId,
    setSubmissionId,
    isFiddle = false,
}) => {
    const selectedSubmission = submissions.find(
        (submission) => submission.id === submissionId
    );

    return (
        <Stack direction="row" width="100%" height="100%">
            <Stack direction="column" spacing={1.25} overflow="auto">
                {submissions.map((submission) => (
                    <SubmissionBox
                        key={`submisison-${submission.id}`}
                        submission={submission}
                        selected={submissionId === submission.id}
                        onClick={() => setSubmissionId(submission.id)}
                    />
                ))}
            </Stack>
            {selectedSubmission ? (
                <SubmissionDetailBox
                    submission={selectedSubmission}
                    isFiddle={isFiddle}
                />
            ) : null}
        </Stack>
    );
};
