import React from "react";
import { Stack, Typography } from "@mui/material";
import { TestCaseView } from "../../templates/TestCaseView";

export const DescriptionTemplate = ({ fiddle }) => {
    return (
        <Stack p={2.5} spacing={5}>
            <Typography
                children={`Q. ${fiddle.title}`}
                variant={
                    fiddle.title.length < 10 && !/\r|\n/.exec(fiddle.title)
                        ? "h3"
                        : "body1"
                }
                color={"#1A1A1A"}
                whiteSpace={"pre-line"}
            />
            <Stack>
                <Typography
                    children={"예제"}
                    color={"#666666"}
                    variant={"body1"}
                    mb={1.25}
                />
                {fiddle.testCases.map((testCase) => (
                    <TestCaseView key={testCase.key} item={testCase} />
                ))}
            </Stack>
        </Stack>
    );
};
