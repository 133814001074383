import React, { useState } from "react";
import { FiddleButton } from "./components/FiddleButton";
import { Stack, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { TestCaseView } from "./templates/TestCaseView";
import { DefaultCodeView } from "./templates/DefaultCodeView";
import { DeleteOutline, Lock, LockOpenOutlined } from "@mui/icons-material";
import { ShareLinkCopyDialog } from "./templates/ShareLinkCopyDialog";
import { setIn } from "../../../utils/immutableLodash";
import { TestCaseDAOModel } from "../../../common/modules/fiddles/FiddleModel";
import { FiddleDAOModel } from "../../../common/modules/fiddles/FiddleModel";
import { useStores } from "../../../common/modules/useStores";
import _ from "lodash";
import { AdminAlertDialog } from "../../../common/components/dialog/AdminAlertDialog";

export const PSFiddleViewForm = ({ inputs, startEdit }) => {
    const { fiddleStore, fiddleProgressStore } = useStores();
    const [shareLinkCopyOpen, setShareLinkCopyOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const openLinkCopyDialog = () => {
        setShareLinkCopyOpen(true);
    };

    const onCloseLinkCopyDialog = () => {
        setShareLinkCopyOpen(false);
    };

    const handleLinkAccess = _.debounce((fiddle, isPrivate) => {
        const inputs = { ...fiddle };
        const newInputs = setIn(
            "testCases",
            inputs.testCases.map(
                (testCase, i) =>
                    new TestCaseDAOModel({ ...testCase, order: i + 1 })
            ),
            inputs
        );
        const body = new FiddleDAOModel({
            ...newInputs,
            isPublished: true,
            isPrivate,
        });
        fiddleStore.update({
            fiddleId: fiddle.id,
            fiddleKey: fiddle.key,
            body,
            prevent_replace: true,
        });
    }, 250);

    const handleDelete = (fiddle) => {
        fiddleStore.delete(fiddle);
        fiddleProgressStore.deleteLocalFiddle(fiddle);
        setDeleteOpen(false);
    };

    return (
        <Stack
            sx={{
                borderRadius: 2,
                border: `3px dashed ${deleteOpen ? `#D24D57` : `#CDCDCD`}`,
                px: 2.5,
                py: 3.75,
                m: 5,
            }}
            spacing={4.25}
            minWidth={700}
        >
            <Typography
                children={inputs.title}
                color={"#666666"}
                variant={
                    inputs.title.length < 10 && !/\r|\n/.exec(inputs.title)
                        ? "h3"
                        : "body1"
                }
                whiteSpace={"pre-line"}
                sx={{
                    ml: 1.25,
                }}
            />
            {inputs.testCases.map((testCase) => (
                <TestCaseView key={testCase.key} item={testCase} />
            ))}
            {inputs.defaultCodes.map((defaultCode) => (
                <DefaultCodeView key={defaultCode.key} item={defaultCode} />
            ))}
            <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                spacing={2.5}
                sx={{
                    my: 1.25,
                    mx: 2.5,
                }}
            >
                <FiddleButton
                    label={inputs.isPrivate ? "비공개됨" : "공개됨"}
                    color={"#666666"}
                    Icon={inputs.isPrivate ? Lock : LockOpenOutlined}
                    onClick={openLinkCopyDialog}
                />
                <FiddleButton
                    label={"수정"}
                    color={"#666666"}
                    Icon={ModeEditOutlineOutlinedIcon}
                    onClick={startEdit}
                />
                <FiddleButton
                    label={"링크복사"}
                    color={"#666666"}
                    Icon={LinkOutlinedIcon}
                    onClick={openLinkCopyDialog}
                />
                <FiddleButton
                    label={"삭제"}
                    color={"#D24D57"}
                    Icon={DeleteOutline}
                    onClick={() => setDeleteOpen(true)}
                />
            </Stack>
            <ShareLinkCopyDialog
                open={shareLinkCopyOpen}
                onClose={onCloseLinkCopyDialog}
                fiddle={inputs}
                handleLinkAccess={handleLinkAccess}
            />
            <AdminAlertDialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                title={"선택한 퀴즈가 삭제됩니다."}
                description={
                    "삭제한 퀴즈는 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
                }
                onConfirm={() => handleDelete(inputs)}
            />
        </Stack>
    );
};
