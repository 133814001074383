import React from "react";
import moment from "moment";
import { Box, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    SubmissionStatusToColor,
    SubmissionStatusToKorean,
    SubmissionStatusType,
} from "../../../../../../../common/modules/submission/SubmissionModel";
import { SubmissionTableCell } from "../components/SubmissionTableCell";

export const SubmissionTableRow = observer(({ submission, handleClick }) => (
    <TableRow
        key={submission.id}
        hover
        onClick={() => handleClick(submission)}
        sx={{
            cursor: "pointer",
        }}
    >
        <SubmissionTableCell align="center">
            {moment(submission.createdAt).format(
                "YYYY[년] MM[월] DD[일] HH:mm:ss"
            )}
        </SubmissionTableCell>
        <SubmissionTableCell align="center">
            {submission.problemName}
        </SubmissionTableCell>
        <SubmissionTableCell align="center">
            <Box
                component="span"
                color={SubmissionStatusToColor[submission.result.status]}
            >
                {SubmissionStatusToKorean[submission.result.status]}
                {submission.result.status === SubmissionStatusType.InProgress
                    ? ` (${submission.result.process}%)`
                    : ""}
            </Box>
        </SubmissionTableCell>
        {submission.result.runtime !== -1 ? (
            <SubmissionTableCell align="center">
                {submission.result.runtime}
                ms
            </SubmissionTableCell>
        ) : (
            <SubmissionTableCell align="center">N/A</SubmissionTableCell>
        )}
        {submission.result.memory !== -1 ? (
            <SubmissionTableCell align="center">
                {submission.result.memory}
                MB
            </SubmissionTableCell>
        ) : (
            <SubmissionTableCell align="center">N/A</SubmissionTableCell>
        )}
        <SubmissionTableCell align="center">
            {submission.language}
        </SubmissionTableCell>
    </TableRow>
));
