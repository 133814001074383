import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ClassroomEditTemplate } from "./ClassroomEditTemplate";
import { useStores } from "../../../../common/modules/useStores";
import { ClassroomDAOModel } from "../../../../common/modules/classroom/ClassroomModel";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";
import { setIn } from "../../../../utils/immutableLodash";
import { errorToast } from "../../../../utils/errorToast";

export const ClassroomEditContainer = observer(() => {
    const { classroomStore } = useStores();
    const { classroom } = classroomStore;

    const [inputs, setInputs] = useState({
        ...classroom,
    });

    useEffect(() => {
        setInputs({
            ...classroom,
        });
    }, [classroom]);

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const handleEdit = (_inputs) => {
        const body = new ClassroomDAOModel(_inputs);
        classroomStore
            .changeClassroom({ body })
            .then(() => {
                snackActions.success("성공적으로 수정되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            });
    };

    const handleDelete = () => {
        classroomStore
            .deleteClassroom()
            .then(() => {
                snackActions.success("성공적으로 삭제되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            });
    };

    const handleCancel = () => {
        setInputs({
            ...classroom,
        });
    };

    return (
        <ClassroomEditTemplate
            inputs={inputs}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleSubmit={handleEdit}
            handleCancel={handleCancel}
        />
    );
});
