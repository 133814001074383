import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CuratedProblemBox } from "./CuratedProblemBox";

export const CuratedProblemsBox = ({
    missionId,
    curatedProblems,
    selectable,
    handleClickProblem,
    getChecked,
}) => {
    const [focusGroup, setFocusGroup] = useState(-1);

    const handleFocusGroup = (targetFocusGroup) => {
        setFocusGroup(targetFocusGroup);
    };

    const navigate = useNavigate();

    const handleClick = (curatedProblem) => {
        if (selectable) handleClickProblem(curatedProblem.curatedProblemId);
        else navigate(`/missions/${missionId}/problems/${curatedProblem.id}`);
    };

    const getBackground = (curatedProblem) => {
        if (selectable && getChecked(curatedProblem.curatedProblemId)) {
            return "linear-gradient(0deg, #F2F7F3, #F2F7F3)";
        }
        return "#FFFFFF";
    };

    return (
        <Stack direction="column" spacing={0.5}>
            {curatedProblems.map((curatedProblem, i) => (
                <CuratedProblemBox
                    index={i}
                    curatedProblems={curatedProblems}
                    handleFocusGroup={handleFocusGroup}
                    handleClick={handleClick}
                    focusGroup={focusGroup}
                    curatedProblem={curatedProblem}
                    bgcolor={getBackground(curatedProblem)}
                    selectable={selectable}
                    getChecked={getChecked}
                    key={`curation-prob-${curatedProblem.id}`}
                />
            ))}
        </Stack>
    );
};
