import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../../../Missions/Playground/templates/NoResultsTemplate";
import { useStores } from "../../../../../../common/modules/useStores";
import { SubmissionsTemplate } from "../../../../Missions/Playground/templates/submissions/SubmissionsTemplate";

export const SubmissionsContainer = observer(({ fiddle }) => {
    const { submissionStore } = useStores();
    const {
        submissions,
        isFetching,
        page,
        rowsPerPage,
        count,
        setPage,
        setRowsPerPage,
    } = submissionStore;

    useEffect(() => {
        setPage(0);
    }, [fiddle]);

    useEffect(() => {
        submissionStore.findAllByUser({
            objectId: fiddle.id,
            contentTypeName: "fiddle",
        });
    }, [fiddle, page, rowsPerPage]);

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="제출 기록을 불러오는 중입니다."
            />
        );

    if (!(submissions && submissions.length)) {
        return <NoResultsTemplate text="제출 기록이 존재하지 않습니다." />;
    }

    return (
        <SubmissionsTemplate
            isFiddle
            submissions={submissions}
            rowsPerPage={rowsPerPage}
            page={page}
            count={count}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
});
