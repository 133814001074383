import React from "react";
import { Stack, Typography } from "@mui/material";
import { LanguageType } from "../../../../common/modules/problems/ProblemModel";
import { CloseDialog } from "../../../../common/components/dialog/CloseDialog";
import { DefaultCodeView } from "./DefaultCodeView";

export const DefaultCodeDialog = ({ open, onClose }) => {
    return (
        <CloseDialog open={open} onClose={onClose}>
            <Stack spacing={3}>
                <Typography
                    fontWeight={700}
                    variant={"h4"}
                    children={"뼈대 코드란?"}
                    textAlign={"center"}
                />
                <Typography
                    variant={"body1"}
                    children={
                        "완벽하게 동작하는 코드의 일부분으로써 \n" +
                        "퀴즈를 푸는 사람이 핵심만 코드로 작성할 수 있도록 도와줘요.\n\n" +
                        "아래 뼈대코드 예시를 참고해보세요!"
                    }
                    textAlign={"center"}
                    whiteSpace={"pre-line"}
                />
                <DefaultCodeView
                    item={{
                        language: LanguageType.Python3,
                        code:
                            "a, b = int(input())\n" +
                            "\n" +
                            "# 두 숫자 a, b의 합이 c가 되도록 아래 코드를 완성해볼까요?\n" +
                            "c =  \n" +
                            "\n" +
                            "print(c) ",
                    }}
                />
                <DefaultCodeView
                    item={{
                        language: LanguageType.Python3,
                        code:
                            "# 변수 msg에 “Hello world”를 할당해보세요.\n" +
                            "msg =  \n" +
                            "\n" +
                            "# 변수 msg를 출력해보세요. (힌트: print 함수를 써보세요)\n",
                    }}
                />
            </Stack>
        </CloseDialog>
    );
};
