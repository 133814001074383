import React from "react";
import { Route, Routes } from "react-router-dom";
import { QuizDashboardContainer } from "./QuizDashboardContainer";
import { QuizDashboardDetailContainer } from "./QuizDashboardDetailContainer";

export const QuizDashboardPage = () => (
    <Routes>
        <Route path="/" element={<QuizDashboardContainer />} />
        <Route
            path="/users/:userId/quiz/:fiddleId"
            element={<QuizDashboardDetailContainer />}
        />
    </Routes>
);
