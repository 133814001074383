import React, { useState } from "react";
import { Collapse, List, ListItem, Stack, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdminChildListItem } from "./AdminChildListItem";

export const AdminListItem = ({ treeData }) => {
    const [open, setOpen] = useState(true);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <ListItem
                onClick={toggleOpen}
                sx={{
                    pt: 5,
                    pb: 1.25,
                    px: 0,
                    cursor: "pointer",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Typography
                        variant="caption"
                        fontWeight={700}
                        children={treeData.name}
                    />
                    {open ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                </Stack>
            </ListItem>
            <Collapse in={open}>
                <List>
                    {treeData.children.map((child, i) => (
                        <AdminChildListItem
                            key={`tree-child-${i}`}
                            treeData={child}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
};
