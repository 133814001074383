import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButtonWithTooltip } from "./IconButtonWithTooltip";

export const TextDownloadButton = ({
    iconSize,
    tooltipTitle,
    tooltipPlacement,
    downloadFile,
}) => (
    <IconButtonWithTooltip
        iconSize={iconSize}
        Icon={FileDownloadIcon}
        onClick={downloadFile}
        tooltipTitle={tooltipTitle || "다운로드"}
        tooltipPlacement={tooltipPlacement}
    />
);
