import { GET, POST } from "../../../utils/api";

class BlobRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    getTextFromBlob({ containerName, blobName }) {
        return GET(
            `problems/containers/${containerName}/blobs/${blobName}/text/`
        );
    }

    getUrlFromBlob({ containerName, blobName, fileName }) {
        return GET(
            `problems/containers/${containerName}/blobs/${blobName}/url/${fileName}/`
        );
    }

    getTextFromPrivateUrl(body) {
        return POST("problems/get_text_from_private_url/", body);
    }
}

// 싱글톤으로 리턴
export default new BlobRepository();
