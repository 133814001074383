import React, { useState } from "react";
import {
    Button,
    darken,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProblemNumberTextField } from "../components/ProblemNumberTextField";
import { getMissionAndProblemId } from "../../../../utils/string";

export const NavigateProblemNumberBox = () => {
    const [problemNumber, setProblemNumber] = useState("");

    const navigate = useNavigate();

    const navigateToProblem = (number) => {
        const { missionId, problemId } = getMissionAndProblemId(number);

        navigate(`/missions/${missionId}/problems/${problemId}`);
    };

    return (
        <Stack spacing={30 / 8}>
            <Stack
                spacing={20 / 8}
                padding={20 / 8}
                alignItems={"center"}
                borderRadius={12 / 4}
                sx={{
                    backgroundColor: "#fff",
                    border: "2px solid #e1e1e7",
                }}
            >
                <Typography variant="h4" color={"#434858"} fontWeight={800}>
                    문제로 바로가기
                </Typography>
                <Stack direction={"row"} spacing={16 / 8}>
                    <ProblemNumberTextField
                        fullWidth
                        placeholder={"5653"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            ),
                        }}
                        value={problemNumber}
                        onChange={(e) => setProblemNumber(e.target.value)}
                        // eslint-disable-next-line
                        inputProps={{
                            sx: {
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                            },
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                navigateToProblem(problemNumber);
                                e.preventDefault();
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: 8 / 4,
                            backgroundColor: "#E8F7EC",
                            color: "#2B6531",
                            "&:hover": {
                                backgroundColor: darken("#E8F7EC", 0.05),
                            },
                        }}
                        onClick={() => navigateToProblem(problemNumber)}
                    >
                        이동
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};
