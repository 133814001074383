import React from "react";
import { Dialog, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ComponentDialog = ({
    open,
    children,
    onClose,
    title,
    description,
    isDark,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: {
                position: "relative",
                maxWidth: 900,
                p: 3.75,
                pt: 7,
                pb: 5,
                background: isDark ? "#000000" : "#FFFFFF",
                color: isDark ? "#FFFFFF" : "#1A1A1A",
            },
        }}
        disableScrollLock
    >
        <IconButton
            onClick={onClose}
            sx={{
                position: "absolute",
                top: 25,
                right: 35,
                color: isDark ? "#FFFFFF" : "#1A1A1A",
            }}
        >
            <CloseIcon />
        </IconButton>
        <Stack direction="column" spacing={1.25}>
            <Typography
                variant="h1"
                fontWeight={700}
                whiteSpace="pre-line"
                children={title}
            />
            <Typography
                variant="body2"
                whiteSpace="pre-line"
                children={description}
            />
        </Stack>
        {children}
    </Dialog>
);
