import React from "react";
import {
    styled,
    Typography,
    Button,
    Stack,
    Avatar,
    Box,
    keyframes,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Markdown } from "../../../../../../../common/components/markdown/Markdown";
import { useNavigate } from "react-router-dom";

const InfoStack = styled(Stack)(({ theme }) => ({
    color: theme.palette.mode === "light" ? "#666666" : "#666666",
    alignItems: "center",
    padding: "12px 8px 8px 8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgb(210, 247, 245)",
    border: "1px solid rgb(83, 130, 132)",
    borderRadius: "8px",
    margin: "10px 0",
}));

const AvatarImage = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#ffffff",
    marginRight: "16px",
    padding: "5px",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.palette.mode === "light"
            ? "rgb(83, 130, 132)"
            : "rgb(83, 130, 132)",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
}));

const OneLineSummaryTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.palette.mode === "light"
            ? "rgb(83, 130, 132)"
            : "rgb(83, 130, 132)",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
}));

const HelpTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.palette.mode === "light"
            ? "rgb(83, 130, 132)"
            : "rgb(83, 130, 132)",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
}));

const AskButton = styled(Button)(({ theme }) => ({
    color: theme.palette.mode === "light" ? "#ffffff" : "#ffffff",
    fontWeight: "bold",
    backgroundColor: "rgb(83, 130, 132)",
    borderRadius: "6px",
    padding: "6px 8px 6px 8px",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "rgb(63, 110, 112)",
    },
}));

const TextStack = styled(Stack)(() => ({
    flexGrow: 1,
    marginLeft: "16px",
}));

const ActionStack = styled(Stack)(() => ({
    textAlign: "right",
}));

// bounce 애니메이션을 위한 keyframes 정의
const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
`;

// StyledBox 컴포넌트 스타일링
const StyledBox = styled(Box)(() => ({
    display: "flex",
    gap: "8px",
    alignItems: "flex-end",
    borderRadius: "16px",
    padding: "8px",
    width: "55px",
    animation: "fadeInDelay 0.5s", // fadeInDelay 애니메이션을 정의해야 합니다.
}));

// Dot 컴포넌트 스타일링
const Dot = styled("span")({
    animation: `${bounce} 2.2s infinite`,
    animationTimingFunction: "ease-in-out",
    fontSize: "20px",

    "&:nth-of-type(1)": {
        animationDelay: "0s",
    },
    "&:nth-of-type(2)": {
        animationDelay: "0.3s",
    },
    "&:nth-of-type(3)": {
        animationDelay: "0.6s",
    },
});

const HintBox = styled(Box)(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    width: "max-content",
    maxWidth: "80%", // 최대 너비 설정 (필요에 따라 변경 가능)

    // 마우스 올릴 때의 스타일
    "&:hover": {
        backgroundColor: "rgba(220, 220, 220, 0.9)", // 약간 어두운 투명한 배경
    },
}));

const WaitingOpenAI = () => {
    return (
        <StyledBox>
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
        </StyledBox>
    );
};

const OneLineMarkdown = ({ message, isBlurred, handleBlurToggle }) => {
    return (
        <Box position="relative">
            {/* 실제 Markdown 컴포넌트 */}
            {/* <StyledMarkdownBox
                data-isblurred={isBlurred ? "true" : "false"}
                onClick={isBlurred ? handleBlurToggle : undefined}
            >
                <Markdown colorMode={"clear-light"} children={message} />
            </StyledMarkdownBox> */}

            {/* 블러 처리된 상태에서의 클릭 가능한 텍스트 */}
            {isBlurred ? (
                <HintBox onClick={handleBlurToggle}>
                    힌트를 확인해보세요 (클릭)
                </HintBox>
            ) : (
                <Markdown colorMode={"clear-light"} children={message} />
            )}
        </Box>
    );
};

export const FriendlyOneLineSummary = ({ isExecuting, message }) => {
    const navigate = useNavigate();

    const redirectToDiscussion = () => {
        navigate(`discussions`);
    };

    const [isBlurred, setIsBlurred] = useState(true); // 블러 처리 상태

    const handleBlurToggle = () => {
        setIsBlurred(!isBlurred);
    };

    useEffect(() => {
        if (isExecuting) setIsBlurred(true);
    }, [isExecuting]);

    return (
        <>
            <InfoStack direction="row" spacing={2}>
                {/* 왼쪽 아바타 */}
                <AvatarImage src="https://contents.codetree.ai/homepage/images/playground/seedling.png" />

                {/* 중앙의 제목 및 내용 */}
                <TextStack spacing={1}>
                    <TitleTypography>From. 코드트리: </TitleTypography>
                    <OneLineSummaryTypography>
                        {isExecuting ? (
                            <WaitingOpenAI />
                        ) : (
                            <OneLineMarkdown
                                message={message}
                                isBlurred={isBlurred}
                                handleBlurToggle={handleBlurToggle}
                            />
                        )}
                    </OneLineSummaryTypography>
                </TextStack>

                {/* 오른쪽의 텍스트 및 버튼 */}
                <ActionStack spacing={1} flexShrink={0}>
                    <HelpTypography>도움이 필요하신가요?</HelpTypography>
                    <AskButton
                        variant="contained"
                        onClick={redirectToDiscussion}
                    >
                        질문하기
                    </AskButton>
                </ActionStack>
            </InfoStack>
        </>
    );
};
