import { computed, makeObservable, observable } from "mobx";

export class TreeNodeModel {
    _name = "";
    _nodeLabel = "";
    _nodeId = "";
    _parentNode = [];
    _parentName = [];
    _parentNodeLabel = [];
    _parentNodeId = [];

    set name(_name) {
        this._name = _name;
    }
    get name() {
        return this._name;
    }

    set nodeLabel(_nodeLabel) {
        this._nodeLabel = _nodeLabel;
    }
    get nodeLabel() {
        return this._nodeLabel;
    }

    set nodeId(_nodeId) {
        this._nodeId = _nodeId;
    }
    get nodeId() {
        return this._nodeId;
    }

    set parentNode(_parentNode) {
        this._parentNode = _parentNode;
    }
    get parentNode() {
        return this._parentNode;
    }

    set parentName(_name) {
        this._parentName = _name;
    }
    get parentName() {
        return this._parentName;
    }

    set parentNodeLabel(_nodeLabel) {
        this._parentNodeLabel = _nodeLabel;
    }
    get parentNodeLabel() {
        return this._parentNodeLabel;
    }

    set parentNodeId(_nodeId) {
        this._parentNodeId = _nodeId;
    }
    get parentNodeId() {
        return this._parentNodeId;
    }

    constructor() {
        makeObservable(this, {
            _name: observable,
            _nodeLabel: observable,
            _nodeId: observable,
            _parentNode: observable,
            _parentName: observable,
            _parentNodeLabel: observable,
            _parentNodeId: observable,
            name: computed,
            nodeLabel: computed,
            nodeId: computed,
            parentNode: computed,
            parentName: computed,
            parentNodeLabel: computed,
            parentNodeId: computed,
        });
    }
}
