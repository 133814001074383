import React, { useEffect, useState } from "react";
import { useStores } from "../../../../common/modules/useStores";
import ChannelService from "../../../../common/components/channel/ChannelService";
import { LoginTemplate } from "./LoginTemplate";
import { searchParamsToObject } from "../../../../utils/url";
import { AuthProviderType } from "../../../../common/modules/auth/UserModel";

export const LoginContainer = () => {
    const { authStore } = useStores();

    const [inputs, setInputs] = useState({
        username: "",
        password: "",
    });

    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    const login = ({ username, password }) => {
        const params = searchParamsToObject(window.location.search);
        const { nextUrl } = params;

        authStore.login({
            body: { username, password },
            nextUrl,
        });
    };

    const socialLogin = () => {
        const nextUrl = "/accounts/login" + window.location.search;

        authStore.socialLogin({
            provider: AuthProviderType.MICROSOFT,
            nextUrl,
        });
    };

    const handleSubmit = () => {
        login(inputs);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    return (
        <LoginTemplate
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleKeyPress={handleKeyPress}
            inputs={inputs}
            socialLogin={socialLogin}
        />
    );
};
