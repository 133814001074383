import React from "react";
import { useStores } from "../../../../common/modules/useStores";
import { AuthProviderType } from "../../../../common/modules/auth/UserModel";
import { searchParamsToObject } from "../../../../utils/url";
import { useLocation } from "react-router-dom";

export const LoginWithMsTemplate = () => {
    const { authStore } = useStores();
    const { socialLogin } = authStore;
    const { search } = useLocation();

    const params = searchParamsToObject(search);
    const { nextUrl } = params;
    socialLogin({
        provider: AuthProviderType.MICROSOFT,
        nextUrl: `/accounts/login${
            nextUrl
                ? `?nextUrl=${encodeURIComponent(encodeURIComponent(nextUrl))}`
                : ""
        }`,
    });

    return <></>;
};
