import React from "react";
import { Box, Container, Pagination, Stack } from "@mui/material";
import { DiscussionsSearchBox } from "./templates/DiscussionsSearchBox";
import { DiscussionBox } from "./templates/DiscussionBox";
import { NoResultsTemplate } from "../Missions/Playground/templates/NoResultsTemplate";

export const DiscussionsTemplate = ({
    discussions,
    isTeacher,
    page,
    rowsPerPage,
    count,
    setPage,
    isMyQuestions,
    toggleIsMyQuestions,
    resolvedStatus,
    setResolvedStatus,
    isAdmin,
    value,
    setValue,
    searchDiscussions,
}) => (
    <Stack direction="column">
        <DiscussionsSearchBox
            isAdmin={isAdmin}
            isTeacher={isTeacher}
            isMyQuestions={isMyQuestions}
            toggleIsMyQuestions={toggleIsMyQuestions}
            resolvedStatus={resolvedStatus}
            value={value}
            setValue={setValue}
            setResolvedStatus={setResolvedStatus}
            searchDiscussions={searchDiscussions}
        />
        <Box height={120} />
        <Box>
            {discussions.length ? (
                <>
                    <Container maxWidth="md">
                        <Stack
                            direction="column"
                            spacing={1.25}
                            sx={{
                                mt: 2.5,
                                mb: 5,
                            }}
                        >
                            {discussions.map((discussion) => (
                                <DiscussionBox
                                    key={`discussion-box-${discussion.id}`}
                                    discussion={discussion}
                                />
                            ))}
                        </Stack>
                    </Container>
                    <Pagination
                        count={Math.ceil(count / rowsPerPage)}
                        color="secondary"
                        onChange={(e, value) => setPage(value)}
                        page={page}
                        sx={{
                            mb: 2,
                            "& .MuiPagination-ul": {
                                justifyContent: "center",
                            },
                        }}
                    />
                </>
            ) : (
                <NoResultsTemplate text="아직 작성된 질문이 존재하지 않습니다." />
            )}
        </Box>
    </Stack>
);
