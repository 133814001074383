import React from "react";
import { Container } from "@mui/material";

export const MainTemplateWrapper = ({ children, sx }) => (
    <Container
        maxWidth="lg"
        sx={{
            px: {
                lg: 70 / 8,
                md: 3.25,
                sm: 4,
                xs: 2,
            },
            ...sx,
        }}
    >
        {children}
    </Container>
);
