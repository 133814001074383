import React from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MissionContainer } from "./MissionContainer";
import { ProblemsPage } from "./Problems/ProblemsPage";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { getLoginPageUrlFromThisLocation } from "../../../utils/url";

export const MissionPage = observer(() => {
    const { missionId } = useParams();

    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    const getProblemPage = () => {
        if (isUserLoggedIn()) {
            return <ProblemsPage />;
        }

        if (hasToken()) {
            return <LoadingOverlay active />;
        }

        return <Navigate to={getLoginPageUrlFromThisLocation()} />;
    };

    return (
        <Routes>
            <Route
                path="/"
                element={<MissionContainer missionId={missionId} />}
            />
            <Route path={"/problems/:problemId/*"} element={getProblemPage()} />
        </Routes>
    );
});
