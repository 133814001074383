import React from "react";
import { Stack, Typography } from "@mui/material";

export const TestCaseView = ({ item }) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
                transition: "all .5s ease-in-out",
            }}
            mb={1.5}
            spacing={1.5}
        >
            <Stack spacing={1} flexGrow={1}>
                <Stack direction={"row"} alignItems={"center"} spacing={2.5}>
                    <Typography
                        children={"입력"}
                        color={"#AAAAAA"}
                        minWidth={"fit-content"}
                    />
                    <Typography
                        variant={"body2"}
                        whiteSpace={"pre"}
                        sx={{
                            backgroundColor: "#F6F8FA",
                            flexGrow: 1,
                            px: 1.5,
                            py: 1,
                            fontFamily:
                                "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                        children={item.stdin || " "}
                    />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={2.5}>
                    <Typography
                        children={"출력"}
                        color={"#AAAAAA"}
                        minWidth={"fit-content"}
                    />
                    <Typography
                        variant={"body2"}
                        whiteSpace={"pre"}
                        sx={{
                            backgroundColor: "#F6F8FA",
                            flexGrow: 1,
                            px: 1.5,
                            py: 1,
                            fontFamily:
                                "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                        children={item.stdout || " "}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
