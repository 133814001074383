import React, { forwardRef } from "react";
import {
    Box,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";
import { PopUpBox } from "../../../../common/templates/PopUpBox";
import { PopUpType } from "../../../../common/modules/ui/UIModel";
import { PopUpSwiper } from "./PopUpSwiper";

// eslint-disable-next-line
export const GuideBox = forwardRef(({}, ref) => {
    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Paper
            ref={ref}
            sx={{
                boxShadow: 0,
                pt: 12.5,
            }}
        >
            <MainTemplateWrapper>
                <Stack
                    width={{ lg: "100%" }}
                    direction={{ md: "row", xs: "column" }}
                    justifyContent="space-between"
                    spacing={{ md: 0, xs: 3.75 }}
                >
                    <Typography
                        children="가이드 구성"
                        variant="h1"
                        fontWeight={700}
                    />
                    <Box
                        width={{ lg: 800, md: 700, xs: "100%" }}
                        overflow={{ sm: "initial", xs: "hidden" }}
                    >
                        {smBreakpoint ? (
                            <Grid
                                container
                                direction="row"
                                spacing={2.5}
                                sx={{
                                    ml: -2.5,
                                    mt: -2.5,
                                }}
                            >
                                {Object.values(PopUpType).map((type, i) => (
                                    <Grid item key={`pop-up-${i}`}>
                                        <PopUpBox
                                            sx={{
                                                width: { lg: 250, sm: 220 },
                                                height: { lg: 200, sm: 176 },
                                                p: { lg: 5, sm: 2.5 },
                                                pt: { lg: 4.25, sm: 2.5 },
                                            }}
                                            type={type}
                                            key={`pop-up-box-${type}`}
                                            contentVariant="h4"
                                            titleVariant="body1"
                                            indicatorSx={{
                                                height: 24,
                                                top: { lg: 122, sm: 108 },
                                                left: { lg: 96, sm: 76 },
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <PopUpSwiper noSection />
                        )}
                    </Box>
                </Stack>
                <Divider sx={{ mt: 12.5 }} />
            </MainTemplateWrapper>
        </Paper>
    );
});
