import React from "react";
import { Stack, Typography } from "@mui/material";

export const SectionTag = ({ title, description, sx }) => (
    <Stack
        direction="column"
        spacing={{
            xs: 1,
            md: 2,
        }}
        sx={sx}
    >
        <Typography
            children={title}
            color="#3FCB7E"
            variant="body1"
            fontWeight={700}
        />
        <Typography
            children={description}
            color="#1A1A1A"
            variant="h3"
            fontWeight={700}
            whiteSpace="pre-line"
        />
    </Stack>
);
