import React from "react";
import { Grid } from "@mui/material";
import { ComponentDialog } from "../../components/dialog/ComponentDialog";
import { ContentBox } from "./ContentBox";

export const VariousProblemsDialog = ({ open, onClose }) => {
    const Contents = [
        {
            title: "읽기",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/fundamental_01_x4.png",
            description:
                "배운 개념을 정확히 이해하고 있는지 객관식 문항으로 확인해보세요.",
        },
        {
            title: "채우기",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/fundamental_02_x4.png.png",
            description: "빈칸을 채워 정확한 코드를 만드는 연습을 해보세요.",
        },
        {
            title: "쓰기",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/fundamental_03_x4.png",
            description:
                "직접 코드를 쓰는 연습으로 프로그래밍 실력을 완성해보세요.",
        },
    ];

    return (
        <ComponentDialog
            open={open}
            onClose={onClose}
            title="읽기, 채우기, 쓰기"
            description="다각도로 이해도를 검증해 볼 다양한 문제 유형이 준비되어 있습니다."
        >
            <Grid
                container
                direction="row"
                spacing={2.5}
                mt={5}
                flexWrap="wrap"
                justifyContent="center"
            >
                {Contents.map((content, i) => (
                    <Grid key={`kind-solution-content-${i}`} item>
                        <ContentBox width={230} imageWidth={216} {...content} />
                    </Grid>
                ))}
            </Grid>
        </ComponentDialog>
    );
};
