import React, { useEffect, useRef } from "react";
import { Box, Stack } from "@mui/material";
import useRefState from "../../../../../../../common/hooks/useRefState";
import { ResizeHandlerBox } from "../../submissions/components/ResizeHandlerBox";
import { LoadingOverlay } from "../../../../../../../common/templates/LoadingOverlay";
import { NoExecuteResult } from "./NoExecuteResult";
import { UserInputBox } from "../../submissions/templates/UserInputBox";
import { ExecuteResultBox } from "./ExecuteResultBox";

export const ExecuteResultTemplate = ({
    activeTab,
    isLightTheme,
    offsetHeight,
    onClose,
    isExecuting,
    result,
    userInput,
    handleChange,
    isGPTSummaryExecuting,
    gptSummaryMessage,
    aiEnabled,
}) => {
    const panelRef = useRef(null);
    const resizeHandlerRef = useRef(null);

    const [heightRef, setHeight] = useRefState(300);
    const [isResizeRef, setIsResize] = useRefState(false);
    const [lastYRef, setLastY] = useRefState(0);
    const [lastHeightRef, setLastHeight] = useRefState(300);

    useEffect(() => {
        const handleMove = (e) => {
            if (isResizeRef.current) {
                const newHeight = Math.min(
                    offsetHeight,
                    Math.max(
                        0,
                        lastHeightRef.current + (lastYRef.current - e.clientY)
                    )
                );
                if (newHeight === 0) {
                    setIsResize(false);
                    setHeight(300);
                    setLastHeight(300);
                    onClose();
                } else {
                    setHeight(newHeight);
                }
            }
        };

        const handleDown = (e) => {
            const handlerTriggered = e.target === resizeHandlerRef.current;
            if (handlerTriggered) {
                setLastY(e.clientY);
            }
            setIsResize(handlerTriggered);
        };

        const handleUp = (e) => {
            setIsResize(false);
            setLastY(e.clientY);
            setLastHeight(heightRef.current);
        };

        window.addEventListener("mousemove", handleMove);
        window.addEventListener("mousedown", handleDown);
        window.addEventListener("mouseup", handleUp);
        return () => {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mousedown", handleDown);
            window.removeEventListener("mouseup", handleUp);
        };
    }, []);

    const getContent = (
        _isExecuting,
        _result,
        _activeTab,
        _isGPTSummaryExecuting,
        _gptSummaryMessage
    ) => {
        if (_isExecuting)
            return (
                <LoadingOverlay
                    active={_isExecuting}
                    text="코드를 실행시키는 중입니다."
                />
            );

        if (_activeTab === "testCases") {
            return (
                <UserInputBox
                    userInput={userInput}
                    handleChange={handleChange}
                />
            );
        }

        if (_activeTab === "executeResult" && !_result) {
            return <NoExecuteResult />;
        }

        return (
            <ExecuteResultBox
                result={_result}
                onClose={onClose}
                isGPTSummaryExecuting={isGPTSummaryExecuting}
                gptSummaryMessage={gptSummaryMessage}
                aiEnabled={aiEnabled}
            />
        );
    };

    return (
        <Stack
            direction="column"
            ref={panelRef}
            sx={{
                height: `${heightRef.current}px`,
                backgroundColor: isLightTheme ? "#FFFFFF" : "#23241F",
                borderTopWidth: 1,
                borderTopColor: isLightTheme ? "#E1E1E7" : "#41444A",
                borderTopStyle: "solid",
                overflow: "hidden",
            }}
        >
            <ResizeHandlerBox ref={resizeHandlerRef} />
            <Box height="100%" px={3} pb={1.5}>
                {getContent(
                    isExecuting,
                    result,
                    activeTab,
                    isGPTSummaryExecuting,
                    gptSummaryMessage
                )}
            </Box>
        </Stack>
    );
};
