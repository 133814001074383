import React, { useState } from "react";
import { Box } from "@mui/material";
import { CommentEditor } from "./CommentEditor";
import { CommentView } from "./CommentView";

export const Comment = ({ comment, handleEdit }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = (inputs) => {
        handleEdit(inputs, comment.id);
        setIsEditing(false);
    };

    const toggleIsEditing = () => {
        setIsEditing((prev) => !prev);
    };

    return isEditing ? (
        <Box mb={3}>
            <CommentEditor
                onSubmit={handleSubmit}
                isEditing
                comment={comment}
                toggleIsEditing={toggleIsEditing}
            />
        </Box>
    ) : (
        <CommentView comment={comment} toggleIsEditing={toggleIsEditing} />
    );
};
