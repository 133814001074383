import { styled, Toolbar } from "@mui/material";

export const ResponsiveToolbar = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 50,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 48,
    },
    [theme.breakpoints.up("sm")]: {
        minHeight: 54,
    },
}));
