import React from "react";
import { Stack, useTheme } from "@mui/material";
import { SequentialChoiceHeader } from "./SequentialChoiceHeader";
import { observer } from "mobx-react-lite";
import { SelectionButton } from "../components/SelectionButton";
import { Markdown } from "../../../../../../../common/components/markdown/Markdown";
import { MotherTongueType } from "../../../../../../../common/modules/auth/UserModel";
import { ProblemType } from "../../../../../../../common/modules/missions/mission/MissionModel";
import { useStores } from "../../../../../../../common/modules/useStores";

export const MultipleChoiceEditor = observer(
    ({
        mcQuestions,
        handleSelection,
        type,
        mcAnswersCount,
        mcQuestionsEn,
        selections,
    }) => {
        const theme = useTheme();

        const { authStore } = useStores();
        const { currUser } = authStore;

        const isSequentialChoice = type === ProblemType.SequentialChoice;

        const questions =
            currUser?.motherTongue === MotherTongueType.ENG &&
            mcQuestionsEn.length
                ? mcQuestionsEn
                : mcQuestions;

        return (
            <Stack
                direction={"column"}
                height={"100%"}
                alignItems={"center"}
                overflow={"auto"}
                spacing={5}
                sx={{
                    bgcolor:
                        theme.palette.mode === "light" ? "#ffffff" : "#1E2025",
                    py: 5,
                    color:
                        theme.palette.mode === "light" ? "#121212" : "#E3E5EB",
                }}
            >
                {isSequentialChoice ? (
                    <SequentialChoiceHeader
                        selections={selections}
                        mcAnswersCount={mcAnswersCount}
                    />
                ) : null}
                <Stack direction={"column"} spacing={2} width={"80%"}>
                    {questions.map((question, i) => (
                        <SelectionButton
                            key={`selection-button-${i}`}
                            onClick={() => handleSelection(i)}
                            active={selections.some(
                                (selection) => selection === i
                            )}
                        >
                            <Markdown
                                style={{
                                    backgroundColor: "transparent",
                                    color:
                                        theme.palette.mode === "light"
                                            ? "#121212"
                                            : "#E3E5EB",
                                }}
                                children={question}
                            />
                        </SelectionButton>
                    ))}
                </Stack>
            </Stack>
        );
    }
);
