import React from "react";
import { Grid } from "@mui/material";
import { ComponentDialog } from "../../components/dialog/ComponentDialog";
import { ContentBox } from "./ContentBox";

export const QnaDialog = ({ open, onClose }) => {
    const Contents = [
        {
            title: "부담 없이 질문 하기",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/qna_01_x4.png",
            description:
                "단순하게 떠올릴 수 있는 방법부터 가장 효율적인 방법까지 제시합니다.",
        },
        {
            title: "함께 성장하기",
            image: "https://contents.codetree.ai/homepage/images/main_page/catalog/dialog/qna_02_x4.png.png",
            description:
                "바로 옆에서 알려주는 느낌의 친절한 해설이 자세하게 가르쳐드립니다.",
        },
    ];

    return (
        <ComponentDialog
            open={open}
            onClose={onClose}
            title="함께 성장하는 열린 토론의 장"
            description="부담갖지 말고 궁금한 건 함께 나눠보세요. 신속하고 친절한 답변을 받을 수 있습니다."
        >
            <Grid
                container
                direction="row"
                spacing={2.5}
                sx={{
                    mt: 5,
                }}
                flexWrap="wrap"
                justifyContent="center"
            >
                {Contents.map((content, i) => (
                    <Grid item key={`qna-dialog-content-${i}`}>
                        <ContentBox width={350} imageWidth={216} {...content} />
                    </Grid>
                ))}
            </Grid>
        </ComponentDialog>
    );
};
