import { pickBy } from "lodash/object";
import { clone, curry, setWith } from "lodash/fp";

export const pickCls = (obj, data) => {
    const fieldNames = Object.getOwnPropertyNames(obj);
    const clsData = pickBy(
        data,
        (value, key) => fieldNames.includes(key) && typeof value !== "function"
    );
    return clsData;
};

export const objectToQueryString = (obj) =>
    Object.keys(obj).reduce((acc, curr) => {
        if (!(curr && obj[curr])) return acc;

        return `${acc}&${curr}=${obj[curr]}`;
    }, "?");

export const setIn = curry((path, value, obj) =>
    setWith(clone, path, value, clone(obj))
);
