import React from "react";
import { IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";

export const AppBarLogo = ({ redirectUrl }) => {
    const navigate = useNavigate();

    return (
        <IconButton onClick={() => navigate(redirectUrl)}>
            <HomeOutlinedIcon />
        </IconButton>
    );
};
