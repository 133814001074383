import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";
import { Box, Button, Stack, Tooltip, Typography, darken } from "@mui/material";
import { ProblemStatusType } from "../../../../common/modules/missions/curatedProblem/CuratedProblemModel";
import moment from "moment";
import { SubmissionStatusType } from "../../../../common/modules/submission/SubmissionModel";
import { Link, useNavigate } from "react-router-dom";
import { AdminAlertDialog } from "../../../../common/components/dialog/AdminAlertDialog";

const DeleteBox = ({ rowIdx, fiddleProgress, handleDelete }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);

    const onConfirm = () => {
        handleDelete(fiddleProgress);
        setDeleteOpen(false);
    };

    return (
        <>
            <Box
                direction={"row"}
                p={20 / 8}
                sx={{
                    background: rowIdx % 2 ? "#ffffff" : "#FAFAFA",
                }}
                spacing={2}
            >
                <Button
                    onClick={() => setDeleteOpen(true)}
                    variant="contained"
                    sx={{
                        bgcolor: "#D24D57",
                        color: "#fff",
                        "&:hover": {
                            bgcolor: darken("#D24D57", 0.1),
                        },
                    }}
                >
                    삭제
                </Button>
                <AdminAlertDialog
                    open={deleteOpen}
                    onClose={() => setDeleteOpen(false)}
                    title={"선택한 퀴즈가 삭제됩니다."}
                    description={
                        "삭제한 퀴즈는 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
                    }
                    onConfirm={onConfirm}
                />
            </Box>
        </>
    );
};

export const QuizDashboardContainer = observer(() => {
    const { fiddleProgressStore, classroomStore, fiddleStore } = useStores();
    const { isFetching, fiddleProgresses } = fiddleProgressStore;
    const { classroom } = classroomStore;

    const navigate = useNavigate();

    useEffect(() => {
        if (classroom.id) {
            fiddleProgressStore.findAll();
        }
    }, [classroom]);

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="퀴즈 정보를 불러오고 있습니다."
            />
        );

    const redirectToFiddleSubmissions = (fiddleId, userId) => {
        navigate(`users/${userId}/quiz/${fiddleId}`);
    };

    const handleDelete = (fiddleProgress) => {
        fiddleStore.delete(fiddleProgress);
        fiddleProgressStore.deleteLocalFiddle(fiddleProgress);
    };

    return fiddleProgresses.length ? (
        <Stack p={5}>
            <Stack
                sx={{
                    display: "grid",
                    gridTemplateColumns:
                        "minmax(max-content, 240px) 100px 1fr 100px",
                    columnGap: 4 / 8,
                    width: "100%",
                    alignItems: "stretch",
                }}
            >
                <>
                    <Stack p={20 / 8}>
                        <Typography variant="body2">
                            퀴즈(최근 생성순)
                        </Typography>
                    </Stack>
                    <Stack p={20 / 8}>
                        <Typography variant="body2">완료율</Typography>
                    </Stack>
                    <Stack direction={"row"} p={20 / 8}>
                        <Typography variant="body2" color={"#009D27"}>
                            푼 사람(해결순)
                        </Typography>
                        <Typography variant="body2">/</Typography>
                        <Typography variant="body2" color={"#FFC433"}>
                            시도했지만 틀린 사람
                        </Typography>
                        <Typography variant="body2">/</Typography>
                        <Typography variant="body2" color={"#666666"}>
                            시도하지 않은 사람
                        </Typography>
                    </Stack>
                    <Stack p={20 / 8}>
                        <Typography variant="body2">기타</Typography>
                    </Stack>
                </>

                {fiddleProgresses.map((fiddleProgress, rowIdx) => (
                    <>
                        <Stack
                            p={20 / 8}
                            sx={{
                                background: rowIdx % 2 ? "#ffffff" : "#FAFAFA",
                            }}
                        >
                            <Typography
                                component={Link}
                                to={`/psfiddle/${fiddleProgress.key}`}
                                variant="body2"
                                color="#666666"
                            >
                                {fiddleProgress.title.split(/\r?\n/).length > 1
                                    ? `${
                                          fiddleProgress.title.split(/\r?\n/)[0]
                                      } (...생략)`
                                    : fiddleProgress.title}
                            </Typography>
                        </Stack>
                        <Stack
                            p={20 / 8}
                            sx={{
                                textAlign: "center",
                                background: rowIdx % 2 ? "#ffffff" : "#FAFAFA",
                            }}
                        >
                            <Typography variant="body2" color={"#18A0FB"}>
                                {fiddleProgress.passedCount}명 완료
                            </Typography>
                            <Typography variant="body2" color={"#18A0FB"}>
                                {fiddleProgress.passedCount}/
                                {fiddleProgress.studentCount}(
                                {parseInt(
                                    (fiddleProgress.passedCount /
                                        fiddleProgress.studentCount) *
                                        100
                                )}
                                %)
                            </Typography>
                        </Stack>
                        <Box
                            direction={"row"}
                            p={20 / 8}
                            sx={{
                                background: rowIdx % 2 ? "#ffffff" : "#FAFAFA",
                            }}
                            spacing={2}
                        >
                            {fiddleProgress.fiddleUserProgresses.map(
                                (userProgress) => (
                                    <Tooltip
                                        key={userProgress.userId}
                                        title={
                                            userProgress.status ===
                                            SubmissionStatusType.Passed
                                                ? moment(
                                                      userProgress.solvedAt
                                                  ).format("LLL")
                                                : "해결 전"
                                        }
                                        placement={"top"}
                                        arrow={true}
                                    >
                                        <Typography
                                            display="inline-block"
                                            variant="body2"
                                            sx={{
                                                color:
                                                    userProgress.status ===
                                                    SubmissionStatusType.Passed
                                                        ? "#009D27"
                                                        : userProgress.status ===
                                                          ProblemStatusType.Ready
                                                        ? "#666666"
                                                        : "#FFC433",
                                                cursor:
                                                    userProgress.status ===
                                                    ProblemStatusType.Ready
                                                        ? "inherit"
                                                        : "pointer",
                                                textDecoration:
                                                    userProgress.status ===
                                                    ProblemStatusType.Ready
                                                        ? "inherit"
                                                        : "underline",
                                                mr: 1,
                                            }}
                                            onClick={() => {
                                                if (
                                                    userProgress.status !==
                                                    ProblemStatusType.Ready
                                                ) {
                                                    redirectToFiddleSubmissions(
                                                        fiddleProgress.id,
                                                        userProgress.userId
                                                    );
                                                }
                                            }}
                                        >
                                            {userProgress.name}(
                                            {userProgress.username})
                                        </Typography>
                                    </Tooltip>
                                )
                            )}
                        </Box>
                        <DeleteBox
                            rowIdx={rowIdx}
                            fiddleProgress={fiddleProgress}
                            handleDelete={handleDelete}
                        />
                    </>
                ))}
            </Stack>
        </Stack>
    ) : (
        <NoResultsTemplate text={"퀴즈 제출 정보가 존재하지 않습니다."} />
    );
});
