import React from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { Button, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const ReplyOpenButton = ({ toggleIsOpen, replyNum, isOpen }) => {
    const getIcon = (_isOpen, replyNum) => {
        if (_isOpen) {
            return <ExpandLessIcon />;
        }
        if (replyNum) {
            return <ReplyIcon />;
        }
        return <AddCommentIcon />;
    };

    const getContent = (_isOpen) => {
        if (_isOpen) {
            return (
                <Typography variant="body2" ml={0.5} children="답글 숨기기" />
            );
        }
        if (replyNum) {
            return (
                <Stack direction="row" alignItems="center">
                    {replyNum === 1 ? <PersonIcon /> : <GroupIcon />}
                    <Typography
                        variant="body2"
                        ml={0.5}
                        children={`답글 ${replyNum}개`}
                    />
                </Stack>
            );
        }

        return <Typography variant="body2" ml={0.5} children="답글 달기" />;
    };

    return (
        <Button
            color="info"
            onClick={toggleIsOpen}
            startIcon={getIcon(isOpen, replyNum)}
        >
            {getContent(isOpen)}
        </Button>
    );
};
