import React from "react";
import { keyframes, Stack, Typography } from "@mui/material";
import { OpenBook } from "../../../../common/components/book/OpenBook";
import {
    MissionNameType,
    MissionNameTypeToColor,
} from "../../../../common/modules/missions/mission/MissionModel";
import { BookThemeType, SizeType } from "../../../../common/modules/ui/UIModel";
import { MissionBook } from "../../../../common/components/book/MissionBook";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";

const MissionNameTypeToBannerTitle = {
    [MissionNameType.NoviceLow]: (
        <Typography variant="h1" fontWeight={700}>
            {"확실한 "}
            <span style={{ color: "#DEA61D" }}>첫 단추</span>.
        </Typography>
    ),
    [MissionNameType.NoviceMid]: (
        <Typography variant="h1" fontWeight={700}>
            <span style={{ color: "#AA2A34" }}>생각</span>
            {"을 "}
            <span style={{ color: "#D24D57" }}>코드</span>
            로.
        </Typography>
    ),
    [MissionNameType.NoviceHigh]: (
        <Typography variant="h1" fontWeight={700}>
            <span style={{ color: "#964985" }}>개념</span>을 탄탄히
        </Typography>
    ),
    [MissionNameType.IntermediateLow]: (
        <Typography variant="h1" fontWeight={700}>
            <span style={{ color: "#5D7377" }}>실수 없는</span>
            {" 코드 작성"}
        </Typography>
    ),
    [MissionNameType.IntermediateMid]: (
        <Typography variant="h1" fontWeight={700}>
            <span style={{ color: "#6B9362" }}>정확</span>
            {"하게 "}
            <span style={{ color: "#3A5A33" }}>효율적</span>
            으로.
        </Typography>
    ),
    [MissionNameType.IntermediateHigh]: (
        <Typography variant="h1" fontWeight={700}>
            <span style={{ color: "#114FAC" }}>빈틈 없는</span>
            {" 실력"}
        </Typography>
    ),
};

const slideUp = keyframes`
    0% {
      transform: translateY(100%)
    },
    100% {
      transform: translateY(0)
    }
`;

const slideDown = keyframes`
    0% {
      transform: translateY(-100%)
    },
    100% {
      transform: translateY(0)
    }
`;

export const OverviewHeader = ({ mission }) => (
    <MainTemplateWrapper
        sx={{
            height: 205,
            overflow: "hidden",
        }}
    >
        <Stack
            width="100%"
            height="100%"
            sx={{
                position: "relative",
            }}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            {MissionNameTypeToBannerTitle[mission.name]}
            <OpenBook
                color={MissionNameTypeToColor[mission.name]}
                size={SizeType.Large}
                sx={{
                    position: "absolute",
                    bottom: -130,
                    left: 0,
                    animation: `${slideUp} 1s cubic-bezier(0.22, 1, 0.36, 1)`,
                }}
            />
            <MissionBook
                color={MissionNameTypeToColor[mission.name]}
                size={SizeType.Large}
                theme={BookThemeType.Light}
                sx={{
                    position: "absolute",
                    top: -130,
                    right: 0,
                    animation: `${slideDown} 1s cubic-bezier(0.22, 1, 0.36, 1)`,
                }}
            />
        </Stack>
    </MainTemplateWrapper>
);
