import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { ExplanationAccordion } from "./components/ExplanationAccordion";
import { NoResultsTemplate } from "../NoResultsTemplate";

export const ExplanationTemplate = ({ explanation }) => {
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h4" children="Solution" />
            <Divider />
            <Stack direction="column">
                {explanation.solutions.length ? (
                    explanation.solutions.map((solution) => (
                        <ExplanationAccordion
                            solution={solution}
                            key={`solution-${solution.id}`}
                            solutionsCount={explanation.solutions.length}
                        />
                    ))
                ) : (
                    <NoResultsTemplate text="아직 등록된 솔루션이 없습니다." />
                )}
            </Stack>
        </Stack>
    );
};
