import { styled } from "@mui/material";

export const IndicatorDiv = styled("div")(({ theme }) => ({
    animation: "blink 1.2s linear infinite",
    width: 8,
    height: 30,
    backgroundColor: "#43ED9B",
    "@keyframes blink": {
        from: {
            opacity: 1,
        },
        "30%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0,
        },
        "70%": {
            opacity: 1,
        },
        to: {
            opacity: 1,
        },
    },
    [theme.breakpoints.down("md")]: {
        width: 6,
        height: 24,
    },
}));
