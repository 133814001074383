import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Paper, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StudentAutoComplete } from "../../templates/StudentAutoComplete";
import { QuizSelector } from "../../templates/QuizSelector";

export const QuizDashboardDetailHeader = ({ userId, fiddleId }) => {
    const navigate = useNavigate();

    const redirectToUser = (_userId) => {
        navigate(`/admin/quiz-dashboard/users/${_userId}/quiz/${fiddleId}`, {
            replace: true,
        });
    };

    const redirectToQuiz = (_fiddleId) => {
        navigate(`/admin/quiz-dashboard/users/${userId}/quiz/${_fiddleId}`, {
            replace: true,
        });
    };

    return (
        <Paper
            sx={{
                p: 2.5,
            }}
        >
            <Stack direction="row" spacing={1.25} alignItems="center">
                <IconButton
                    children={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                />
                <StudentAutoComplete
                    userId={Number(userId)}
                    setUserId={redirectToUser}
                />
                <QuizSelector
                    sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: 8 / 4,
                        border: "2px solid #e1e1e7",
                        padding: 16 / 8,
                        height: 76,
                        marginRight: 0,
                        color: "#000000",
                        flex: 1,
                        boxShadow: "none",
                    }}
                    fiddleId={Number(fiddleId)}
                    setFiddleId={redirectToQuiz}
                />
                {/* <HomeworkSelector
                    homeworks={homeworks}
                    homeworkId={homeworkId}
                    setHomeworkId={redirectToHomework}
                />
                <CuratedProblemAutoCompleteContainer
                    homeworkId={homeworkId}
                    problemId={problemId}
                    setProblemId={redirectToProblem}
                />
                <LargeButton
                    label="문제보기"
                    color="#FFFFFF"
                    backgroundColor="#000000"
                    endIcon={<OpenInNewIcon />}
                    onClick={() =>
                        redirectToPlaygroundPage(problem && problem.number)
                    }
                /> */}
            </Stack>
        </Paper>
    );
};
