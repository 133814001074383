import { makeAutoObservable } from "mobx";
import { MissionProgressModel } from "./MissionProgressModel";
import MissionProgressRepository from "./MissionProgressRepository";

export default class MissionProgressStore {
    missionProgresses = [];

    isFetching = true;

    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    _replaceMissionProgresses(response) {
        const { results, count } = response.data;

        this.missionProgresses.replace(
            results.map(
                (missionProgress) => new MissionProgressModel(missionProgress)
            )
        );

        this.count = count;
    }

    *findAll(params) {
        this.isFetching = true;
        const response = yield MissionProgressRepository.findAll({
            classroomId: this.rootStore.classroomStore.classroom.id,
            ...params,
        });
        this._replaceMissionProgresses(response);
        this.isFetching = false;
    }
}
