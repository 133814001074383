import React from "react";
import { SectionTag } from "./SectionTag";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Catalog } from "./Catalog";

// Import Swiper styles
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";

export const CatalogList = ({ missions, autoPlay }) => {
    const theme = useTheme();
    const mdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

    const navigate = useNavigate();

    const navigateToCurriculumPage = (missionId) => {
        navigate(`/curriculums/${missionId}`);
    };

    return (
        <Stack direction="column">
            <SectionTag
                title="국가대표 커리큘럼"
                description={
                    "순서대로 차근차근\n단계별 학습 가이드를 만나보세요."
                }
            />
            <Stack direction="column" mt={-4.5} spacing={5}>
                <Stack direction="row" spacing={1.25} alignSelf="flex-end">
                    <IconButton
                        sx={{
                            backgroundColor: "#FFFSFFF",
                            p: {
                                xs: 0.75,
                                md: 1,
                            },
                        }}
                        className="prev"
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                        sx={{
                            backgroundColor: "#FFFFFF",
                            p: {
                                xs: 0.75,
                                md: 1,
                            },
                        }}
                        className="next"
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    navigation={{
                        prevEl: ".prev",
                        nextEl: ".next",
                    }}
                    grabCursor
                    style={{
                        overflow: "visible",
                    }}
                    autoplay={
                        autoPlay && {
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }
                    }
                    slidesPerView={1}
                    spaceBetween={20}
                    width={mdBreakpoint ? 271 : 196}
                >
                    {missions.map((mission) => (
                        <SwiperSlide key={`missions-${mission.id}`}>
                            <Catalog
                                mission={mission}
                                onClick={() =>
                                    navigateToCurriculumPage(mission.id)
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Stack>
        </Stack>
    );
};
