import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IntroductionTemplate } from "./IntroductionTemplate";
import { useStores } from "../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../NoResultsTemplate";
import {
    ProblemClickDAOModel,
    ProblemClickEvent,
} from "../../../../../../common/modules/problems/ProblemModel";
import _ from "lodash";
import { UserProfileDAOModel } from "../../../../../../common/modules/auth/UserModel";

export const IntroductionContainer = observer(
    ({ problem, objectId, stepInfo }) => {
        const { problemStore, authStore } = useStores();
        const { introductions, introLoading } = problemStore;

        useEffect(() => {
            const body = new ProblemClickDAOModel({
                event: ProblemClickEvent.Introduction,
                problem: problem.id,
            });
            problemStore.addClickEvent({ body });
            if (
                !(
                    introductions &&
                    introductions.length &&
                    introductions[0].problemId === problem.id
                )
            )
                problemStore.getIntroductions({
                    missionId: objectId,
                    problemId: problem.id,
                });
        }, [problem]);

        const handleLanguageAPIChange = _.debounce((language) => {
            const body = new UserProfileDAOModel({ language });
            authStore.updateProfile({ body });
        }, 1000);

        if (introLoading)
            return (
                <LoadingOverlay
                    active={introLoading}
                    text="기본 개념을 불러오는 중입니다."
                />
            );

        return introductions && introductions.length ? (
            <IntroductionTemplate
                introductions={introductions}
                handleLanguageAPIChange={handleLanguageAPIChange}
                stepInfo={stepInfo}
            />
        ) : (
            <NoResultsTemplate text="아직 등록된 기본 개념이 없습니다." />
        );
    }
);
