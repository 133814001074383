import React from "react";
import { Route, Routes } from "react-router-dom";
import { ClassroomDashboardContainer } from "./ClassroomDashboardContainer";
import { ClassroomDashboardDetailContainer } from "./ClassroomDashboardDetailContainer";

export const ClassroomDashboardPage = () => (
    <Routes>
        <Route path="/" element={<ClassroomDashboardContainer />} />
        <Route
            path="/users/:userId/missions/:missionId/problems/:problemId"
            element={<ClassroomDashboardDetailContainer />}
        />
    </Routes>
);
