import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ExplanationTemplate } from "./ExplanationTemplate";
import { useStores } from "../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import {
    ProblemClickDAOModel,
    ProblemClickEvent,
} from "../../../../../../common/modules/problems/ProblemModel";

export const ExplanationContainer = observer(({ problem }) => {
    const { problemStore } = useStores();
    const { explanation, explanationLoading } = problemStore;

    useEffect(() => {
        const body = new ProblemClickDAOModel({
            event: ProblemClickEvent.Solution,
            problem: problem.id,
        });
        problemStore.addClickEvent({ body });
        problemStore.getExplanation({ problemId: problem.id });
    }, [problem]);

    if (explanationLoading)
        return (
            <LoadingOverlay
                active={explanationLoading}
                text="해설을 불러오는 중입니다."
            />
        );

    return <ExplanationTemplate explanation={explanation} />;
});
