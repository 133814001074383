import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { useStores } from "../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { DescriptionTemplate } from "./DescriptionTemplate";
import { UserProfileDAOModel } from "../../../../../../common/modules/auth/UserModel";
import {
    ProblemClickDAOModel,
    ProblemClickEvent,
} from "../../../../../../common/modules/problems/ProblemModel";

export const DescriptionContainer = observer(({ problem, stepInfo }) => {
    const { problemStore, authStore } = useStores();
    const { descriptionModel } = problemStore;
    const { currUser, updateProfile } = authStore;

    const loading =
        (descriptionModel && descriptionModel.problemId) !== problem.id;

    const changeMotherTongueAPI = _.debounce((motherTongue) => {
        const body = new UserProfileDAOModel({ motherTongue });
        updateProfile({ body });
    }, 1000);

    useEffect(() => {
        const body = new ProblemClickDAOModel({
            event: ProblemClickEvent.Description,
            problem: problem.id,
        });
        problemStore.addClickEvent({ body });
        if (loading) problemStore.getDescription({ problemId: problem.id });
    }, [problem]);

    if (loading)
        return (
            <LoadingOverlay
                active={loading}
                text="문제 설명을 불러오는 중입니다."
            />
        );

    return (
        <DescriptionTemplate
            descriptionModel={descriptionModel}
            defaultMotherTongue={currUser && currUser.motherTongue}
            changeMotherTongueAPI={changeMotherTongueAPI}
            stepInfo={stepInfo}
        />
    );
});
