import { useEffect } from "react";
import { useStores } from "../../../../common/modules/useStores";
import { useNavigate, useParams } from "react-router-dom";

export const PSFiddleRedirect = () => {
    const { fiddleStore } = useStores();
    const navigate = useNavigate();

    const { fiddleKey } = useParams();

    useEffect(() => {
        fiddleStore
            .find({ fiddleKey })
            .then((fiddle) => navigate(`${fiddle.id}`, { replace: true }));
    }, []);

    return null;
};
