import React from "react";
import { TablePagination } from "@mui/material";

export const DataGridPagination = ({
    page,
    count,
    setPage,
    rowsPerPage,
    setRowsPerPage,
}) => {
    console.log(page, count, rowsPerPage);
    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={(event, value) => setPage(value)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
            labelRowsPerPage={`페이지당 표시 항목 수`}
            labelDisplayedRows={({ from, to, count }) => {
                return `${from}부터–${to}까지 (전체: ${
                    count !== -1 ? count : `more than ${to}`
                })`;
            }}
        />
    );
};
