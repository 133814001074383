import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginContainer } from "./LoginContainer";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { useStores } from "../../../../common/modules/useStores";
import { observer } from "mobx-react-lite";
import { SocialLoginContainer } from "./SocialLogin/SocialLoginContainer";

export const LoginPage = observer(() => {
    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    if (isUserLoggedIn()) {
        return <Navigate to={"/"} replace />;
    }

    if (hasToken()) {
        return <LoadingOverlay active />;
    }

    return (
        <Routes>
            <Route path={""} element={<LoginContainer />} />
            <Route path={":authProvider"} element={<SocialLoginContainer />} />
        </Routes>
    );
});
