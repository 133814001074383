import { makeAutoObservable, set } from "mobx";
import { pickCls } from "../../../utils/immutableLodash";
import { camelizeKeys } from "../../../utils/renameKeys";

export class NotificationModel {
    id = 0;

    linkUrl = "";

    content = "";

    isSeen = false;

    isRead = false;

    createdAt = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const notification = pickCls(this, camelizeKeys(data));
        set(this, notification);
    }
}
