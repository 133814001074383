import React from "react";
import { Stack } from "@mui/material";
import { SelectionBox } from "../components/SelectionBox";

export const SequentialChoiceHeader = ({ mcAnswersCount, selections }) => (
    <Stack direction="row" spacing={2}>
        {new Array(mcAnswersCount).fill(1).map((_, i) => (
            <SelectionBox
                key={`selection-box-${i}`}
                label={selections.length > i ? selections[i] + 1 : "?"}
                isEmpty={selections.length <= i}
            />
        ))}
    </Stack>
);
