import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { HomeworkDashboardTemplate } from "./HomeworkDashboardTemplate";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";

export const HomeworkDashboardContainer = observer(() => {
    const { userHomeworkProgressStore, classroomStore } = useStores();

    const { classroom } = classroomStore;
    const { userHomeworkProgresses, isFetching, count, isDownloading } =
        userHomeworkProgressStore;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchWord, setSearchWord] = useState("");

    const sortOptions = [
        {
            label: "이름순",
            key: "name_asc",
        },
        {
            label: "이름 역순",
            key: "name_desc",
        },
        {
            label: "아이디순",
            key: "username_asc",
        },
        {
            label: "아이디 역순",
            key: "username_desc",
        },
        {
            label: "이수율 높은순",
            key: "progress_desc",
        },
        {
            label: "이수율 낮은순",
            key: "progress_asc",
        },
    ];

    const [sortOption, selectSortOption] = useState(sortOptions[0]);

    useEffect(() => {
        userHomeworkProgressStore.findAll({
            page: page + 1,
            pageSize: rowsPerPage,
            searchWord,
            orderBy: sortOption.key,
        });
    }, [classroom, page, rowsPerPage, sortOption.key]);

    const searchHomeworks = (value) => {
        userHomeworkProgressStore.findAll({
            page: page + 1,
            pageSize: rowsPerPage,
            searchWord: value,
            orderBy: sortOption.key,
        });
    };

    const handleDownload = () => {
        userHomeworkProgressStore.download();
    };

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="진도 정보를 불러오고 있습니다."
            />
        );

    return userHomeworkProgresses.length ? (
        <HomeworkDashboardTemplate
            userHomeworkProgresses={userHomeworkProgresses}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            searchHomeworks={searchHomeworks}
            sortOptions={sortOptions}
            sortOption={sortOption}
            selectSortOption={selectSortOption}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
        />
    ) : (
        <NoResultsTemplate
            text={"학생 정보가 존재하지 않습니다.\n학생을 추가해주세요"}
        />
    );
});
