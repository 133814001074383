import React from "react";
import { Stack, Typography } from "@mui/material";
import { ConceptBox } from "./ConceptBox";

export const MissionBox = ({
    inputs,
    mission,
    handleClickProblem,
    handleScoreChange,
    handleSolutionVisibleChange,
    handleAIEnabledChange,
}) => (
    <Stack direction="column" spacing={1.25}>
        <Typography
            variant="subtitle1"
            fontWeight={700}
            color="#999999"
            children={`${mission.name} - ${mission.copy}`}
        />
        <Stack direction="column" spacing={2}>
            {[...mission.concepts]
                .sort((a, b) => a.level - b.level)
                .map((concept) => (
                    <ConceptBox
                        key={`concept-box-${concept.id}`}
                        inputs={inputs}
                        concept={concept}
                        handleClickProblem={handleClickProblem}
                        handleScoreChange={handleScoreChange}
                        handleSolutionVisibleChange={
                            handleSolutionVisibleChange
                        }
                        handleAIEnabledChange={handleAIEnabledChange}
                    />
                ))}
        </Stack>
    </Stack>
);
