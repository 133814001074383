import React, { forwardRef } from "react";
import { alpha, Button, styled, Typography } from "@mui/material";

const MiddleButtonComponent = styled(Button)(
    ({ theme, ownerState: { backgroundColor, color } }) => ({
        borderRadius: theme.shape,
        padding: theme.spacing(1, 2),
        color,
        backgroundColor,
        minWidth: "max-content",
        "&:hover": {
            backgroundColor: alpha(backgroundColor, 0.5),
        },
        "& .MuiButton-endIcon": {
            marginLeft: theme.spacing(0.5),
        },
    })
);

export const MiddleButton = forwardRef(
    ({ label, backgroundColor, color, typographySx, ...props }, ref) => (
        <MiddleButtonComponent
            ref={ref}
            ownerState={{ backgroundColor, color }}
            {...props}
        >
            <Typography variant={"body2"} sx={typographySx}>
                {label}
            </Typography>
        </MiddleButtonComponent>
    )
);
