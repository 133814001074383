import React, { useState } from "react";
import {
    alpha,
    Button,
    Checkbox,
    darken,
    Dialog,
    DialogActions,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { setIn } from "../../../../../utils/immutableLodash";
import { useStores } from "../../../../../common/modules/useStores";
import { snackActions } from "../../../../../utils/SnackbarUtilsConfigurator";
import { errorToast } from "../../../../../utils/errorToast";
import { LargeWhiteTextField } from "../../../../../common/components/textField/LargeWhiteTextField";
import { HomeworkDAOModel } from "../../../../../common/modules/homework/HomeworkModel";

export const HomeworkCopyDialog = ({ homework, open, onClose }) => {
    const { classroomStore, homeworkStore } = useStores();

    const { classrooms } = classroomStore;

    const [inputs, setInputs] = useState({
        name: `${homework.name}의 복사본`,
    });

    const [checked, setChecked] = useState([]);

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const handleSubmit = (_inputs) => {
        const serializedHomework = {
            ...homework,
            curatedProblems: homework.curatedProblems.map(
                ({ curatedProblemId, score, solutionVisible }) => ({
                    curatedProblemId,
                    score,
                    solutionVisible,
                })
            ),
            isPublic: false,
            lessonTemplateId: null,
        };

        const body = checked.map((checkedIdx) => {
            return new HomeworkDAOModel({
                ...serializedHomework,
                ...inputs,
                classroom: classrooms[checkedIdx].id,
            });
        });

        homeworkStore
            .add({ body })
            .then(() => {
                snackActions.success("성공적으로 복사되었습니다.");
                onClose();
            })
            .catch((e) => {
                errorToast(e);
            });
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    bgcolor: "common.white",
                    borderRadius: 3,
                    width: 551,
                },
            }}
        >
            <Stack
                direction="column"
                spacing={2.5}
                backgroundColor="#F5F5F7"
                p={2.5}
                width="100%"
            >
                <Typography variant="h4" fontWeight={800}>
                    수업/과제 복사
                </Typography>
                <Stack>
                    <Typography variant="body2">복사할 학급 선택</Typography>
                    <List>
                        {classrooms.map((classroom, idx) => (
                            <ListItem
                                key={classroom.id}
                                sx={{
                                    bgcolor: "#fff",
                                }}
                                disablePadding
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={handleToggle(idx)}
                                    dense
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={
                                                checked.indexOf(idx) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                "aria-labelledby": classroom.id,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={classroom.id}
                                        primary={`${classroom.name}`}
                                        secondary={`${classroom.startedDate} ~ ${classroom.finishedDate}`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Stack>

                <LargeWhiteTextField
                    label="복제된 수업/과제 이름"
                    onChange={(e) => {
                        handleChange("name", e.target.value);
                    }}
                    value={inputs.name}
                    placeholder={inputs.name}
                />
            </Stack>
            <DialogActions>
                <Stack direction="row" spacing={1.25}>
                    <Button
                        sx={{
                            bgcolor: "common.white",
                            color: "#808080",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            border: "1px solid #B3B3B3",
                        }}
                        children="취소"
                        onClick={onClose}
                    />
                    <Button
                        sx={{
                            bgcolor: "#FF5555",
                            color: "#FFFFFF",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            "&:hover": {
                                bgcolor: darken("#FF5555", 0.1),
                            },
                            "&.Mui-disabled": {
                                bgcolor: alpha("#FF5555", 0.5),
                                color: "#FFFFFF",
                            },
                        }}
                        children={
                            checked.length === 0
                                ? `학급을 골라주세요`
                                : !inputs.name.trim()
                                ? `올바른 이름을 입력해주세요`
                                : `확인`
                        }
                        onClick={() => handleSubmit(inputs)}
                        disabled={checked.length === 0 || !inputs.name.trim()}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
