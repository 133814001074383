import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import { MarkdownEditor } from "../../../../../../../common/components/markdown/MarkdownEditor";
import { AlertDialog } from "../../../../../../../common/components/dialog/AlertDialog";
import { snackActions } from "../../../../../../../utils/SnackbarUtilsConfigurator";
import { pickCls } from "../../../../../../../utils/immutableLodash";

export const DiscussionForm = forwardRef((props, ref) => {
    const { confirmText, handleCancel, handleSubmit, discussion } = props;

    const placeholder =
        "글에 코드를 추가하고 싶다면, 다음과 같이 추가할 수 있습니다.\n" +
        "\n" +
        "```python\n" +
        "for i in range(n):\n" +
        "\tprint(i)\n" +
        "```\n" +
        "\n" +
        "```cpp\n" +
        "for(int i = 0; i < n; i++)\n" +
        '\tprintf("%d\\n", i);\n' +
        "```\n" +
        "";

    const [inputs, setInputs] = useState({
        title: "",
        description: placeholder,
        isAnonymous: false,
    });

    useEffect(() => {
        if (discussion) {
            setInputs(pickCls(inputs, discussion));
        }
    }, [discussion]);

    const { title, description, isAnonymous } = inputs;

    const [titleError, setTitleError] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);

    const toggleAlertOpen = () => {
        setAlertOpen((prev) => !prev);
    };

    const changeDescription = (description) => {
        setInputs((prev) => ({
            ...prev,
            description,
        }));
    };

    const changeIsAnonymous = () => {
        setInputs((prev) => ({
            ...prev,
            isAnonymous: !prev.isAnonymous,
        }));
    };

    const changeTitle = (e) => {
        setInputs((prev) => ({
            ...prev,
            title: e.target.value,
        }));
    };

    const validateAndSubmit = (_inputs) => {
        if (!_inputs.title) {
            setTitleError(true);
            return;
        }

        if (_inputs.description.length < 10) {
            snackActions.error("글 내용은 10자 이상이어야 합니다.");
            return;
        }

        handleSubmit(_inputs);
        handleCancel();
    };

    return (
        <Paper
            ref={ref}
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100vw",
                height: "50vh",
                zIndex: 1200,
                borderTop: "1px solid #DDDDDD",
                p: 3,
                pt: 1,
            }}
        >
            <Stack direction="column">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                >
                    <TextField
                        value={title}
                        label="제목"
                        onChange={changeTitle}
                        sx={{
                            width: 400,
                        }}
                        helperText={titleError ? "제목은 필수 항목입니다." : ""}
                        error={titleError}
                    />
                    <Stack direction="row" spacing={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAnonymous}
                                    onClick={changeIsAnonymous}
                                    disabled={!!discussion}
                                />
                            }
                            label="익명으로 질문하기"
                        />
                        <Button
                            variant="contained"
                            color="error"
                            onClick={toggleAlertOpen}
                        >
                            취소
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => validateAndSubmit(inputs)}
                        >
                            {confirmText || "등록"}
                        </Button>
                    </Stack>
                </Stack>
                <MarkdownEditor
                    height={window.innerHeight * 0.45 - 60}
                    value={description}
                    onChange={changeDescription}
                    placeholder={placeholder}
                />
                <AlertDialog
                    open={alertOpen}
                    handleClose={toggleAlertOpen}
                    handleConfirm={() => {
                        toggleAlertOpen();
                        handleCancel();
                    }}
                    title="작성중인 내용을 정말 삭제하시겠어요?"
                    description="삭제 후에는 복원할 수 없어요."
                />
            </Stack>
        </Paper>
    );
});
