export const SizeType = {
    Small: "small",
    Medium: "medium",
    Large: "large",
};

export const BookColorType = {
    Yellow: "yellow",
    Blue: "blue",
    Green: "green",
    Purple: "purple",
    Red: "red",
    Indigo: "indigo",
};

export const BookThemeType = {
    Light: "light",
    Dark: "dark",
};

export const PopUpType = {
    Explanation: "explanation",
    AutoJudge: "autoJudge",
    KindSolution: "kindSolution",
    VariousProblems: "variousProblems",
    Qna: "qna",
    Difficulty: "difficulty",
};
