import React from "react";
import { ListItemButton, styled } from "@mui/material";

const EclipseButtonComponent = styled(ListItemButton)(({ theme }) => ({
    backgroundColor: "transparent",
    color: "#999999",
    padding: "4px 12px",
    flexGrow: 0,
    borderRadius: "50px",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
    "&:hover": {
        background: "#000000",
        color: "#F2F2F2",
    },
    "&.Mui-selected": {
        background: "#000000",
        color: "#F2F2F2",
        "&:hover": {
            background: "#000000",
            color: "#F2F2F2",
        },
    },
}));

export const EclipseButton = (props) => (
    <EclipseButtonComponent {...props} disableRipple />
);
