import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingOverlay } from "../../../../../../../common/templates/LoadingOverlay";
import { CopyButton } from "../../../../../../../common/components/button/CopyButton";
import { OpenInNewTabButton } from "../../../../../../../common/components/button/OpenInNewTabButton";
import { useStores } from "../../../../../../../common/modules/useStores";

export const CodeBox = ({
    title,
    textUrl,
    isInput,
    isTest,
    submissionId,
    backgroundColor,
    defaultText,
}) => {
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState(defaultText || "");
    const [invisible, setInvisible] = useState(false);

    const { blobStore } = useStores();

    useEffect(() => {
        if (isTest) return;

        if (!textUrl) {
            setLoading(false);
            return;
        }

        if (textUrl.includes("problems/")) {
            blobStore
                .getTextFromPrivateUrl({
                    url: textUrl,
                })
                .then((_text) => {
                    if (isInput && !_text) {
                        setInvisible(true);
                    }
                    setLoading(false);
                    setText(_text);
                });
        } else {
            blobStore
                .getTextFromBlob({
                    containerName: `submissions-${submissionId}`,
                    blobName: textUrl,
                })
                .then((_text) => {
                    if (isInput && !_text) {
                        setInvisible(true);
                    }
                    setLoading(false);
                    setText(_text);
                });
        }
    }, []);

    if (invisible) return <Box mb={-2.5} />;

    return (
        <Stack direction="column" width="100%">
            {title}
            {!isTest ? (
                <Box
                    width="100%"
                    color="#000000"
                    height={143}
                    fontSize={14}
                    lineHeight="143%"
                    padding={1.25}
                    whiteSpace="pre"
                    direction="row"
                    backgroundColor={backgroundColor || "#F4F7F9"}
                    borderRadius={1}
                    overflow="auto"
                >
                    {!loading ? (
                        <Stack direction="row" width="100%">
                            <Box flexGrow="1" overflow="auto" height="100%">
                                {text}
                            </Box>
                            <Stack
                                flexGrow={0}
                                width="fit-content"
                                direction="column"
                            >
                                <CopyButton
                                    fontSize={14}
                                    text={text}
                                    tooltipPlacement="left"
                                />
                                <OpenInNewTabButton
                                    fontSize={14}
                                    text={text}
                                    tooltipPlacement="left"
                                />
                            </Stack>
                        </Stack>
                    ) : (
                        <LoadingOverlay active={loading} spinnerSize={50} />
                    )}
                </Box>
            ) : (
                <Stack
                    direction="column"
                    alignItems="center"
                    color="#78828F"
                    justifyContent="center"
                >
                    <LockOutlinedIcon fontSize="large" />
                    <Typography
                        children="실력 체크 문항에서는 보실 수 없어요."
                        mt={1}
                    />
                </Stack>
            )}
        </Stack>
    );
};
