import { makeAutoObservable } from "mobx";
import LessonTemplateRepository from "./LessonTemplateRepository";
import { LessonTemplateModel } from "./LessonTemplateModel";

export default class LessonTemplateStore {
    lessonTemplate = null;

    lessonTemplates = [];

    isFetching = true;

    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *find({ lessonTemplateId }) {
        const response = yield LessonTemplateRepository.find({
            lessonTemplateId,
        });

        this.lessonTemplate = new LessonTemplateModel(response.data);

        this.lessonTemplates = this.lessonTemplates.map((lessonTemplate) => {
            console.log(lessonTemplate.id, lessonTemplateId);
            if (lessonTemplate.id == lessonTemplateId) {
                return this.lessonTemplate;
            } else {
                return lessonTemplate;
            }
        });
    }

    *findAll() {
        this.isFetching = true;

        const response = yield LessonTemplateRepository.findAll();

        this.lessonTemplates = response.data.map(
            (lessonTemplate) => new LessonTemplateModel(lessonTemplate)
        );

        this.lessonTemplate = this.lessonTemplate[0];

        this.isFetching = false;
    }

    *findAllSimple() {
        this.isFetching = true;

        const response = yield LessonTemplateRepository.findAllSimple();

        this.lessonTemplates = response.data.map(
            (lessonTemplate) => new LessonTemplateModel(lessonTemplate)
        );

        this.isFetching = false;
    }

    *folk({ classroomId, lessonTemplateId }) {
        yield LessonTemplateRepository.folk({
            classroomId,
            lessonTemplateId,
        });
    }

    setLessonTemplate(lessonTemplateId) {
        const target = this.lessonTemplates.find(
            ({ id }) => id === lessonTemplateId
        );
        if (target) {
            this.lessonTemplate = target;
        } else {
            this.lessonTemplate = null;
        }
    }
}
