import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { ConceptAccordionBox } from "./templates/ConceptAccordionBox";

export const MissionTemplate = ({ mission, concepts }) => (
    <MainTemplateWrapper
        sx={{
            py: 5,
        }}
    >
        <Stack direction="column" spacing={2.5}>
            <Paper
                sx={{
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Stack spacing={1.5}>
                    <Typography
                        children={mission.name}
                        fontWeight={700}
                        variant="h3"
                    />
                    <Typography
                        children={mission.description}
                        variant="body2"
                    />
                </Stack>
            </Paper>
            <ConceptAccordionBox missionId={mission.id} concepts={concepts} />
        </Stack>
    </MainTemplateWrapper>
);
