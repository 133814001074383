import { makeAutoObservable } from "mobx";
import { CuratedProblemModel } from "./CuratedProblemModel";
import CuratedProblemRepository from "./CuratedProblemRepository";

export default class CuratedProblemStore {
    curatedProblems = [];

    curatedProblem = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll({ conceptId, studyUnitId, problemName }) {
        const concept = this.rootStore.conceptStore.concepts.find(
            (concept) => concept.id === Number(conceptId)
        );
        const studyUnit = concept.studyUnits.find(
            (studyUnit) => studyUnit.id === Number(studyUnitId)
        );
        const response = yield CuratedProblemRepository.findAll({
            conceptId,
            studyUnitId,
            problemName,
        });
        studyUnit.setCuratedProblems(
            response.data.map(
                (curatedProblem) => new CuratedProblemModel(curatedProblem)
            )
        );
    }

    *searchProblems({ name, problemId, missionId, homeworkId }) {
        const response = yield CuratedProblemRepository.searchProblems({
            name,
            missionId,
            problemId,
            page: 1,
            pageSize: 5,
            homeworkId,
        });
        const { results } = response.data;
        this.curatedProblems.replace(
            results.map(
                (curatedProblem) => new CuratedProblemModel(curatedProblem)
            )
        );
    }

    *find({ problemId, missionId, homeworkId }) {
        if (!problemId) {
            this.curatedProblem = undefined;
            return;
        }
        const response = yield CuratedProblemRepository.find({
            problemId,
            missionId,
            homeworkId,
        });
        this.curatedProblem = new CuratedProblemModel(response.data);
    }

    *findDetail({ problemId, missionId }) {
        const response = yield CuratedProblemRepository.findDetail({
            problemId,
            missionId,
        });
        this.curatedProblem = new CuratedProblemModel(response.data);
    }
}
