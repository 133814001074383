import React from "react";
import { observer } from "mobx-react-lite";
import { HomeworksTemplate } from "./HomeworksTemplate";
import { useStores } from "../../../common/modules/useStores";

export const HomeworksContainer = observer(() => {
    const { homeworkStore } = useStores();
    const { homework, isFetching } = homeworkStore;

    return (
        <HomeworksTemplate
            isTeacher
            homework={homework}
            isFetching={isFetching}
        />
    );
});
