import { makeAutoObservable, set } from "mobx";
import { camelizeKeys } from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";
import { HomeworkModel } from "../homework/HomeworkModel";

export class LessonTemplateModel {
    id = 0;

    name = "";

    description = "";

    homeworks = [];

    numTotal = 0;

    minuteTotal = 0;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const lessonTemplate = pickCls(this, camelizeKeys(data));
        if (lessonTemplate.homeworks && lessonTemplate.homeworks.length) {
            console.log(lessonTemplate);
            lessonTemplate.homeworks = lessonTemplate.homeworks.map(
                (homework) => new HomeworkModel(homework)
            );
        }
        set(this, lessonTemplate);
    }
}
