import React from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { MissionProgress } from "../../Missions/templates/MissionProgress";
import { ProblemTreeItem } from "../components/ProblemTreeItem";
import { IconButtonWithTooltip } from "../../../../common/components/button/IconButtonWithTooltip";

export const MenuTreeDrawer = ({
    open,
    treeItem,
    selected,
    handleClose,
    problem,
    handleClick,
    homePath,
}) => {
    const navigate = useNavigate();

    const navigateToMainPage = () => {
        navigate(homePath);
    };

    const renderTree = (nodes) => {
        if (nodes.nodeLabel === "problem") {
            return (
                <ProblemTreeItem
                    key={nodes.nodeId}
                    nodeId={nodes.nodeId}
                    problem={nodes}
                    selected={nodes.nodeId === problem.nodeId}
                    handleClick={() =>
                        handleClick({ problemId: nodes.problemId })
                    }
                />
            );
        }

        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.nodeId}
                label={<Typography variant={"body2"}>{nodes.name}</Typography>}
            >
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        );
    };

    return (
        <Drawer
            anchor={"left"}
            open={open}
            onClose={handleClose}
            sx={{
                minHeight: "100%",
                "& .MuiDrawer-paper": {
                    minWidth: 400,
                    backgroundColor: "#252525",
                    color: "#FFFFFF",
                },
            }}
        >
            <Stack direction={"column"}>
                <Stack
                    direction={"column"}
                    position={"sticky"}
                    top={0}
                    backgroundColor={"#252525"}
                    zIndex={1}
                >
                    <Stack
                        p={1.5}
                        direction={"row"}
                        spacing={0.25}
                        alignItems={"center"}
                    >
                        <IconButtonWithTooltip
                            Icon={ArrowBackIosIcon}
                            iconSize={"small"}
                            tooltipPlacement={"right"}
                            tooltipTitle={`${
                                treeItem.name || "메인"
                            } ${"바로가기"}`}
                            onClick={navigateToMainPage}
                            sx={{
                                color: "common.white",
                            }}
                        />
                        <Typography variant={"subtitle1"}>
                            {treeItem.name}
                        </Typography>
                    </Stack>
                    <Box px={2.5} pb={1}>
                        <MissionProgress
                            width={"100%"}
                            progress={treeItem.progress}
                            textColor={"#FFFFFF"}
                        />
                    </Box>
                </Stack>
                <Box flexGrow={1}>
                    <TreeView
                        selected={selected}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={selected}
                        defaultExpandIcon={<ChevronRightIcon />}
                        multiSelect
                        sx={{
                            "& .MuiTreeItem-root": {
                                p: 0,
                            },
                            "& .MuiTreeItem-content": {
                                width: "auto",
                                padding: "8px 0px",
                                margin: "1px 10px",
                                borderRadius: "100px",
                                transition: "all 0.3s ease 0s",
                            },
                            "& .MuiTreeItem-content:hover": {
                                backgroundColor: "rgba(17, 17, 17, 0.9)",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                margin: "1px 0",
                            },
                            "& .MuiTreeItem-content.Mui-selected": {
                                backgroundColor: "rgba(17, 17, 17, 0.7)",
                                padding: "8px 10px",
                                margin: "1px 0",
                            },
                        }}
                    >
                        {treeItem.children.map((nodes) => renderTree(nodes))}
                    </TreeView>
                </Box>
            </Stack>
        </Drawer>
    );
};
