import React from "react";
import { Stack, Typography } from "@mui/material";
import { MotherTongueType } from "../../../../../../../common/modules/auth/UserModel";

export const JudgeSpecBox = ({ motherTongue, timeLimit, memoryLimit }) => {
    const isKorean = motherTongue === MotherTongueType.KOR;

    const judgeSpecText = `${
        isKorean ? "시간 제한" : "Time Limit"
    }: ${timeLimit}ms
${isKorean ? "메모리 제한" : "Memory Limit"}: ${memoryLimit}MB`;

    return (
        <Stack direction="column" spacing={2}>
            <Typography
                variant="h3"
                fontWeight={700}
                children={isKorean ? "제한" : "Constraints"}
            />
            <div className="wmde-markdown">
                <pre>
                    <code children={judgeSpecText} />
                </pre>
            </div>
        </Stack>
    );
};
