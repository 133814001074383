import React, { useState } from "react";
import { Stack } from "@mui/material";
import { PlaygroundPage } from "../../Missions/Playground/PlaygroundPage";
import { MenuTreeTemplate } from "../../MenuTree/MenuTreeTemplate";
import { useNavigate } from "react-router-dom";

export const HomeworksProblemTemplate = ({
    missionTree,
    curatedProblem,
    problem,
    objectId,
    changeToPrevProblem,
    changeToNextProblem,
    changeToTreeProblem,
    treeUpdate,
    solutionVisible,
    submitUntil,
    discussionVisible,
    aiEnabled,
}) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => {
            if (!prev) {
                treeUpdate();
            }

            return !prev;
        });
    };

    const redirectToNextProblemOrOpen = () => {
        const nextId = changeToNextProblem();
        navigate(`/homeworks/${missionTree.id}/${nextId}`);
    };

    const redirectToPrevProblemOrOpen = () => {
        const prevId = changeToPrevProblem();
        navigate(`/homeworks/${missionTree.id}/${prevId}`);
    };

    const redirectToTreeProblemOrOpen = ({ problemId }) => {
        const problemNumber = changeToTreeProblem({ problemId });
        navigate(`/homeworks/${missionTree.id}/${problemNumber}`);
        setOpen(false);
    };

    const stepInfo = {
        curr:
            missionTree.children.findIndex(
                (curatedProblem) => curatedProblem.id === problem.id
            ) + 1,
        total: missionTree.children.length,
    };

    return (
        <Stack height="calc(100vh - 54px)" direction="column">
            <PlaygroundPage
                problem={problem}
                objectId={objectId}
                solutionVisible={solutionVisible}
                submitUntil={submitUntil}
                discussionVisible={discussionVisible}
                stepInfo={stepInfo}
                aiEnabled={aiEnabled}
            />
            <MenuTreeTemplate
                treeItem={missionTree}
                selected={[`problem_${curatedProblem.number}`]}
                homePath={"/homeworks"}
                open={open}
                toggleOpen={toggleOpen}
                problem={curatedProblem}
                redirectToNextProblemOrOpen={redirectToNextProblemOrOpen}
                redirectToPrevProblemOrOpen={redirectToPrevProblemOrOpen}
                redirectToTreeProblemOrOpen={redirectToTreeProblemOrOpen}
                stepInfo={stepInfo}
            />
        </Stack>
    );
};
