import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import {
    SubmissionStatusToColor,
    SubmissionStatusToKorean,
} from "../../../../../../common/modules/submission/SubmissionModel";

export const SubmissionBox = ({ submission, selected, onClick }) => {
    const { result, createdAt } = submission;
    const { status, runtime, memory } = result;

    return (
        <Paper
            sx={{
                width: 190,
                borderRadius: 2,
                py: 1.25,
                px: 2.5,
                cursor: "pointer",
                mr: 5,
                border: selected ? "2px solid #000000" : "none",
            }}
            onClick={onClick}
        >
            <Stack direction="column" spacing={1.25}>
                <Typography
                    children={SubmissionStatusToKorean[status]}
                    variant="h4"
                    color={SubmissionStatusToColor[status]}
                    fontWeight={700}
                />
                <Stack direction="column">
                    <Stack direction="row">
                        <Typography
                            children="Runtime: "
                            variant="caption"
                            whiteSpace="pre"
                        />
                        <Typography
                            children={`${runtime}ms`}
                            variant="caption"
                            fontWeight={700}
                        />
                    </Stack>
                    <Stack direction="row">
                        <Typography
                            children="Memory: "
                            variant="caption"
                            whiteSpace="pre"
                        />
                        <Typography
                            children={`${memory}MB`}
                            variant="caption"
                            fontWeight={700}
                        />
                    </Stack>
                    <Typography
                        children={moment(createdAt).locale("ko").format("LLL")}
                        variant="caption"
                        whiteSpace="pre"
                    />
                </Stack>
            </Stack>
        </Paper>
    );
};
