export function copyToClipboard({ text, onCopy, onError }) {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
            .writeText(text)
            .then(onCopy || (() => {}))
            .catch(onError || (() => {}));
    } else {
        // text area method
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise(() => {
            // here the magic happens
            document.execCommand("copy") ? onCopy() : onError();
            textArea.remove();
        });
    }
}

export const openNewTabForText = (text) => {
    const tab = window.open("", "_blank", "status=no");
    tab.document.write(`<p style="white-space: pre">${text}</p>`);
};

export const downloadFileFromUrl = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const insertAtCursor = (input, textToInsert) => {
    // get current text of the input
    const { value } = input;

    // save selection start and end position
    const start = input.selectionStart;

    // update the value with our text inserted
    // eslint-disable-next-line
  input.value = value.slice(0, start) + textToInsert + value.slice(start);

    // update cursor to be at the end of insertion
    input.selectionStart = input.selectionEnd = start + textToInsert.length;

    return input.value;
};
