import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { MarkdownEditor } from "../../../../../../../../../common/components/markdown/MarkdownEditor";
import { insertAtCursor } from "../../../../../../../../../utils/clipboard";
import {
    pickCls,
    setIn,
} from "../../../../../../../../../utils/immutableLodash";
import { snackActions } from "../../../../../../../../../utils/SnackbarUtilsConfigurator";
import { IconButtonWithTooltip } from "../../../../../../../../../common/components/button/IconButtonWithTooltip";
import { useStores } from "../../../../../../../../../common/modules/useStores";
import { errorToast } from "../../../../../../../../../utils/errorToast";
import { LoadingOverlay } from "../../../../../../../../../common/templates/LoadingOverlay";

export const CommentEditor = ({
    onSubmit,
    confirmText,
    comment,
    toggleIsEditing,
    fillGPTAnswer,
}) => {
    const { authStore } = useStores();
    const { currUser } = authStore;
    const [tabValue, setTabValue] = useState("edit");
    const scriptRef = useRef();

    const [inputs, setInputs] = useState({
        content: "",
        isAnonymous: false,
    });

    useEffect(() => {
        if (comment) {
            setInputs(pickCls(inputs, comment));
        }
    }, [comment]);

    const { content, isAnonymous } = inputs;

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const handleTab = (e, newValue) => {
        setTabValue(newValue);
    };

    const putPredefinedVar = (preStr) => {
        handleChange(
            "content",
            insertAtCursor(scriptRef.current.textarea, preStr)
        );
        scriptRef.current.textarea.focus();
    };

    const validateAndSubmit = (_inputs) => {
        if (!content) {
            snackActions.error("내용이 비어있습니다!");
            return;
        }

        onSubmit(_inputs);
        setInputs({
            content: "",
            isAnonymous: false,
        });
    };

    const [isWaitingGPT, setIsWaitingGPT] = React.useState(false);

    const handleGPTAnswer = () => {
        setIsWaitingGPT(true);
        fillGPTAnswer()
            .then((message) => {
                changeContent(message);
                setIsWaitingGPT(false);
            })
            .catch((e) => {
                errorToast(e);
                setIsWaitingGPT(false);
            });
    };

    const changeContent = (value) => {
        handleChange("content", value);
    };

    return (
        <Paper elevation={1}>
            <Stack direction="column" bgcolor="background.paper" px={2} py={1}>
                <Stack direction="row" justifyContent="space-between">
                    <Tabs value={tabValue} onChange={handleTab}>
                        <Tab label="작성창" value="edit" />
                        <Tab label="미리보기" value="preview" />
                    </Tabs>
                    <Stack direction="row">
                        <IconButtonWithTooltip
                            iconSize="small"
                            Icon={CodeIcon}
                            onClick={() =>
                                putPredefinedVar(
                                    "\n```cpp\n여기에 코드를 넣어주세요\n```\n"
                                )
                            }
                            tooltipPlacement="top"
                            tooltipTitle="코드 입력"
                        />
                        <IconButtonWithTooltip
                            iconSize="small"
                            Icon={FormatListBulletedIcon}
                            onClick={() =>
                                putPredefinedVar(
                                    "\n+ 여기에 항목을 적어주세요\n+ 여기에 항목을 적어주세요\n"
                                )
                            }
                            tooltipPlacement="top"
                            tooltipTitle="리스트 입력"
                        />
                    </Stack>
                </Stack>
                <LoadingOverlay active={isWaitingGPT === true}>
                    <MarkdownEditor
                        ref={scriptRef}
                        preview={tabValue}
                        hideToolbar
                        autoFocus
                        value={content}
                        onChange={changeContent}
                    />
                </LoadingOverlay>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    {currUser?.chatAvailable && fillGPTAnswer ? (
                        <Button
                            color={"secondary"}
                            children={"GPT의 답 추천"}
                            variant={"contained"}
                            onClick={handleGPTAnswer}
                        />
                    ) : null}
                    <Stack
                        direction="row"
                        alignSelf="flex-end"
                        spacing={1}
                        mt={1}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!!comment}
                                    checked={isAnonymous}
                                    name="isAnonymous"
                                    onChange={(e) =>
                                        handleChange(
                                            e.target.name,
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="익명으로 댓글달기"
                        />
                        {comment ? (
                            <Button
                                children="취소"
                                color="error"
                                variant="contained"
                                onClick={toggleIsEditing}
                            />
                        ) : null}
                        <Button
                            color="primary"
                            children={confirmText || "댓글달기"}
                            variant="contained"
                            onClick={() => validateAndSubmit(inputs)}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};
