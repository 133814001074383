import React from "react";
import { IconButton, Input, Stack, Typography, useTheme } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { IconButtonWithTooltip } from "../../../../common/components/button/IconButtonWithTooltip";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export const TestCaseEdit = ({ item, handleDelete, handleChange }) => {
    const theme = useTheme();

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
                transition: "all .5s ease-in-out",
            }}
            mb={1.5}
            spacing={1.5}
        >
            <Stack spacing={1} flexGrow={1}>
                <Stack direction={"row"} alignItems={"center"} spacing={2.5}>
                    <Typography
                        children={"입력"}
                        color={"#AAAAAA"}
                        minWidth={"fit-content"}
                    />
                    <Input
                        multiline
                        fullWidth
                        sx={{
                            ...theme.typography.body2,
                            backgroundColor: "#F6F8FA",
                            px: 1.5,
                            py: 1,
                            fontFamily:
                                "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                        disableUnderline
                        value={item.stdin}
                        onChange={(e) =>
                            handleChange(item.key, "stdin", e.target.value)
                        }
                    />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={2.5}>
                    <Typography
                        children={"출력"}
                        color={"#AAAAAA"}
                        minWidth={"fit-content"}
                    />
                    <Input
                        multiline
                        fullWidth
                        sx={{
                            ...theme.typography.body2,
                            backgroundColor: "#F6F8FA",
                            px: 1.5,
                            py: 1,
                            fontFamily:
                                "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                        disableUnderline
                        value={item.stdout}
                        onChange={(e) =>
                            handleChange(item.key, "stdout", e.target.value)
                        }
                    />
                </Stack>
            </Stack>
            <IconButtonWithTooltip
                Icon={
                    item.isVisible
                        ? VisibilityOutlinedIcon
                        : VisibilityOffOutlinedIcon
                }
                onClick={() =>
                    handleChange(item.key, "isVisible", !item.isVisible)
                }
                tooltipPlacement={"top"}
                tooltipTitle={
                    item.isVisible
                        ? "모두 이 예제를 볼 수 있습니다."
                        : "편집자만 이 예제를 볼 수 있습니다."
                }
            />
            <IconButton
                sx={{
                    color: "#C3565A",
                }}
                onClick={() => handleDelete(item.key)}
            >
                <DoNotDisturbOnIcon />
            </IconButton>
        </Stack>
    );
};
