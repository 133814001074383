import React from "react";
import { alpha, Box, CircularProgress, Stack, Typography } from "@mui/material";

export const LoadingOverlay = ({
    active,
    text,
    sx,
    spinnerSize,
    children,
    spinnerProps = {},
    spinnerTextProps = {},
}) => (
    <Box
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={sx}
    >
        {active ? (
            <Box
                position="absolute"
                height="100%"
                width="100%"
                top={0}
                left={0}
                sx={{
                    background: alpha("#000000", 0.7),
                    transition: "opacity 500ms ease-in",
                    opacity: active ? 1 : 0,
                    zIndex: 100000,
                }}
            >
                <Stack
                    direction="column"
                    width="100%"
                    height="100%"
                    sx={{
                        margin: "auto",
                    }}
                    alignItems="center"
                    justifyContent="center"
                    spacing={1.25}
                >
                    <CircularProgress
                        size={spinnerSize}
                        thickness={2}
                        sx={{
                            color: "#FFFFFF",
                        }}
                        {...spinnerProps}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            color: "#FFFFFF",
                        }}
                        {...spinnerTextProps}
                    >
                        {text}
                    </Typography>
                </Stack>
            </Box>
        ) : null}
        {children}
    </Box>
);

LoadingOverlay.defaultProps = {
    spinnerSize: 100,
    sx: {},
};
