import React from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Markdown } from "../../../../../../../../common/components/markdown/Markdown";

export const DiscussionBox = ({ discussion, toggleIsEditing }) => {
    const { title, username, createdAt, description, isAuthor } = discussion;

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <Stack direction="column" p={1}>
            <Stack direction="row" justifyContent="space-between" mb={1}>
                <Stack direction="row" alignItems="center">
                    <Button
                        color="primary"
                        children="< 뒤로 가기"
                        mr={1}
                        onClick={goBack}
                    />
                    <Divider orientation="vertical" flexItem />
                    <Typography
                        fontWeight={700}
                        variant="body1"
                        children={title}
                        ml={2}
                    />
                </Stack>
                {isAuthor ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Button
                            variant="outlined"
                            endIcon={<EditIcon />}
                            onClick={toggleIsEditing}
                        >
                            수정
                        </Button>
                    </Stack>
                ) : null}
            </Stack>
            <Divider />
            <Stack direction="column" p={3}>
                <Stack direction="row" alignItems="center" spacing={1} mb={3}>
                    <PermIdentityIcon
                        sx={{
                            background:
                                "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
                            borderRadius: "50%",
                            py: 0.5,
                            color: "#FFFFFF",
                        }}
                    />
                    <Typography
                        variant="body2"
                        color="#9E9E9E"
                        children={username}
                        letterSpacing="-0.6px"
                    />
                    <Typography
                        variant="body2"
                        color="#9E9E9E"
                        children={moment(createdAt).locale("ko").format("LLL")}
                        letterSpacing="-0.6px"
                    />
                </Stack>
                <Markdown children={description} />
            </Stack>
        </Stack>
    );
};
