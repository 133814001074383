import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { getPercentString } from "../../../../../utils/string";
import { HomeworkProgressBox } from "./HomeworkProgressBox";

export const UserHomeworkProgressBox = ({ userHomeworkProgress, order }) => {
    const solvedProblemCount = userHomeworkProgress.homeworkProgresses.reduce(
        (acc, curr) => acc + curr.solvedProblemCount,
        0
    );
    const problemCount = userHomeworkProgress.homeworkProgresses.reduce(
        (acc, curr) => acc + curr.problemCount,
        0
    );

    const { userId, name, username } = userHomeworkProgress;

    return (
        <Stack
            direction="row"
            alignItems="center"
            height={150}
            spacing={2.5}
            sx={{
                backgroundColor: order % 2 ? "#E7ECF0" : "#F5F5F7",
                pr: 5,
            }}
        >
            <Stack
                direction={"column"}
                width={200}
                height={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={0.5}
                sx={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: order % 2 ? "#E7ECF0" : "#F5F5F7",
                    zIndex: 100,
                    borderRight: "1px solid #E9EBEE",
                }}
            >
                <Stack direction="row" alignItems="center">
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                        }}
                    />
                    <Typography
                        ml={0.5}
                        color="#6D7A9F"
                        fontWeight={700}
                        variant="caption"
                        children={`${name}(${username})`}
                    />
                </Stack>
                <Typography
                    color="primary"
                    fontWeight={700}
                    variant="body2"
                    children={`${getPercentString(
                        solvedProblemCount,
                        problemCount
                    )} (${solvedProblemCount}/${problemCount})`}
                />
            </Stack>
            {userHomeworkProgress.homeworkProgresses.map(
                (homeworkProgress, i) => (
                    <HomeworkProgressBox
                        key={`homeworkProgress-${userId}-${i}`}
                        userId={userId}
                        homeworkProgress={homeworkProgress}
                    />
                )
            )}
        </Stack>
    );
};
