import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { defaultThemeOption } from "./default";
import { appBarThemeOption } from "./appBar";
import { baseThemeOption } from "./base";
import { darkThemeOption } from "./dark";
import { drawerThemeOption } from "./drawer";
import { stickyAppBarThemeOption } from "./stickyAppBar";

export const DefaultTheme = createTheme(
    deepmerge(baseThemeOption, defaultThemeOption)
);
export const AppBarTheme = createTheme(
    deepmerge(baseThemeOption, appBarThemeOption)
);
export const DarkTheme = createTheme(
    deepmerge(baseThemeOption, darkThemeOption)
);
export const DrawerTheme = createTheme(
    deepmerge(baseThemeOption, drawerThemeOption)
);
export const StickyAppBarTheme = createTheme(
    deepmerge(baseThemeOption, stickyAppBarThemeOption)
);
