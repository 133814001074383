import React, { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { QuizAddButton } from "../components/QuizAddButton";

export const QuizSelector = observer(({ sx, fiddleId, setFiddleId }) => {
    const { classroomStore, fiddleProgressStore } = useStores();
    const { classroom } = classroomStore;
    const { isFetching, fiddleProgresses, fiddleProgress } =
        fiddleProgressStore;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (classroom.id) {
            fiddleProgressStore.findAll().then((fiddleProgresses) => {
                const exist = fiddleProgress
                    ? fiddleProgresses.some(
                          (_fiddle) => _fiddle.id === fiddleId
                      )
                    : false;
                fiddleProgressStore.updateLocalFiddle(
                    exist
                        ? fiddleProgresses.filter(
                              (_fiddle) => _fiddle.id === fiddleId
                          )[0]
                        : fiddleProgresses[0],
                    true
                );
            });
        }
    }, [classroom]);

    const handleMenuClick = (_fiddle) => {
        fiddleProgressStore.updateLocalFiddle(_fiddle);
        setFiddleId(_fiddle.id);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!fiddleProgresses.length) {
        return <QuizAddButton sx={sx} />;
    }

    return (
        <>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                    padding: 2,
                    borderRadius: 2,
                    ...sx,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box display="flex" flexDirection="column">
                        {fiddleProgress ? (
                            <>
                                <Stack
                                    direction={"column"}
                                    alignItems={"flex-start"}
                                    justifyContent={"center"}
                                >
                                    <Typography
                                        variant="body1"
                                        textAlign="left"
                                        fontWeight={700}
                                    >
                                        {fiddleProgress.title.split(/\r?\n/)[0]}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color={"#18A0FB"}
                                    >
                                        완료한 사람 {fiddleProgress.passedCount}{" "}
                                        / {fiddleProgress.studentCount} (
                                        {parseInt(
                                            (fiddleProgress.passedCount /
                                                fiddleProgress.studentCount) *
                                                100
                                        )}
                                        %)
                                    </Typography>
                                </Stack>
                            </>
                        ) : isFetching ? (
                            "학급 정보를 불러오는 중입니다"
                        ) : null}
                    </Box>
                    <ArrowDropDownIcon />
                </Box>
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: { width: anchorEl && anchorEl.offsetWidth },
                }}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                    },
                }}
            >
                {fiddleProgresses.map((_fiddle) => (
                    <MenuItem
                        key={`fiddle-menu-${_fiddle.id}`}
                        onClick={() => handleMenuClick(_fiddle)}
                        selected={
                            fiddleProgress
                                ? fiddleProgress.id === _fiddle.id
                                : false
                        }
                    >
                        <Stack direction="column">
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <Typography
                                    variant="body1"
                                    textAlign="left"
                                    fontWeight={700}
                                >
                                    {_fiddle.title.split(/\r?\n/)[0]}
                                </Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
});
