import React, { useEffect, useState } from "react";
import {
    Divider,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
    Chip,
} from "@mui/material";
import { Markdown } from "../../../../../../common/components/markdown/Markdown";
import { Copyright } from "../Copyright";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../../common/modules/useStores";
import { LanguageType } from "../../../../../../common/modules/problems/ProblemModel";

export const IntroductionTemplate = observer(
    ({ introductions, handleLanguageAPIChange, stepInfo }) => {
        const introductionMap = introductions.reduce((acc, curr) => {
            const mdRaw = curr.description;
            const mdMatch = mdRaw.match(
                /(#+)(\s+)([^\n#]+)(#*)([\r\n|\r|\n]*)([\S\s]*)/
            );
            let title = "";
            let body = mdRaw;

            if (mdMatch) {
                title = mdMatch[3];
                body = mdMatch[6];
            }

            return {
                ...acc,
                [curr.language]: {
                    title,
                    body,
                    ...curr,
                },
            };
        }, {});

        const { authStore } = useStores();
        const { currUser } = authStore;

        const [language, setLanguage] = useState(introductions[0].language);

        const changeLanguage = (_language) => {
            setLanguage(_language);
            if (Object.values(LanguageType).includes(_language))
                handleLanguageAPIChange(_language);
        };

        useEffect(() => {
            if (currUser.language in introductionMap) {
                setLanguage(currUser.language);
            }
        }, [currUser.language]);

        const indexSuffix = stepInfo
            ? `${stepInfo.curr}번째 문제의 개념 (총 ${stepInfo.total}개)`
            : null;

        return (
            <Stack direction="column" spacing={2}>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    spacing={1.25}
                >
                    <Stack alignItems="flex-start" spacing={0.75}>
                        {indexSuffix && (
                            <Chip size="small" label={indexSuffix} />
                        )}
                        <Typography variant="h1" fontWeight={800}>
                            {introductionMap[language].title}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body1" children="언어 선택:" />
                        <FormControl size={"small"}>
                            <Select value={language} autoWidth>
                                {Object.keys(introductionMap).map((lang, i) => (
                                    <MenuItem
                                        key={`lang-menu-${i}`}
                                        value={lang}
                                        onClick={() => changeLanguage(lang)}
                                    >
                                        <Typography
                                            children={lang}
                                            variant="body2"
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <Divider />

                <Markdown>{introductionMap[language].body}</Markdown>
                <Divider />
                <Copyright />
            </Stack>
        );
    }
);
