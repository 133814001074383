import { makeAutoObservable } from "mobx";
import { TutorialType } from "./TutorialModel";

export const TutorialTypeKey = "CT_TUTORIAL_TYPE";

export default class TutorialStore {
    currTutorial = TutorialType.Problem;

    tutorialList = [
        TutorialType.Problem,
        TutorialType.Language,
        TutorialType.Editor,
        TutorialType.Execute,
        TutorialType.Submit,
        TutorialType.TestCase,
        TutorialType.None,
    ];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
        this.currTutorial =
            localStorage.getItem(TutorialTypeKey) || TutorialType.Problem;
    }

    setTutorial(tutorialType) {
        if (tutorialType in TutorialType) {
            this.currTutorial = tutorialType;
            localStorage.setItem(TutorialTypeKey, tutorialType);
        }
    }
}
