export const SEPERATOR = "&sep;";

export const paddingZero = (num, size) => {
    let numStr = num.toString();
    while (numStr.length < size) numStr = `0${numStr}`;
    return numStr;
};

export const thousandSeperator = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPercentString = (numerator, denominator, isZeroException) => {
    if (!denominator) return isZeroException ? "100%" : "0%";

    return `${Math.floor((numerator * 100) / denominator)}%`;
};

export function getBetweenText(source, left, right) {
    // eslint-disable-next-line
    const leftSources = source.split(left);

    if (leftSources.length < 2) return null;

    return left + leftSources[1].split(right)[0];
}

export const makeValuesString = (obj) =>
    Object.values(obj)
        .reduce((acc, curr) => `${acc + curr} - `, "")
        .slice(0, -3);

export const getMissionAndProblemId = (number) => {
    const zeroIndex = 1;
    const missionId = number.slice(0, zeroIndex);
    const problemId = number.slice(zeroIndex);

    return {
        missionId,
        problemId,
    };
};

export const phoneNumberify = (value) => {
    const seven = /^\d{7}$/;
    const eleven = /^\d{11}$/;
    const elevenwithline = /^\d{3}-\d{8}$/;

    if (eleven.test(value)) {
        return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(
            7,
            11
        )}`;
    }
    if (elevenwithline.test(value)) {
        return `${value.slice(0, 3)}-${value.slice(4, 8)}-${value.slice(
            8,
            12
        )}`;
    }
    if (seven.test(value)) {
        return `${value.slice(0, 3)}-${value.slice(3, 7)}`;
    }
    return value;
};

export const textToArray = (text) => text.split(SEPERATOR);

export const arrayToText = (array) =>
    array.reduce((acc, curr) => acc + curr + SEPERATOR, "").slice(0, -5);
