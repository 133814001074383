import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { DiscussionTemplate } from "./DiscussionTemplate";
import { useStores } from "../../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../../common/templates/LoadingOverlay";
import { DiscussionDAOModel } from "../../../../../../../common/modules/discussions/DiscussionModel";
import { snackActions } from "../../../../../../../utils/SnackbarUtilsConfigurator";

export const DiscussionContainer = observer(() => {
    const { discussionId } = useParams();

    const { discussionStore } = useStores();
    const { discussion, comments } = discussionStore;

    useEffect(() => {
        discussionStore.find({ discussionId });
    }, [discussionId]);

    const loading = Number(discussionId) !== (discussion && discussion.id);

    if (loading)
        return (
            <LoadingOverlay active={loading} text="토론을 불러오는 중입니다." />
        );

    const handleEdit = (inputs) => {
        const body = new DiscussionDAOModel(inputs);
        discussionStore
            .update({
                body,
                discussionId,
            })
            .then(() => {
                snackActions.success("정상적으로 수정되었습니다.");
            });
    };

    return (
        <DiscussionTemplate
            discussion={discussion}
            comments={comments}
            handleEdit={handleEdit}
        />
    );
});
