import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import {
    blankStudent,
    StudentDAOModel,
    StudentFormsKey,
} from "../../../../../common/modules/classroom/ClassroomModel";
import { setIn } from "../../../../../utils/immutableLodash";
import { StudentFormsTemplate } from "./StudentFormsTemplate";
import { useStores } from "../../../../../common/modules/useStores";
import { snackActions } from "../../../../../utils/SnackbarUtilsConfigurator";
import { LoadingOverlay } from "../../../../../common/templates/LoadingOverlay";
import { errorToast } from "../../../../../utils/errorToast";
import { phoneNumberify } from "../../../../../utils/string";

const handleSave = (_students) => {
    localStorage.setItem(StudentFormsKey, JSON.stringify(_students));
};

const debouncedSave = _.debounce(handleSave, 500);

export const StudentFormsContainer = observer(({ isAdd }) => {
    const [students, setStudents] = useState(
        JSON.parse(localStorage.getItem(StudentFormsKey)) || []
    );

    const { studentId } = useParams();

    const { classroomStore } = useStores();
    const { failedStudents } = classroomStore;

    const navigate = useNavigate();

    const [failedOpen, setFailedOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const redirectToClassroomStudent = () => {
        navigate("/admin/classroom-student");
    };

    useEffect(() => {
        if (isAdd) debouncedSave(students);
    }, [students]);

    useEffect(() => {
        if (!isAdd) {
            setLoading(true);
            classroomStore
                .findStudent({ studentId })
                .then((student) => {
                    setStudents([student]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const handleCancel = () => {
        localStorage.removeItem(StudentFormsKey);
        redirectToClassroomStudent();
    };

    const handleStudentChange = (name, value, student) => {
        if (name === "phoneNumber") {
            return setIn(name, phoneNumberify(value), student);
        }
        return setIn(name, value, student);
    };

    const handleChange = (studentId, name, value) => {
        const student = students.find((_student) => _student.id === studentId);
        const newStudents = students.map((_student) => {
            if (_student.id === studentId) {
                return handleStudentChange(name, value, student);
            }
            return _student;
        });
        setStudents(newStudents);
    };

    const handleAdd = () => {
        if (students.length) {
            setStudents((prev) => [
                ...prev,
                blankStudent(prev[prev.length - 1].id + 1),
            ]);
        } else {
            setStudents([blankStudent(1)]);
        }
    };

    const handleDelete = (studentId) => {
        setStudents((prev) =>
            prev.filter((student) => student.id !== studentId)
        );
    };

    const handleSubmit = (_students) => {
        localStorage.removeItem(StudentFormsKey);
        if (isAdd) {
            const body = _students.map(
                (student) => new StudentDAOModel(student)
            );
            classroomStore.addStudents({ body }).then((resp) => {
                if (resp.status === 204) {
                    snackActions.success("성공적으로 추가되었습니다.");
                    redirectToClassroomStudent();
                } else setFailedOpen(true);
            });
        } else {
            const body = new StudentDAOModel(_students[0]);
            classroomStore
                .updateStudent({ studentId, body })
                .then(() => {
                    snackActions.success("성공적으로 수정되었습니다.");
                    redirectToClassroomStudent();
                })
                .catch((e) => {
                    errorToast(e);
                });
        }
    };

    const handleConfirm = () => {
        setFailedOpen(false);
    };

    if (loading)
        return (
            <LoadingOverlay
                active={loading}
                text="학생 정보를 불러오는 중입니다."
            />
        );

    return (
        <StudentFormsTemplate
            isAdd={isAdd}
            students={students}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleChange={handleChange}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            failedStudents={failedStudents}
            handleConfirm={handleConfirm}
            failedOpen={failedOpen}
        />
    );
});
