import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    Stack,
    darken,
    alpha,
} from "@mui/material";
import moment from "moment";
import { ClassroomForm } from "./ClassroomForm";
import { setIn } from "../../../../utils/immutableLodash";
import { useStores } from "../../../../common/modules/useStores";
import { ClassroomDAOModel } from "../../../../common/modules/classroom/ClassroomModel";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";
import { errorToast } from "../../../../utils/errorToast";

export const ClassroomAddDialog = ({ open, onClose }) => {
    const { classroomStore } = useStores();

    const [inputs, setInputs] = useState({
        name: "",
        startedDate: moment().format("YYYY-MM-DD"),
        finishedDate: moment().format("YYYY-MM-DD"),
    });

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const handleSubmit = (_inputs) => {
        const body = new ClassroomDAOModel(_inputs);
        classroomStore
            .addClassroom({ body })
            .then(() => {
                snackActions.success("성공적으로 등록되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            })
            .finally(() => {
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    bgcolor: "common.white",
                    borderRadius: 3,
                    width: 551,
                },
            }}
        >
            <ClassroomForm inputs={inputs} handleChange={handleChange} />
            <DialogActions>
                <Stack direction="row" spacing={1.25}>
                    <Button
                        sx={{
                            bgcolor: "common.white",
                            color: "#808080",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            border: "1px solid #B3B3B3",
                        }}
                        children="취소"
                        onClick={onClose}
                    />
                    <Button
                        sx={{
                            bgcolor: "#FF5555",
                            color: "#FFFFFF",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            "&:hover": {
                                bgcolor: darken("#FF5555", 0.1),
                            },
                            "&.Mui-disabled": {
                                bgcolor: alpha("#FF5555", 0.5),
                                color: "#FFFFFF",
                            },
                        }}
                        children="확인"
                        onClick={() => handleSubmit(inputs)}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
