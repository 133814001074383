import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { MissionsTemplate } from "./MissionsTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { searchParamsToObject } from "../../../utils/url";

export const MissionsContainer = observer(() => {
    const { missionStore, conceptStore } = useStores();
    const { missions, latestSolvedProblemSet } = missionStore;
    const { concepts } = conceptStore;

    const [missionId, setMissionId] = useState(0);

    const { missionId: defaultMissionId } = searchParamsToObject(
        window.location.search
    );

    useEffect(() => {
        missionStore.findAll();
        if (!defaultMissionId) {
            missionStore
                .getLatestSolvedProblem({})
                .then((latestSolvedProblem) => {
                    setMissionId(latestSolvedProblem.missionId);
                });
        } else {
            setMissionId(Number(defaultMissionId));
        }
    }, []);

    useEffect(() => {
        if (missionId) {
            conceptStore.findAll({ missionId });
            if (
                !(
                    latestSolvedProblemSet &&
                    latestSolvedProblemSet.missionId === missionId
                )
            ) {
                missionStore.getLatestSolvedProblem({ missionId });
            }
        }
    }, [missionId]);

    if (
        !(
            missions.length &&
            missionId &&
            concepts.length &&
            concepts[0].missionId === missionId &&
            latestSolvedProblemSet &&
            latestSolvedProblemSet.missionId === missionId
        )
    ) {
        return <LoadingOverlay text="정보를 불러오는 중입니다." active />;
    }

    return (
        <MissionsTemplate
            isTeacher
            missions={missions}
            missionId={missionId}
            setMissionId={setMissionId}
            concepts={concepts}
            latestSolvedProblemSet={latestSolvedProblemSet}
        />
    );
});
