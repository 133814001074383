import React from "react";
import { Divider, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import { SpecStack } from "./SpecStack";
import { MissionNameType } from "../../../../common/modules/missions/mission/MissionModel";
import { RecommendRow } from "./RecommendRow";
import { MainTemplateWrapper } from "../../../../common/templates/MainTemplateWrapper";

const MissionNameTypeToTextArray = {
    [MissionNameType.NoviceLow]: [
        [
            "코딩을 배우고는 싶은데 어디서부터 시작해야할지 잘 모르겠는 분",
            "코딩을 배웠지만, 이해가 안가서 포기했던 실패 경험이 있으신 분",
            "코딩을 한번 배울 때 기본부터 제대로 배워보고 싶으신 분",
            "입력으로 n개의 숫자를 입력받아 주어진 모든 숫자의 합을 구하는 코드를 작성하는데 5분 이상의 시간이 소요되는 분",
            "1부터 100까지의 숫자 중 소수의 개수가 몇 개인지를 판단하는 코드를 작성하는 데 10분 이상의 시간이 소요되는 분",
        ],
        [
            "코딩테스트 1번 문제는 항상 해결이 가능하신 분",
            "2021년 1월 1일부터 오늘까지 총 몇 일이 있는지를 구하는 코드를 20분 내로 작성이 가능하신 분",
        ],
        [
            "C / C++ / Python / Java 각 언어별 기초 문법을 습득하게 됩니다.",
            "포맷에 맞춰 값을 입력받고 문제에서 원하는 답을 출력하는 프로그램을 작성할 수 있게 됩니다.",
            "코딩테스트를 응시했을 때, 첫 번째 문제를 읽고 그 의미를 이해할 수 있게 됩니다.",
            "숫자 n이 주어졌을 때, n이 소수인지를 판단하는 코드를 5분안에 완벽하게 작성할 수 있게 됩니다. ",
        ],
        "코딩 공부를 해보고는 싶은데, 어디서부터 시작해야 할지 막막하신가요?\n" +
            "코딩 공부를 해보기는 했는데, 도중에 이해가 안가서 포기한 경험이 있지는 않으셨나요?\n" +
            "처음부터 알아듣기 어려운 단어로 범벅된 자료로 공부하시지는 않았나요?\n" +
            "코드를 읽지도 못하는데, 계속 쓰기만 강요당하지는 않으셨나요?\n" +
            "\n" +
            "코딩 공부도 이제는 정석대로 해야합니다!\n" +
            "읽고 이해하는 것을 시작으로, 빈 부분 없이 탄탄하게 코딩공부를 시작해보세요.\n" +
            "전세계 누구나 충분히 소화할 수 있는 커리큘럼입니다.\n",
    ],
    [MissionNameType.NoviceMid]: [
        [
            "기본적인 문법은 아는데, 아직 코드 작성 경험이 많이 없으신 분",
            "능수능란하게 코드 짜는 연습을 해보고 싶으신 분",
            "조건이 복잡한 문제의 경우 코드 작성이 어려우신 분",
            "문제가 주어졌을 때 느리더라도 올바른 답을 내는 방법이 떠오르지 않는 분",
            "n개의 숫자를 정렬하는 코드를 작성하는데 5분 이상의 시간이 소요되는 분",
            "dx, dy 테크닉이 무엇인지 모르는 분",
            "완전탐색이 무엇인지 모르거나, 문제를 많이 풀어보지 못한 분",
            "미국 정보올림피아드 (USACO) 브론즈 시험을 준비하시는 분",
        ],
        [
            "주어진 문제에 대해 효율성 상관없이 올바른 답을 내는 코드를 항상 작성하실 수 있으신 분",
            "미국 정보올림피아드 (USACO) 브론즈 시험을 이미 통과하신 분",
        ],
        [
            "이제는 틀린 코드를 빠른 시간 내에 스스로 찾아내 고칠 수 있게 됩니다.",
            "떠오른 생각을 코드로 구현하는 근육이 생기게 됩니다.",
            "주어진 모든 문제에 대해 느리더라도 올바른 답을 내는 코드를 작성할 수 있게 됩니다.",
            "미국 정보올림피아드 (USACO) 브론즈 시험을 통과할 수 있게 됩니다.",
            "네이버, 카카오, 라인 코딩테스트 1번 문제를 해결할 수 있게 됩니다.",
        ],
        "분명히 프로그래밍 언어를 배운 적이 있는데, 아직 프로그래밍이 두려우신가요?\n" +
            "문법은 아는데 문제만 보면 어떻게 코드를 작성해야 할지 전혀 갈피를 못 잡고 계시지는 않나요?\n" +
            "\n" +
            "영어 단어 외웠다고 영작이 바로 되지는 않죠.\n" +
            "프로그래밍 언어의 기초적인 문법을 배웠다면, 이제는 언어 사용에 익숙해질 차례!\n" +
            "더이상 프로그래밍이 두렵지 않도록, 내 생각을 코드로 옮기는 훈련을 해보세요.\n",
    ],
    [MissionNameType.NoviceHigh]: [
        [
            "알고리즘/자료구조 수업을 듣기 전에 미리 준비하여 학점 A+을 안정적으로 받고 싶으신 분",
            "알고리즘/자료구조 공부하던 도중 너무 어려워 포기하셨던 분",
            "알고리즘/자료구조 공부를 해본적은 있지만, 정확히 기억이 나지 않아 다시 개념을 다지고 싶으신 분",
            "알고리즘/자료구조 공부를 해야하는데 어디서부터 어떻게 공부해야할지 몰라 헤메고 계신 분",
            "코딩테스트 날짜가 얼마 남지 않았는데도 불구하고 알고리즘/자료구조에 대해 잘 모르고 계신 분",
            "코딩테스트 기출문제 해설을 읽었는데 용어를 몰라 이해를 못하고 계신 분",
            "영재고 입학 전에 코딩은 배웠지만 아직 알고리즘/자료구조에 대해서는 잘 모르는 분",
            "미국 정보올림피아드 (USACO) 실버 시험 문제 해설에 모르는 용어가 너무 많아 이해를 못하고 계신 분",
            "배열만 이용하여 주먹구구식으로 풀리는 문제는 다 해결이 가능한데, 이 방법만으로는 해결이 안되는 문제에 부딪혀 끙끙 앓고 계신 분",
        ],
        [
            "주어진 문제에 대해 효율성 상관없이 올바른 답을 내는 코드를 작성하기가 힘드신 분",
            "알고리즘/자료구조 개념에 대한 공부보다는 문제 풀이가 더 필요하다고 생각하시는 분",
            "시간복잡도, 이진탐색, 트리, 해싱, 그래프 등의 지식을 충분히 가지고 계신 분",
            "미국 정보올림피아드 (USACO) 실버 시험을 이미 통과하신 분",
        ],
        [
            "어떤 문제의 해설을 읽던 용어를 몰라 이해가 안가는 경우는 없게 됩니다.",
            "각 알고리즘/자료구조의 특징과 그 쓰임에 대해 확실히 이해하게 됩니다.",
        ],
        "어디서 분명히 들어본 알고리즘/자료구조인데, 개념이 흔들려 문제풀이에 어려움을 겪고 계시지는 않으신가요?\n" +
            "알고리즘/자료구조 개념을 공부하던 중 내용이 너무 어려워 포기하시지는 않으셨나요?\n" +
            "코딩테스트 준비를 시작해야 하는데, 어디서부터 준비해야 할지 몰라 헤매고 계시지는 않으신가요?\n" +
            "\n" +
            "이제는 알고리즘/자료구조 개념을 국가대표가 만든 컨텐츠로 확실하게 배우고, 유형별로 양질의 문제를 풀어보며 개념을 확실하게 다져보세요.\n" +
            "알고리즘/자료구조는 비전공자와 전공자를 가를 수 있는 가장 확실한 기준이며, 효율적인 코드를 작성하기 위해 반드시 제대로 알아야만 합니다.\n" +
            "자료구조와 알고리즘이 어떤 특징을 갖고 어떤 쓰임이 있는지에 대해 확실하게 배워보세요.\n",
    ],
    [MissionNameType.IntermediateLow]: [
        [
            "자료구조/알고리즘 개념은 아는데 문제풀이는 거의 진행해본 적이 없으신 분",
            "코딩실수가 잦으신 분",
            "시뮬레이션 문제 하나를 푸는데 1시간 이상 시간이 소요되는 분",
            "원하는 순열과 조합을 만들어내는데 10분 이상 시간이 소요되는 분",
            "그래프에서 인접한 모든 정점을 방문하는 코드를 작성하는데 10분 이상 시간이 소요되는 분",
            "2차원 격자 내에서 최단거리를 구하는 코드를 작성하는데 10분 이상 시간이 소요되는 분",
            "BFS로 최단거리를 구해야 하는 문제를 10문제 이상 풀어보지 못한 분",
            "삼성 코딩테스트를 앞두고 계신 분",
        ],
        [
            "알고리즘/자료구조 개념을 아직 잘 모르시는 분",
            "알고리즘/자료구조 개념은 알지만, 풀어본 문제수가 100문제가 채 안돼 코딩 기본기가 너무 부족한 분",
            "DFS, BFS, 시뮬레이션 문제 구현에 자신이 있으신 분",
            "미국 정보올림피아드 (USACO) 실버 시험을 이미 통과하신 분",
            "삼성 코딩테스트 A형 기준 3시간에 2문제 문제풀이가 충분히 가능하신 분",
        ],
        [
            "코딩 실수가 현저히 줄어들게 됩니다.",
            "정확도 측면에서 항상 문제가 원하는 올바른 답을 구하는 코드를 작성할 수 있게 됩니다.",
            "순열과 조합, 그래프 탐색, 시뮬레이션 류의 문제를 확실히 해결할 수 있게 됩니다.",
            "삼성 코딩테스트를 통과할 수 있게 됩니다.",
            "네이버, 카카오, 라인 코딩테스트 1, 2번 문제를 안정적으로 해결할 수 있게 됩니다.",
        ],
        "분명 풀이를 모르는 것은 아닌데, 어떤 자료구조를 써야하는지 아직 잘 판단이 안서시나요?\n" +
            "맞았다고 생각되는 코드가 왜 계속 틀렸다고 나오는지 이해가 안가는 경우가 자주 있으신가요?\n" +
            "아직 시뮬레이션 류의 문제를 만나면 실수하여 틀릴까봐 겁이 나지는 않으신가요?\n" +
            "\n" +
            "이제는 DFS, BFS, Backtracking 등과 같은 가장 기초적인 자료구조/알고리즘을 이용해 문제에서 요구하는 정확한 답을 구해내는 코드를 작성해보세요.\n" +
            "다양한 시뮬레이션 문제를 경험하며 실수하는 습관을 많이 줄여보세요.\n" +
            "삼성 코팅테스트 준비에 최적인 문제집입니다. \n",
    ],
    [MissionNameType.IntermediateMid]: [
        [
            "원하는 답을 구해내는 코드는 확실히 작성할 수 있으나, 효율적인 코드를 작성해본 경험이 많이 없으신 분",
            "Ordered-Set, Tree-Map, Priority-Queue, Hashing 등의 사용 경험이 많이 없으신 분",
            "Two Pointer, Binary Search 관련 문제를 10문제 이상 풀어보지 못한 분",
            "네카라쿠배 코딩테스트를 앞두고 계신 분",
        ],
        [
            "알고리즘/자료구조 개념을 아직 잘 모르시는 분",
            "코딩실수가 잦으신 분",
            "효율성과 무관하게 원하는 답을 구해내는 코드 작성에 아직 자신이 없으신 분",
            "미국 정보올림피아드 (USACO) 실버 시험을 이미 통과하신 분",
            "네카라쿠배 코딩테스트를 이미 통과하신 분",
        ],
        [
            "정확도는 물론, 가장 효율적으로 돌아가는 코드를 작성할 수 있는 문제가 많아지게 됩니다.",
            "네카라쿠배 코딩테스트에 나오는 범위의 모든 자료구조를 문제를 푸는 데 사용할 수 있게 됩니다.",
            "삼성 SW 역량테스트 B형 시험을 통과할 수 있게 됩니다.",
            "네이버, 카카오, 라인 코딩테스트 문제의 70%를 안정적으로 해결할 수 있게 됩니다.",
        ],
        "분명 작성한 코드를 통해 원하는 답은 나오는데, 시간초과가 발생하나요?\n" +
            "시간초과가 발생했을 때, 수행시간을 줄이는 것이 막막하지는 않으신가요?\n" +
            "\n" +
            "이제는 Priority Queue나 Hash 등의 자료구조와 Two Pointer 등의 알고리즘을 이용해 더 효율적인 프로그램을 작성하는 연습을 진행해보세요.\n" +
            "더 효율적인 프로그램을 작성할 수 있는지가 그 사람의 잠재성을 판단하는 기준이 됩니다.\n" +
            "네이버, 카카오, 라인, 쿠팡, 배민 코딩테스트 준비에 최적인 문제집 입니다.\n",
    ],
    [MissionNameType.IntermediateHigh]: [
        [
            "원하는 답을 구해내는 코드를 확실히 작성할 수 있으며, 효율적으로 문제를 풀어본 경험은 있지만 다양한 알고리즘에 대한 문제 풀이 경험이 부족한 분",
            "Disjoint Set, Dijkstra, Tree, MST, Divide and Conquer 등과 관련된 문제 풀이 경험이 많이 없으신 분",
            "네카라쿠배 코딩테스트를 보면 30% 이상은 해결이 가능하나, 100%까지 맞추지는 못하시는 분",
        ],
        [
            "네카라쿠베 문제를 30% 이상 해결하지 못하는 분",
            "코딩실수가 잦으신 분",
            "효율성과 무관하게 원하는 답을 구해내는 코드 작성에 아직 자신이 없으신 분",
            "미국 정보올림피아드 (USACO) 실버 시험을 이미 통과하신 분",
            "네카라쿠배 혹은 구글 코딩테스트를 이미 통과하신 분",
        ],
        [
            "각 상황에 어떤 자료구조를 이용하여 어떤 알고리즘을 적용하는 것이 적합한지를 판단하고 코드로 유연하게 작성할 수 있게 됩니다.",
            "모든 문제에 대해 정확도는 물론, 가장 효율적으로 돌아가는 코드를 작성할 수 있게 됩니다.",
            "네카라쿠배 코딩테스트에 나오는 범위의 모든 알고리즘을 문제를 푸는 데 사용할 수 있게 됩니다.",
            "미국 정보올림피아드 (USACO) 실버 시험을 통과할 수 있게 됩니다.",
            "한국 정보올림피아드 전국대회에서 동상을 수상할 수 있게 됩니다.",
            "네이버, 카카오, 라인 코딩테스트 문제의 100%를 안정적으로 해결할 수 있게 됩니다.",
            "구글 코딩테스트를 통과할 수 있게 됩니다.",
        ],
        "시간복잡도를 줄여본 경험은 있지만, 아직 익숙하지 않은 알고리즘들이 많이 남아있나요?\n" +
            "알고 있는 알고리즘/자료구조를 이용해 풀어본 문제 수가 너무 적다는 생각이 들지는 않으신가요?\n" +
            "\n" +
            "이제는 Disjoint Set, Dijkstra, Tree, MST, Divide and Conquer 등과 관련된 문제 풀이를 진행하여 다양한 문제에 대해 정확도는 물론, 가장 효율적으로 돌아가는 코드를 작성하는 연습을 진행해보세요.\n" +
            "네카라쿠배 만점 달성, 혹은 구글 코딩테스트 준비에 최적인 문제집 입니다.\n",
    ],
};

export const OverviewContent = ({ mission }) => {
    const theme = useTheme();

    const { lessonCount, quizCount, testCount } = mission;

    const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Paper
            sx={{
                boxShadow: 0,
            }}
        >
            <Stack direction="column">
                <MainTemplateWrapper>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: 84,
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                width: { md: 700, xs: "100%" },
                                maxWidth: 700,
                            }}
                        >
                            <SpecStack
                                specValue="2개월 ~ 6개월"
                                specName="학습 소요 시간"
                                icon={
                                    <CalendarTodayIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#666666",
                                        }}
                                    />
                                }
                            />
                            {smBreakpoint ? (
                                <Stack direction="row" spacing={5}>
                                    <SpecStack
                                        specValue={`${lessonCount}문항`}
                                        specName="기본 개념"
                                    />
                                    <SpecStack
                                        specValue={`${quizCount}문항`}
                                        specName="연습 문제"
                                    />
                                    <SpecStack
                                        specValue={`${testCount}문항`}
                                        specName="테스트"
                                    />
                                </Stack>
                            ) : (
                                <SpecStack
                                    specValue={`${
                                        testCount + lessonCount + quizCount
                                    }문항`}
                                    specName="기본/연습/테스트"
                                />
                            )}
                            <SpecStack
                                specValue="바로 코딩"
                                specName="설치 없는 실습 환경"
                                icon={
                                    <CloudQueueIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#666666",
                                        }}
                                    />
                                }
                            />
                        </Stack>
                    </Stack>
                </MainTemplateWrapper>
                <Divider />
                <MainTemplateWrapper>
                    <Stack
                        direction="column"
                        alignItems="center"
                        sx={{
                            py: 10,
                        }}
                    >
                        <Stack direction="column" spacing={7}>
                            <RecommendRow
                                title={"이런 분께\n추천드려요"}
                                contents={
                                    MissionNameTypeToTextArray[mission.name][0]
                                }
                            />
                            <RecommendRow
                                title={"이런 분께는\n추천드리지 않아요"}
                                contents={
                                    MissionNameTypeToTextArray[mission.name][1]
                                }
                            />
                            <RecommendRow
                                title={"이런 것들을\n얻어가실 수 있어요"}
                                contents={
                                    MissionNameTypeToTextArray[mission.name][2]
                                }
                            />
                        </Stack>
                    </Stack>
                    <Divider />
                </MainTemplateWrapper>
            </Stack>
        </Paper>
    );
};
