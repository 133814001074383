import { FormControlLabel, styled } from "@mui/material";

export const DiscussionsFormControlLabel = styled(FormControlLabel)(
    ({ theme }) => ({
        marginRight: 10,
        "& .MuiFormControlLabel-label": {
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
            fontSize: theme.typography.body2.fontSize,
        },
    })
);
