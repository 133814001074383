import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FAQList } from "./FAQList";

export const FAQTemplate = ({ faqKey, FAQ }) => {
    const [selectedFAQ, setSelectedFAQ] = useState(
        FAQ.find((element) => element.key === faqKey) ? faqKey : null
    );

    const navigate = useNavigate();

    const rootURL = `https://contents.codetree.ai/ooe/faq/mp4/`;

    const handleClick = (faqKey) => {
        setSelectedFAQ(faqKey);
        navigate(`/help/faq/${faqKey}`);
    };

    const theme = useTheme();
    const mdBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

    // "기타 문의 사항은 어디에 말해야 할까요?"
    return (
        <Stack spacing={2.5} width={"100%"} py={10}>
            <Typography variant="h1" fontWeight={600}>
                자주 묻는 질문
            </Typography>
            <Stack
                display={mdBreakpoint ? "flex" : "grid"}
                width={"100%"}
                gridTemplateColumns={"max-content 1fr"}
                columnGap={2.5}
                spacing={2.5}
            >
                <Stack spacing={2.5}>
                    <FAQList
                        FAQ={FAQ}
                        selectedFAQ={selectedFAQ}
                        onClick={(key) => handleClick(key)}
                        swipe={mdBreakpoint}
                    />
                </Stack>
                {selectedFAQ !== -1 ? (
                    <video width={"100%"} controls key={selectedFAQ} autoPlay>
                        <source src={`${rootURL}${selectedFAQ}.mp4`} />
                    </video>
                ) : null}
            </Stack>
        </Stack>
    );
};
