import { makeAutoObservable } from "mobx";
import { StudyUnitModel } from "./StudyUnitModel";
import StudyUnitRepository from "./StudyUnitRepository";

export default class StudyUnitStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *findAll({ conceptId, problemName }) {
        const concept = this.rootStore.conceptStore.concepts.find(
            (concept) => concept.id === Number(conceptId)
        );
        if (problemName || !concept.isFetched) {
            const response = yield StudyUnitRepository.findAll({
                conceptId,
                problemName,
            });
            concept.setStudyUnits(
                response.data.map((studyUnit) => new StudyUnitModel(studyUnit))
            );
        }
    }
}
