import React from "react";
import { LinearProgress, styled } from "@mui/material";

const StyledLinearProgressComponent = styled(LinearProgress)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 10,
    height: 8,
    "& .MuiLinearProgress-bar": {
        borderRadius: theme.shape.borderRadius * 10,
    },
}));

export const StyledLinearProgress = (props) => (
    <StyledLinearProgressComponent
        color="primary"
        variant="determinate"
        {...props}
    />
);
