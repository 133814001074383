import { makeAutoObservable, set } from "mobx";
import { snakelizeKeys, camelizeKeys } from "../../../utils/renameKeys";
import { pickCls } from "../../../utils/immutableLodash";

export class ReplyDAOModel {
    content = "";

    isAnonymous = false;

    constructor(data) {
        const reply = pickCls(this, data);
        Object.assign(this, snakelizeKeys(reply));
    }
}

export class ReplyModel {
    id = 0;

    content = "";

    username = "";

    createdAt = "";

    isAnonymous = false;

    isAuthor = false;

    isDiscussionAuthor = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const reply = pickCls(this, camelizeKeys(data));
        set(this, reply);
    }
}

export class CommentModel {
    id = 0;

    content = "";

    username = "";

    createdAt = "";

    replyNum = 0;

    isAnonymous = false;

    isAuthor = false;

    isDiscussionAuthor = false;

    replies = [];

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const comment = pickCls(this, camelizeKeys(data));
        set(this, comment);
    }

    setReplies(_replies) {
        this.replies = _replies;
    }
}

export class CommentDAOModel {
    content = "";

    isAnonymous = false;

    constructor(data) {
        const comment = pickCls(this, data);
        Object.assign(this, snakelizeKeys(comment));
    }
}

export class DiscussionModel {
    problemName = "";

    problemId = 0;

    createdAt = "";

    isCommented = false;

    isResolved = false;

    linkUrl = "";

    description = "";

    title = "";

    username = "";

    commentNum = 0;

    isAnonymous = false;

    isAuthor = false;

    id = 0;

    isReplied = false;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const discussion = pickCls(this, camelizeKeys(data));
        set(this, discussion);
    }
}

export class DiscussionDAOModel {
    title = "";

    description = "";

    isAnonymous = false;

    linkUrl = "";

    isResolved = false;

    constructor(data) {
        const discussion = pickCls(this, data);
        Object.assign(this, snakelizeKeys(discussion));
    }
}
