import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    SubmissionStatusToColor,
    SubmissionStatusToKorean,
    SubmissionStatusType,
} from "../../../../../../../common/modules/submission/SubmissionModel";

export const ExecuteResultHeader = ({ result, onClose }) => {
    const { runtime, status } = result;

    const hasRuntime =
        status === SubmissionStatusType.Passed ||
        status === SubmissionStatusType.WrongAnswer;

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            userSelect="none"
        >
            <Stack direction="row" alignItems="flex-end">
                <Typography
                    variant="h4"
                    fontWeight={500}
                    color={SubmissionStatusToColor[status]}
                    children={SubmissionStatusToKorean[status]}
                />
                <Typography
                    color="#959595"
                    variant="body2"
                    letterSpacing="-0.6px"
                    ml={1.5}
                    children={hasRuntime ? `${runtime}ms` : `${status}`}
                />
            </Stack>
            <IconButton onClick={onClose}>
                <ExpandMoreIcon />
            </IconButton>
        </Stack>
    );
};
