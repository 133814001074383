import React, { useRef, useState } from "react";
import {
    Stack,
    Tabs,
    Tab,
    Container,
    Popper,
    Grow,
    IconButton,
} from "@mui/material";
import { HomeworkFormHeader } from "./HomeworkFormHeader";
import { CuratedProblemSelectorContainer } from "./CuratedProblemSelectorContainer";
import { MissionBox } from "../CuratedProblemsPreview/MissionBox";
import { NoResultsTemplate } from "../../../Missions/Playground/templates/NoResultsTemplate";
import { Close } from "@mui/icons-material";

export const HomeworkFormTemplate = ({
    inputs,
    handleChange,
    isAdd,
    handleSubmit,
    handleCancel,
    homeworkTree,
}) => {
    const [activeTab, setActiveTab] = useState(isAdd ? 0 : 1);
    const [tempClose, setTempClose] = useState(false);

    const anchorRef = useRef(null);

    const { curatedProblems } = inputs;

    const handleTabChange = (e, newValue) => {
        setActiveTab(newValue);
    };

    const handleClickProblem = (curatedProblemId) => {
        handleChange(
            "curatedProblems",
            curatedProblems.filter(
                (item) => item.curatedProblemId !== curatedProblemId
            )
        );
    };

    const handleScoreChange = (curatedProblemId, score) => {
        handleChange(
            "curatedProblems",
            curatedProblems.map((item) => {
                if (item.curatedProblemId !== curatedProblemId) {
                    return item;
                } else {
                    return {
                        ...item,
                        score,
                    };
                }
            })
        );
    };

    const handleSolutionVisibleChange = (curatedProblemId, solutionVisible) => {
        handleChange(
            "curatedProblems",
            curatedProblems.map((item) => {
                if (item.curatedProblemId !== curatedProblemId) {
                    return item;
                } else {
                    return {
                        ...item,
                        solutionVisible,
                    };
                }
            })
        );
    };

    const handleAIEnabledChange = (curatedProblemId, aiEnabled) => {
        handleChange(
            "curatedProblems",
            curatedProblems.map((item) => {
                if (item.curatedProblemId !== curatedProblemId) {
                    return item;
                } else {
                    return {
                        ...item,
                        aiEnabled,
                    };
                }
            })
        );
    };

    return (
        <Stack direction="column" height="100%">
            <HomeworkFormHeader
                inputs={inputs}
                handleChange={handleChange}
                isAdd={isAdd}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                homeworkTree={homeworkTree}
            />
            <Stack alignItems={"center"} borderBottom={"1px solid #e1e1e7"}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="전체 문제" />
                    <Tab
                        ref={anchorRef}
                        label={`선택된 문제(${curatedProblems.length})`}
                    />
                </Tabs>
            </Stack>

            <Popper
                anchorEl={anchorRef.current}
                open={curatedProblems.length && !tempClose && activeTab !== 1}
                placement="bottom"
                transition
                style={{
                    zIndex: 1200,
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "top",
                        }}
                    >
                        <Stack
                            p={16 / 8}
                            direction={"row"}
                            alignItems={"center"}
                            borderRadius={8 / 8}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                                position: "relative",
                                "&:before": {
                                    content: `""`,
                                    position: "absolute",
                                    top: -1,
                                    left: "50%",
                                    transform: "translate(-50%, -100%)",
                                    display: "block",
                                    width: 0,
                                    height: 0,
                                    borderStyle: "solid",
                                    borderWidth: "0 10px 10px 10px",
                                    borderColor:
                                        "transparent transparent rgba(0, 123, 255, .5) transparent",
                                },
                                "&:after": {
                                    content: `""`,
                                    position: "absolute",
                                    top: 1,
                                    left: "50%",
                                    transform: "translate(-50%, -100%)",
                                    display: "block",
                                    width: 0,
                                    height: 0,
                                    borderStyle: "solid",
                                    borderWidth: "0 10px 10px 10px",
                                    borderColor:
                                        "transparent transparent #ffffff  transparent",
                                },
                            }}
                        >
                            문항별 배점, 학생들의 해설 열람 여부를 편집할 수
                            있어요.
                            <IconButton onClick={() => setTempClose(true)}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Grow>
                )}
            </Popper>

            {activeTab === 0 ? (
                <CuratedProblemSelectorContainer
                    curatedProblems={curatedProblems}
                    handleChange={handleChange}
                />
            ) : (
                <Stack direction="column" alignItems="center" overflow="auto">
                    <Container maxWidth="md" sx={{ flex: 1 }}>
                        <Stack direction="column" spacing={1.25} py={9} px={2}>
                            {homeworkTree.length === 0 ? (
                                <NoResultsTemplate
                                    sx={{
                                        pt: 3,
                                    }}
                                    text="아직 선택된 항목이 없습니다."
                                />
                            ) : (
                                homeworkTree.map((mission) => (
                                    <MissionBox
                                        key={`mission-box-${mission.id}`}
                                        inputs={inputs}
                                        mission={mission}
                                        handleClickProblem={handleClickProblem}
                                        handleScoreChange={handleScoreChange}
                                        handleSolutionVisibleChange={
                                            handleSolutionVisibleChange
                                        }
                                        handleAIEnabledChange={
                                            handleAIEnabledChange
                                        }
                                    />
                                ))
                            )}
                        </Stack>
                    </Container>
                </Stack>
            )}
        </Stack>
    );
};
