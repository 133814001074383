import React from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { openNewTabForText } from "../../../utils/clipboard";
import { IconButtonWithTooltip } from "./IconButtonWithTooltip";

export const OpenInNewTabButton = ({
    iconSize,
    text,
    tooltipPlacement,
    tooltipTitle,
    fontSize,
    sx,
}) => (
    <IconButtonWithTooltip
        fontSize={fontSize}
        sx={sx}
        iconSize={iconSize}
        Icon={LaunchIcon}
        onClick={() => openNewTabForText(text)}
        tooltipTitle={tooltipTitle || "새 탭에서 보기"}
        tooltipPlacement={tooltipPlacement}
    />
);
