import React, { forwardRef } from "react";
import MDEditor from "@uiw/react-md-editor";

import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

import "katex/dist/katex.min.css";

export const MarkdownEditor = forwardRef(({ ...props }, ref) => (
    <MDEditor
        ref={ref}
        previewOptions={{
            remarkPlugins: [remarkMath],
            rehypePlugins: [rehypeKatex],
        }}
        {...props}
    />
));
