import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";
import { GET } from "../../../utils/api";

class SubmissionRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAllByProblem(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`/problems/submissions/${queryParams}`);
    }

    findAllByUser(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`/problems/submissions/${queryParams}`);
    }

    findAllByProblemAndUser(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`/submissions/${queryParams}`);
    }

    findAllByFiddleAndUser(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`/submissions/${queryParams}`);
    }

    errorSummary({ submissionId }) {
        return GET(`/problems/submissions/${submissionId}/gpt-error-summary/`);
    }
}

// 싱글톤으로 리턴
export default new SubmissionRepository();
