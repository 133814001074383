import React, { useEffect, useState } from "react";
import { ClassroomsTemplate } from "./ClassroomsTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { observer } from "mobx-react-lite";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { searchParamsToObject } from "../../../utils/url";
import { objectToQueryString } from "../../../utils/immutableLodash";

export const ClassroomsContainer = observer(() => {
    const { classroomStore, authStore } = useStores();
    const { currUser, isUserLoggedIn, hasToken } = authStore;
    const { availableIsFetching, availableClassrooms } = classroomStore;

    const navigate = useNavigate();
    const location = useLocation();
    const { invitation } = searchParamsToObject(location.search);

    const [selectedClassroom, setSelectedClassroom] = useState(null);

    const handleSelect = (classroom) => {
        setSelectedClassroom(classroom);
    };

    const encodeInvitation = (classroom) => {
        const queryParams = objectToQueryString({
            invitation: btoa(
                `${classroom.teacher.username}&${classroom.school.id}&${classroom.id}`
            ),
        });
        return `${window.location.origin}/classrooms/${queryParams}`;
    };

    const decodeInvitation = (code) => {
        try {
            return atob(code).split("&");
        } catch (error) {
            return [];
        }
    };

    const handleInvitationError = () => {
        navigate("/classrooms/", { replace: true });
        snackActions.error("유효하지 않은 초대 링크입니다");
    };

    useEffect(() => {
        if (invitation && availableClassrooms.length) {
            const [teacherUsername, schoolId, classroomId] =
                decodeInvitation(invitation);
            if (classroomId) {
                const searchedClassroom = availableClassrooms.find(
                    (classroom) => classroom.id == classroomId
                );

                if (
                    !searchedClassroom ||
                    searchedClassroom.teacher.username != teacherUsername ||
                    searchedClassroom.school.id != schoolId
                ) {
                    handleInvitationError();
                } else {
                    handleSelect(searchedClassroom);
                }
            } else {
                handleInvitationError();
            }
        }
    }, [invitation, availableClassrooms.length]);

    const handleRequest = (classroomId) => {
        classroomStore.requestClassroom({ classroomId }).then(() => {
            snackActions.success("신청되었습니다.");
        });
    };

    if (!isUserLoggedIn() && !hasToken()) {
        const { pathname, search } = location;
        return (
            <Navigate
                to={`/accounts/loginWithMs?nextUrl=${encodeURIComponent(
                    `${pathname}${search}`
                )}`}
                replace={true}
            />
        );
    }

    if (!currUser) return <LoadingOverlay active />;

    return (
        <ClassroomsTemplate
            invitation={invitation}
            targetSchool={currUser.school}
            handleRequest={handleRequest}
            loading={availableIsFetching}
            handleSelect={handleSelect}
            selectedClassroom={selectedClassroom}
            encodeInvitation={encodeInvitation}
        />
    );
});
