import React from "react";
import { List, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { AdminListItem } from "./AdminListItem";

const treeDatas = [
    {
        id: "classroom",
        name: "학급",
        children: [
            {
                id: "classroom-dashboard",
                name: "진도 이수 현황",
            },
            {
                id: "classroom-student",
                name: "학생 관리",
            },
            {
                id: "classroom-queue",
                name: "대기열 관리",
            },
            {
                id: "classroom-edit",
                name: "학급 관리",
            },
        ],
    },
    {
        id: "homework",
        name: "수업/과제",
        children: [
            {
                id: "homework-dashboard",
                name: "진도 이수 현황",
            },
            {
                id: "homework-edit",
                name: "수업/과제 관리",
            },
            {
                id: "homework-market",
                name: "수업/과제 템플릿",
            },
        ],
    },
    {
        id: "quiz",
        name: "퀴즈",
        children: [
            {
                id: "quiz-dashboard",
                name: "퀴즈 제출 현황",
            },
        ],
    },
    {
        id: "discussion",
        name: "질문",
        children: [
            {
                id: "discussion-list",
                name: "질문 목록",
            },
        ],
    },
];

export const AdminList = observer(() => {
    const { classroomStore } = useStores();
    const { classrooms } = classroomStore;

    return classrooms.length ? (
        <List
            sx={{
                width: "100%",
                py: 0,
            }}
        >
            {treeDatas.map((treeData) => (
                <AdminListItem
                    treeData={treeData}
                    key={`admin-list-${treeData.id}`}
                />
            ))}
        </List>
    ) : (
        <Typography
            variant="h4"
            children="학급을 먼저 만들어주세요."
            color="#FFFFFF"
            mt={5}
        />
    );
});
