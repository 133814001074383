import React, { forwardRef } from "react";
import {
    ThemeProvider,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    Fade,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        black: {
            main: "#2B6531",
            contrastText: "#fff",
        },
    },
});

export const TutorialCard = forwardRef(
    (
        {
            title,
            body,
            handlePrevTutorial,
            handleNextTutorial,
            prevLabel = "이전",
            nextLabel = "다음",
        },
        ref
    ) => (
        <Fade in timeout={350}>
            <Card
                ref={ref}
                elevation={8}
                sx={{
                    backgroundColor: "#68CB7E",
                    color: "#013516",
                }}
            >
                <CardContent>
                    <Typography
                        variant="h4"
                        color="#fff"
                        fontWeight={800}
                        mb={3}
                    >
                        {title}
                    </Typography>
                    <Typography variant="body1">{body}</Typography>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "flex-end",
                    }}
                >
                    <ThemeProvider theme={theme}>
                        {handlePrevTutorial ? (
                            <Button
                                color="black"
                                onClick={handlePrevTutorial}
                                size="small"
                            >
                                {prevLabel}
                            </Button>
                        ) : null}
                        {handleNextTutorial ? (
                            <Button
                                color="black"
                                onClick={handleNextTutorial}
                                size="small"
                            >
                                {nextLabel}
                            </Button>
                        ) : null}
                    </ThemeProvider>
                </CardActions>
            </Card>
        </Fade>
    )
);
