import React from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { copyToClipboard } from "../../../utils/clipboard";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { IconButtonWithTooltip } from "./IconButtonWithTooltip";

export const CopyButton = ({
    iconSize,
    text,
    tooltipPlacement,
    tooltipTitle,
    fontSize,
    sx,
}) => {
    const handleCopy = () =>
        copyToClipboard({
            text,
            onCopy: () => snackActions.success("클립보드에 복사 되었습니다."),
        });

    return (
        <IconButtonWithTooltip
            fontSize={fontSize}
            iconSize={iconSize}
            Icon={FileCopyIcon}
            onClick={handleCopy}
            tooltipTitle={tooltipTitle || "복사하기"}
            tooltipPlacement={tooltipPlacement}
            sx={sx}
        />
    );
};
