import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import { CurriculumsContainer } from "./CurriculumsContainer";
// import { CurriculumContainer } from "./CurriculumContainer";
import ChannelService from "../../../common/components/channel/ChannelService";

export const AssessmentPage = () => {
    useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, []);

    return (
        <Routes>
            <Route path="/" element={<div />} />
            {/* <Route path="/:missionId" element={<CurriculumContainer />} /> */}
        </Routes>
    );
};
