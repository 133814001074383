import React from "react";
import {
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { LargeWhiteTextField } from "../../../../../common/components/textField/LargeWhiteTextField";
import { LargeButton } from "../../../../../common/components/button/LargeButton";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";

export const HomeworkFormHeader = ({
    inputs,
    handleChange,
    handleCancel,
    handleSubmit,
    isAdd,
}) => {
    const {
        name,
        startedAt,
        finishedAt,
        isDiscussionHide,
        isSubBlockedAfterFinish,
    } = inputs;

    return (
        <Stack
            direction="column"
            alignItems="center"
            pt={2.5}
            maxHeight={"100vh"}
        >
            <Container maxWidth="md" sx={{ flexShrink: 0 }}>
                <Stack direction="column" spacing={2.5}>
                    <LargeWhiteTextField
                        label="수업/과제 이름"
                        onChange={(e) => {
                            handleChange("name", e.target.value);
                        }}
                        value={name}
                        placeholder="숙제 3"
                    />
                    <Stack direction="column" alignItems="flex-end">
                        <Stack
                            direction="row"
                            spacing={2.5}
                            alignSelf={"flex-start"}
                        >
                            <Stack direction="column" spacing={1.25}>
                                <Typography variant="body2" children="시작일" />
                                <DateTimePicker
                                    value={startedAt}
                                    onChange={(newValue) => {
                                        if (
                                            moment(newValue).isAfter(
                                                moment(finishedAt)
                                            )
                                        ) {
                                            alert(
                                                "시작일은 종료일보다 뒤여야 합니다"
                                            );
                                        } else {
                                            handleChange("startedAt", newValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Stack>
                            <Stack direction="column" spacing={1.25}>
                                <Typography variant="body2" children="종료일" />
                                <DateTimePicker
                                    value={finishedAt}
                                    onChange={(newValue) => {
                                        if (
                                            moment(newValue).isBefore(
                                                moment(startedAt)
                                            )
                                        ) {
                                            alert(
                                                "종료일은 시작일보다 뒤여야 합니다"
                                            );
                                        } else {
                                            handleChange(
                                                "finishedAt",
                                                newValue
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Stack>
                            <Stack component={FormGroup} spacing={0}>
                                <Typography variant="body2" children="옵션" />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="secondary"
                                            checked={!!isSubBlockedAfterFinish}
                                            onChange={(e) => {
                                                handleChange(
                                                    "isSubBlockedAfterFinish",
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <Typography variant="body2">
                                                종료 뒤엔 제출받지 않기{" "}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography variant="body2">
                                                        종료 뒤에 수업/과제의
                                                        제출 버튼이 비활성화
                                                        됩니다. 단 같은 문항들을
                                                        &apos;혼자
                                                        학습하기&apos;에서는
                                                        제출할 수 있습니다.
                                                        수업/과제 편집에서
                                                        수정할 수 있습니다.
                                                    </Typography>
                                                }
                                                placement="top"
                                            >
                                                <HelpIcon fontSize="small" />
                                            </Tooltip>
                                        </Stack>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="secondary"
                                            checked={!!isDiscussionHide}
                                            onChange={(e) => {
                                                handleChange(
                                                    "isDiscussionHide",
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <Typography variant="body2">
                                                토론 가리기{" "}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography variant="body2">
                                                        토론탭을 숨깁니다.
                                                        수업/과제 편집에서
                                                        수정할 수 있습니다.
                                                    </Typography>
                                                }
                                                placement="top"
                                            >
                                                <HelpIcon fontSize="small" />
                                            </Tooltip>
                                        </Stack>
                                    }
                                />
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2.5}
                            alignItems="center"
                        >
                            <Stack direction="row" spacing={1.25}>
                                <LargeButton
                                    label="취소"
                                    onClick={handleCancel}
                                    variant="outlined"
                                    sx={{
                                        borderColor: "#808080",
                                    }}
                                    color="#808080"
                                    backgroundColor="#FFFFFF00"
                                />
                                <LargeButton
                                    label={isAdd ? "저장" : "변경사항 저장"}
                                    onClick={() => handleSubmit(inputs)}
                                    sx={{
                                        borderColor: "#808080",
                                    }}
                                    color="#FFFFFF"
                                    backgroundColor="#FF5555"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};
