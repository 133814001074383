import { makeAutoObservable } from "mobx";
import MissionRepository from "./MissionRepository";
import {
    LatestSolvedProblemSetModel,
    MissionModel,
    MissionTreeModel,
} from "./MissionModel";

export default class MissionStore {
    mission = undefined;

    missionTree = undefined;

    curatedProblems = [];

    currProblem = undefined;

    missions = [];

    currProblemIdx = -1;

    latestSolvedProblemSet = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    *find({ missionId }) {
        const response = yield MissionRepository.find({ missionId });
        this.mission = new MissionModel(response.data);
    }

    *findAll() {
        const response = yield MissionRepository.findAll();
        this.missions.replace(
            response.data.map((mission) => new MissionModel(mission))
        );
        return this.missions;
    }

    *findDetail({ missionId }) {
        const response = yield MissionRepository.findDetail({ missionId });
        this.mission = new MissionModel(response.data);
    }

    *getLatestSolvedProblem({ missionId = null }) {
        const response = yield MissionRepository.getLatestSolvedProblem({
            missionId,
        });
        this.latestSolvedProblemSet = new LatestSolvedProblemSetModel(
            response.data
        );

        return this.latestSolvedProblemSet;
    }

    changeToTreeProblem({ problemId }) {
        this.currProblemIdx = this.curatedProblems.findIndex(
            (curatedProblem) => curatedProblem.id === Number(problemId)
        );
        this.currProblem = this.curatedProblems[this.currProblemIdx];
        return this.currProblem.id;
    }

    *getMissionTree({ missionId, problemId }) {
        const response = yield MissionRepository.getMissionTree({ missionId });
        // yield this.rootStore.problemStore.getDescription({ problemId });
        this.missionTree = new MissionTreeModel(response.data);
        const curatedProblems = [];
        this.missionTree.children.forEach((concept) => {
            concept.children.forEach((studyUnit) => {
                studyUnit.children.forEach((curatedProblem) => {
                    curatedProblems.push(curatedProblem);
                });
            });
        });
        this.curatedProblems.replace(curatedProblems);
        this.changeToTreeProblem({ problemId });
    }

    changeToNextProblem() {
        if (this.curatedProblems.length - 1 === this.currProblemIdx) {
            return -1;
        }
        this.currProblem = this.curatedProblems[this.currProblemIdx + 1];
        this.currProblemIdx += 1;
        return this.currProblem.id;
    }

    changeToPrevProblem() {
        if (!this.currProblemIdx) {
            return -1;
        }
        this.currProblem = this.curatedProblems[this.currProblemIdx - 1];
        this.currProblemIdx -= 1;
        return this.currProblem.id;
    }
}
