import React, { useRef } from "react";
import {
    Badge,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuItem,
    Paper,
    Popper,
    Stack,
    Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import moment from "moment";
import { LoadingOverlay } from "../LoadingOverlay";
import { useStores } from "../../modules/useStores";
import { AutoAwesome } from "@mui/icons-material";

export const NotificationsTemplate = ({
    isFetching,
    notifications,
    open,
    toggleOpen,
    handleClose,
    unseenCount,
}) => {
    const { notificationStore } = useStores();

    const anchorRef = useRef(null);

    const handleOpen = (notification) => {
        window.location.href = notification.linkUrl;
        notificationStore.readNotification({ notificationId: notification.id });
        toggleOpen();
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={toggleOpen}
                color="inherit"
                sx={{
                    p: 0,
                }}
            >
                <Badge badgeContent={unseenCount} max={99} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popper
                anchorEl={anchorRef.current}
                open={open}
                role={undefined}
                placement="bottom-end"
                transition
                style={{
                    zIndex: 1200,
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "left top",
                        }}
                    >
                        <Paper>
                            <LoadingOverlay active={isFetching}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Stack>
                                        {notifications.length ? (
                                            notifications.map(
                                                (notification) => (
                                                    <MenuItem
                                                        key={`notifications-${notification.id}`}
                                                        sx={{
                                                            p: 1,
                                                            background:
                                                                notification.isRead
                                                                    ? "#F6F6F6"
                                                                    : "#FFFFFF",
                                                        }}
                                                        onClick={() =>
                                                            handleOpen(
                                                                notification
                                                            )
                                                        }
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            width={456}
                                                            sx={{
                                                                whiteSpace:
                                                                    "pre-line",
                                                            }}
                                                            alignItems="center"
                                                        >
                                                            <div
                                                                style={{
                                                                    width: 8,
                                                                    height: 8,
                                                                    borderRadius:
                                                                        "50%",
                                                                    background:
                                                                        notification.isRead
                                                                            ? "transparent"
                                                                            : "#18A0FB",
                                                                    marginRight: 6,
                                                                }}
                                                            />
                                                            <img
                                                                src="https://contents.codetree.ai/homepage/images/main_page/missions/comment_with_bubble.svg"
                                                                style={{
                                                                    marginRight: 10,
                                                                }}
                                                            />
                                                            <Stack
                                                                direction="column"
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    color={
                                                                        notification.isRead
                                                                            ? "#616161"
                                                                            : "#111111"
                                                                    }
                                                                >
                                                                    {
                                                                        notification.content
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    variant="overline"
                                                                    color="#8E8E8E"
                                                                    sx={{
                                                                        wordBreak:
                                                                            "keep-all",
                                                                        mt: 0.5,
                                                                        alignSelf:
                                                                            "flex-end",
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        notification.createdAt
                                                                    ).fromNow()}
                                                                </Typography>
                                                            </Stack>
                                                            <Divider />
                                                        </Stack>
                                                    </MenuItem>
                                                )
                                            )
                                        ) : (
                                            <Paper
                                                sx={{
                                                    padding: 3,
                                                }}
                                            >
                                                <Stack alignItems={"center"}>
                                                    <AutoAwesome
                                                        sx={{
                                                            color: "#42D760",
                                                        }}
                                                    />
                                                    <Typography>
                                                        표시할 알림이 없습니다.
                                                    </Typography>
                                                </Stack>
                                            </Paper>
                                        )}
                                    </Stack>
                                </ClickAwayListener>
                            </LoadingOverlay>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
