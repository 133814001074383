import React from "react";
import { TextField } from "@mui/material";

export const SearchField = ({ value, setValue, onSubmit, ...props }) => (
    <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={({ key }) => {
            if (key === "Enter") {
                onSubmit(value);
            }
        }}
        autoFocus
        fullWidth
        placeholder="검색어 입력"
        InputProps={{
            sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
            },
        }}
        inputProps={{
            sx: {
                fontSize: 14,
                lineHeight: "20px",
                fontWeight: 700,
                py: 1,
            },
        }}
        sx={{
            bgcolor: "background.paper",
        }}
        {...props}
    />
);
