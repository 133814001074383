import React, { useEffect, useRef, useState } from "react";
import { Stack, useTheme } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../../common/components/appBar/ResponsiveAppBar";
import { StickyAppBarTheme } from "../../../style/theme";
import { MissionButton } from "./templates/MissionButton";
import { MiddleButton } from "../../../common/components/button/MiddleButton";
import { OverviewBox } from "./templates/OverviewBox";
import { GuideBox } from "./templates/GuideBox";
import { ContentsBox } from "./templates/ContentsBox";
import { CatalogListBox } from "./templates/CatalogListBox";

const TAB_ITEMS = [
    { value: "overview", label: "살펴보기", position: "left" },
    { value: "guide", label: "가이드 구성", position: "left" },
    { value: "contents", label: "목차", position: "left" },
];

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const CurriculumTemplate = ({ missions, mission, concepts }) => {
    const navigate = useNavigate();

    const theme = useTheme();

    const overviewRef = useRef();
    const guideRef = useRef();
    const contentsRef = useRef();

    const navigationRef = useRef();

    const [value, setValue] = useState("overview");

    useEffect(() => {
        const navigationHeight = navigationRef.current.offsetHeight;

        const st = ScrollTrigger.create({
            trigger: overviewRef.current,
            start: `top ${navigationHeight}px`,
            end: `top ${navigationHeight}px`,
            onEnter: () => {
                setValue("overview");
            },
            onEnterBack: () => {
                setValue("overview");
            },
        });

        const st1 = ScrollTrigger.create({
            trigger: guideRef.current,
            start: `top ${navigationHeight}px`,
            end: `top ${navigationHeight}px`,
            onEnter: () => {
                setValue("guide");
            },
            onEnterBack: () => {
                setValue("overview");
            },
        });

        const st2 = ScrollTrigger.create({
            trigger: contentsRef.current,
            start: `top ${navigationHeight}px`,
            end: `top ${navigationHeight}px`,
            onEnter: () => {
                setValue("contents");
            },
            onEnterBack: () => {
                setValue("guide");
            },
        });

        return () => {
            st.kill();
            st1.kill();
            st2.kill();
        };
    }, []);

    const scrollToActive = (_value) => {
        if (_value === "overview") {
            gsap.to(window, {
                duration: 0.5,
                scrollTo: {
                    y: overviewRef.current,
                    offsetY: navigationRef.current.offsetHeight,
                },
            });
        } else if (_value === "guide") {
            gsap.to(window, {
                duration: 0.5,
                scrollTo: {
                    y: guideRef.current,
                    offsetY: navigationRef.current.offsetHeight,
                },
            });
        } else if (_value === "contents") {
            gsap.to(window, {
                duration: 0.5,
                scrollTo: {
                    y: contentsRef.current,
                    offsetY: navigationRef.current.offsetHeight,
                },
            });
        }
    };

    const handleClick = () => navigate(`/missions/${mission.id}`);

    return (
        <Stack direction="column">
            <ResponsiveAppBar
                ref={navigationRef}
                noMobileMenu
                tabItems={TAB_ITEMS}
                theme={StickyAppBarTheme}
                value={value}
                onClick={scrollToActive}
                sx={{
                    position: "sticky",
                    backgroundColor: theme.palette.common.white,
                    top: 0,
                    color: theme.palette.common.black,
                    boxShadow: 2,
                }}
                toolbarSx={{
                    height: 80,
                }}
                logoElement={<MissionButton mission={mission} />}
                rightElement={
                    <MiddleButton
                        label="학습하기"
                        color={theme.palette.common.white}
                        backgroundColor={theme.palette.common.black}
                        onClick={handleClick}
                        sx={{
                            alignSelf: "center",
                        }}
                    />
                }
            />
            <OverviewBox ref={overviewRef} mission={mission} />
            <GuideBox ref={guideRef} />
            <ContentsBox
                mission={mission}
                concepts={concepts}
                ref={contentsRef}
            />
            <CatalogListBox missions={missions} />
        </Stack>
    );
};
