import React, { useEffect } from "react";
import { ResizableDrawer } from "../../../../common/templates/ResizableDrawer";
import { Box, Stack } from "@mui/material";
import {
    Route,
    Routes,
    useNavigate,
    useParams,
    Navigate,
} from "react-router-dom";
import { EditorContainer } from "./templates/editor/EditorContainer";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { Lock } from "@mui/icons-material";

export const PSFiddlePlaygroundContainer = ({
    fiddle,
    navigateToSubmissions,
    tabItems,
}) => {
    const { authStore, submissionStore, fiddleStore } = useStores();
    const { currUser } = authStore;
    const { fiddleId, fiddleKey } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        submissionStore.open({
            problemId: `fiddle-${fiddleId}`,
        });
        if (!fiddle || fiddle.id !== Number(fiddleId)) {
            fiddleStore.find({ fiddleKey, fiddleId }).then((fiddle) => {
                if (fiddle.id !== Number(fiddleId)) {
                    navigate(`/psfiddle/${fiddle.key}/${fiddle.id}`, {
                        replace: true,
                    });
                }
            });
        }

        return () => {
            submissionStore.close();
        };
    }, [fiddle]);

    const PlaygroundDrawerWidthKey = "PlaygroundDrawerWidthKey";

    if (!fiddle) return <LoadingOverlay active />;

    return (
        <Stack
            direction={{ md: "row", xs: "column" }}
            flexGrow={1}
            flexShrink={1}
            overflow={{ md: "hidden", xs: "auto" }}
        >
            {!fiddle.isPrivate ||
            (currUser &&
                fiddle &&
                fiddle.username === currUser.username &&
                (fiddle.isPublished || fiddle.isOwner)) ? (
                <>
                    <ResizableDrawer
                        localStorageKey={PlaygroundDrawerWidthKey}
                        minWidth={450}
                    >
                        <Stack direction="column" width="100%" height="100%">
                            <Box
                                width="100%"
                                flexGrow={1}
                                p={2}
                                overflow={{
                                    md: "auto",
                                    xs: "visible",
                                }}
                            >
                                <Routes>
                                    <Route
                                        path={`/`}
                                        element={
                                            <Navigate
                                                replace
                                                to={"description"}
                                            />
                                        }
                                    />
                                    {tabItems.map((item) => (
                                        <Route
                                            path={`/${item.value}/*`}
                                            key={item.value}
                                            element={
                                                <item.Element fiddle={fiddle} />
                                            }
                                        />
                                    ))}
                                </Routes>
                            </Box>
                        </Stack>
                    </ResizableDrawer>
                    <EditorContainer
                        fiddle={fiddle}
                        navigateToSubmissions={navigateToSubmissions}
                    />
                </>
            ) : (
                <Stack
                    sx={{ width: "100%" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Lock fontSize="large" />
                    아직 공개되지 않은 퀴즈입니다.
                </Stack>
            )}
        </Stack>
    );
};
