import React, { useEffect, useState } from "react";
import { PSFiddleEditForm } from "../PSFiddleEditForm";
import { Stack } from "@mui/material";
import { setIn } from "../../../../utils/immutableLodash";
import {
    DefaultCodeDAOModel,
    FiddleDAOModel,
    TestCaseDAOModel,
} from "../../../../common/modules/fiddles/FiddleModel";
import { useStores } from "../../../../common/modules/useStores";
import { AlertDialog } from "../../../../common/components/dialog/AlertDialog";
import { getLoginPageUrlFromThisLocation } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";

const LocalFiddleKey = "codetree-fiddle-new";

export const PSFiddleNewContainer = () => {
    const { fiddleStore, authStore } = useStores();
    const { isUserLoggedIn } = authStore;

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        title: "",
        testCases: [],
        defaultCodes: [],
    });

    const [loginOpen, setLoginOpen] = useState(false);

    const handleChange = (name, value) => {
        const newInputs = setIn(name, value, inputs);
        setInputs(newInputs);
        localStorage.setItem(LocalFiddleKey, JSON.stringify(newInputs));
    };

    useEffect(() => {
        const localFiddle = localStorage.getItem(LocalFiddleKey);
        if (localFiddle) {
            setInputs(JSON.parse(localFiddle));
        }
    }, []);

    const saturateInputs = () => {
        let newInputs = setIn(
            "testCases",
            inputs.testCases.map(
                (testCase, i) =>
                    new TestCaseDAOModel({ ...testCase, order: i + 1 })
            ),
            inputs
        );
        newInputs = setIn(
            "defaultCodes",
            newInputs.defaultCodes.map(
                (defaultCode) => new DefaultCodeDAOModel(defaultCode)
            ),
            newInputs
        );

        return newInputs;
    };

    const handleSave = () => {
        if (!isUserLoggedIn()) {
            setLoginOpen(true);
            return;
        }

        const newInputs = saturateInputs();

        const body = new FiddleDAOModel({ ...newInputs, isPublished: true });

        fiddleStore.create({ body }).then((fiddle) => {
            navigate(`/psfiddle/${fiddle.key}`);
            snackActions.success("정상적으로 등록되었습니다.");
        });
        localStorage.removeItem(LocalFiddleKey);
    };

    const handlePreview = () => {
        const newInputs = saturateInputs();

        const body = new FiddleDAOModel({ ...newInputs, isPublished: false });

        fiddleStore.create({ body }).then((fiddle) => {
            navigate(`/psfiddle/${fiddle.key}/${fiddle.id}`);
        });
    };

    const navigateLoginPage = () => {
        navigate(getLoginPageUrlFromThisLocation());
    };

    return (
        <Stack alignItems={"center"} justifyContent={"center"} flexGrow={1}>
            <PSFiddleEditForm
                inputs={inputs}
                handleChange={handleChange}
                handleSave={handleSave}
                handlePreview={handlePreview}
            />
            <AlertDialog
                open={loginOpen}
                handleClose={() => setLoginOpen(false)}
                handleConfirm={navigateLoginPage}
                title={"저장을 위해서는 로그인이 필요합니다."}
                description={
                    "현재 작성된 문제는 저장되어 로그인 후 다시 돌아와도 사라지지 않습니다.\n\n로그인 화면으로 이동하시겠습니까?"
                }
            />
        </Stack>
    );
};
