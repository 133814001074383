import { Button } from "@mui/material";
import React from "react";

export const TabButton = ({ isLightTheme, isActive, ...props }) => (
    <Button
        isLightTheme={isLightTheme}
        sx={[
            {
                width: 127,
                color: isLightTheme ? "#C4C4C4" : "#959595",
                "&:hover": {
                    color: isLightTheme ? "#959595" : "#C4C4C4",
                    bgcolor: "background.default",
                },
            },
            isActive && {
                color: isLightTheme ? "#959595" : "#C4C4C4",
                backgroundColor: isLightTheme ? "#FFFFFF" : "#23241F",
                boxShadow: isLightTheme
                    ? "0 -2px 0 #FFFFFF"
                    : "0 -2px 0 #23241F",
                "&:hover": {
                    color: isLightTheme ? "#959595" : "#C4C4C4",
                    backgroundColor: isLightTheme ? "#FFFFFF" : "#23241F",
                    boxShadow: isLightTheme
                        ? "0 -2px 0 #FFFFFF"
                        : "0 -2px 0 #23241F",
                },
            },
        ]}
        {...props}
    />
);
