import React, { useRef } from "react";
import {
    Box,
    Collapse,
    Paper,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Header } from "./templates/Header";
import { StudentGrid } from "./templates/StudentGrid";
import { AlertDialog } from "../../../../common/components/dialog/AlertDialog";
import { DataGridPagination } from "../../../../common/components/pagination/DataGridPagination";
import { MiddleButton } from "../../../../common/components/button/MiddleButton";
import { AdminAlertDialog } from "../../../../common/components/dialog/AdminAlertDialog";
import CheckIcon from "@mui/icons-material/Check";

export const ClassroomStudentTemplate = ({
    isAdding,
    handleClose,
    students,
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    selectionModel,
    setSelectionModel,
    handleDelete,
    handleApprove,
    deleteOpen,
    toggleDeleteOpen,
    isQueue,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const redirectToEditPage = () => {
        navigate("add");
    };

    const footerRef = useRef(null);

    return (
        <Stack direction="column" height="100%">
            <Header />
            <Stack direction="column" flexGrow={1}>
                <Box flexGrow={1} p={2.5} position="relative">
                    <Paper
                        sx={{
                            p: 2.5,
                            borderRadius: 3,
                            height: "100%",
                        }}
                    >
                        <StudentGrid
                            students={students}
                            count={Math.ceil(count / rowsPerPage)}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selectionModel={selectionModel}
                            setSelectionModel={setSelectionModel}
                            handleApprove={handleApprove}
                            isQueue={isQueue}
                        />
                    </Paper>
                    <Collapse
                        in={!!selectionModel.length}
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                backgroundColor: "#D24D57",
                                width:
                                    footerRef.current &&
                                    footerRef.current.clientWidth,
                                py: 1.25,
                            }}
                            justifyContent="center"
                            spacing={2.5}
                            alignItems="center"
                        >
                            <Typography
                                variant="body1"
                                color="#FFFFFF"
                                children={`${selectionModel.length}명의 학생 선택됨`}
                            />
                            <Stack direction="row" spacing={1.25}>
                                <MiddleButton
                                    label="선택 해제"
                                    variant="outlined"
                                    color="#FFFFFF"
                                    backgroundColor="#FFFFFF00"
                                    sx={{
                                        borderColor: "#FFFFFF",
                                    }}
                                    onClick={() => setSelectionModel([])}
                                />
                                {isQueue ? (
                                    <MiddleButton
                                        onClick={() =>
                                            handleApprove(selectionModel)
                                        }
                                        color={
                                            theme.palette.primary.contrastText
                                        }
                                        backgroundColor={
                                            theme.palette.primary.main
                                        }
                                        label="승인"
                                        endIcon={<CheckIcon fontSize="small" />}
                                    />
                                ) : null}
                                <MiddleButton
                                    onClick={toggleDeleteOpen}
                                    color="#FFFFFF"
                                    backgroundColor="#000000"
                                    label="삭제"
                                    endIcon={<DeleteIcon fontSize="small" />}
                                />
                            </Stack>
                        </Stack>
                    </Collapse>
                </Box>
                <Stack
                    ref={footerRef}
                    direction="row"
                    justifyContent="center"
                    p={2.5}
                    bgcolor="common.white"
                >
                    <DataGridPagination
                        page={page}
                        count={count}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Stack>
            </Stack>
            <AlertDialog
                open={isAdding}
                title="추가하던 학생 리스트가 남아있습니다."
                description="학생 추가 화면으로 이동하시겠습니까?"
                handleClose={handleClose}
                handleConfirm={redirectToEditPage}
            />
            <AdminAlertDialog
                open={deleteOpen}
                onClose={toggleDeleteOpen}
                title={`${selectionModel.length}명의 학생 정보, 기록이 모두 삭제됩니다.`}
                description={
                    "사라진 학생은 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
                }
                onConfirm={() => handleDelete(selectionModel)}
            />
        </Stack>
    );
};
