import React from "react";
import { Stack, Typography } from "@mui/material";

export const ContentBox = ({
    title,
    image,
    description,
    width,
    imageWidth,
}) => (
    <Stack
        direction="column"
        spacing={2.5}
        alignItems="center"
        sx={{
            width,
        }}
    >
        <Typography children={title} variant="body2" fontWeight={700} />
        <img
            src={image}
            style={{
                width: imageWidth,
            }}
        />
        <Typography
            sx={{
                p: 1.25,
            }}
            children={description}
            variant="body2"
        />
    </Stack>
);
