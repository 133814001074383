import React from "react";
import { Stack, Typography } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

export const NoResultsTemplate = ({ text, sx }) => (
    <Stack
        direction="column"
        alignItems="center"
        pt={12.5}
        sx={{
            ...sx,
        }}
    >
        <HelpCenterIcon fontSize="large" />
        <Typography variant="body1" whiteSpace="pre-line" textAlign="center">
            {text}
        </Typography>
    </Stack>
);
