import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export const AlertDialog = ({
    open,
    handleClose,
    handleConfirm,
    title,
    description,
    confirmText,
}) => (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirm} variant="contained" color="success">
                {confirmText || "확인"}
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
                취소
            </Button>
        </DialogActions>
    </Dialog>
);
