import { Stack, Typography, Box, Button, darken } from "@mui/material";
import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

export const PlaygroundIntro = forwardRef((props, ref) => {
    const navigate = useNavigate();

    return (
        <Stack
            ref={ref}
            py={72 / 8}
            spacing={40 / 8}
            bgcolor={"#F3F5F7"}
            alignItems={"center"}
        >
            <Stack alignItems={"center"} spacing={80 / 8}>
                <Stack spacing={10 / 8} alignItems={"center"}>
                    <Typography variant="h2" fontWeight={800}>
                        학습과 연습, 따로 하지 말고 같이하세요!
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Typography variant="body2">대상</Typography>
                        <Box
                            sx={(theme) => ({
                                px: 12 / 8,
                                py: 6 / 8,
                                color: "#fff",
                                borderRadius: 8 / 4,
                                bgcolor: "#18A0FB",
                                fontSize: theme.typography.caption.fontSize,
                            })}
                        >
                            선생님
                        </Box>
                        <Box
                            sx={(theme) => ({
                                px: 12 / 8,
                                py: 6 / 8,
                                color: "#fff",
                                borderRadius: 8 / 4,
                                bgcolor: "#3FCB7E",
                                fontSize: theme.typography.caption.fontSize,
                            })}
                        >
                            학생
                        </Box>
                    </Stack>
                    <Typography variant="body2">
                        지원언어: Python, C++, C
                    </Typography>
                </Stack>

                <Stack spacing={2} alignItems={"center"}>
                    <Stack
                        px={16 / 8}
                        py={8 / 8}
                        borderRadius={8 / 8}
                        flexDirection={"row"}
                        gap={1}
                        sx={{
                            backgroundColor: "#ffffff",
                            boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                            position: "relative",
                            "&:before": {
                                content: `""`,
                                position: "absolute",
                                bottom: -2,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "rgba(0, 123, 255, .5) transparent transparent transparent",
                            },
                            "&:after": {
                                content: `""`,
                                position: "absolute",
                                bottom: 1,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "#ffffff transparent transparent transparent",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                                bgcolor: "#3FCB7E",
                                color: "#fff",
                                fontWeight: 800,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            1
                        </Box>
                        <Typography variant="body1" color={"#264348"}>
                            순서대로 따라가면
                            <br />
                            알아서 실력이 쌓이는 <b>국가대표 커리큘럼</b>
                        </Typography>
                    </Stack>
                    <img
                        width={568}
                        src={
                            "https://contents.codetree.ai/ooe/about/concept.png"
                        }
                    />
                </Stack>

                <Stack spacing={2} alignItems={"center"}>
                    <Stack
                        px={16 / 8}
                        py={8 / 8}
                        borderRadius={8 / 8}
                        flexDirection={"row"}
                        gap={1}
                        sx={{
                            backgroundColor: "#ffffff",
                            boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                            position: "relative",
                            "&:before": {
                                content: `""`,
                                position: "absolute",
                                bottom: -2,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "rgba(0, 123, 255, .5) transparent transparent transparent",
                            },
                            "&:after": {
                                content: `""`,
                                position: "absolute",
                                bottom: 1,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "#ffffff transparent transparent transparent",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                                bgcolor: "#3FCB7E",
                                color: "#fff",
                                fontWeight: 800,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            2
                        </Box>
                        <Typography variant="body1" color={"#264348"}>
                            <b>다양한 프로그래밍 언어</b>를 <b>멀티미디어</b>로
                            배우고 익히는
                            <br /> 개념학습, 문제, 해설 콘텐츠
                        </Typography>
                    </Stack>
                    <img
                        width={568}
                        src={"https://contents.codetree.ai/ooe/about/tutor.png"}
                    />
                </Stack>

                <Stack spacing={2} alignItems={"center"}>
                    <Stack
                        px={16 / 8}
                        py={8 / 8}
                        borderRadius={8 / 8}
                        flexDirection={"row"}
                        gap={1}
                        sx={{
                            backgroundColor: "#ffffff",
                            boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                            position: "relative",
                            "&:before": {
                                content: `""`,
                                position: "absolute",
                                bottom: -2,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "rgba(0, 123, 255, .5) transparent transparent transparent",
                            },
                            "&:after": {
                                content: `""`,
                                position: "absolute",
                                bottom: 1,
                                left: "50%",
                                transform: "translate(-50%, 100%)",
                                display: "block",
                                width: 0,
                                height: 0,
                                borderStyle: "solid",
                                borderWidth: "10px 10px 0 10px",
                                borderColor:
                                    "#ffffff transparent transparent transparent",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                                bgcolor: "#3FCB7E",
                                color: "#fff",
                                fontWeight: 800,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            3
                        </Box>
                        <Typography variant="body1" color={"#264348"}>
                            설치 없이
                            <br />
                            <b>바로 코딩 → 실행 → 채점</b>
                        </Typography>
                    </Stack>
                    <img
                        width={568}
                        src={
                            "https://contents.codetree.ai/ooe/about/editor.png"
                        }
                    />
                </Stack>
            </Stack>

            <Button
                sx={{
                    flexDirection: "column",
                    minWidth: 250,
                    height: 80,
                    borderRadius: 8 / 4,
                    borderBottom: "4px solid rgba(0,0,0,0.2)",
                    bgcolor: "#18A0FB",
                    "&:hover": {
                        bgcolor: darken("#18A0FB", 0.1),
                    },
                }}
                onClick={() => navigate("/missions")}
            >
                <Typography variant="body2" color={"#fff"}>
                    혼자 학습하기
                </Typography>
                <Typography variant="h4" fontWeight={800} color={"#fff"}>
                    시작
                </Typography>
            </Button>
        </Stack>
    );
});
