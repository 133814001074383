import { createRef, useEffect } from "react";

export const useKeyDownRef = (handler) => {
    const ref = createRef();

    useEffect(() => {
        // Call this when modal window opens
        if (ref.current) {
            ref.current.addEventListener("keydown", handler, false);
        }
    }, [ref]);

    useEffect(
        () => () => {
            // Call this when modal window closes/unmounts
            if (ref.current) {
                ref.current.removeEventListener("keydown", handler, false);
            }
        },
        []
    );

    return ref;
};

export const useCopyRef = (handler) => {
    const ref = createRef();

    useEffect(() => {
        // Call this when modal window opens
        if (ref.current) {
            ref.current.addEventListener("copy", handler, false);
            ref.current.addEventListener("contextmenu", handler, false);
        }
    }, [ref]);

    useEffect(
        () => () => {
            // Call this when modal window closes/unmounts
            if (ref.current) {
                ref.current.removeEventListener("copy", handler, false);
                ref.current.removeEventListener("contextmenu", handler, false);
            }
        },
        []
    );

    return ref;
};
