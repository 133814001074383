import React from "react";
import { Box, Paper, Stack, TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { HomeworkHeaderBox } from "./HomeworkHeaderBox";
import { SortMenu } from "../../components/SortMenu";

export const HomeworkDashboardHeader = ({
    homeworkProgresses,
    searchWord,
    setSearchWord,
    searchHomeworks,
    sortOptions,
    sortOption,
    selectSortOption,
}) => (
    <Paper
        sx={{
            minWidth: "fit-content",
            pr: 5,
            position: "sticky",
            top: 0,
            zIndex: 1000,
        }}
    >
        <Stack direction="row" spacing={2.5} height={100} alignItems="flex-end">
            <Stack
                width={200}
                px={2}
                justifyContent={"center"}
                height={"100%"}
                spacing={0.5}
            >
                <SortMenu
                    sortOptions={sortOptions}
                    sortOption={sortOption}
                    selectSortOption={selectSortOption}
                />
                <Box display="flex" justifyContent="center" flexDirection="row">
                    <TextField
                        autoFocus
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                        sx={{ width: 160 }}
                        variant="filled"
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            sx: {
                                fontSize: 13,
                                lineHeight: "16px",
                                fontWeight: 700,
                                height: 40,
                            },
                            disableUnderline: true,
                        }}
                        placeholder="숙제 이름 검색"
                        onKeyDown={({ key }) => {
                            if (key === "Enter") {
                                searchHomeworks(searchWord);
                            }
                        }}
                        inputProps={{
                            sx: {
                                paddingTop: 1.75,
                                paddingBottom: 1.75,
                            },
                        }}
                    />
                    <Button
                        sx={{
                            color: "#ffffff",
                            bgcolor: "#000000",
                            p: 1,
                            minWidth: "auto",
                            "&:hover": {
                                bgcolor: "#1a1a1a",
                            },
                        }}
                        onClick={() => searchHomeworks(searchWord)}
                    >
                        <SearchIcon fontSize="small" />
                    </Button>
                </Box>
            </Stack>

            {homeworkProgresses.map((homeworkProgress, i) => (
                <HomeworkHeaderBox
                    key={`homeworkProgress-${i}`}
                    homeworkProgress={homeworkProgress}
                />
            ))}
        </Stack>
    </Paper>
);
