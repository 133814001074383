import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomeworkEditContainer } from "./HomeworkEditContainer";
import { HomeworkFormContainer } from "./templates/HomeworkFormContainer";

export const HomeworkEditPage = () => (
    <Routes>
        <Route path="/" element={<HomeworkEditContainer />} />
        <Route path="/add" element={<HomeworkFormContainer isAdd />} />
        <Route path="/:homeworkId/edit" element={<HomeworkFormContainer />} />
    </Routes>
);
