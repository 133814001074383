import AuthStore from "./auth/AuthStore";
import MissionStore from "./missions/mission/MissionStore";
import ConceptStore from "./missions/concept/ConceptStore";
import StudyUnitStore from "./missions/studyUnit/StudyUnitStore";
import CuratedProblemStore from "./missions/curatedProblem/CuratedProblemStore";
import ProblemStore from "./problems/ProblemStore";
import GlobalStateStore from "./ui/GlobalStateStore";
import SubmissionStore from "./submission/SubmissionStore";
import BlobStore from "./blobStorage/BlobStore";
import DiscussionStore from "./discussions/DiscussionStore";
import ClassroomStore from "./classroom/ClassroomStore";
import HomeworkStore from "./homework/HomeworkStore";
import UserHomeworkProgressStore from "./homework/progress/UserHomeworkProgressStore";
import MissionProgressStore from "./missions/mission/progress/MissionProgressStore";
import TutorialStore from "./ui/tutorial/TutorialStore";
import NotificationStore from "./notification/NotificationStore";
import FiddleStore from "./fiddles/FiddleStore";
import FiddleProgressStore from "./fiddles/progress/FiddleProgressStore";
import LessonTemplateStore from "./lessonTemplate/LessonTemplateStore";

export class RootStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.missionStore = new MissionStore(this);
        this.conceptStore = new ConceptStore(this);
        this.studyUnitStore = new StudyUnitStore(this);
        this.curatedProblemStore = new CuratedProblemStore(this);
        this.problemStore = new ProblemStore(this);
        this.globalStateStore = new GlobalStateStore(this);
        this.submissionStore = new SubmissionStore(this);
        this.blobStore = new BlobStore(this);
        this.discussionStore = new DiscussionStore(this);
        this.classroomStore = new ClassroomStore(this);
        this.homeworkStore = new HomeworkStore(this);
        this.userHomeworkProgressStore = new UserHomeworkProgressStore(this);
        this.missionProgressStore = new MissionProgressStore(this);
        this.tutorialStore = new TutorialStore(this);
        this.notificationStore = new NotificationStore(this);
        this.fiddleStore = new FiddleStore(this);
        this.fiddleProgressStore = new FiddleProgressStore(this);
        this.lessonTemplateStore = new LessonTemplateStore(this);
    }
}
