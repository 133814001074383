import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { HomeworkDashboardDetailTemplate } from "./HomeworkDashboardDetailTemplate";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";

export const HomeworkDashboardDetailContainer = observer(() => {
    const { userId, homeworkId, problemId } = useParams();
    const { curatedProblemStore, homeworkStore } = useStores();

    const { curatedProblem } = curatedProblemStore;
    const { simpleHomeworks } = homeworkStore;

    useEffect(() => {
        homeworkStore.findAllSimple({});
    }, []);

    if (!simpleHomeworks.length)
        return <LoadingOverlay text="숙제를 불러오는 중입니다." active />;

    return (
        <HomeworkDashboardDetailTemplate
            userId={userId}
            homeworkId={Number(homeworkId)}
            problemId={Number(problemId)}
            problem={curatedProblem}
            homeworks={simpleHomeworks}
        />
    );
});
