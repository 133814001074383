import React from "react";
import {
    Stack,
    Typography,
    Paper,
    InputBase,
    Tooltip,
    Box,
    FormControlLabel,
} from "@mui/material";
import { TimerOutlined } from "@mui/icons-material";
import { ProblemStatusIcon } from "../../../../../common/templates/ProblemStatusIcon";
import { getProblemTypeToLabel } from "../../../../../common/modules/missions/curatedProblem/CuratedProblemModel";
import { AntSwitch } from "../../../../../common/components/antSwitch/AntSwitch";

export const StudyUnitBox = ({
    inputs,
    handleClickProblem,
    handleScoreChange,
    handleSolutionVisibleChange,
    handleAIEnabledChange,
    studyUnit,
}) => {
    const handleClick = (curatedProblem) => {
        handleClickProblem(curatedProblem.curatedProblemId);
    };
    const handleScore = (curatedProblem, score) => {
        handleScoreChange(curatedProblem.curatedProblemId, score);
    };
    const handleSolutionVisible = (curatedProblem, solutionVisible) => {
        handleSolutionVisibleChange(
            curatedProblem.curatedProblemId,
            solutionVisible
        );
    };
    const handleAIEnabled = (curatedProblem, aiEnabled) => {
        handleAIEnabledChange(curatedProblem.curatedProblemId, aiEnabled);
    };

    const getInputScore = (curatedProblem) => {
        const idx = inputs.curatedProblems.findIndex(
            ({ curatedProblemId }) =>
                Number(curatedProblemId) ===
                Number(curatedProblem.curatedProblemId)
        );
        return idx === -1 ? 0 : inputs.curatedProblems[idx].score;
    };
    const getInputSolutionVisible = (curatedProblem) => {
        const idx = inputs.curatedProblems.findIndex(
            ({ curatedProblemId }) =>
                Number(curatedProblemId) ===
                Number(curatedProblem.curatedProblemId)
        );
        return idx === -1 ? false : inputs.curatedProblems[idx].solutionVisible;
    };

    const getInputAIEnabled = (curatedProblem) => {
        const idx = inputs.curatedProblems.findIndex(
            ({ curatedProblemId }) =>
                Number(curatedProblemId) ===
                Number(curatedProblem.curatedProblemId)
        );
        return idx === -1 ? false : inputs.curatedProblems[idx].aiEnabled;
    };

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="column" spacing={1} py={2}>
                <Typography
                    variant="subtitle1"
                    color="#1A1A1A"
                    children={studyUnit.name}
                />
                <Typography
                    variant="body2"
                    color="#1A1A1A"
                    children={studyUnit.description}
                />
            </Stack>
            <Stack direction="column" spacing={0.5}>
                {[...studyUnit.curatedProblems]
                    .sort((a, b) => a.order - b.order)
                    .map((curatedProblem, index) => {
                        const currProblemType =
                            getProblemTypeToLabel(curatedProblem);

                        const prevProblemType = getProblemTypeToLabel(
                            studyUnit.curatedProblems[index - 1]
                        );
                        return (
                            <Stack
                                key={`curated-problem-${curatedProblem.id}`}
                                direction="row"
                                alignItems={"center"}
                                spacing={1}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        background: "#fafafa",
                                    },
                                }}
                            >
                                <Stack
                                    direction={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    alignSelf={"stretch"}
                                    sx={{
                                        width: 50,
                                        position: "relative",
                                        // mr: isDense ? 1 : 3,
                                        mr: 3,
                                    }}
                                >
                                    {!index ||
                                    currProblemType !== prevProblemType ? (
                                        <>
                                            <Typography variant={"caption"}>
                                                {currProblemType}
                                            </Typography>
                                            {index !==
                                            studyUnit.curatedProblems.length -
                                                1 ? (
                                                <Box
                                                    width={"0.5px"}
                                                    height={"calc(30%)"}
                                                    position={"absolute"}
                                                    bottom={-10}
                                                    sx={[
                                                        {
                                                            backgroundColor:
                                                                "#666666",
                                                            transition:
                                                                "background-color 0.3s",
                                                        },
                                                    ]}
                                                />
                                            ) : null}
                                        </>
                                    ) : (
                                        <Box
                                            width={"0.5px"}
                                            height={"calc(100% + 16px)"}
                                            position={"absolute"}
                                            bottom={-10}
                                            sx={[
                                                {
                                                    backgroundColor: "#666666",
                                                    transition:
                                                        "background-color 0.3s",
                                                },
                                            ]}
                                        />
                                    )}
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    px={2}
                                    py={2}
                                    flex={1}
                                >
                                    <ProblemStatusIcon
                                        selectable={true}
                                        getChecked={() => true}
                                        curatedProblem={curatedProblem}
                                        onChange={() =>
                                            handleClick(curatedProblem)
                                        }
                                    />
                                    <Stack
                                        direction={"row"}
                                        flex={1}
                                        spacing={2}
                                    >
                                        <Typography
                                            ml={2}
                                            variant="body2"
                                            color="#1A1A1A"
                                            children={curatedProblem.name}
                                        />
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <TimerOutlined
                                                sx={{
                                                    fontSize: 14,
                                                }}
                                            />
                                            <Typography
                                                variant={"caption"}
                                                children={`${
                                                    !curatedProblem.solvedMinute ||
                                                    curatedProblem.solvedMinute >
                                                        180
                                                        ? "180+"
                                                        : curatedProblem.solvedMinute
                                                }${"분"}`}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Paper
                                        component="form"
                                        variant="outlined"
                                        sx={{
                                            p: "2px 4px",
                                            display: "flex",
                                            alignItems: "center",
                                            width: 120,
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="배점"
                                            type="number"
                                            inputProps={{
                                                "aria-label": "point",
                                                inputMode: "numeric",
                                                pattern: "[0-9]*",
                                            }}
                                            defaultValue={getInputScore(
                                                curatedProblem
                                            )}
                                            onChange={(e) =>
                                                handleScore(
                                                    curatedProblem,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {`점`}
                                    </Paper>

                                    <Tooltip
                                        title={`학생들이 해설을 볼 수 ${
                                            getInputSolutionVisible(
                                                curatedProblem
                                            )
                                                ? `있습니다.`
                                                : `없습니다.`
                                        }`}
                                    >
                                        <FormControlLabel
                                            sx={{ width: 60 }}
                                            labelPlacement="top"
                                            control={
                                                <AntSwitch
                                                    checked={getInputSolutionVisible(
                                                        curatedProblem
                                                    )}
                                                    onChange={(e) => {
                                                        handleSolutionVisible(
                                                            curatedProblem,
                                                            !getInputSolutionVisible(
                                                                curatedProblem
                                                            )
                                                        );
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="caption">{`해설 ${
                                                    getInputSolutionVisible(
                                                        curatedProblem
                                                    )
                                                        ? `공개`
                                                        : `비공개`
                                                }`}</Typography>
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip
                                        title={`학생들이 컴파일, 런타임 에러 발생시 AI 힌트를 볼 수 ${
                                            getInputAIEnabled(curatedProblem)
                                                ? `있습니다.`
                                                : `없습니다.`
                                        }`}
                                    >
                                        <FormControlLabel
                                            sx={{ width: 60 }}
                                            labelPlacement="top"
                                            control={
                                                <AntSwitch
                                                    checked={getInputAIEnabled(
                                                        curatedProblem
                                                    )}
                                                    onChange={(e) => {
                                                        handleAIEnabled(
                                                            curatedProblem,
                                                            !getInputAIEnabled(
                                                                curatedProblem
                                                            )
                                                        );
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="caption">{`AI 힌트 ${
                                                    getInputAIEnabled(
                                                        curatedProblem
                                                    )
                                                        ? `공개`
                                                        : `비공개`
                                                }`}</Typography>
                                            }
                                        />
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        );
                    })}
            </Stack>
        </Stack>
    );
};
