import React, { useState, useEffect } from "react";
import {
    Stack,
    Typography,
    Button,
    darken,
    Box,
    FormGroup,
    FormControlLabel,
    Switch,
    Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { NoResultsTemplate } from "../Missions/Playground/templates/NoResultsTemplate";
import { ClassroomSelector } from "../../../common/components/menu/ClassroomSelector";
import { QuizSelector } from "../../../common/components/menu/QuizSelector";
import { Medal, MedalOrder } from "../../../common/components/medal/Medal";
import { SubmissionStatusType } from "../../../common/modules/submission/SubmissionModel";
import { ProblemStatusType } from "../../../common/modules/missions/curatedProblem/CuratedProblemModel";
import { FAQSpeedDial } from "../Help/FAQ/FAQSpeedDial";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";
import { AdminAlertDialog } from "../../../common/components/dialog/AdminAlertDialog";

export const QuiztimeTemplate = ({
    currUser,
    classroom,
    fiddleProgress,
    fiddle,
    fiddleIsFetching,
    handleLinkAccess,
    deleteFiddle,
}) => {
    const navigate = useNavigate();
    const navigateToDashboard = () => {
        navigate("/admin/quiz-dashboard");
    };
    const navigateToFiddle = (fiddleKey) => {
        navigate(`/psfiddle/${fiddleKey}`);
    };

    const [isPrivate, setIsPrivate] = useState(
        fiddle ? fiddle.isPrivate : true
    );
    const [faqIn, setFaqIn] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const teacherFAQ = [
        {
            key: "create_quiz",
            label: "학생들의 흥미를 끌고 싶어요 (퀴즈 만들기)",
        },
    ];

    useEffect(() => {
        setTimeout(() => {
            setFaqIn(true);
        }, 500);
    }, []);

    const closeFAQ = () => {
        setFaqIn(false);
        snackActions.info(
            "'프로필 아이콘 > 자주묻는질문'에서 다시 보실 수 있습니다."
        );
    };

    useEffect(() => {
        if (fiddle) {
            setIsPrivate(fiddle.isPrivate);
        }
    }, [fiddle]);

    const onSwitchChange = (e) => {
        setIsPrivate(!e.target.checked);
        handleLinkAccess(fiddle, !e.target.checked);
    };

    const rankedProgress = fiddleProgress
        ? fiddleProgress.fiddleUserProgresses.filter(
              (userProgress) =>
                  userProgress.status === SubmissionStatusType.Passed
          )
        : [];
    const isPassed = rankedProgress.some(
        (userProgress) => userProgress.userId === currUser.id
    );

    const handleDelete = (fiddle) => {
        deleteFiddle(fiddle);
        setDeleteOpen(false);
    };

    return (
        <MainTemplateWrapper
            sx={{
                pt: 5,
            }}
        >
            <Stack direction="column" spacing={52 / 8}>
                <Stack direction={"row"} spacing={20 / 8}>
                    <ClassroomSelector
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 8 / 4,
                            border: "2px solid #e1e1e7",
                            padding: 16 / 8,
                            height: 76,
                            marginRight: 0,
                            width: 250,
                            color: "#000000",
                            boxShadow: "none",
                        }}
                    />
                    <QuizSelector
                        allowCreateQuiz={classroom ? classroom.isOwner : false}
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 8 / 4,
                            border: "2px solid #e1e1e7",
                            padding: 16 / 8,
                            height: 76,
                            marginRight: 0,
                            color: "#000000",
                            flex: 1,
                            boxShadow: "none",
                        }}
                    />
                </Stack>
                {fiddleIsFetching ? (
                    <LoadingOverlay
                        text={"퀴즈를 가져오는 중입니다."}
                        spinnerProps={{ sx: { color: "#3FCB7E" } }}
                        spinnerTextProps={{ sx: { color: "#000" } }}
                        active
                    />
                ) : fiddleProgress ? (
                    <Stack alignItems={"center"} spacing={30 / 8}>
                        <Stack
                            p={16 / 8}
                            borderRadius={8 / 8}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxShadow: `0 0 0 2px rgba(0, 123, 255, .5)`,
                                position: "relative",
                                "&:before": {
                                    content: `""`,
                                    position: "absolute",
                                    bottom: -2,
                                    left: "50%",
                                    transform: "translate(-50%, 100%)",
                                    display: "block",
                                    width: 0,
                                    height: 0,
                                    borderStyle: "solid",
                                    borderWidth: "10px 10px 0 10px",
                                    borderColor:
                                        "rgba(0, 123, 255, .5) transparent transparent transparent",
                                },
                                "&:after": {
                                    content: `""`,
                                    position: "absolute",
                                    bottom: 1,
                                    left: "50%",
                                    transform: "translate(-50%, 100%)",
                                    display: "block",
                                    width: 0,
                                    height: 0,
                                    borderStyle: "solid",
                                    borderWidth: "10px 10px 0 10px",
                                    borderColor:
                                        "#ffffff transparent transparent transparent",
                                },
                            }}
                        >
                            <Typography
                                variant={
                                    fiddleProgress.title.length < 10 &&
                                    !/\r|\n/.exec(fiddleProgress.title)
                                        ? "h4"
                                        : "body1"
                                }
                                whiteSpace={"pre-line"}
                            >{`Q.${fiddleProgress.title}`}</Typography>
                        </Stack>
                        <Typography variant="body2">
                            만든날:{" "}
                            {moment(fiddleProgress.createdAt).format("LLL")}
                        </Typography>

                        <Stack direction={"row"} spacing={20 / 8}>
                            <Button
                                onClick={() =>
                                    navigateToFiddle(fiddleProgress.key)
                                }
                                sx={{
                                    width: 196,
                                    height: 48,
                                    py: 10 / 8,
                                    px: 16 / 8,
                                    borderBottom: isPassed
                                        ? "4px solid #CCCCCC"
                                        : "4px solid #0BAC54",
                                    borderRadius: 2,
                                    background: isPassed
                                        ? "#FFFFFF"
                                        : "#3FCB7E",
                                    "&:hover": {
                                        background: isPassed
                                            ? "#F0F0F0"
                                            : darken("#3FCB7E", 0.1),
                                        borderBottom: isPassed
                                            ? "4px solid #CCCCCC"
                                            : `4px solid ${darken(
                                                  "#0BAC54",
                                                  0.1
                                              )}`,
                                    },
                                    transition: "all .15s",
                                }}
                            >
                                <Typography
                                    variant={"h4"}
                                    color={isPassed ? "#111111" : "#FFFFFF"}
                                    children={
                                        isPassed ? "다시 풀기" : "풀러가기"
                                    }
                                />
                            </Button>
                            {fiddleProgress.isOwner && (
                                <>
                                    <Button
                                        onClick={navigateToDashboard}
                                        sx={{
                                            height: 48,
                                            py: 10 / 8,
                                            px: 16 / 8,
                                            borderBottom: "4px solid #1A64D3",
                                            borderRadius: 2,
                                            background: "#18A0FB",
                                            "&:hover": {
                                                background: darken(
                                                    "#18A0FB",
                                                    0.1
                                                ),
                                                borderBottom: `4px solid ${darken(
                                                    "#1A64D3",
                                                    0.1
                                                )}`,
                                            },
                                            transition: "all .15s",
                                        }}
                                    >
                                        <Typography
                                            variant={"h4"}
                                            color={"#FFFFFF"}
                                            children={"퀴즈 관리(선생님 전용)"}
                                        />
                                    </Button>
                                    <Button
                                        onClick={() => setDeleteOpen(true)}
                                        sx={{
                                            height: 48,
                                            py: 10 / 8,
                                            px: 16 / 8,
                                            borderBottom: `4px solid ${darken(
                                                "#D24D57",
                                                0.1
                                            )}`,
                                            borderRadius: 2,
                                            background: "#D24D57",
                                            "&:hover": {
                                                background: darken(
                                                    "#D24D57",
                                                    0.1
                                                ),
                                                borderBottom: `4px solid ${darken(
                                                    "#D24D57",
                                                    0.2
                                                )}`,
                                            },
                                            transition: "all .15s",
                                        }}
                                    >
                                        <Typography
                                            variant={"h4"}
                                            color={"#FFFFFF"}
                                            children={"퀴즈 삭제(선생님 전용)"}
                                        />
                                    </Button>
                                    <AdminAlertDialog
                                        open={deleteOpen}
                                        onClose={() => setDeleteOpen(false)}
                                        title={"선택한 퀴즈가 삭제됩니다."}
                                        description={
                                            "삭제한 퀴즈는 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
                                        }
                                        onConfirm={() => handleDelete(fiddle)}
                                    />
                                </>
                            )}
                        </Stack>
                        {fiddleProgress.isOwner && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={onSwitchChange}
                                            checked={!isPrivate}
                                        />
                                    }
                                    label={
                                        <Tooltip
                                            title={
                                                "퀴즈를 '공개' 설정해야 링크를 전달받은 사람이 퀴즈를 볼 수 있습니다."
                                            }
                                            arrow
                                        >
                                            <Typography variant="body2">
                                                {isPrivate ? "비공개" : "공개"}
                                            </Typography>
                                        </Tooltip>
                                    }
                                />
                            </FormGroup>
                        )}
                        <Stack direction={"row"} spacing={33 / 8}>
                            {rankedProgress.length ? (
                                rankedProgress
                                    .slice(0, 3)
                                    .map((userProgress, order) => (
                                        <Stack
                                            key={order}
                                            alignItems={"center"}
                                            spacing={1.5}
                                            sx={{
                                                width: 238,
                                                border: "6px solid #FFFFFF",
                                                boxShadow: "0 0 0 6px #009D27",
                                                background: "#D9EDB2",
                                                borderRadius: 5,
                                                p: 6 / 8,
                                            }}
                                        >
                                            <Medal
                                                color={
                                                    MedalOrder[
                                                        Math.min(
                                                            Math.max(0, order),
                                                            2
                                                        )
                                                    ]
                                                }
                                            />

                                            <Typography
                                                variant={"h3"}
                                                children={userProgress.username}
                                                textAlign={"center"}
                                                sx={{
                                                    wordBreak: "keep-all",
                                                }}
                                            />
                                        </Stack>
                                    ))
                            ) : (
                                <Typography variant="h3">
                                    아직 정답을 맞춘 학생이 없습니다
                                </Typography>
                            )}
                        </Stack>

                        <Typography variant="h4" fontWeight={700}>
                            제출 현황
                        </Typography>

                        <Box
                            component={"div"}
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "100px 1fr 1fr",
                                columnGap: 20 / 8,
                                background: "#fff",
                                border: "2px solid #e1e1e7",
                                borderRadius: 12 / 4,
                                p: 20 / 8,
                                width: "100%",
                                maxWidth: 880,
                            }}
                        >
                            {fiddleProgress.fiddleUserProgresses.map(
                                (userProgress, rank) => (
                                    <>
                                        <Box
                                            component={"span"}
                                            sx={{ textAlign: "right" }}
                                        >
                                            {rank + 1}
                                        </Box>
                                        <Box
                                            component={"span"}
                                            sx={{
                                                color:
                                                    userProgress.status ===
                                                    SubmissionStatusType.Passed
                                                        ? "#009D27"
                                                        : userProgress.status ===
                                                          ProblemStatusType.Ready
                                                        ? "#666666"
                                                        : "#FFC433",
                                                textAlign: "center",
                                            }}
                                        >
                                            {userProgress.name}(
                                            {userProgress.username})
                                        </Box>
                                        <Box
                                            component={"span"}
                                            sx={{ textAlign: "center" }}
                                        >
                                            {userProgress.status ===
                                            SubmissionStatusType.Passed
                                                ? moment(
                                                      userProgress.solvedAt
                                                  ).format("LLL")
                                                : userProgress.status ===
                                                  ProblemStatusType.Ready
                                                ? "시도하지 않음"
                                                : "시도함"}
                                        </Box>
                                    </>
                                )
                            )}
                        </Box>
                    </Stack>
                ) : (
                    <>
                        <NoResultsTemplate
                            sx={{
                                pt: 3,
                            }}
                            text="퀴즈를 선택해주세요."
                        />
                        <FAQSpeedDial
                            teacherFAQ={teacherFAQ}
                            onClose={closeFAQ}
                            faqIn={faqIn}
                        />
                    </>
                )}
            </Stack>
        </MainTemplateWrapper>
    );
};
