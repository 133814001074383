import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MainContainer } from "./MainContainer";
import { useStores } from "../../common/modules/useStores";
import { AdminPage } from "./Admin/AdminPage";
import { LoadingOverlay } from "../../common/templates/LoadingOverlay";
import { PSFiddlePage } from "./PSFiddle/PSFiddlePage";

export const MainPage = observer(() => {
    const { authStore } = useStores();
    const { isUserLoggedIn, hasToken } = authStore;

    const getAdminPage = () => {
        if (isUserLoggedIn()) {
            return <AdminPage />;
        }

        if (!isUserLoggedIn() && hasToken()) {
            return <LoadingOverlay active />;
        }

        return <Navigate to="/accounts/loginWithMs?nextUrl=/admin" />;
    };

    return (
        <Routes>
            <Route path="/admin/*" element={getAdminPage()} />
            <Route path="/psfiddle/*" element={<PSFiddlePage />} />
            <Route path="/*" element={<MainContainer />} />
        </Routes>
    );
});
