export const stickyAppBarThemeOption = {
    palette: {
        primary: {
            main: "#FFFFFF",
            contrastText: "#000000",
        },
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    height: "100%",
                },
                flexContainer: {
                    height: "100%",
                    "& button": {
                        margin: "0 16px",
                    },
                    "& :first-of-type": {
                        marginLeft: 0,
                    },
                    "& :last-of-type": {
                        marginRight: 0,
                    },
                },
                indicator: {
                    backgroundColor: "#000000",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#999999",
                    padding: "0 10px",
                    "&.Mui-selected": {
                        color: "#000000",
                    },
                    minWidth: 0,
                    fontSize: 14,
                    lineHeight: "20px",
                    fontWeight: 400,
                },
            },
        },
    },
};
