import React, { useRef } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { downloadFileFromUrl } from "../../../../../utils/clipboard";
import {
    ExcelUrl,
    StudentFormsKey,
} from "../../../../../common/modules/classroom/ClassroomModel";
import { snackActions } from "../../../../../utils/SnackbarUtilsConfigurator";
import { makeValuesString } from "../../../../../utils/string";

export const StudentExcelTemplate = () => {
    const navigate = useNavigate();

    const hiddenFileInputRef = useRef(null);

    const setDragAndDropOpacity = (e, alpha) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName("drag-drop-zone")[0].style.opacity =
            alpha;
    };

    const validateAndFilterDatas = (datas) => {
        const uniqueChecker = new Set();
        datas.forEach((data) => uniqueChecker.add(JSON.stringify(data)));
        const uniqueDatas = Array.from(uniqueChecker).map((data) =>
            JSON.parse(data)
        );
        const phoneRegex = /^[0-9]{3,4}-[0-9]{3,4}-[0-9]{4}$/;
        for (let i = 0; i < uniqueDatas.length; i++) {
            if (!uniqueDatas[i].phoneNumber.toString().match(phoneRegex)) {
                alert(
                    `${makeValuesString(
                        uniqueDatas[i]
                    )} 학생의 휴대폰 정보가 "XXX-XXXX-XXXX" 형식이 아닙니다.`
                );
                return [];
            }
            uniqueDatas[i].id = i;
        }
        return uniqueDatas;
    };

    async function extractDataFromXlsx(f) {
        const data = await f.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetDatas = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1, {
            header: ["username", "name", "phoneNumber"],
            blankrows: false,
            range: "A2:C1000",
        });
        const uniqueDatas = validateAndFilterDatas(sheetDatas);
        if (!uniqueDatas.length) {
            return;
        }
        localStorage.setItem(StudentFormsKey, JSON.stringify(uniqueDatas));
        navigate("/admin/classroom-student/add");
    }

    const handleDragEnter = (e) => {
        setDragAndDropOpacity(e, 0.5);
    };

    const handleDragLeave = (e) => {
        setDragAndDropOpacity(e, 1);
    };

    const handleDragOver = (e) => {
        setDragAndDropOpacity(e, 0.5);
    };

    const handleDrop = (e) => {
        setDragAndDropOpacity(e, 1);
        if (e.dataTransfer.files.length === 1) {
            extractDataFromXlsx(e.dataTransfer.files[0]);
        } else {
            snackActions.error("한 개의 엑셀 파일만 올려주세요.");
        }
    };

    const handleFileUpload = (e) => {
        extractDataFromXlsx(e.target.files[0]);
    };

    const redirectToClassroomStudent = () => {
        navigate("/admin/classroom-student/");
    };

    const handleInputClick = () => {
        if (hiddenFileInputRef.current) {
            hiddenFileInputRef.current.click();
        }
    };

    return (
        <Stack
            className="drag-drop-zone"
            direction="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
        >
            <img src="https://ooepublic.blob.core.windows.net/images/admin/excel_component.svg" />
            <Typography
                mt={2.5}
                variant="h3"
                children="엑셀 파일을 이곳에 가져다 놓으세요"
                fontWeight={700}
                color="#4E5968"
            />
            <Stack direction="row" spacing={1.25} mt={1.25}>
                <Button
                    sx={{
                        px: 2.25,
                        py: 1.5,
                        color: "#FFFFFF",
                        backgroundColor: "#000000",
                    }}
                    onClick={() => downloadFileFromUrl(ExcelUrl)}
                >
                    <Typography variant="body1" children="템플릿 다운받기" />
                </Button>
                <Button
                    sx={{
                        px: 2.25,
                        py: 1.5,
                        color: "#FFFFFF",
                        backgroundColor: "#FF5555",
                    }}
                    onClick={handleInputClick}
                >
                    <Typography variant="body1" children="파일 업로드" />
                </Button>
            </Stack>
            <Button
                variant="outlined"
                children="취소"
                sx={{
                    color: "#808080",
                    px: 2,
                    py: 1,
                    mt: 1.25,
                    borderColor: "#808080",
                }}
                onClick={redirectToClassroomStudent}
            >
                <Typography variant="body2" children="취소" />
            </Button>
            <input
                type="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                ref={hiddenFileInputRef}
                style={{
                    display: "none",
                }}
            />
        </Stack>
    );
};
