import React from "react";
import { Container, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { MiddleButton } from "../../../../../common/components/button/MiddleButton";
import { SearchField } from "../../../../../common/components/textField/SearchField";

export const HomeworkEditHeader = ({
    setSearchWord,
    searchHomeworks,
    searchWord,
}) => {
    const navigate = useNavigate();

    const redirectAddPage = () => {
        navigate("add");
    };

    return (
        <Stack direction="column" alignItems="center" py={2.5} boxShadow={1}>
            <Container maxWidth="md">
                <Stack direction="row" spacing={1.25}>
                    <SearchField
                        value={searchWord}
                        setValue={setSearchWord}
                        placeholder={"과제 이름 검색"}
                        onSubmit={searchHomeworks}
                    />
                    <MiddleButton
                        onClick={() => searchHomeworks(searchWord)}
                        color="#FFFFFF"
                        label="검색"
                        backgroundColor="#000000"
                        endIcon={<SearchIcon fontSize="small" />}
                    />
                    <MiddleButton
                        color="#FFFFFF"
                        onClick={redirectAddPage}
                        label="수업/과제 추가"
                        backgroundColor="#FF5555"
                        endIcon={<AddCircleIcon fontSize="small" />}
                    />
                </Stack>
            </Container>
        </Stack>
    );
};
