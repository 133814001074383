import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CurriculumsTemplate } from "./CurriculumsTemplate";
import { useStores } from "../../../common/modules/useStores";
import { LoadingOverlay } from "../../../common/templates/LoadingOverlay";

export const CurriculumsContainer = observer(() => {
    const { missionStore, authStore } = useStores();
    const { missions, latestSolvedProblemSet } = missionStore;
    const { isUserLoggedIn, currUser } = authStore;

    useEffect(() => {
        missionStore.findAll();
        if (isUserLoggedIn()) {
            missionStore.getLatestSolvedProblem({});
        }
    }, [isUserLoggedIn()]);

    if (!(missions && missions.length)) return <LoadingOverlay active />;

    return (
        <CurriculumsTemplate
            username={isUserLoggedIn() ? currUser.username : ""}
            isLogin={isUserLoggedIn()}
            missions={missions}
            latestSolvedProblemSet={latestSolvedProblemSet}
        />
    );
});
