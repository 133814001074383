import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ProblemsTemplate } from "./ProblemsTemplate";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import ChannelService from "../../../../common/components/channel/ChannelService";

export const ProblemsContainer = observer(({ missionId, problemId }) => {
    const { missionStore, submissionStore, curatedProblemStore, problemStore } =
        useStores();
    const {
        missionTree,
        changeToPrevProblem,
        changeToNextProblem,
        changeToTreeProblem,
        currProblem,
    } = missionStore;
    const { curatedProblem } = curatedProblemStore;
    const { descriptionModel, getDescription } = problemStore;

    useEffect(() => {
        ChannelService.shutdown();

        return () => {
            ChannelService.boot({
                pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
            });
        };
    }, []);

    useEffect(() => {
        getDescription({ problemId });
    }, []);

    useEffect(() => {
        if ((missionTree && missionTree.id) !== Number(missionId)) {
            missionStore.getMissionTree({ missionId, problemId });
        } else if ((currProblem && currProblem.id) !== Number(problemId)) {
            missionStore.changeToTreeProblem({ problemId });
        }
        curatedProblemStore.findDetail({
            problemId,
            missionId,
        });
    }, [missionId, problemId]);

    useEffect(() => {
        if (currProblem && currProblem.id) {
            submissionStore.open({ problemId: currProblem.id });
            submissionStore.executeOpen();
        }

        return () => {
            submissionStore.close();
            submissionStore.executeClose();
        };
    }, [currProblem]);

    const loading =
        Number(problemId) !== (curatedProblem && curatedProblem.id) ||
        Number(problemId) !== (currProblem && currProblem.id) ||
        (missionTree && missionTree.id !== Number(missionId)) ||
        descriptionModel.length === 0;

    if (loading)
        return (
            <LoadingOverlay active={loading} text="문제를 불러오는 중입니다." />
        );

    return (
        <ProblemsTemplate
            missionTree={missionTree}
            problem={curatedProblem}
            curatedProblem={currProblem}
            changeToPrevProblem={changeToPrevProblem}
            changeToNextProblem={changeToNextProblem}
            changeToTreeProblem={changeToTreeProblem}
            treeUpdate={() => {}}
        />
    );
});
