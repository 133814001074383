import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const AdminDataGrid = styled(DataGrid)(({ theme }) => ({
    border: "none",
    "& .MuiDataGrid-footerContainer": {
        display: "none",
    },
    "& .MuiDataGrid-iconSeparator": {
        color: "transparent",
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.common.white,
    },
    "& .data-grid-row--even": {
        backgroundColor: "#F5F5F7",
        "&:hover": {
            backgroundColor: "#FFCFCF",
        },
    },
    "& .data-grid-row--odd": {
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFEBEB",
        },
    },
    "& .data-grid-row--odd.MuiDataGrid-row.Mui-selected": {
        backgroundColor: "#FFEBEB",
    },
    "& .data-grid-row--even.MuiDataGrid-row.Mui-selected": {
        backgroundColor: "#FFCFCF",
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
}));
