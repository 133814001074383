import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ClassroomStudentTemplate } from "./ClassroomStudentTemplate";
import { useStores } from "../../../../common/modules/useStores";
import {
    StudentDAOModel,
    StudentFormsKey,
} from "../../../../common/modules/classroom/ClassroomModel";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";

export const ClassroomStudentContainer = observer(() => {
    const { classroomStore } = useStores();
    const { classroom, students, count } = classroomStore;

    const [isAdding, setIsAdding] = useState(
        !!localStorage.getItem(StudentFormsKey)
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [selectionModel, setSelectionModel] = useState([]);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const toggleDeleteOpen = () => {
        setDeleteOpen((prev) => !prev);
    };

    const handleClose = () => {
        setIsAdding(false);
        localStorage.removeItem(StudentFormsKey);
    };

    useEffect(() => {
        classroomStore.findStudents({
            page: page + 1,
            pageSize: rowsPerPage,
        });
    }, [classroom, page, rowsPerPage]);

    const updateRows = () => {
        setPage((prev) => {
            if (!prev) {
                classroomStore.findStudents({
                    page: 1,
                    pageSize: rowsPerPage,
                });
            }
            return 0;
        });
    };

    const handleDelete = (_selectionModel) => {
        const selectedStudents = students.filter((student) =>
            _selectionModel.some((id) => id === student.id)
        );
        const body = selectedStudents.map(
            (student) => new StudentDAOModel(student)
        );
        classroomStore.removeStudents({ body }).then(() => {
            snackActions.success("정상적으로 삭제되었습니다.");
            updateRows();
            toggleDeleteOpen();
        });
    };

    return (
        <ClassroomStudentTemplate
            isAdding={isAdding}
            handleClose={handleClose}
            students={students.map((student, i) => ({
                ...student,
                order: i + 1,
            }))}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            handleDelete={handleDelete}
            deleteOpen={deleteOpen}
            toggleDeleteOpen={toggleDeleteOpen}
        />
    );
});
