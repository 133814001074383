import React from "react";
import { ExecuteResultTextField } from "../../editor/templates/ExecuteResultTextField";

export const UserInputBox = ({ userInput, handleChange }) => (
    <ExecuteResultTextField
        placeholder="테스트하고 싶은 Input을 입력해주세요."
        value={userInput}
        autoFocus
        onChange={handleChange}
    />
);
