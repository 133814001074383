import { GET } from "../../../../utils/api";

class MissionRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll() {
        return GET("problems/missions/");
    }

    find({ missionId }) {
        return GET(`problems/missions/${missionId}/`);
    }

    findDetail({ missionId }) {
        return GET(`problems/missions-detail/${missionId}/`);
    }

    getMissionTree({ missionId }) {
        return GET(`problems/mission_tree/${missionId}/`);
    }

    getLatestSolvedProblem({ missionId }) {
        return GET(
            `problems/missions/latest/${missionId ? `${missionId}/` : ""}`
        );
    }
}

export default new MissionRepository();
