import React from "react";
import { Box, Stack } from "@mui/material";
import { SubmissionsContainer } from "../templates/Submission/SubmissionsContainer";
import { ClassroomDashboardHeader } from "./templates/ClassroomDashboardHeader";

export const ClassroomDashboardDetailTemplate = ({
    userId,
    missions,
    missionId,
    problemId,
    problem,
}) => (
    <Stack direction="column" height="100%">
        <ClassroomDashboardHeader
            userId={userId}
            missionId={missionId}
            missions={missions}
            problemId={problemId}
            problem={problem}
        />
        <Box p={2.5} flexGrow={1} overflow="hidden">
            <SubmissionsContainer
                contentTypeName="mission"
                objectId={problemId}
                userId={userId}
            />
        </Box>
    </Stack>
);
