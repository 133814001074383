import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../common/modules/useStores";
import { QuiztimeTemplate } from "./QuiztimeTemplate";
import {
    TestCaseDAOModel,
    FiddleDAOModel,
} from "../../../common/modules/fiddles/FiddleModel";
import { setIn } from "../../../utils/immutableLodash";
import _ from "lodash";

export const QuiztimeContainer = observer(() => {
    const { fiddleProgressStore, authStore, fiddleStore, classroomStore } =
        useStores();
    const { classroom } = classroomStore;
    const { fiddleProgress, isFetching: isFetchingFiddleProgress } =
        fiddleProgressStore;
    const { fiddle, isFetching: isFetchingFiddle } = fiddleStore;

    useEffect(() => {
        if (fiddleProgress) {
            fiddleStore.find({
                fiddleKey: fiddleProgress.key,
                fiddleId: fiddleProgress.id,
            });
        }
    }, [fiddleProgress]);

    const handleLinkAccess = _.debounce((fiddle, isPrivate) => {
        const inputs = { ...fiddle };
        const newInputs = setIn(
            "testCases",
            inputs.testCases.map(
                (testCase, i) =>
                    new TestCaseDAOModel({ ...testCase, order: i + 1 })
            ),
            inputs
        );
        const body = new FiddleDAOModel({
            ...newInputs,
            isPublished: true,
            isPrivate,
        });
        fiddleStore
            .update({
                fiddleId: fiddle.id,
                fiddleKey: fiddle.key,
                body,
                prevent_replace: true,
            })
            .then((newFiddle) => {
                fiddleProgressStore.find({ fiddleKey: newFiddle.key });
            });
    }, 250);

    const deleteFiddle = (fiddle) => {
        fiddleStore.delete(fiddle);
        fiddleProgressStore.deleteLocalFiddle(fiddle);
    };

    return (
        <QuiztimeTemplate
            currUser={authStore.currUser}
            classroom={classroom}
            fiddle={fiddle}
            fiddleIsFetching={isFetchingFiddle || isFetchingFiddleProgress}
            fiddleProgress={fiddleProgress}
            handleLinkAccess={handleLinkAccess}
            deleteFiddle={deleteFiddle}
        />
    );
});
