export const disableParentClick = (e, func, ...args) => {
    e.preventDefault();
    e.stopPropagation();
    func(...args);
};

export const scrollToTop = (isSmooth) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: isSmooth ? "smooth" : "auto",
    });
};

export const makeId = (length) => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const f = (x) => {
    return x;
};
