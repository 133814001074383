import React from "react";
import { Box, Stack } from "@mui/material";
import { SubmissionsContainer } from "../templates/Submission/SubmissionsContainer";
import { QuizDashboardDetailHeader } from "./templates/QuizDashboardDetailHeader";

export const QuizDashboardDetailTemplate = ({ userId, fiddleId }) => (
    <Stack direction="column" height="100%">
        <QuizDashboardDetailHeader userId={userId} fiddleId={fiddleId} />
        <Box p={2.5} flexGrow={1} overflow="hidden">
            <SubmissionsContainer
                contentTypeName="fiddle"
                objectId={fiddleId}
                userId={userId}
            />
        </Box>
    </Stack>
);
