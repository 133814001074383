import React, { useState } from "react";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MissionNameTypeToColorCode } from "../modules/missions/mission/MissionModel";

export const MissionSelector = ({ missionId, missions, setMissionId }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const changeMissionId = (missionId) => {
        setMissionId(missionId);
        handleClose();
    };

    const selectedMission = missions.find(
        (mission) => mission.id === missionId
    );

    return (
        <>
            <Button
                onClick={handleClick}
                sx={{
                    color: "#000000",
                    backgroundColor: "#ffffff",
                    borderRadius: 8 / 4,
                    border: "2px solid #e1e1e7",
                    padding: 16 / 8,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width={180}
                >
                    <Stack direction="column" alignItems="flex-start">
                        <Typography
                            children={selectedMission.name}
                            variant="overline"
                            fontWeight={700}
                        />
                        <Typography
                            children={selectedMission.copy}
                            variant="caption"
                        />
                    </Stack>
                    <ArrowDropDownIcon fontSize="small" />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                sx={{
                    "& .MuiMenu-paper": {
                        width: 200,
                        borderRadius: 2,
                        boxShadow: 2,
                    },
                }}
            >
                {missions.map((mission, i) => (
                    <MenuItem
                        onClick={() => changeMissionId(mission.id)}
                        selected={missionId === mission.id}
                        key={`mission-menu-${i}`}
                        sx={{
                            borderLeft: `2px solid ${
                                MissionNameTypeToColorCode[mission.name]
                            }`,
                            "& .Mui-selected": {
                                background: "#F5F5F7",
                                color: "#000000",
                            },
                        }}
                    >
                        <Stack direction="column">
                            <Typography
                                children={mission.name}
                                variant="overline"
                                fontWeight={700}
                            />
                            <Typography
                                children={mission.copy}
                                variant="caption"
                            />
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
