import { makeAutoObservable, set } from "mobx";
import { ProblemStatusType } from "../../missions/curatedProblem/CuratedProblemModel";
import { camelizeKeys } from "../../../../utils/renameKeys";
import { pickCls } from "../../../../utils/immutableLodash";

export class FiddleUserProgressModel {
    name = "";

    username = "";

    userId = 0;

    status = "";

    solvedAt = "";

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const progress = pickCls(this, camelizeKeys(data));
        progress.status = progress.status || ProblemStatusType.Ready;
        set(this, progress);
    }
}

export class FiddleUserProgressesModel {
    title = "";

    key = "";

    id = "";

    fiddleUserProgresses = [];

    passedCount = 0;

    studentCount = 0;

    isOwner = false;

    createdAt = "";

    isPrivate = true;

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const fiddleProgress = pickCls(this, camelizeKeys(data));
        set(this, fiddleProgress);
    }
}
