import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, IconButton, Stack } from "@mui/material";
import { ProblemStatusType } from "../modules/missions/curatedProblem/CuratedProblemModel";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "swiper/css";
import { Navigation } from "swiper";
import useSwiperRef from "../hooks/useSwiperRef";
import { observer } from "mobx-react-lite";
import { QuestProblemBox } from "./QuestProblemBox";
import { useNavigate } from "react-router-dom";

export const QuestIndicator = observer(({ questName, questId, quest }) => {
    const navigate = useNavigate();

    const navigateToProblem = ({ problemNumber }) => {
        navigate(`/${questName}/${questId}/${problemNumber}`);
    };

    const [nextEl, nextElRef] = useSwiperRef();
    const [prevEl, prevElRef] = useSwiperRef();

    const nowIndex = quest.reduce((acc, curr, index) => {
        if (curr.progressStatus === ProblemStatusType.Passed) {
            if (index !== quest.length - 1) {
                return index + 1;
            } else {
                return index;
            }
        }

        if (curr.progressStatus === ProblemStatusType.InProgress) {
            return index;
        }

        return acc;
    }, 0);

    return (
        <Stack py={18.5} alignItems={"center"} width={"calc(100vw - 60px)"}>
            <Box width={"100%"} overflow={"visible"} position={"relative"}>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={40}
                    slidesPerView={5}
                    centeredSlides
                    navigation={{
                        prevEl,
                        nextEl,
                    }}
                    onInit={(swiper) => {
                        swiper.activeIndex = nowIndex;
                    }}
                    css={css`
                        .swiper-wrapper {
                            align-items: center;
                        }
                    `}
                >
                    {quest.map((problem, i) => (
                        <SwiperSlide key={`${i}-quest`}>
                            <QuestProblemBox
                                order={i + 1}
                                problem={problem}
                                isLast={i === quest.length - 1}
                                onClick={() =>
                                    navigateToProblem({
                                        problemNumber: problem.number,
                                    })
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <IconButton
                    size={"large"}
                    ref={prevElRef}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 26px)",
                        left: 10,
                        background: "#FFFFFF",
                        borderRadius: "50%",
                        border: "3px solid #8C929B",
                        zIndex: 1,
                    }}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
                <IconButton
                    size={"large"}
                    ref={nextElRef}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 26px)",
                        right: 10,
                        background: "#FFFFFF",
                        borderRadius: "50%",
                        border: "3px solid #8C929B",
                        zIndex: 1,
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Stack>
    );
});
