import React, { useEffect, useState } from "react";
import { Box, Button, Menu, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { observer } from "mobx-react-lite";
import { useStores } from "../../modules/useStores";
import { ClassroomMenuItem } from "./ClassroomMenuItem";
import AddIcon from "@mui/icons-material/Add";
import { QuizAddButton } from "../../../pages/Main/Admin/components/QuizAddButton";
import moment from "moment/moment";
import { Lock, LockOpenOutlined } from "@mui/icons-material";

export const QuizSelector = observer(({ sx, allowCreateQuiz }) => {
    const { classroomStore, fiddleProgressStore } = useStores();
    const { classroom } = classroomStore;
    const { isFetching, fiddleProgresses, fiddleProgress } =
        fiddleProgressStore;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (classroom && classroom.id) {
            fiddleProgressStore.findAll().then((fiddleProgresses) => {
                const exist = fiddleProgress
                    ? fiddleProgresses.some(
                          (_fiddle) => _fiddle.key === fiddleProgress.key
                      )
                    : false;
                fiddleProgressStore.updateLocalFiddle(
                    exist
                        ? fiddleProgresses.filter(
                              (_fiddle) => _fiddle.key === fiddleProgress.key
                          )[0]
                        : fiddleProgresses[0],
                    true
                );
            });
        }
    }, [classroom]);

    const handleMenuClick = (_fiddle) => {
        fiddleProgressStore.updateLocalFiddle(_fiddle);

        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToFiddleStudio = () => {
        window.open("/psfiddle/new", "_blank");
    };

    if (classroom && isFetching) {
        return (
            <Button
                variant="contained"
                sx={{
                    bgcolor: "common.white",
                    borderRadius: 2,
                    color: "common.black",
                    border: "1px solid #1A1D24",
                    width: 250,
                    py: 2,
                    justifyContent: "space-between",
                    "&:hover": {
                        bgcolor: "#C4C4C4",
                    },
                    ...sx,
                }}
                disabled
            >
                <Typography
                    variant="body1"
                    fontWeight={700}
                    children="퀴즈를 가져오는 중입니다."
                />
            </Button>
        );
    }

    if (!fiddleProgresses.length) {
        if (classroom && classroom.isOwner) {
            return <QuizAddButton sx={sx} />;
        } else {
            return (
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: "common.white",
                        borderRadius: 2,
                        color: "common.black",
                        border: "1px solid #1A1D24",
                        width: 250,
                        py: 2,
                        justifyContent: "space-between",
                        "&:hover": {
                            bgcolor: "#C4C4C4",
                        },
                        ...sx,
                    }}
                >
                    <Typography
                        variant="body1"
                        fontWeight={700}
                        children="아직 퀴즈가 없어요."
                    />
                </Button>
            );
        }
    }

    return (
        <Stack direction={"row"} spacing={1} flex={1}>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                    padding: 2,
                    borderRadius: 2,
                    ...sx,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box display="flex" flexDirection="column" flex={1}>
                        {fiddleProgress ? (
                            <Stack
                                direction={"row"}
                                flex={1}
                                alignItems="center"
                            >
                                <Stack
                                    direction={"column"}
                                    alignItems={"flex-start"}
                                    justifyContent={"center"}
                                    flex={1}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                    >
                                        <Typography
                                            variant="body1"
                                            textAlign="left"
                                            fontWeight={700}
                                        >
                                            {
                                                fiddleProgress.title.split(
                                                    /\r?\n/
                                                )[0]
                                            }
                                        </Typography>
                                        {fiddleProgress.isOwner &&
                                            (fiddleProgress.isPrivate ? (
                                                <Typography variant="caption">
                                                    (비공개)
                                                </Typography>
                                            ) : (
                                                <Typography variant="caption">
                                                    (공개)
                                                </Typography>
                                            ))}
                                    </Stack>
                                    <Typography
                                        variant="body2"
                                        color={"#18A0FB"}
                                    >
                                        완료한 사람 {fiddleProgress.passedCount}{" "}
                                        / {fiddleProgress.studentCount} (
                                        {Number(fiddleProgress.studentCount) ===
                                        0
                                            ? 0
                                            : parseInt(
                                                  (fiddleProgress.passedCount /
                                                      fiddleProgress.studentCount) *
                                                      100
                                              )}
                                        %)
                                    </Typography>
                                </Stack>
                                <Typography variant="caption">
                                    {moment(fiddleProgress.createdAt).format(
                                        "LLL"
                                    )}
                                </Typography>
                            </Stack>
                        ) : isFetching ? (
                            "학급 정보를 불러오는 중입니다"
                        ) : null}
                    </Box>
                    <ArrowDropDownIcon />
                </Box>
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: { width: anchorEl && anchorEl.offsetWidth },
                }}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                    },
                }}
            >
                {fiddleProgresses.map((_fiddle) => (
                    <ClassroomMenuItem
                        key={`fiddle-menu-${_fiddle.id}`}
                        onClick={() => handleMenuClick(_fiddle)}
                        selected={
                            fiddleProgress
                                ? fiddleProgress.id === _fiddle.id
                                : false
                        }
                    >
                        <Stack direction="column">
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography
                                    variant="body1"
                                    textAlign="left"
                                    fontWeight={700}
                                >
                                    {_fiddle.title.split(/\r?\n/)[0]}
                                </Typography>
                                {_fiddle.isOwner &&
                                    (_fiddle.isPrivate ? (
                                        <Lock
                                            sx={{
                                                fontSize: "1rem",
                                                color: "#959595",
                                            }}
                                        />
                                    ) : (
                                        <LockOpenOutlined
                                            sx={{
                                                fontSize: "1rem",
                                                color: "#959595",
                                            }}
                                        />
                                    ))}
                            </Stack>
                            <Typography variant="body2" color={"#18A0FB"}>
                                완료한 사람 {_fiddle.passedCount} /{" "}
                                {_fiddle.studentCount} (
                                {Number(_fiddle.studentCount) === 0
                                    ? 0
                                    : parseInt(
                                          (_fiddle.passedCount /
                                              _fiddle.studentCount) *
                                              100
                                      )}
                                %)
                            </Typography>
                        </Stack>
                    </ClassroomMenuItem>
                ))}
            </Menu>
            {allowCreateQuiz && (
                <Button
                    onClick={navigateToFiddleStudio}
                    startIcon={<AddIcon />}
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        ...sx,
                        flex: "initial",
                    }}
                >
                    {/* <ClassroomMenuItem onClick={navigateToFiddleStudio}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                width="100%"
                            > */}
                    <Typography
                        variant="body1"
                        textAlign="left"
                        fontWeight={700}
                        children="새 퀴즈 만들기"
                    />
                    {/* <AddIcon /> */}
                    {/* </Stack>
                        </ClassroomMenuItem> */}
                </Button>
            )}
        </Stack>
    );
});
