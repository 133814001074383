import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { HomeworkEditTemplate } from "./HomeworkEditTemplate";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { snackActions } from "../../../../utils/SnackbarUtilsConfigurator";

export const HomeworkEditContainer = observer(() => {
    const { homeworkStore, classroomStore } = useStores();
    const { homeworks, isFetching } = homeworkStore;
    const { classroom } = classroomStore;

    const [searchWord, setSearchWord] = useState("");

    useEffect(() => {
        homeworkStore.findAll({
            isPublic: false,
        });
    }, [classroom]);

    const handleDelete = (homeworkId, callback) => {
        homeworkStore.remove({ homeworkId }).then(() => {
            snackActions.success("정상적으로 삭제되었습니다.");
            callback();
        });
    };

    const handleHidden = (homeworkId, is_hidden) => {
        homeworkStore.toggleHidden({
            homeworkId,
            body: {
                is_hidden,
            },
        });
    };

    const searchHomeworks = (value) => {
        homeworkStore.findAll({
            searchWord: value,
        });
    };

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="숙제를 불러오는 중입니다."
            />
        );

    return (
        <HomeworkEditTemplate
            searchHomeworks={searchHomeworks}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            homeworks={homeworks}
            handleDelete={handleDelete}
            handleHidden={handleHidden}
        />
    );
});
