import React from "react";
import {
    Button,
    ButtonBase,
    List,
    ListItemButton,
    Stack,
    Typography,
} from "@mui/material";
import { HomeworkBox } from "./templates/HomeworkBox";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";
import { MiddleButton } from "../../../../common/components/button/MiddleButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const HomeworkMarketTemplate = ({
    fetchLessonTemplateDetail,
    lessonTemplate,
    lessonTemplates,
    setLessonTemplate,
    handleUseTemplate,
    redirectAddPage,
}) => {
    const handleSelectTemplate = (template) => {
        setLessonTemplate(template.id);
        if (template.homeworks.length === 0) {
            fetchLessonTemplateDetail(template.id);
        }
    };

    return (
        <Stack direction="column">
            {lessonTemplates.length ? (
                <Stack
                    direction={"row"}
                    width={"100%"}
                    spacing={4}
                    px={4}
                    bgcolor={"#F5F5F7"}
                >
                    {lessonTemplate ? (
                        <List
                            sx={{
                                minWidth: 200,
                                bgcolor: "#F5F5F7",
                                height: "100%",
                                overflow: "auto",
                                position: "sticky",
                                top: 0,
                            }}
                        >
                            {lessonTemplates.map((template) => (
                                <ListItemButton
                                    key={`template-${template.id}`}
                                    selected={
                                        lessonTemplate
                                            ? lessonTemplate.id === template.id
                                            : false
                                    }
                                    onClick={() =>
                                        handleSelectTemplate(template)
                                    }
                                    sx={(theme) => ({
                                        borderRadius: 2,
                                        border: "2px solid #e1e1e7",
                                        "&.Mui-selected": {
                                            background: "#fff",
                                            color: theme.palette.primary.main,
                                            borderColor:
                                                theme.palette.primary.main,
                                            fontWeight: 800,
                                        },
                                    })}
                                >
                                    <Stack>
                                        <Typography variant="body1">
                                            {template.name}
                                        </Typography>
                                        <Typography variant="body2">{`총 ${template.numTotal}문항 (${template.minuteTotal}분)`}</Typography>
                                    </Stack>
                                </ListItemButton>
                            ))}
                        </List>
                    ) : (
                        <Stack
                            width={"100%"}
                            flex={1}
                            alignItems={"center"}
                            py={4}
                        >
                            <Typography variant="h1" mb={4}>
                                이번학기 수업, 템플릿으로 바로 시작해보세요.
                            </Typography>
                            <Stack spacing={4} alignItems={"center"}>
                                <Stack spacing={2}>
                                    {lessonTemplates.map((template) => (
                                        <Stack
                                            key={`template-${template.id}`}
                                            component={ButtonBase}
                                            onClick={() =>
                                                handleSelectTemplate(template)
                                            }
                                            sx={{
                                                border: "2px solid #e1e1e7",
                                                borderRadius: 4,
                                                bgcolor: "#fff",
                                                px: 3,
                                                py: 2,
                                                transition: "all .25s",
                                                "&:hover": {
                                                    borderColor: "#000",
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h2"
                                                fontWeight={800}
                                            >
                                                {template.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                {template.description}
                                            </Typography>
                                            <Typography variant="body2">{`총 ${template.numTotal}문항 (${template.minuteTotal}분)`}</Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                                <Typography>또는</Typography>
                                <MiddleButton
                                    color="#FFFFFF"
                                    onClick={redirectAddPage}
                                    label="직접 수업/과제 추가"
                                    backgroundColor="#FF5555"
                                    endIcon={<AddCircleIcon fontSize="small" />}
                                />
                            </Stack>
                        </Stack>
                    )}
                    {lessonTemplate && (
                        <Stack flex={1}>
                            <Stack maxWidth={720}>
                                <Stack
                                    position={"sticky"}
                                    top={0}
                                    zIndex={100}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    p={2}
                                    bgcolor={"#fff"}
                                    sx={{
                                        borderBottomLeftRadius: 12,
                                        borderBottomRightRadius: 12,
                                        border: "1px solid #e1e1e7",
                                    }}
                                >
                                    <Stack>
                                        <Typography
                                            variant="h2"
                                            fontWeight={800}
                                        >
                                            {lessonTemplate.name}
                                        </Typography>
                                        <Typography variant="body1">
                                            {lessonTemplate.description}
                                        </Typography>
                                        <Typography variant="body2">{`총 ${lessonTemplate.numTotal}문항 (${lessonTemplate.minuteTotal}분)`}</Typography>
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={() =>
                                            handleUseTemplate(lessonTemplate.id)
                                        }
                                        disabled={
                                            lessonTemplate.homeworks.length ===
                                            0
                                        }
                                    >
                                        이 템플릿으로 수업 만들기
                                    </Button>
                                </Stack>
                                <Stack p={2} bgcolor={"#F5F5F7"} spacing={2}>
                                    {lessonTemplate.homeworks.length === 0 ? (
                                        <Typography>
                                            템플릿을 가져오는 중입니다. 잠시만
                                            기다려주세요.
                                        </Typography>
                                    ) : (
                                        lessonTemplate.homeworks.map(
                                            (homework) => (
                                                <HomeworkBox
                                                    homework={homework}
                                                    key={`homework-box-${homework.id}`}
                                                    handleDelete={() => {}}
                                                    handleHidden={() => {}}
                                                />
                                            )
                                        )
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            ) : (
                <NoResultsTemplate text="아직 이용할 수 있는 템플릿이 없어요." />
            )}

            {/* {homeworks.length ? (
                <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                    <Container maxWidth="md">
                        <Stack spacing={5} py={3}>
                            {homeworks.map((homework) => (
                                <HomeworkBox
                                    homework={homework}
                                    key={`homework-box-${homework.id}`}
                                    handleDelete={handleDelete}
                                />
                            ))}
                        </Stack>
                    </Container>
                </Box>
            ) : (
                <NoResultsTemplate text="아직 이용할 수 있는 템플릿이 없어요." />
            )} */}
        </Stack>
    );
};
