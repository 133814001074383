import React from "react";
import { Drawer, Stack } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { ClassroomSelector } from "../../../../common/components/menu/ClassroomSelector";
import { AdminList } from "./AdminList";
import { MiddleButton } from "../../../../common/components/button/MiddleButton";

export const AdminDrawer = () => {
    const navigate = useNavigate();

    const redirectToMain = () => {
        navigate("/");
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 290,
                "& .MuiDrawer-paper": {
                    p: 2.5,
                },
            }}
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                height="100%"
            >
                <Stack direction="column">
                    <ClassroomSelector
                        sx={{
                            backgroundColor: "#FFFFFF",
                            marginRight: 0,
                            width: 250,
                            color: "#000000",
                            "&:hover": {
                                backgroundColor: "#C4C4C4",
                            },
                        }}
                    />
                    <AdminList />
                </Stack>
                <MiddleButton
                    label="메인으로 돌아가기"
                    startIcon={<LoginIcon />}
                    onClick={redirectToMain}
                    backgroundColor="#FFFFFF00"
                    color="#FFFFFF"
                />
            </Stack>
        </Drawer>
    );
};
