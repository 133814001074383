import React from "react";
import { Stack, Typography } from "@mui/material";

export const SpecStack = ({ specName, specValue, icon }) => (
    <Stack direction="column" alignItems="center">
        <Typography variant="body1" children={specValue} fontWeight={700} />
        <Stack direction="row" spacing={0.75} alignItems="center">
            {icon}
            <Typography variant="caption" children={specName} color="#666666" />
        </Stack>
    </Stack>
);
