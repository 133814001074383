import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const MissionButton = ({ mission }) => {
    const navigate = useNavigate();

    const navigateToCurriculumPage = () => {
        navigate("/curriculums");
    };

    return (
        <ButtonBase
            onClick={navigateToCurriculumPage}
            sx={{
                mr: 12.5,
            }}
        >
            <Stack direction="column" alignItems="flex-start">
                <Typography
                    children={mission.name}
                    textTransform="uppercase"
                    fontWeight={700}
                    variant="caption"
                    color="#666666"
                />
                <Typography
                    children={mission.copy}
                    color="#1A1A1A"
                    variant="h4"
                    fontWeight={700}
                />
            </Stack>
        </ButtonBase>
    );
};
