import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../common/templates/LoadingOverlay";
import { SubmissionsTemplate } from "./SubmissionsTemplate";
import { NoResultsTemplate } from "../../../Missions/Playground/templates/NoResultsTemplate";

export const SubmissionsContainer = observer(
    ({ userId, contentTypeName, objectId }) => {
        const { submissionStore } = useStores();
        const { submissions, isFetching } = submissionStore;

        const [submissionId, setSubmissionId] = useState(0);

        useEffect(() => {
            if (contentTypeName === "fiddle") {
                submissionStore.findAllByFiddleAndUser({
                    fiddleId: objectId,
                    userId,
                });
            } else {
                submissionStore.findAllByProblemAndUser({
                    problemId: objectId,
                    userId,
                });
            }
        }, [userId, objectId]);

        if (isFetching)
            return (
                <LoadingOverlay text="제출 기록을 불러오는 중입니다." active />
            );

        return submissions.length ? (
            <SubmissionsTemplate
                submissions={submissions}
                submissionId={submissionId}
                setSubmissionId={setSubmissionId}
                isFiddle={contentTypeName === "fiddle"}
            />
        ) : (
            <NoResultsTemplate text="제출 기록이 존재하지 않습니다." />
        );
    }
);
