import React from "react";
import { Route, Routes } from "react-router-dom";
import { ClassroomStudentContainer } from "./ClassroomStudentContainer";
import { StudentExcelTemplate } from "./templates/StudentExcelTemplate";
import { StudentFormsContainer } from "./templates/StudentFormsContainer";

export const ClassroomStudentPage = () => (
    <Routes>
        <Route path="/" element={<ClassroomStudentContainer />} />
        <Route path="/excel" element={<StudentExcelTemplate />} />
        <Route path="/add" element={<StudentFormsContainer isAdd />} />
        <Route path="/:studentId/edit" element={<StudentFormsContainer />} />
    </Routes>
);
