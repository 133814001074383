import React, { useState } from "react";
import { Slide, Stack } from "@mui/material";
import { DiscussionBox } from "./templates/DiscussionBox";
import { CommentsBox } from "./comments/CommentsBox";
import { DiscussionForm } from "../templates/DiscussionForm";

export const DiscussionTemplate = ({ discussion, comments, handleEdit }) => {
    const [isEditing, setIsEditing] = useState(false);

    const toggleIsEditing = () => {
        setIsEditing((prev) => !prev);
    };

    return (
        <Stack direction="column">
            <DiscussionBox
                discussion={discussion}
                toggleIsEditing={toggleIsEditing}
            />
            <CommentsBox discussionId={discussion.id} comments={comments} />
            <Slide in={isEditing} direction="up">
                <DiscussionForm
                    confirmText="수정"
                    handleCancel={toggleIsEditing}
                    handleSubmit={handleEdit}
                    discussion={discussion}
                />
            </Slide>
        </Stack>
    );
};
