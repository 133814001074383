import { makeAutoObservable, set } from "mobx";
import { ProgressModel } from "../../../homework/progress/UserHomeworkProgressModel";
import { pickCls } from "../../../../../utils/immutableLodash";
import { camelizeKeys } from "../../../../../utils/renameKeys";

export class MissionProgressModel {
    userId = 0;

    username = "";

    name = "";

    progresses = [];

    constructor(data) {
        makeAutoObservable(this, {}, { autoBind: true });
        const missionProgress = pickCls(this, camelizeKeys(data));
        missionProgress.progresses = missionProgress.progresses.map(
            (progress) => new ProgressModel(progress)
        );
        set(this, missionProgress);
    }
}
