import React from "react";
import { Dialog, DialogActions, Stack, Typography } from "@mui/material";
import { LargeButton } from "../button/LargeButton";

export const AdminAlertDialog = ({
    title,
    description,
    open,
    onClose,
    onConfirm,
    confirmText,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
        disableScrollLock={true}
        PaperProps={{
            sx: {
                borderRadius: 3,
                p: 4,
            },
        }}
    >
        <Stack direction="column" alignItems="center" mb={2.5}>
            <Typography children={title} variant="body1" fontWeight={700} />
            <Typography
                children={description}
                whiteSpace="pre-line"
                variant="body1"
                textAlign="center"
            />
        </Stack>
        <DialogActions
            sx={{
                justifyContent: "center",
                p: 0,
            }}
        >
            <Stack direction="row" spacing={1.25}>
                <LargeButton
                    label="취소"
                    variant="outlined"
                    onClick={onClose}
                    backgroundColor="#FFFFFF00"
                    color="#808080"
                    sx={{
                        borderColor: "#808080",
                    }}
                />
                <LargeButton
                    label={confirmText || "삭제"}
                    color="#FFFFFF"
                    backgroundColor="#D24D57"
                    onClick={onConfirm}
                />
            </Stack>
        </DialogActions>
    </Dialog>
);
