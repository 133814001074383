export const drawerThemeOption = {
    palette: {
        primary: {
            main: "#1A1D24",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#000000",
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#F5F5F7",
            paper: "#1A1D24",
        },
        text: {
            primary: "#FFFFFF",
        },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                },
            },
        },
    },
};
