import React from "react";
import { Box, Stack, Button } from "@mui/material";
import { HomeworkDashboardHeader } from "./templates/HomeworkDashboardHeader";
import { UserHomeworkProgressBox } from "./templates/UserHomeworkProgressBox";
import { DataGridPagination } from "../../../../common/components/pagination/DataGridPagination";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";
import moment from "moment";
import { Download } from "@mui/icons-material";

export const HomeworkDashboardTemplate = ({
    userHomeworkProgresses,
    page,
    setPage,
    count,
    rowsPerPage,
    setRowsPerPage,
    searchWord,
    setSearchWord,
    searchHomeworks,
    sortOptions,
    sortOption,
    selectSortOption,
    handleDownload,
    isDownloading,
}) => {
    if (!userHomeworkProgresses[0].homeworkProgresses.length) {
        return (
            <NoResultsTemplate
                text={"숙제 정보가 존재하지 않습니다.\n숙제를 추가해주세요."}
            />
        );
    }

    const sortedUserHomeworkProgresses = userHomeworkProgresses.map(
        (userHomeworkProgress) => {
            return {
                ...userHomeworkProgress,
                homeworkProgresses: [
                    ...userHomeworkProgress.homeworkProgresses,
                ].sort((a, b) => {
                    const ma = moment(a.startedAt);
                    const mb = moment(b.startedAt);
                    return ma.isBefore(mb) ? -1 : ma.isAfter(mb) ? 1 : 0;
                }),
            };
        }
    );

    return (
        <Stack direction="column" height="100%">
            <Box
                sx={{
                    overflowX: "auto",
                    flexGrow: 1,
                }}
            >
                <HomeworkDashboardHeader
                    searchHomeworks={searchHomeworks}
                    searchWord={searchWord}
                    setSearchWord={setSearchWord}
                    homeworkProgresses={
                        sortedUserHomeworkProgresses[0].homeworkProgresses
                    }
                    sortOptions={sortOptions}
                    sortOption={sortOption}
                    selectSortOption={selectSortOption}
                />
                <Stack
                    minWidth="fit-content"
                    direction="column"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {sortedUserHomeworkProgresses.map(
                        (userHomeworkProgress, order) => (
                            <UserHomeworkProgressBox
                                key={`user-homework-progress-${order}`}
                                userHomeworkProgress={userHomeworkProgress}
                                order={order}
                            />
                        )
                    )}
                </Stack>
            </Box>
            <Stack
                direction="row"
                justifyContent={"center"}
                alignItems={"center"}
                p={2.5}
                bgcolor="common.white"
            >
                <Button
                    variant="contained"
                    startIcon={<Download />}
                    onClick={handleDownload}
                    disabled={isDownloading}
                >
                    {isDownloading ? `로딩중...` : `엑셀 다운`}
                </Button>
                <DataGridPagination
                    page={page}
                    count={count}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Stack>
        </Stack>
    );
};
