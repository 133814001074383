import React, { useState } from "react";
import { Stack } from "@mui/material";
import { PlaygroundPage } from "../Playground/PlaygroundPage";
import { useNavigate } from "react-router-dom";
import { MenuTreeTemplate } from "../../MenuTree/MenuTreeTemplate";

export const ProblemsTemplate = ({
    missionTree,
    curatedProblem,
    problem,
    changeToPrevProblem,
    changeToNextProblem,
    changeToTreeProblem,
    treeUpdate,
}) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prev) => {
            if (!prev) {
                treeUpdate();
            }

            return !prev;
        });
    };

    const redirectToNextProblemOrOpen = () => {
        const nextId = changeToNextProblem();
        navigate(`/missions/${missionTree.id}/problems/${nextId}`);
    };

    const redirectToPrevProblemOrOpen = () => {
        const prevId = changeToPrevProblem();
        navigate(`/missions/${missionTree.id}/problems/${prevId}`);
    };

    const redirectToTreeProblemOrOpen = ({ problemId }) => {
        changeToTreeProblem({ problemId });
        navigate(`/missions/${missionTree.id}/problems/${problemId}`);
        setOpen(false);
    };
    console.log(curatedProblem, problem);
    return (
        <Stack height="calc(100vh - 54px)" direction="column">
            <PlaygroundPage problem={problem} objectId={missionTree.id} />
            <MenuTreeTemplate
                treeItem={missionTree}
                problem={curatedProblem}
                selected={[
                    `${curatedProblem.parentNodeId[1]}`,
                    `${curatedProblem.parentNodeId[2]}`,
                    `${curatedProblem.nodeId}`,
                ]}
                homePath={"/"}
                open={open}
                toggleOpen={toggleOpen}
                redirectToNextProblemOrOpen={redirectToNextProblemOrOpen}
                redirectToPrevProblemOrOpen={redirectToPrevProblemOrOpen}
                redirectToTreeProblemOrOpen={redirectToTreeProblemOrOpen}
            />
        </Stack>
    );
};
