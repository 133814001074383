import React, { useState } from "react";
import { Box } from "@mui/material";
import { CommentEditor } from "../CommentEditor";
import { CommentView } from "../CommentView";

export const Reply = ({ reply, handleEdit }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = (inputs) => {
        handleEdit(inputs, reply.id);
        setIsEditing(false);
    };

    const toggleIsEditing = () => {
        setIsEditing((prev) => !prev);
    };

    return isEditing ? (
        <Box mb={3}>
            <CommentEditor
                onSubmit={handleSubmit}
                confirmText="수정"
                comment={reply}
                toggleIsEditing={toggleIsEditing}
            />
        </Box>
    ) : (
        <CommentView
            comment={reply}
            toggleIsEditing={toggleIsEditing}
            isReply
        />
    );
};
