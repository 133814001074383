import React, { forwardRef } from "react";
import { Stack } from "@mui/material";
import { OverviewHeader } from "./OverviewHeader";
import { OverviewContent } from "./OverviewContent";

export const OverviewBox = forwardRef(({ mission }, ref) => (
    <Stack direction="column" ref={ref}>
        <OverviewHeader mission={mission} />
        <OverviewContent mission={mission} />
    </Stack>
));
