import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../../Missions/Playground/templates/NoResultsTemplate";
import { ClassroomDashboardTemplate } from "./ClassroomDashboardTemplate";
import { SearchField } from "../../../../common/components/textField/SearchField";
import { MiddleButton } from "../../../../common/components/button/MiddleButton";
import SearchIcon from "@mui/icons-material/Search";

export const ClassroomDashboardContainer = observer(() => {
    const { missionProgressStore, classroomStore, missionStore } = useStores();

    const { missions } = missionStore;
    const { classroom } = classroomStore;
    const { missionProgresses, isFetching, count } = missionProgressStore;

    const [page, setPage] = useState(0);
    const [missionId, setMissionId] = useState(0);
    const [searchWord, setSearchWord] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const sortOptions = [
        {
            label: "이름순",
            key: "name_asc",
        },
        {
            label: "이름 역순",
            key: "name_desc",
        },
        {
            label: "아이디순",
            key: "username_asc",
        },
        {
            label: "아이디 역순",
            key: "username_desc",
        },
        {
            label: "이수율 높은순",
            key: "progress_desc",
        },
        {
            label: "이수율 낮은순",
            key: "progress_asc",
        },
    ];

    const [sortOption, selectSortOption] = useState(sortOptions[0]);

    useEffect(() => {
        if (missions.length) {
            setMissionId(missions[0].id);
        } else {
            missionStore.findAll().then((missions) => {
                setMissionId(missions[0].id);
            });
        }
    }, []);

    useEffect(() => {
        if (missionId) {
            missionProgressStore.findAll({
                page: page + 1,
                pageSize: rowsPerPage,
                missionId,
                searchWord,
                orderBy: sortOption.key,
            });
        }
    }, [classroom, page, missionId, rowsPerPage, sortOption.key]);

    const searchUsers = (value) => {
        missionProgressStore.findAll({
            page: page + 1,
            pageSize: rowsPerPage,
            missionId,
            searchWord: value,
            orderBy: sortOption.key,
        });
    };

    if (!missionId || !missions.length || isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="진도 정보를 불러오고 있습니다."
            />
        );

    return (
        <Stack direction="column" width="100%" height="100%">
            <Box p={2.5} boxShadow={1}>
                <Stack spacing={1.25} direction="row">
                    <SearchField
                        onSubmit={searchUsers}
                        value={searchWord}
                        setValue={setSearchWord}
                        placeholder={"학생 아이디 입력"}
                    />
                    <MiddleButton
                        onClick={() => searchUsers(searchWord)}
                        color="#FFFFFF"
                        label="검색"
                        backgroundColor="#000000"
                        endIcon={<SearchIcon fontSize="small" />}
                    />
                </Stack>
            </Box>
            {missionProgresses.length ? (
                <ClassroomDashboardTemplate
                    searchUsers={searchUsers}
                    searchWord={searchWord}
                    setSearchWord={setSearchWord}
                    missionProgresses={missionProgresses}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    missionId={missionId}
                    setMissionId={setMissionId}
                    missions={missions}
                    sortOptions={sortOptions}
                    sortOption={sortOption}
                    selectSortOption={selectSortOption}
                />
            ) : (
                <NoResultsTemplate
                    text={"학생 정보가 존재하지 않습니다.\n학생을 추가해주세요"}
                />
            )}
        </Stack>
    );
});
