import React from "react";
import { Box, Typography } from "@mui/material";

export const PrivacyTemplate = () => {
    return (
        <Box
            sx={{
                wordBreak: "keep-all",
                p: 5,
            }}
        >
            <Typography align="center" variant="h3" fontWeight={700}>
                브랜치앤바운드 개인정보처리방침
            </Typography>
            <br />
            <Typography variant="body2">
                주식회사 브랜치앤바운드는 코드트리 서비스(이하 “서비스”)를
                제공함에 있어 개인정보보호법에 따라 이용자의 개인정보 및 권익을
                보호하고, 개인정보와 관련한 고충을 신속하고 원활하게 처리할 수
                있도록 하기 위하여 다음과 같은 개인정보처리방침을 두고 있습니다.
                주식회사 브랜치앤바운드는 개인정보처리방침을 개정하는 경우
                서비스에 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
                <br />
                <br />
                <strong>1. 개인정보의 수집항목, 이용목적 및 수집방법</strong>
                <br />
                <br />
                주식회사 브랜치앤바운드는 회원가입 및 서비스 제공을 위해 아래와
                같은 개인정보를 수집하고 있습니다.
                <br />
                - 필수 수집항목 : 아이디(이메일 주소), 비밀번호, 휴대전화번호
                <br />
                - 이용목적 : <br />
                ㅤㅤ1) 회원 가입 및 관리 - 회원제 서비스 이용에 따른 본인확인 및
                회원 가입 의사 확인, 회원 자격 유지 및 관리, 서비스 부정 이용
                방지와 비인가 사용 방지, 민원/문의 처리, 고지사항 전달
                <br />
                ㅤㅤ2) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른
                요금정산 - 본인 인증, 서비스 유효성 확인, 서비스 제공, 서비스
                구매 및 요금 결제, 유료 서비스 구매 및 이용 시 요금 정산
                <br />
                ㅤㅤ3) 서비스 개발 및 마케팅/광고 활용 - 맞춤 서비스 제공,
                서비스 개선 및 신규 서비스 개발을 위한 통계, 신규 서비스 런칭 시
                안내 및 이벤트 등 광고성 정보 전달
                <br />
                - 수집방법 :<br />
                ㅤㅤ1) 회원가입 시 이용자의 입력 및 동의 : 주식회사
                브랜치앤바운드는 회원가입 시 개인정보 수집 및 이용에 대한 별도의
                동의 획득 절차를 두고 있습니다. 이용자가 &apos;동의한다&apos;
                버튼을 클릭하면 개인정보 수집 및 이용에 동의한 것으로 봅니다.
                <br />
                ㅤㅤ2) 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할
                권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은 없습니다.
                단, 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.
                <br />
                ㅤㅤ3) 그 외 서비스 이용과정에서 IP Address, 쿠키, 접속 로그,
                서비스 이용기록, 결제 기록, 이용정지 기록이 자동으로 수집될 수
                있습니다.
                <br />
                <br />
                <strong>2. 개인정보의 처리 및 보유 기간</strong>
                <br />
                <br />
                개인정보는 고객이 동의한 서비스의 수집∙이용 기간에만 다음 처리
                목적을 위하여 보유∙이용 되며, 회원 탈퇴 시 지체없이 파기합니다.
                단, 회원의 탈퇴에도 불구하고 관련 법령에서 의무보유기간에 대해
                별도로 정한 경우에는 해당 기간 종료 시까지 개인정보를
                보유합니다.
                <br />
                - 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의
                소비자보호에 관한 법률)
                <br />
                - 대금결제, 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의
                소비자보호에 관한 법률)
                <br />
                - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래
                등에서의 소비자보호에 관한 법률)
                <br />
                - 전자금융 거래에 관한 기록: 5년(전자금융거래법)
                <br />
                - 사이트 방문에 관한 기록, 로그인 기록: 3개월(통신비밀보호법)
                <br />
                <br />
                <strong fontWeight="fontWeightBold">
                    3. 개인정보처리 위탁
                </strong>
                <br />
                <br />
                ㅤ① 주식회사 브랜치앤바운드는 원활한 서비스 제공을 위해 아래와
                같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 운영하고
                있습니다.
                <br />
                <img
                    style={{
                        width: "85vw",
                        maxWidth: "600px",
                    }}
                    src="https://contents.codetree.ai/homepage/images/policy/policy_210128.png"
                />
                <br />
                ㅤ② 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무
                수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁
                제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을
                계약서 등 문서에 명확히 규정하고, 당해 계약 내용을 서면 및
                전자적으로 보관하고 있습니다.
                <br />
                ㅤ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
                개인정보 처리방침을 통하여 공개하도록 하겠습니다. <br />
                <br />
                <strong>4. 개인정보의 파기절차 및 방법</strong>
                <br />
                <br />
                주식회사 브랜치앤바운드는 원칙적으로 개인정보 처리목적이 달성된
                경우에는 지체없이 해당 개인정보를 파기합니다. 단, 관련 법령 등에
                따라 보존할 필요가 있는 개인정보의 경우 해당 개인정보 또는
                개인정보파일을 다른 개인정보와 분리해서 저장 및 관리합니다. 이
                때 별도로 보관되는 개인정보는 각각의 보존근거에 해당하는 목적
                이외의 다른 목적으로 이용되지 않습니다. 개인정보의 파기절차,
                기한 및 방법은 다음과 같습니다.
                <br />
                - 파기절차 : 수집·이용목적이 달성된 개인정보는 지체없이
                파기되며, 관련 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져
                내부 규정 및 관련 법령을 준수하여 일정기간(개인정보의 처리 및
                보유 기간 참조) 동안 안전하게 보관된 후 지체없이 파기됩니다.
                이때, DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른
                목적으로 이용하지 않습니다. <br />
                - 파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는
                기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로
                분쇄하거나 소각을 통하여 파기합니다.
                <br />
                <br />
                <strong>
                    5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법
                </strong>
                <br />
                <br />
                이용자는 개인정보 주체로써 다음과 같은 권리를 행사할 수
                있습니다.
                <br />
                ① 정보주체는 주식회사 브랜치앤바운드에 대해 언제든지 개인정보
                열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.
                <br />
                ② 제1항에 따른 권리 행사는 주식회사 브랜치앤바운드에 대해
                개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편,
                웹사이트 등을 통하여 하실 수 있으며 주식회사 브랜치앤바운드는
                이에 대해 지체 없이 조치하겠습니다.
                <br />
                ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 개인정보
                보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
                합니다.
                <br />
                ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항,
                제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                <br />
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
                수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                없습니다.
                <br />
                ⑥ 주식회사 브랜치앤바운드은(는) 정보주체 권리에 따른 열람의
                요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한
                자가 본인이거나 정당한 대리인인지를 확인합니다.
                <br />
                <br />
                <strong>
                    6. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
                </strong>
                <br />
                <br />
                ① 주식회사 브랜치앤바운드는 개별적인 맞춤서비스를 제공하기 위해
                이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.
                쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
                컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
                컴퓨터내의 하드디스크에 저장되기도 합니다.
                <br />
                ㅤ- 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에
                대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
                이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                <br />
                ㅤ- 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
                옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
                있습니다.
                <br />
                ㅤ- 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
                수 있습니다.
                <br />
                ② 주식회사 브랜치앤바운드는 서비스 세션(session)을 통하여
                이용자의 정보를 얻고 있습니다. 세션이란, 이용자가 “서비스”
                사이트를 방문할 때 “서비스”의 서버로부터 이용자의 컴퓨터에
                보내진 시스템 파일로서 이용자의 연결 유지를 위하여 잠시 이용자의
                컴퓨터에 머무는 파일입니다. 이러한 세션은 현재 연결의 유지에만
                사용되며 브라우저를 종료할 경우 삭제됩니다.
                <br />
                <br />
                <strong>7. 개인정보의 안전성 확보 조치</strong>
                <br />
                <br />
                주식회사 브랜치앤바운드는 개인정보보호법 제29조에 따라 다음과
                같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고
                있습니다.
                <br />
                ① 이용자의 개인정보는 비밀번호에 의해 철저히 보호되고 있으며
                본인이 제공한 이메일주소에 매칭되는 비밀번호는 본인만이 알고
                있습니다. 따라서 개인정보의 확인 및 변경도 비밀번호를 알고 있는
                본인에 의해서만 가능합니다.
                <br />
                ② 이용자는 회원은 본인의 비밀번호를 누구에게도 알려주면
                안됩니다. 이를 위해 기본적으로 PC에서의 사용을 마치신 후
                온라인상에서 로그아웃(LOG-OUT)하고 웹 브라우저를 종료하도록
                권장합니다.
                <br />
                ③ 주식회사 브랜치앤바운드에서 수행하는 개인정보 보호를 위한
                조치는 다음과 같습니다.
                <br />
                ㅤ- 개인정보 취급자에 대한 지정과 권한의 설정 및 교육, 개인정보
                취급자를 최소한으로 지정
                <br />
                ㅤ- 개인정보 처리 시스템에 대한 접근권한 설정
                <br />
                ㅤ- 개인정보 처리 시스템은 접근이 통제된 별도의 서버에 두고 있음
                <br />
                ㅤ- 개인정보 처리 시스템 접속기록의 보관 및 위조, 변조 방지를
                위한 조치를 취하고 있음
                <br />
                ㅤ- 고유식별정보 등의 암호화 처리
                <br />
                ㅤ- 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
                막기 위한 보안프로그램 설치 및 갱신
                <br />
                <br />
                <strong>8. 개인정보 보호책임자 작성</strong>
                <br />
                <br />
                주식회사 브랜치앤바운드는 개인정보 처리에 관한 업무를 총괄해서
                책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
                등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                정보주체께서는 서비스를 이용하시면서 발생한 모든 개인정보 보호
                관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
                보호책임자 에게 문의하실 수 있습니다. 주식회사 브랜치앤바운드는
                정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                <br />
                ㅤ- 성명 :이승용
                <br />
                ㅤ- 직책 :대표이사
                <br />
                ㅤ- 전화번호 :070-8098-6235
                <br />
                ㅤ- 전자우편 : codetree@codetree.ai
                <br />
                <br />
                <strong>9. 고지의 의무</strong>
                <br />
                <br />
                이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
                변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행
                7일 전부터 공지사항을 통하여 고지할 것입니다.
                <br />
                <br />
                <br />
                ○ 본 방침은 2021년 1월 28일부터 시행됩니다.
                <br />
                <br />
            </Typography>
        </Box>
    );
};
