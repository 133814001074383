import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import Editor from "@monaco-editor/react";
import { LangToCodeMirrorMode } from "../../../../../../common/modules/problems/ProblemModel";
import { useKeyDownRef } from "../../../../../../utils/ref";
import { EditorHeader } from "../../../../Missions/Playground/templates/editor/templates/EditorHeader";

export const EditorTemplate = ({
    monacoTheme,
    handleModeChange,
    defaultLanguage,
    handleLanguageAPIChange,
    handleSubmit,
    disabled,
    fiddle,
}) => {
    const isLightTheme = monacoTheme === "light";

    const preventKeyDown = (e) => {
        if (e.key === "[") {
            e.stopPropagation();
        }
    };

    const editorRef = useRef(null);
    const CodeMirrorRef = useKeyDownRef(preventKeyDown);

    const [language, setLanguage] = useState(defaultLanguage);

    const LocalCodeKey = `fiddle-${fiddle && fiddle.key}-${language}`;

    const [code, setCode] = useState(localStorage.getItem(LocalCodeKey) || "");

    const isCodeBlock = true;

    useEffect(() => {
        setCode(
            localStorage.getItem(LocalCodeKey) ||
                fiddle.defaultCodes.find(
                    (defaultCode) => defaultCode.language === language
                )?.code
        );
    }, [fiddle, language]);

    const handleCodeChange = (value) => {
        localStorage.setItem(LocalCodeKey, value);
        setCode(value);
    };

    const handleLanguageChange = (language) => {
        handleLanguageAPIChange(language);
        setLanguage(language);
    };

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;
    };

    const handleEditorWillMount = (monaco) => {
        import("monaco-themes/themes/Monokai.json")
            .then((data) => {
                monaco.editor.defineTheme("monokai", data);
            })
            .then((_) => {
                if (isLightTheme) {
                    return;
                }
                monaco.editor.setTheme("monokai");
            });
    };

    const validateAndSubmit = (_code, _language) => {
        handleSubmit({ code: _code, language: _language });
    };

    const submitDisabled = disabled;

    const handleReset = () => {
        setCode(
            fiddle.defaultCodes.find(
                (defaultCode) => defaultCode.language === language
            )?.code
        );
    };

    return (
        <Stack
            direction="column"
            bgcolor="background.default"
            height={"100%"}
            sx={(theme) => ({
                overflow: "hidden",
                mx: {
                    md: 0,
                    xs: 1,
                },
                my: {
                    md: 0,
                    xs: 1,
                },
                border: {
                    md: "none",
                    xs: `1px solid ${theme.palette.common.black}`,
                },
            })}
        >
            <EditorHeader
                isLightTheme={isLightTheme}
                handleLanguageChange={handleLanguageChange}
                handleModeChange={handleModeChange}
                language={language}
                isCodeBlock={isCodeBlock}
                handleReset={handleReset}
            />
            <Box ref={CodeMirrorRef} flex="1 1 100%" minHeight={0}>
                <Editor
                    language={LangToCodeMirrorMode[language]}
                    theme={monacoTheme}
                    value={code}
                    onChange={handleCodeChange}
                    options={{
                        fontSize: 14,
                        minimap: {
                            enabled: false,
                        },
                        cursorSurroundingLines: false,
                        renderLineHighlight: "none",
                    }}
                    beforeMount={handleEditorWillMount}
                    onMount={handleEditorDidMount}
                />
            </Box>
            <Stack direction="column" flex="1 1 0%">
                <Stack
                    direction="row"
                    flexGrow={0}
                    pr={2}
                    justifyContent="space-between"
                    sx={{
                        bgcolor: "background.default",
                        borderTop: `1px solid ${
                            isLightTheme ? "#F1F1F7" : "#41444A"
                        }`,
                    }}
                >
                    <Stack direction="row"></Stack>
                    <Stack direction="row" my={1.125} spacing={1}>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => validateAndSubmit(code, language)}
                            disabled={submitDisabled}
                        >
                            제출 및 채점
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
