import React from "react";
import { Chip, Stack, Tooltip, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { CuratedProblemStatusIcon } from "../../../Missions/templates/CuratedProblemStatusIcon";
import { TimerOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { ProblemStatusIcon } from "../../../../../common/templates/ProblemStatusIcon";
import { getProblemTypeToLabel } from "../../../../../common/modules/missions/curatedProblem/CuratedProblemModel";

export const StudyUnitBox = ({ homework, studyUnit }) => {
    const navigate = useNavigate();

    const navigateToProblemPage = (number) => {
        navigate(`/homeworks/${homework.id}/${number}`);
    };

    const getInputScore = (curatedProblem) => {
        const idx = homework.curatedProblems.findIndex(
            ({ curatedProblemId }) =>
                Number(curatedProblemId) ===
                Number(curatedProblem.curatedProblemId)
        );
        return idx === -1 ? 0 : homework.curatedProblems[idx].score;
    };
    const getInputSolutionVisible = (curatedProblem) => {
        const idx = homework.curatedProblems.findIndex(
            ({ curatedProblemId }) =>
                Number(curatedProblemId) ===
                Number(curatedProblem.curatedProblemId)
        );
        return idx === -1
            ? false
            : homework.curatedProblems[idx].solutionVisible;
    };

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="column" spacing={1} py={2}>
                <Typography
                    variant="subtitle1"
                    color="#1A1A1A"
                    children={studyUnit.name}
                />
                <Typography
                    variant="body2"
                    color="#1A1A1A"
                    children={studyUnit.description}
                />
            </Stack>
            <Stack direction="column" spacing={0.5}>
                {studyUnit.curatedProblems.map((curatedProblem, index) => {
                    const currProblemType =
                        getProblemTypeToLabel(curatedProblem);

                    const prevProblemType = getProblemTypeToLabel(
                        studyUnit.curatedProblems[index - 1]
                    );
                    return (
                        <Stack
                            key={`curated-problem-${curatedProblem.id}`}
                            direction="row"
                            // px={2.5}
                            // py={2}
                            alignItems={"center"}
                            onClick={() =>
                                navigateToProblemPage(curatedProblem.number)
                            }
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            {/* <CuratedProblemStatusIcon
                            curatedProblem={curatedProblem}
                        /> */}

                            <Stack
                                direction={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                alignSelf={"stretch"}
                                sx={{
                                    width: 50,
                                    position: "relative",
                                    // mr: isDense ? 1 : 3,
                                    mr: 3,
                                }}
                            >
                                {!index ||
                                currProblemType !== prevProblemType ? (
                                    <>
                                        <Typography variant={"caption"}>
                                            {currProblemType}
                                        </Typography>
                                        {index !==
                                        studyUnit.curatedProblems.length - 1 ? (
                                            <Box
                                                width={"0.5px"}
                                                height={"calc(30%)"}
                                                position={"absolute"}
                                                bottom={-10}
                                                sx={[
                                                    {
                                                        backgroundColor:
                                                            "#666666",
                                                        transition:
                                                            "background-color 0.3s",
                                                    },
                                                ]}
                                            />
                                        ) : null}
                                    </>
                                ) : (
                                    <Box
                                        width={"0.5px"}
                                        height={"calc(100% + 16px)"}
                                        position={"absolute"}
                                        bottom={-10}
                                        sx={[
                                            {
                                                backgroundColor: "#666666",
                                                transition:
                                                    "background-color 0.3s",
                                            },
                                        ]}
                                    />
                                )}
                            </Stack>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                px={2}
                                py={2}
                                flex={1}
                            >
                                <ProblemStatusIcon
                                    curatedProblem={curatedProblem}
                                />
                                <Typography
                                    ml={2}
                                    variant="body2"
                                    color="#1A1A1A"
                                    children={curatedProblem.name}
                                    flex={1}
                                />
                                <Stack direction={"row"} spacing={2}>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                    >
                                        <TimerOutlined
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        />
                                        <Typography
                                            variant={"caption"}
                                            children={`${
                                                !curatedProblem.solvedMinute ||
                                                curatedProblem.solvedMinute >
                                                    180
                                                    ? "180+"
                                                    : curatedProblem.solvedMinute
                                            }${"분"}`}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={"row"}>
                                        <Chip
                                            label={`${getInputScore(
                                                curatedProblem
                                            )}점`}
                                        />
                                        <Tooltip
                                            title={`학생들이 해설을 볼 수 ${
                                                getInputSolutionVisible(
                                                    curatedProblem
                                                )
                                                    ? `있습니다.`
                                                    : `없습니다.`
                                            }`}
                                        >
                                            {getInputSolutionVisible(
                                                curatedProblem
                                            ) ? (
                                                <Visibility
                                                    sx={{ color: "#959595" }}
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    sx={{ color: "#959595" }}
                                                />
                                            )}
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
