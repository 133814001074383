import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";
import { DarkTheme, DefaultTheme } from "../../../../../../style/theme";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../../common/modules/useStores";
import _ from "lodash";
import { UserProfileDAOModel } from "../../../../../../common/modules/auth/UserModel";
import { LanguageType } from "../../../../../../common/modules/problems/ProblemModel";
import { SubmissionDAOModel } from "../../../../../../common/modules/submission/SubmissionModel";
import { EditorTemplate } from "./EditorTemplate";
import { getLoginPageUrlFromThisLocation } from "../../../../../../utils/url";
import { useNavigate } from "react-router-dom";

export const EditorContainer = observer(({ fiddle, navigateToSubmissions }) => {
    const { globalStateStore, authStore, submissionStore, fiddleStore } =
        useStores();
    const { setMonacoTheme, monacoTheme } = globalStateStore;
    const { send, isExecuting } = submissionStore;
    const { currUser } = authStore;
    const isLightTheme = monacoTheme === "light";

    const navigate = useNavigate();

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleModeChange = () => {
        setMonacoTheme(isLightTheme ? "monokai" : "light");
    };

    const handleLanguageAPIChange = _.debounce((language) => {
        if (authStore.isUserLoggedIn()) {
            const body = new UserProfileDAOModel({ language });
            authStore.updateProfile({ body });
        }
    }, 1000);

    const handleSubmit = ({ code, language }) => {
        if (!authStore.isUserLoggedIn()) {
            navigate(getLoginPageUrlFromThisLocation());
            return;
        }

        fiddleStore
            .checkFiddle({ fiddleId: fiddle.id, fiddleKey: fiddle.key })
            .then((check) => {
                if (!check) {
                    alert("퀴즈가 업데이트되어서 새로고침합니다.");
                    location.reload();
                    return;
                }

                const body = new SubmissionDAOModel({
                    code,
                    language,
                    contentTypeName: "fiddle",
                    objectId: fiddle.id,
                });

                submissionStore.setPage(0);
                navigateToSubmissions();

                send({ body });
                setDisabled(true);
                setTimeout(() => {
                    setDisabled(false);
                }, 5000);
            });
    };

    return (
        <ThemeProvider theme={isLightTheme ? DefaultTheme : DarkTheme}>
            <LoadingOverlay
                active={loading}
                sx={{
                    height: "100%",
                    width: "auto",
                    display: "flex",
                    flex: {
                        md: "10 1 0%",
                        xs: "0 0 100%",
                    },
                    overflow: "hidden",
                    color: "#FFFFFF",
                }}
            >
                <EditorTemplate
                    handleModeChange={handleModeChange}
                    monacoTheme={monacoTheme}
                    handleLanguageAPIChange={handleLanguageAPIChange}
                    defaultLanguage={
                        currUser ? currUser.language : LanguageType.Python3
                    }
                    fiddle={fiddle}
                    handleSubmit={handleSubmit}
                    isExecuting={isExecuting}
                    disabled={disabled}
                    testCase={fiddle.testCases.length && fiddle.testCases[0]}
                    loading={loading}
                />
            </LoadingOverlay>
        </ThemeProvider>
    );
});
