import { PlayCircleFilled } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

// Import Swiper styles
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

export const FAQList = ({ FAQ, selectedFAQ, onClick, swipe }) => {
    // "기타 문의 사항은 어디에 말해야 할까요?"
    return swipe ? (
        <Swiper
            modules={[Navigation]}
            navigation={{
                prevEl: ".prev",
                nextEl: ".next",
            }}
            grabCursor
            style={{
                overflow: "visible",
            }}
            slidesPerView={1}
            spaceBetween={10}
            width={200}
        >
            {FAQ.map(({ key, label }) => (
                <SwiperSlide key={`buttons-${key}`}>
                    <Button
                        sx={{
                            width: "100%",
                            height: 120,
                            p: 16 / 8,
                            borderRadius: 8 / 4,
                            px: 2.25,
                            py: 2,
                            color: `${
                                selectedFAQ === key ? `#18A0FB` : `#000000`
                            }`,
                            bgcolor: "#ffffff",
                            border: `2px solid ${
                                selectedFAQ === key ? `#18A0FB` : `#e1e1e7`
                            }`,
                            justifyContent: "flex-start",
                            flexDirection: "column",
                        }}
                        onClick={() => onClick(key)}
                    >
                        <Typography flex={1} textAlign={"left"} variant="body1">
                            {label}
                        </Typography>
                        <PlayCircleFilled
                            sx={{
                                alignSelf: "flex-end",
                            }}
                        />
                    </Button>
                </SwiperSlide>
            ))}
        </Swiper>
    ) : (
        <Stack spacing={1.25}>
            {FAQ.map(({ key, label }) => (
                <Button
                    key={`buttons-${key}`}
                    sx={{
                        p: 16 / 8,
                        borderRadius: 8 / 4,
                        px: 2.25,
                        py: 2,
                        color: `${selectedFAQ === key ? `#18A0FB` : `#000000`}`,
                        bgcolor: "#ffffff",
                        border: `2px solid ${
                            selectedFAQ === key ? `#18A0FB` : `#e1e1e7`
                        }`,
                        justifyContent: "flex-start",
                    }}
                    onClick={() => onClick(key)}
                >
                    <Typography
                        flex={1}
                        textAlign={"left"}
                        variant="body1"
                        mr={1}
                    >
                        {label}
                    </Typography>
                    <PlayCircleFilled />
                </Button>
            ))}
        </Stack>
    );
};
