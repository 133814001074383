import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingOverlay } from "../../../../common/templates/LoadingOverlay";
import { useStores } from "../../../../common/modules/useStores";
import { observer } from "mobx-react-lite";
import ChannelService from "../../../../common/components/channel/ChannelService";
import { getMissionAndProblemId } from "../../../../utils/string";
import { HomeworksProblemTemplate } from "./HomeworksProblemTemplate";
import { ProblemStatusType } from "../../../../common/modules/missions/curatedProblem/CuratedProblemModel";
import moment from "moment";

export const HomeworksProblemContainer = observer(() => {
    const { homeworkId, problemNumber } = useParams();

    const {
        homeworkStore,
        curatedProblemStore,
        problemStore,
        submissionStore,
    } = useStores();

    const {
        find: findHomework,
        homework,
        changeToPrevProblem,
        changeToTreeProblem,
        changeToNextProblem,
        currProblem,
    } = homeworkStore;
    const { curatedProblem } = curatedProblemStore;
    const { descriptionModel, getDescription } = problemStore;

    useEffect(() => {
        ChannelService.shutdown();

        return () => {
            ChannelService.boot({
                pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
            });
        };
    }, []);

    useEffect(() => {
        if (Number(homework && homework.id) !== Number(homeworkId)) {
            findHomework({
                homeworkId: Number(homeworkId),
            });
        }
    }, [homeworkId]);

    useEffect(() => {
        const { problemId } = getMissionAndProblemId(problemNumber);
        if (
            homework &&
            homeworkStore.problemExist({ problemId: Number(problemId) }) &&
            Number(currProblem && currProblem.number) !== Number(problemNumber)
        ) {
            changeToTreeProblem({ problemId: Number(problemId) });
        }
    }, [homework, problemNumber]);

    useEffect(() => {
        const { missionId, problemId } = getMissionAndProblemId(problemNumber);
        getDescription({ problemId: Number(problemId) });
        curatedProblemStore.findDetail({
            problemId,
            missionId,
        });
    }, [problemNumber]);

    useEffect(() => {
        if (curatedProblem && curatedProblem.id) {
            submissionStore.open({ problemId: curatedProblem.id });
            submissionStore.executeOpen();
        }

        return () => {
            submissionStore.close();
            submissionStore.executeClose();
        };
    }, [curatedProblem]);

    const { missionId, problemId } = getMissionAndProblemId(problemNumber);

    const loading =
        Number(problemId) !== Number(curatedProblem && curatedProblem.id) ||
        Number(problemNumber) !== Number(currProblem && currProblem.number) ||
        (homework && homework.id) !== Number(homeworkId) ||
        descriptionModel.length === 0;

    if (loading)
        return (
            <LoadingOverlay active={loading} text="문제를 불러오는 중입니다." />
        );

    return (
        <HomeworksProblemTemplate
            missionTree={{
                id: homeworkId,
                name: homework.name,
                progress: parseInt(
                    (homework.curatedProblems.filter(
                        ({ progressStatus }) =>
                            progressStatus === ProblemStatusType.Passed
                    ).length /
                        homework.curatedProblems.length) *
                        100
                ),
                children: homework.curatedProblems,
            }}
            submitUntil={
                homework.isSubBlockedAfterFinish
                    ? moment(homework.finishedAt)
                    : moment().add(10, "y")
            }
            objectId={missionId}
            problem={curatedProblem}
            curatedProblem={currProblem}
            solutionVisible={currProblem.solutionVisible || homework.isOwner}
            aiEnabled={currProblem.aiEnabled || homework.isOwner}
            discussionVisible={!homework.isDiscussionHide}
            changeToPrevProblem={changeToPrevProblem}
            changeToNextProblem={changeToNextProblem}
            changeToTreeProblem={changeToTreeProblem}
            treeUpdate={() => {}}
        />
    );
});
