import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { SubmissionsTemplate } from "./SubmissionsTemplate";
import { useStores } from "../../../../../../common/modules/useStores";
import { LoadingOverlay } from "../../../../../../common/templates/LoadingOverlay";
import { NoResultsTemplate } from "../NoResultsTemplate";
import {
    ProblemClickDAOModel,
    ProblemClickEvent,
} from "../../../../../../common/modules/problems/ProblemModel";

export const SubmissionsContainer = observer(({ problem }) => {
    const { submissionStore, problemStore } = useStores();
    const {
        submissions,
        isFetching,
        page,
        rowsPerPage,
        count,
        setPage,
        setRowsPerPage,
    } = submissionStore;

    useEffect(() => {
        setPage(0);
    }, [problem]);

    useEffect(() => {
        const body = new ProblemClickDAOModel({
            event: ProblemClickEvent.Submissions,
            problem: problem.id,
        });
        problemStore.addClickEvent({ body });
        submissionStore.findAllByProblem({
            problemId: problem.id,
        });
    }, [problem, page, rowsPerPage]);

    if (isFetching)
        return (
            <LoadingOverlay
                active={isFetching}
                text="제출 기록을 불러오는 중입니다."
            />
        );

    if (!(submissions && submissions.length)) {
        return <NoResultsTemplate text="제출 기록이 존재하지 않습니다." />;
    }

    return (
        <SubmissionsTemplate
            submissions={submissions}
            rowsPerPage={rowsPerPage}
            page={page}
            count={count}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
});
