import React from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { MainTemplateWrapper } from "../../../common/templates/MainTemplateWrapper";
import { Header } from "./templates/Header";
import { CatalogList } from "./templates/CatalogList";
import { PopUpGrid } from "./templates/PopUpGrid";
import { PopUpSwiper } from "./templates/PopUpSwiper";

export const CurriculumsTemplate = ({
    missions,
    latestSolvedProblemSet,
    isLogin,
    username,
}) => {
    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    return (
        <Box
            width="100%"
            sx={{
                overflowX: "hidden",
            }}
        >
            <MainTemplateWrapper>
                <Stack
                    direction="column"
                    spacing={{
                        xs: 5,
                        md: 10,
                    }}
                    my={10}
                >
                    <Header
                        latestSolvedProblemSet={latestSolvedProblemSet}
                        isLogin={isLogin}
                        username={username}
                    />
                    <CatalogList missions={missions} />
                    {smBreakpoint ? <PopUpGrid /> : <PopUpSwiper />}
                </Stack>
            </MainTemplateWrapper>
        </Box>
    );
};
