import React from "react";
import { Box } from "@mui/material";
import {
    BookColorType,
    BookThemeType,
    SizeType,
} from "../../modules/ui/UIModel";

export const MissionBook = ({ color, size, theme, sx, ...props }) => {
    const sizeDict = {
        [SizeType.Small]: {
            width: 60,
            height: 84,
            borderRadius: "2.6px 5.3px 5.3px 2.6px",
            shadow: (_theme) =>
                `https://contents.codetree.ai/homepage/images/templates/books/small_shadow_${_theme}.png`,
        },
        [SizeType.Medium]: {
            width: 90,
            height: 126,
            borderRadius: "4px 8px 8px 4px",
            shadow: (_theme) =>
                `https://contents.codetree.ai/homepage/images/templates/books/default_shadow_${_theme}.png`,
        },
        [SizeType.Large]: {
            width: 135,
            height: 189,
            borderRadius: "6px 12px 12px 6px",
            shadow: (_theme) =>
                `https://contents.codetree.ai/homepage/images/templates/books/large_shadow_${_theme}.png`,
        },
    };

    const colorDict = {
        [BookColorType.Yellow]: {
            backgroundColor: "#FFC433",
            lineColor: "#DEA61D",
        },
        [BookColorType.Blue]: {
            backgroundColor: "#1A64D3",
            lineColor: "#114FAC",
        },
        [BookColorType.Green]: {
            backgroundColor: "#6B9362",
            lineColor: "#3A5A33",
        },
        [BookColorType.Purple]: {
            backgroundColor: "#763568",
            lineColor: "#964985",
        },
        [BookColorType.Red]: {
            backgroundColor: "#D24D57",
            lineColor: "#AE2B35",
        },
        [BookColorType.Indigo]: {
            backgroundColor: "#264348",
            lineColor: "#5D7377",
        },
    };

    return (
        <Box
            position="relative"
            width={sizeDict[size].width}
            height={sizeDict[size].height}
            borderRadius={sizeDict[size].borderRadius}
            backgroundColor={colorDict[color].backgroundColor}
            sx={{
                userSelect: "none",
                pointerEvents: "none",
                ...sx,
            }}
            {...props}
        >
            <Box
                position="absolute"
                top={0}
                width="100%"
                height="100%"
                borderRadius={sizeDict[size].borderRadius}
                sx={{
                    background:
                        "linear-gradient(333.23deg, rgba(37, 37, 37, 0.05) 22.31%, rgba(37, 37, 37, 0) 57.6%, rgba(37, 37, 37, 0) 78.87%)",
                }}
            />
            <Box
                position="absolute"
                top={0}
                width="100%"
                height="100%"
                borderRadius={sizeDict[size].borderRadius}
                sx={{
                    background:
                        'url("https://contents.codetree.ai/' +
                        'homepage/images/landing_page/texture.png")',
                    mixBlendMode: "color-burn",
                }}
            />
            <Box
                height="102%"
                width={`${sizeDict[size].width * 0.0625}px`}
                borderRadius="1px"
                backgroundColor={colorDict[color].lineColor}
                marginLeft={`${sizeDict[size].width * 0.775}px`}
                position="relative"
                top="-1%"
                zIndex={1}
            />
            <img
                style={{
                    width: sizeDict[size].width * 3,
                    height: sizeDict[size].height + 100,
                    position: "absolute",
                    top: -50,
                    left: sizeDict[size].width * -1,
                }}
                src={sizeDict[size].shadow(theme)}
            />
        </Box>
    );
};

MissionBook.defaultProps = {
    size: SizeType.Medium,
    color: BookColorType.Yellow,
    theme: BookThemeType.Light,
};
