import { snakelizeKeys } from "../../../../../utils/renameKeys";
import { objectToQueryString } from "../../../../../utils/immutableLodash";
import { GET } from "../../../../../utils/api";

class MissionProgressRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll(params) {
        const snakelizedParams = snakelizeKeys(params);
        const queryParams = objectToQueryString(snakelizedParams);
        return GET(`mission_progresses/${queryParams}`);
    }
}

export default new MissionProgressRepository();
