import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FAQContainer } from "./FAQContainer";

export const FAQPage = () => {
    const FAQ = [
        { key: "create_class", label: "학급을 만들고 싶어요." },
        { key: "create_hw", label: "문제를 골라서 수업에 활용하고 싶어요." },
        { key: "copy_hw", label: "만든 수업/과제를 복제하고 싶어요." },
        { key: "create_quiz", label: "학생들의 흥미를 끌고 싶어요(퀴즈)" },
        { key: "enroll_class", label: "학생 학급 신청은 어떻게 하나요." },
        { key: "accept_apply", label: "학급 신청 승인은 어떻게 하나요." },
        {
            key: "submissions",
            label: "학급 학생들의 제출 기록을 확인하고 싶어요.",
        },
    ];

    return (
        <Routes>
            <Route
                path={"/"}
                exact
                element={<Navigate to={`/help/faq/${FAQ[0].key}`} replace />}
            />
            <Route path={":faqKey"} element={<FAQContainer FAQ={FAQ} />} />
        </Routes>
    );
};
